import React from "react";
import { useLocation } from "react-router-dom";
import ArrestDetailInfo  from "../../components/Detail/_ArrestDetailInfo";
import {SiteName} from "../../services/config.service";
import {SITE} from "../../interfaces/config.interface";
import M43ArrestDetailInfo from "../../components/Detail/_M43_ArrestDetailInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

  
function ArrestDetail() {
    let query = useQuery();

    return (
        <div>
            {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ? 
                <M43ArrestDetailInfo ID={query.get("ID")} department={query.get("Dept")} /> :
                <ArrestDetailInfo ID={query.get("ID")} department={query.get("Dept")}/>
            }
        </div>
    );
}

export default ArrestDetail;