import React, {useState} from "react";
import ReactECharts from "echarts-for-react";
import {SiteName} from "../../../services/config.service";
import {SITE} from "../../../interfaces/config.interface";

interface StolenVehiclesProps {
    data:any,
    tab:string,
    city:string,
    getAbbreviated:any
}


const RecoveredVehiclesDashboardGrid: React.FC<StolenVehiclesProps> = ({data, tab, city, getAbbreviated}) => {
    var option;

    const handleFormatXAxis = () => {
        let xAxis:any = []
        switch(tab){
            case '1':
                if('RecoveredVehicles' in data && Array.isArray(data.RecoveredVehicles))
                {
                    for(let d of data.RecoveredVehicles)
                    {
                        if(city === 'ALL CITIES'){
                            if((SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && data.RecoveredVehicles.length > 2)
                                xAxis.push(getAbbreviated(d.County?.toUpperCase()))
                            else
                                xAxis.push(d.County?.toUpperCase())
                        }
                        else {
                            xAxis.push( d.City ? d.City?.toUpperCase() : d.County?.toUpperCase())
                        }
                    }
                }
                break;
            case '2':
                if('RecoveredPlates' in data && Array.isArray(data.RecoveredPlates))
                {
                    for(let d of data.RecoveredVehicles)
                    {
                        if(city === 'ALL CITIES'){
                            if((SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && data.RecoveredVehicles.length > 2)
                                xAxis.push(getAbbreviated(d.County?.toUpperCase()))
                            else
                                xAxis.push(d.County?.toUpperCase())
                        }
                        else {
                            xAxis.push( d.City ? d.City?.toUpperCase() : d.County?.toUpperCase())
                        }
                    }
                }
                break;
            case '3':
                if('RecoveredPlates' in data && Array.isArray(data.RecoveredPlates) && 'RecoveredVehicles' in data && Array.isArray(data.RecoveredVehicles))
                {
                    let counties = []
                    for(let d of data.RecoveredVehicles)
                    {
                        counties.push(d.County?.toUpperCase())
                    }
                    for(let d of data.RecoveredPlates)
                    {
                        if(counties.findIndex((e:any) => e?.toUpperCase() === d.County?.toUpperCase()) === -1)
                            counties.push(d.County?.toUpperCase())
                    }
                    if((SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && counties.length > 2){
                        for(let i = 0; i < counties.length; i++){
                            counties[i] = getAbbreviated(counties[i])
                        }
                    }
                    if(city === 'ALL CITIES' || counties.length === 0){
                        xAxis = counties
                    }
                    else {
                        xAxis.push( counties[0].City ? counties[0].City?.toUpperCase() : counties[0].County?.toUpperCase())
                    }
                }
                break;
        }
        
        return xAxis
    }

    const handleSeries = () => {
        let recovered:any = []
        let recoveredP:any = []
        let xAxis = handleFormatXAxis()
        let series:any = []

        switch(tab){
            case '1':
                if('RecoveredVehicles' in data && Array.isArray(data.RecoveredVehicles))
                {
                    for(let d of data.RecoveredVehicles)
                    {
                        recovered.push(d.Count)
                    }
                    series.push({
                        type: 'bar',
                        color:'#1e3a8a',
                        emphasis: {
                            focus: 'series'
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            rotate:20,
                            fontSize:10,
                        },
                        data: recovered
                    },)
                }
                break;
            case '2':
                if('RecoveredPlates' in data && Array.isArray(data.RecoveredPlates))
                {
                    for(let d of data.RecoveredPlates)
                    {
                        recoveredP.push(d.Count)
                    }
                    series.push({
                        type: 'bar',
                        color:'#1e3a8a',
                        emphasis: {
                            focus: 'series'
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            rotate:20,
                            fontSize:11,
                        },
                        data: recoveredP
                    },)
                }
                break;
            case '3':
                if('RecoveredPlates' in data && Array.isArray(data.RecoveredPlates)  && 'RecoveredVehicles' in data && Array.isArray(data.RecoveredVehicles))
                {
                    if(city === 'ALL CITIES') {
                        for (let temp of xAxis) {
                            recovered.push(0)
                            recoveredP.push(0)
                        }
                        for (let d of data.RecoveredVehicles) {
                            let index = -1
                            if((SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && xAxis.length > 2)
                                index = xAxis.findIndex((e: any) => e?.toUpperCase() === getAbbreviated(d?.County?.toUpperCase()))
                            else
                                index = xAxis.findIndex((e: any) => e?.toUpperCase() === d.County?.toUpperCase())
                            if (index !== -1)
                                recovered[index] = d.Count
                        }
                        for (let d of data.RecoveredPlates) {
                            let index = -1
                            if((SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && xAxis.length > 2)
                                index = xAxis.findIndex((e: any) => e?.toUpperCase() === getAbbreviated(d?.County?.toUpperCase()))
                            else
                                index = xAxis.findIndex((e: any) => e?.toUpperCase() === d.County?.toUpperCase())
                            if (index !== -1)
                                recoveredP[index] = d.Count
                        }
                    }
                    else{
                        for (let d of data.StolenVehicles) {
                            recovered.push(d.Count)

                        }
                        for (let d of data.StolenPlates) {
                            recoveredP.push(d.Count)
                        } 
                    }
                    series.push({
                        name: 'Vehicles',
                        type: 'bar',
                        stack: 'total',
                        color:'#1e3a8a',
                        emphasis: {
                            focus: 'series'
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            rotate:20,
                            fontSize:11,
                        },
                        data: recovered
                    },)
                    series.push({
                        name: 'Plates',
                        type: 'bar',
                        stack: 'total',
                        color:'#8E1600',
                        emphasis: {
                            focus: 'series'
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            rotate:20,
                            fontSize:11,
                        },
                        data: recoveredP
                    },)
                }
                break;
        }
        
        return series
    }

    option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        height:'180',
        width:'95%',
        toolbox: {
            feature: {
                saveAsImage: { show: true }
            }
        },
        legend: {
        },
        grid: {
            left: '1%',
            right: '1%',
            bottom: '1%',
            top:'3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval:0,
                rotate:(handleFormatXAxis().length <= 2 ? 0 : 45),
                fontSize:8,
            },
            data: handleFormatXAxis(),
        },
        yAxis: {
            type: 'value'
        },
        series: handleSeries()
    };

    return (
        <>
            {data && (data?.RecoveredVehicles?.length !== 0 || data?.RecoveredPlates?.length !== 0)  ?
                <div>
                    <ReactECharts option={option} style={{height:'200px'}}/>
                </div>
                :
                <div className={"text-center text-3xl mt-6 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
            }
        </>
    );
}

export default RecoveredVehiclesDashboardGrid;