import React, {useState, useEffect} from "react";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import {convertUTCtoLocalTimeRange, formatDate} from "../../../services/formatDate.service";
import BTPersonGridImage from "../../shared/_PersonGridImage";
import {PersonInfo} from "../../../interfaces/getDarInfo_interface";


interface DetailProps {
    rpt: any | null
}

const M43FieldContactReport: React.FC<DetailProps> = ({rpt}) => {
    
    const PersonImg = (person:any) => {
        let pQuery : any = {
            DOB: person?.dateOfBirth,
            FBI:  person?.fbiNumber,
            SBINo:  person?.stateIdNumber,
            FName:  person?.firstName,
            MName:  person?.middleName,
            LName:  person?.lastName

        }

        return (
            <BTPersonGridImage person={pQuery} />
        )
    }
    
    return (
        <div className="bg-white p-6 max-w-4xl mx-auto shadow-lg border border-gray-300 mt-10 ">
            <div className="bg-blue-900 text-white p-2">
                <h1 className="text-xl font-bold">CCN# {rpt.reportNumber} / FIELD CONTACT # {rpt.recordNumber}</h1>
            </div>
            <div className="grid grid-cols-3">
                <div className="border-r border-b border-gray-300 pl-2 ">
                    <p className="font-bold text-xs	">REPORT DATE / TIME</p>
                    <p> {convertUTCtoLocalTimeRange(rpt.createdDateUtc, null)}</p>
                </div>
                <div className="border-r border-b border-gray-300  pl-2">
                    <p className="font-bold text-xs	">REPORT OFFICER</p>
                    <p>{rpt?.respondingOfficer?.lastName}, {rpt?.respondingOfficer?.firstName} {rpt?.respondingOfficer?.middleName}&nbsp;</p>
                </div>
                <div className="border-b border-gray-300  pl-2">
                    <p className="font-bold text-xs">EVENT START DATE / TIME - EVENT END DATE / TIME</p>
                    <p>{convertUTCtoLocalTimeRange(rpt.eventStartUtc, rpt.eventEndUtc)}</p>
                </div>
            </div>


            <div className="border-b border-gray-300">
                <h2 className="font-bold text-base bg-blue-900 text-white p-2">NARRATIVE</h2>
                <p style={{overflow: 'auto'}}>
                    <pre className="text-xs" style={{whiteSpace: 'pre-wrap'}}>{rpt.narrative}</pre>
                </p>
            </div>


            {rpt?.reportingPersons && rpt?.reportingPersons?.map((person: any, idx: number) => (
                <div className="border-b border-gray-300">
                    <h2 className=" text-xs bg-blue-900 text-white p-2">REPORTING PERSON - {idx + 1}</h2>

                    <div className="grid grid-cols-6">
                        <div>{PersonImg(person)}&nbsp;</div>
                        <div className={"col-span-5"}>
                            <div className="grid grid-cols-2">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">NAME (LAST, FIRST
                                        MIDDLE)</p>
                                    <p>{person?.lastName}, {person?.firstName} {person?.middleName}&nbsp;</p>
                                </div>
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">Date Of Birth</p>
                                    <p>{person?.dateOfBirth}&nbsp;</p>
                                </div>

                            </div>

                            <div className="grid grid-cols-5">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">PDID#</p>
                                    <p>{person?.stateIdNumber}&nbsp;</p>
                                </div>

                                <div className="border-b border-r border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">FBI#</p>
                                    <p>{person?.fbiNumber}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DL#</p>
                                    <p>{person?.licenseState} {person?.licenseNumber}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">SSN#</p>
                                    <p>{person?.ssn}&nbsp;</p>
                                </div>

                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">Phone#</p>
                                    <p>{person?.phoneNumber}&nbsp;</p>
                                </div>
                            </div>

                            <div className="grid grid-cols-4">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">SEX</p>
                                    <p>{person?.sexDisplayName}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">EYE COLOR</p>
                                    <p>{person?.eyeColorDisplayName}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">RACE</p>
                                    <p>{person?.raceDisplayName}&nbsp;</p>
                                </div>
                                <div className=" border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">ETHNICITY</p>
                                    <p>{person?.ethnicityDisplayName}&nbsp;</p>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="grid grid-cols-1">
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">HOME ADDRESS</p>
                            <p>{person?.homeAddress?.streetNumber} {person?.homeAddress?.streetName}</p>
                        </div>

                    </div>
                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">CITY</p>
                            <p>{person?.homeAddress?.city}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">STATE</p>
                            <p>{person?.homeAddress?.state}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">ZIP</p>
                            <p>{person?.homeAddress?.zip}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">COUNTRY</p>
                            <p>{person?.homeAddress?.country}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INTERSECTION STREET 1</p>
                            <p>{person?.homeAddress?.crossStreet1}&nbsp;</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INTERSECTION STREET 2</p>
                            <p>{person?.homeAddress?.crossStreet2}&nbsp;</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-3">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">LOCATION CATEGORY</p>
                            <p>{person?.homeAddress?.category}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">DISTRICT / PSA</p>
                            <p>{person?.homeAddress?.subdivision1}/{person?.homeAddress?.subdivision2}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">PUBLIC / PRIVATE</p>
                            <p>{person?.homeAddress?.locationPropertyType}</p>
                        </div>
                    </div>

                </div>
            ))}

            {rpt?.externalFieldContact?.fieldContactLocations && rpt?.externalFieldContact?.fieldContactLocations?.map((location: any, idx: number) => (
                <div className="border-b border-gray-300">
                    <h2 className=" text-xs bg-blue-900 text-white p-2">LOCATION</h2>
                    <div className="grid grid-cols-1">
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">LOCATION NAME / STREET
                                ADDRESS</p>
                            <p>{location?.placeName} {location?.streetNumber} {location?.streetName} &nbsp;</p>
                        </div>

                    </div>
                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">CITY</p>
                            <p>{location?.city}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">STATE</p>
                            <p>{location?.state}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">ZIP</p>
                            <p>{location?.zip}&nbsp;</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">COUNTRY</p>
                            <p>{location?.country}&nbsp;</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INTERSECTION STREET 1</p>
                            <p>{location?.crossStreet1}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INTERSECTION STREET 2</p>
                            <p>{location?.crossStreet2}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	"> LATITUDE</p>
                            <p>{location?.latitude}&nbsp;</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">LONGITUDE</p>
                            <p>{location?.longitude}&nbsp;</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-3">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">LOCATION CATEGORY</p>
                            <p>{location?.category}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">DISTRICT / PSA</p>
                            <p>{location?.subdivision1}/{location?.subdivision2}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">PUBLIC / PRIVATE</p>
                            <p>{location?.locationPropertyType}</p>
                        </div>
                    </div>

                </div>
            ))}


            {rpt?.externalFieldContact?.fieldContactSubjects && rpt?.externalFieldContact?.fieldContactSubjects?.map((person: any, idx: number) => (
                <div className="border-b border-gray-300">
                    <h2 className=" text-xs bg-blue-900 text-white p-2">{person?.subjectPerson?.involvement ? person?.subjectPerson?.involvement?.replace('_IN_FIELD_CONTACT', '') : "OTHER"}</h2>

                    <div className="grid grid-cols-6">
                        <div>{PersonImg(person?.subjectPerson)}&nbsp;</div>
                        <div className={"col-span-5"}>
                            <div className="grid grid-cols-3">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">NAME (LAST, FIRST
                                        MIDDLE)</p>
                                    <p>{person?.subjectPerson?.lastName}{person?.subjectPerson?.firstName ? ',' : ''} {person?.subjectPerson?.firstName} {person?.subjectPerson?.middleName}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DATE OF BIRTH</p>
                                    <p>{person?.subjectPerson?.dateOfBirth}&nbsp;</p>
                                </div>
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DATE OF DEATH</p>
                                    <p>{formatDate(person?.subjectPerson?.dateOfDeath)}&nbsp;</p>
                                </div>

                            </div>
                            <div className="grid grid-cols-5">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">PDID#</p>
                                    <p>{person?.subjectPerson?.stateIdNumber}&nbsp;</p>
                                </div>

                                <div className="border-b border-r border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">FBI#</p>
                                    <p>{person?.subjectPerson?.fbiNumber}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DL#</p>
                                    <p>{person?.subjectPerson?.licenseState} {person?.subjectPerson?.licenseNumber}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">SSN#</p>
                                    <p>{person?.subjectPerson?.ssn}&nbsp;</p>
                                </div>

                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">Phone#</p>
                                    <p>{person?.subjectPerson?.phoneNumber}&nbsp;</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-4">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">SEX</p>
                                    <p>{person?.subjectPerson?.sexDisplayName}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">EYE COLOR</p>
                                    <p>{person?.subjectPerson?.eyeColorDisplayName}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">RACE</p>
                                    <p>{person?.subjectPerson?.raceDisplayName}&nbsp;</p>
                                </div>
                                <div className=" border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">ETHNICITY</p>
                                    <p>{person?.subjectPerson?.ethnicityDisplayName}&nbsp;</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    {person?.subjectPerson?.personInjuries && person?.subjectPerson?.personInjuries?.length > 0 && (
                        <div className="grid grid-cols-1">
                            <div className=" border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">INJURIES</p>
                                <p>
                                    <ul>
                                        {person?.subjectPerson?.personInjuries && person?.subjectPerson?.personInjuries?.map((injury: any) => (
                                            <li>{injury?.injuryTypeName}, {injury?.bodyPartName}, {injury?.injuryDescription} </li>
                                        ))}
                                    </ul>
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                    )}
                    {person?.subjectPerson?.identifyingMarks && person?.subjectPerson?.identifyingMarks?.length > 0 && (
                        <div className="grid grid-cols-1">
                            <div className=" border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">IDENTIFYING MARKS</p>
                                <p>
                                    <ul>
                                        {person?.subjectPerson?.identifyingMarks && person?.subjectPerson?.identifyingMarks?.map((marks: any) => (
                                            <li>{marks?.identifyingMarkType}, {marks?.identifyingMarkLocation}, {marks?.identifyingMarkDescription} </li>
                                        ))}
                                    </ul>
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                    )}

                    {person?.subjectPerson?.description && (<div className="grid grid-cols-1">
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">DESCRIPTION</p>
                            <p>
                                <pre>{person?.subjectPerson?.description}</pre>
                                &nbsp;</p>
                        </div>
                    </div>)}


                    <div className="grid grid-cols-1">
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">HOME ADDRESS</p>
                            <p>{person?.subjectPerson?.homeAddress?.streetNumber} {person?.subjectPerson?.homeAddress?.streetName}</p>
                        </div>

                    </div>
                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">CITY</p>
                            <p>{person?.subjectPerson?.homeAddress?.city}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">STATE</p>
                            <p>{person?.subjectPerson?.homeAddress?.state}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">ZIP</p>
                            <p>{person?.subjectPerson?.homeAddress?.zip}&nbsp;</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">COUNTRY</p>
                            <p>{person?.subjectPerson?.homeAddress?.country}&nbsp;</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INTERSECTION STREET 1</p>
                            <p>{person?.subjectPerson?.homeAddress?.crossStreet1}&nbsp;</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INTERSECTION STREET 2</p>
                            <p>{person?.subjectPerson?.homeAddress?.crossStreet2}&nbsp;</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">LOCATION CATEGORY</p>
                            <p>{person?.subjectPerson?.homeAddress?.category}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">DISTRICT / PSA</p>
                            <p>{person?.subjectPerson?.homeAddress?.subdivision1}/{person?.subjectPerson?.homeAddress?.subdivision2}</p>
                        </div>
                        <div className="border-b border-r border-gray-300 pl-2">
                            <p className="font-bold text-xs	">PUBLIC / PRIVATE</p>
                            <p>{person?.subjectPerson?.homeAddress?.locationPropertyType}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">Resident Status</p>
                            <p>{person?.subjectPerson?.residentStatus}&nbsp;</p>
                        </div>
                    </div>

                </div>
            ))}


        </div>
    )
}

export default M43FieldContactReport;