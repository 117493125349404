import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Button, CircularProgress} from "@mui/material";
import JailIcon from "../../../assets/Images/jail_door.png";

import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Inject,
    Page,
    Sort,
    Filter, SortSettingsModel,
} from '@syncfusion/ej2-react-grids';
import {JAOS} from "../../../services/JAOS.service";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";
import {SiteName} from "../../../services/config.service";

interface PanelJailProps {
    person: PersonInfo,
    JailInfo: any[],
    isLoading:boolean
}

const  PanelJail: React.FC<PanelJailProps> = ({person,JailInfo,isLoading})=> {

    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...JailInfo]
        for (let p of tempP) {
            if (p.COMMITMENT_DATETIME) {
                p.COMMITMENT_DATETIME = new Date(p.COMMITMENT_DATETIME)
            }
            if (p.RELEASE_DATETIME) {
                p.RELEASE_DATETIME = new Date(p.RELEASE_DATETIME)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [JailInfo])

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'COMMITMENT_DATETIME', direction: 'Descending' }
        ] };

    return(
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Jail {<img src={JailIcon} alt={''} width='30' style={{ display: 'inline' }} />} {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {JailInfo?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            
                            <GridComponent
                                dataSource={JailInfo}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                sortSettings={sortSettings}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='VICTIM_INVESTIGATION_AGENCY' headerText='AGENCY'/>
                                    <ColumnDirective field='COMMITMENT_DATETIME' headerText='COMMITMENT DATE' format={{ type: 'date', format: 'MM/dd/yyyy HH:mm' }} width={250}/>
                                    <ColumnDirective field='RELEASE_DATETIME' headerText='RELEASE DATE' format={{ type: 'date', format: 'MM/dd/yyyy HH:mm' }} width={180}/>
                                    <ColumnDirective field='RELEASE_REASON' headerText='RELEASE REASON' width={180}/>
                                    {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<ColumnDirective field='LOCATION_UNIT' headerText='UNIT'/> }
                                    {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<ColumnDirective field='LOCATION_SECTION' headerText='SECTION'/> }
                                    {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<ColumnDirective field='LOCATION_CELL' headerText='CELL'/> }
                                    <ColumnDirective field='INMATE_COUNTY' headerText={`${GetStringNameForSite(SiteName,'JURISDICTION')}`} width={160}/>
                                    <ColumnDirective template={(item: any) => (<Button onClick={() => { window.open('/detail/Jail?ID=' + new JAOS().objToStack(item) + '&Dept=', '_blank') }} >View</Button>)} headerText='View' />

                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelJail;