import React, {useEffect, useState} from "react";
import { Tab } from '@headlessui/react'
import PeopleSearch from "../../components/tools/main_dashboard/_peopleSearch";
import PlacesSearch from "../../components/tools/main_dashboard/_placesSearch";
import ThingSearch from "../../components/tools/main_dashboard/_thingSearch";
import SearchResults from "../../components/tools/main_dashboard/_searchResultsMain";
import {GetDarInfo_Query, PersonInfo, getDarInfoResponse} from "../../interfaces/getDarInfo_interface";
import {RunDarQuery} from "../../services/getDar.service";
import {Account} from "../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../services/auth.service";
import {handleSearchTime} from "../../services/formatDate.service";
import {NJSTAD_URL, SiteName} from "../../services/config.service";
import {SITE} from "../../interfaces/config.interface";

function classNames(...classes:string[]) {
    return classes.filter(Boolean).join(' ')
}

interface parmaData {
    params:any,
    radius?:any | null,
    latitude?:any | null,
    longitude?:any | null,
}

function MainDashboard() {
    let [darIntitalRun, setdarIntitalRun] = useState<boolean>(false)
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    let [initialDarTbl, setInitialDarTbl] = useState<PersonInfo[]>([])
    let [darTbl, setDarTbl] = useState<PersonInfo[]>([])
    let [secondaryTable, setSecondaryTable] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)            
            RedirectUser()
        }
    }, [initialRun]);

    const RedirectUser = async () => {
        switch (user.DeptAccountType)
        {
            case "ACCOUNTING":
                window.location.href = '/departments'
                return;
        }
        switch (user.AuthLevel) {
            case "D": 
                window.location.href = '/tools/codis_notification';
                return
            case "C":
            case "E":
            case "O":
            case "W":
                window.location.href = '/poi';  //show POI page as default for Civiliain, Agency Coordinator, County Coordinator, State Coordinator
                return;
        }
    }

    const LoadDarInitial = async () => {
        var today = new Date();
        today.setHours(5)
        today.setMinutes(59)
        today.setSeconds(59)
        var toDate = today
        var prevDate = new Date();
        var fromDate = prevDate
        
        
        if (new Date().getDay() === 1) {
            prevDate = new Date(prevDate.setDate(prevDate.getDate() - 2));
            fromDate = prevDate //Default - Previous weekend
        }
        if(new Date().getHours() < 6 && new Date().getDay() !== 1)
        {
            fromDate = new Date(prevDate.setDate(prevDate.getDate() - 1));
        }
        else{
            if((SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL  || SiteName == SITE.NYPROD || SiteName == SITE.NYDEMO || SiteName == SITE.NYLOCAL) && new Date().getDay() !== 1){
                
                fromDate = new Date(prevDate.setDate(prevDate.getDate() - 1));
            }
            toDate = new Date(today.setDate(today.getDate() + 1));
        }
        
        let search_query: GetDarInfo_Query = {
            "QueryType": "\0",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ? 1 : 0,
            "FromDate": handleSearchTime(fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": toDate?.toLocaleString(),
            "SearchType": "AND",
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames": [],
            "LNames": [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": [],
            "States": [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs": [],
            "SBIs": [],
            "DLNos": [],
            "PlateNos": [],
            "VINs": [],
            "AgencyArrested": [],
            "InfocopDept": [],
            "Indicators": [],
            "IndicatorOccurrence": null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": [],
            "ArrestedCounty": [],
            "Arrest_Type": [],
            "CaseNumbers": [],
            "FTSIDs": [],
            "PhoneNumbers": [],
            "SystemCall": true,
        }
        let qRes:getDarInfoResponse = await RunDarQuery(search_query)
        setSecondaryTable([])
        setSecondaryTable(qRes?.SecondaryTable)
        setDarTbl([])
        setDarTbl(qRes.PersonList)
        setInitialDarTbl([])
        setInitialDarTbl(qRes.PersonList)
        if (qRes?.PersonList?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }

    useEffect(() => {
        if (!darIntitalRun && isUserPermission('Arrest')) {
            setdarIntitalRun(true)
            LoadDarInitial()
        }
    });
    
    const DataCleared = async () => {
        let data = initialDarTbl
        if(data && data.length > 0)
        {
            await setDarTbl([])
            setDarTbl(data)
            await setSecondaryTable([])
            setSecondaryTable(data)
        }
    }

    const SubmitSearch = async (params:any, radius:any, lat:any, lng:any ) => {
        let today:Date = new Date();
        let fromDate = new Date()
        fromDate.setFullYear(fromDate.getFullYear() -1)
        let search_query: GetDarInfo_Query = {
            "QueryType": "E",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": false,
            "DateTypeValue": 0,
            "FromDate": handleSearchTime(fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(today, 'toDate')?.toLocaleString(),
            "SearchType": "AND",
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "SEX": [],
            "Address": params.Street ? params.Street : "",
            "Cities": params.City ? params.City.split(',') : [],
            "States": params.State ? params.State.split(',') : [],
            "Zip": params.Zip ? params.Zip : "",
            "Statutes": [],
            "Gang": [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": params.DL ? params.DL.split(',') : [],
            "PlateNos": params.Plate ? params.Plate.split(',') : [],
            "VINs": params.VIN ? params.VIN.split(',') : [],
            "AgencyArrested": [],
            "InfocopDept": [],
            "Indicators": [],
            "IndicatorOccurrence": null,
            "Latitude": lat ? lat : null,
            "Longitude": lng ? lng : null,
            "Radius": radius ? radius : 0,
            "ResidentCounty": [],
            "ArrestedCounty": [],
            "Arrest_Type": [],
            "CaseNumbers": params.Case ? params.Case.split(',') : [],
            "FTSIDs": params.FTSID ? params.FTSID.split(',') : [],
            "PhoneNumbers": params.Phone ? params.Phone?.replaceAll('+1','')?.replaceAll('-','')?.split(',') : [],
            "SystemCall": params.systemCall ?? false,
        }
        if(params.Plate && (params.Plate.includes("%") || params.Plate.includes("*") || params.Plate.includes("?") || params.Plate.includes("_")|| params?.Plate?.length > 10))
        {
            search_query.QueryType ='V'
        }
        let qRes:getDarInfoResponse = await RunDarQuery(search_query)
        setSecondaryTable([])
        setSecondaryTable(qRes?.SecondaryTable)
        setDarTbl([])
        setDarTbl(qRes.PersonList)
        if (qRes?.PersonList?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }

    const SubmitSearchPlaces = async (params:any, radius:any, lat:any, lng:any ) => {
        let today:Date = new Date()
        let fromDate = new Date()
        fromDate.setFullYear(fromDate.getFullYear() -5)
        let search_query: GetDarInfo_Query = {
            "QueryType": "2",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": 0,
            "FromDate": handleSearchTime(fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(today, 'toDate')?.toLocaleString(),
            "SearchType": "AND",
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "SEX": [],
            "Address": params.Street ? params.Street : "",
            "Cities": params.City ? params.City.split(',') : [],
            "States": params.State ? params.State.split(',') : [],
            "Zip": params.Zip ? params.Zip : "",
            "Statutes": [],
            "Gang": [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": params.DL ? params.DL.split(',') : [],
            "PlateNos": params.Plate ? params.Plate.split(',') : [],
            "VINs": params.VIN ? params.VIN.split(',') : [],
            "AgencyArrested": [],
            "InfocopDept": [],
            "Indicators": [],
            "IndicatorOccurrence": null,
            "Latitude": lat ? lat : null,
            "Longitude": lng ? lng : null,
            "Radius": radius ? radius : 0,
            "ResidentCounty": [],
            "ArrestedCounty": [],
            "Arrest_Type": [],
            "CaseNumbers": params.Case ? params.Case.split(',') : [],
            "FTSIDs": params.FTSID ? params.FTSID.split(',') : [],
            "SystemCall": params.systemCall ?? false,
        }
        let qRes:getDarInfoResponse = await RunDarQuery(search_query)
        setSecondaryTable([])
        setSecondaryTable(qRes?.SecondaryTable)
        setDarTbl([])
        setDarTbl(qRes.PersonList)
        if (qRes?.PersonList?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }

    //<SearchParams sParams={[["FName: L", "FName: A"], ["LName: A"]]}/>
    return (
        <div>
            {isUserPermission('Arrest')? <>
                <div className="w-full max-w-3xl mx-auto">
                    <Tab.Group>
                        <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl">
                            <Tab className={({ selected }) =>
                                classNames(
                                    'pt-2 pb-2 pl-10 pr-10 bg-white w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                    selected
                                        ? 'bg-blue-700 text-white shadow'
                                        : 'bg-blue-900 text-white hover:bg-white/[0.12] hover:text-white'
                                )
                            } style={{"fontSize":"14pt"}}>
                                PEOPLE
                            </Tab>
                            <Tab className={({ selected }) =>
                                classNames(
                                    'pt-2 pb-2 pl-10 pr-10 bg-white w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                    selected
                                        ? 'bg-blue-700 text-white shadow'
                                        : 'bg-blue-900 text-white hover:bg-white/[0.12] hover:text-white'
                                )
                            } style={{"fontSize":"14pt"}}>PLACES</Tab>
                            <Tab className={({ selected }) =>
                                classNames(
                                    'pt-2 pb-2 pl-10 pr-10 bg-white w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                    selected
                                        ? 'bg-blue-700 text-white shadow'
                                        : 'bg-blue-900 text-white hover:bg-white/[0.12] hover:text-white'
                                )
                            } style={{"fontSize":"14pt"}}>THINGS</Tab>
                        </Tab.List>
                        <Tab.Panels>
                            <Tab.Panel>
                                <div className="shadow-2xl rounded w-full mt-5 border border-black">
                                    <PeopleSearch SubmitSearch={SubmitSearch} DataCleared={DataCleared} />
                                </div>
                            </Tab.Panel>
                            <Tab.Panel>
                                <div className="shadow-2xl rounded w-full mt-5 border border-black">
                                    <PlacesSearch  SubmitSearch={SubmitSearchPlaces} DataCleared={DataCleared} />
                                </div>
                            </Tab.Panel>
                            <Tab.Panel>
                                <div className="shadow-2xl rounded w-full mt-5 border border-black">
                                    <ThingSearch SubmitSearch={SubmitSearch} DataCleared={DataCleared}/>
                                </div>
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
                {!noData ? <SearchResults Persons={darTbl} SecondaryTable={secondaryTable} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
            </>:<></>}
        </div>
    );
}

export default MainDashboard;