import axios, { AxiosResponse } from "axios";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import { base64_to_blob } from "./detail.service";
import { SearchDL } from "../interfaces/searchDL.interface";
import { profileObject } from "../interfaces/PersonReport.interface";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";
import AuthenticatedAPIReqSplashDL from "./AuthenticatedAPIReqSplashDeviceLocation.service";

export async function RunUserList(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/UserList', params)
    return (res?.data)
}

export async function RunDepartmentList(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/DeptList', params)
    return (res?.data)
}

export async function RunDeptUnitList(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/DeptUnitList', params)
    return (res.data)
}

export async function RunGetUser(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/GetUser', params)
    return (res.data)
}

export async function RunGetDeptUnit(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/GetDeptUnit', params)
    return (res.data)
}

export async function RunSetDeptUnit(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/DeptUnit', params)
    return (res.data)
}

export async function RunMangers(id: string, Default: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Admin/Managers?id=' + id + '&Default=' + Default)
    return (res.data)
}

export async function RunAuthorizedBy(id: string, Default: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Admin/AuthorizedBy?id=' + id + '&Default=' + Default)
    return (res.data)
}

export async function RunDeptUnits(id: string, Default: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Admin/DeptUnits?id=' + id + '&Default=' + Default)
    return (res.data)
}

export async function RunDeptSearchList(id: string, Default: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Admin/Depts?id=' + id + '&Default=' + Default)
    return (res.data)
}

export async function RunEmpAuthLevels(id: string, Default: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Admin/EmpAuthLevels?id=' + id + '&Default=' + Default)
    return (res.data)
}

export async function RunAuditPermission(id: string, Default: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/AuditPermission?id=' + id + '&Default=' + Default)
    return (res.data)
}

export async function RunSetUser(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/User', params)
    return (res.data)
}

export async function RunPOIList(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/POIList', params)
    return (res.data)
}

export async function RunGetPOI(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/GetPOI', params)
    return (res.data)
}

export async function RunSetPOI(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/POI', params)
    return (res.data)
}

export async function RunGetDept(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/GetDept', params)
    return (res.data)
}

export async function RunSetDept(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/Dept', params)
    return (res.data)
}

export async function RunGetPersonPOIHit(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/POIHit', params)
    return (res.data)
}

export async function RunGetPersonMainRecord(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/GetPersonMainRecord', params)
    return (res.data)
}

export async function RunSetPersonMainRecord(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/SetPersonMainRecord', params)
    return (res.data)
}

export async function RunGetNCIC(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/GetNCIC', params)
    return (res.data)
}

export async function RunSetNCIC(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/NCIC', params)
    return (res.data)
}

export async function RunNCICBatch(guid: string, params: any[]): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/NCICBatch?guid=' + guid, params)
    return (res.data)
}

export async function RunUserExpiryDays(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/GetUserExpiryDays', params)
    return (res.data)
}

export async function RunSocialMedia(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Account/SocialMedia', params)
    return (res.data)
}

export async function RunCorrection(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Account/Correction', params)
    return (res.data)
}

export async function RunPromisGavel(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Account/PromisGavel', params)
    return (res.data)
}

export async function RunExpiryToken(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Account/ExpiryToken', params)
    return (res.data)
}

//REMOVE THIS LATER OR PUT IN PDF_SERVICE

export async function RunPersonSummaryPDF(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Export/PersonSummaryPDF', params, { responseType: 'blob' })
    if (res != null) {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', params?.PersonInfo?.FName + ' ' + params?.PersonInfo?.LName + '.pdf');
        document.body.appendChild(link);
        link.click();
    }
}
export async function GetPersonRelatedPIR(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/GetPersonRelatedPIR', params)
    return (res.data)
}

export async function GetPersonRelatedTSC(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/GetPersonRelatedTSC', params)
    return (res.data)
}

//INSERT INTO DB
export async function RunProfileReport(profile: profileObject): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Report/ProfileReport', profile)
    return (res.data)
}

export async function SetProfileReport(profile: profileObject): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Report/SetProfileReport', profile)
    return (res.data)
}

//UPDATE INTO DB
export async function UpdateProfileReport(profile: profileObject): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Report/UpdateProfileReport', profile)
    return (res.data)
}

export async function GetProfileReport1(reportId = null, FBI = null, SBI = null, FName = null, LName = null, DOB = null): Promise<any> {
    let res: AxiosResponse<any>;
    if (reportId) {
        res = await AuthenticatedAPIReq.get('/api/Report/GetProfileReportData?ReportID=' + reportId);
    }
    else {
        res = await AuthenticatedAPIReq.get('/api/Report/GetProfileReportData?FBI=' + FBI + '&SBI=' + SBI + '&FName=' + FName + '&LName=' + LName + '&DOB=' + DOB);
    }
    return (res.data)
}

export async function GetProfileReport(profile: profileObject): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Report/GetProfileReport', profile);
    return (res.data)
}

export async function ClearProfileReportUser(reportId: string): Promise<any> {
    let res: AxiosResponse<any>;
    res = await AuthenticatedAPIReq.get('/api/Report/ClearActiveUser?reportID=' + reportId);
    return (res.data)
}

export async function DeleteProfileReport(reportId: string): Promise<any> {
    let res: AxiosResponse<any>;
    res = await AuthenticatedAPIReq.get('/api/Report/DeleteReport?reportID=' + reportId);
    return (res.data)
}

export async function GetProfileReportPDF(ID: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.get('/api/Report/ProfileReport_PDF?ID=' + ID, { responseType: 'blob' });
    if (res != null) {
        const url = window.URL.createObjectURL(new File([res.data], "FileName.pdf", { type: 'application/pdf' }));
        return url;
    }
    return null;
}

export async function GetProfileReportPreview(profile: profileObject): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/Report/ProfileReport_Preview', profile, { responseType: 'blob' });
    if (res != null) {
        const url = window.URL.createObjectURL(new File([res.data], "FileName.pdf", { type: 'application/pdf' }));
        return url;
    }
    return null;
}

export async function CheckDuplicateReportNum(reportID: number, reportNum: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Report/CheckDuplicateReportNum?reportID=' + reportID + '&reportNum=' + reportNum);
    if (res != null) {
        return (res.data)
    }
    return null;
}

export async function HasProfileReport(FBI: string, SBI: string, FName: string, LName: string, DOB: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Report/HasProfileReport?FBI=' + FBI + '&SBI=' + SBI + '&fName=' + FName + '&lName=' + LName + '&DOB=' + DOB)
    return (res.data)
}

export async function RunProfileReportFiles(file: FormData, ID: number): Promise<any> {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Report/ProfileReportFiles/?ID=' + ID, file)
        return (res.data)
    } catch (e) {
        console.error(e)
    }
}

export async function ProfileReportAttachment(file: FormData): Promise<any> {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Report/ProfileReportAttachment', file)
        return (res.data)
    } catch (e) {
        console.error(e)
    }
}

export async function ProfileReportFilesOld(files: any): Promise<any> {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Report/ProfileReportFilesOld', files)
        return (res.data)
    } catch (e) {
        console.error(e)
    }
}

export async function GetProfileReportFiles(File: any): Promise<any> {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Report/GetProfileReportFiles', File)
        if (res.data.AnyData != null) {
            base64_to_blob(res.data.AnyData.FileContents, res.data.AnyData.ContentType).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, "_target")
            })
        }
    } catch (e) {
        console.error(e)
    }
}

export async function RunSharedToken(id: any, dept: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Account/SharedToken?ID=' + id + '&Dept=' + dept)
    return (res.data)
}

export async function GetNJTrace(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/NJTrace/GetNJTrace', params)
    return (res.data)
}

export async function SetNJTrace(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/NJTrace/SetNJTrace', params)
    return (res.data)
}

export async function SetNJTraceWeaponImg(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/NJTrace/SetNJTraceWeaponEachImage', params)
    return (res.data)
}

export async function SendNJTracetoATF(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/NJTrace/SendNJTrace_ToATF', params)
    return (res.data)
}

export async function GetDuplicateNJTraceWeapon(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/NJTrace/GetDuplicateNJTraceWeapon', params)
    return (res.data)
}

export async function NJTracePDFReport(params : any): Promise<any> {
    try{
        console.log(params)
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/NJTrace/NJTraceDailyPDFReport', params)
        //return (res)
        if (res != null) {
            const url = window.URL.createObjectURL(new File([res.data], "FileName.pdf", { type: 'application/pdf' }));
            return url;
        }
        return null;
    }catch (e) {
        console.error(e)
    }
 
}

export async function ETraceResults_PDF(params : any): Promise<any> {
    try{
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Detail/ETraceResultsPDF', params ,{responseType:'blob'})
        if (res != null) {
            const url = window.URL.createObjectURL(new File([res.data], "FileName.pdf", { type: 'application/pdf' }));
            window.open(url, '_blank')
        }
        return null;
    }catch (e) {
        console.error(e)
    }
 
}

export async function GetETrace(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Detail/GetETrace', params)
    return (res.data)
}


export async function TraceDashboard(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/NJTrace/Dashboard', params)
    return (res.data)
}

