import React, { useState } from 'react'
import '../../../index.css';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { getDropDownALPRVendors } from "../../../services/getAlpr.service";
import LPRTabGridResults from "./_searchResultsGrid";
import { renderToString } from 'react-dom/server'
import Marquee from 'react-fast-marquee';

interface SearchResultsProps {
    VINInfo: any
    Errors: any[]
    NextPage: any
    PagingInfo: any
}

const SearchResults: React.FC<SearchResultsProps> = ({ VINInfo, Errors, NextPage, PagingInfo }) => {
    const [errorList, setErrorList] = React.useState<string>("");
    const [recordCount, setRecordCount] = React.useState<any>("");
    //
    //const [alprVendorList, setALPRVendorList] = React.useState<any[]>([]);
    //const [avlLoaded, setAvlLoaded] = React.useState<boolean>(false)

    React.useEffect(() => {
        buildErrorList()
    }, [Errors])

    const buildErrorList = async () => {
        setErrorList("")
        let p = ""
        if (Errors?.length > 0) {
            for (let ers of Errors) {
                for (const [key, value] of Object.entries(ers)) {
                    if (key === "Record") {
                        setRecordCount(value)
                    }
                    else {
                        if (p.length !== 0) {
                            p = p + " | " + key + " : " + value
                        }
                        else {
                            p = key + " : " + value
                        }
                    }
                }
            }
            setErrorList(p)
        }
    }

    //React.useEffect(() => {
    //    if (!avlLoaded) {
    //        setAvlLoaded(true)
    //        handleALPRVendors()
    //    }
    //}, [alprVendorList]);

    //const handleALPRVendors = () => {
    //    getDropDownALPRVendors().then(results => {
    //        let vg: any = []
    //        vg = parseALPRVendorGroup(results)
    //        setALPRVendorList(vg)
    //    })
    //}

    const handleTabList = () => {
        let tabs = ['ALL'].concat(Object.keys(VINInfo))
        return tabs
    }

    //const parseALPRVendorGroup = (data: any): any => {
    //    let vendorGroup = [];
    //    let count = 1;

    //    //Add ALL, Eticket and ParkMobile tabs to the GUI
    //    vendorGroup.push({ group: 'ALL' })
    //    vendorGroup.push({ group: 'ETicket' })
    //    vendorGroup.push({ group: 'ParkMobile' })
    //    //
    //    for (const [key, val] of Object.entries(data)) {
    //        let val1: any = val;
    //        let jsonData = JSON.parse(key);
    //        let vaItem: any = {
    //            group: jsonData['Group'],
    //        }
    //        vendorGroup.push(vaItem)
    //    }
    //    return vendorGroup
    //}

    const CustomHeader = (item: any) => {
        //VINInfo, PagingInfo, Errors
        let errList = JSON.stringify(Errors ?? [])
        //
        let kGroup = item.item
        let tableclassname: any = ''
        tableclassname = 'custom-header-default'
        if (!kGroup || Object.keys(VINInfo).length === 0) {
            //Do nothing
        }
        else if (VINInfo && VINInfo[kGroup]) {
            if (errList?.toLowerCase()?.includes(kGroup?.toLowerCase())) {
                tableclassname = 'custom-header-issue'
            }
            else if ((VINInfo[kGroup]?.length % 1000 === 0) && (VINInfo[kGroup]?.length !== 0)) {
                tableclassname = 'custom-header-processing'
            }
            else if (VINInfo[kGroup]?.length % 1000 !== 0) {
                tableclassname = 'custom-header-done'
            }
        } else {
            //item = 'ALL'
            let isDone = true
            for (let key of Object.keys(VINInfo)) {
                if ((VINInfo[key]?.length % 1000 === 0) && (VINInfo[key]?.length !== 0)) {
                    isDone = false
                }
            }
            tableclassname = isDone ? 'custom-header-done' : 'custom-header-processing'
        }
        return (
            <div className={tableclassname} >
                {kGroup}
            </div>
        );
    };

    return (
        <div className='control-pane'>
            {Errors.length > 0 && errorList.length > 0 ?
                <Marquee speed={20} pauseOnHover={true}>
                    <span className="font-bold text-red-700">{errorList?.length > 0 ? errorList : ""}</span>
                </Marquee>
                : <></>}
            <div className='control-section tab-control-section'>
                {/* Render the Tab Component */}
                <TabComponent id="defaultTab">
                    <TabItemsDirective>
                        {handleTabList().map((item: any, index) => (
                            <TabItemDirective
                                key={index}
                                header={{ text: `${renderToString(CustomHeader({ item }))}` }}
                                content={{
                                    template: () => (
                                        <LPRTabGridResults
                                            TabGroup={item}
                                            VINInfo={VINInfo}
                                            PagingInfo={PagingInfo}
                                            NextPage={NextPage}
                                            Errors={Errors}
                                        />)
                                }} />
                        ))}
                    </TabItemsDirective>
                </TabComponent>
            </div>
        </div>);
};

export default SearchResults