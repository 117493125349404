import React, { useState } from "react";
import AuditLogSearch from "../../components/Admin/AuditLogSearch";
import AuditLogSearchResults from "../../components/Admin/_searchResultsAuditLog";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import { RunDarQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import { getUser } from "../../services/auth.service";
import TraceAuditLogSearchResults from "../../components/Admin/_searchResultsTraceAuditLog";
import NJTraceAuditLogSearch from "../../components/Admin/NJTraceAuditLogSearch";

function NJTraceAuditLog() {
    let [traceAuditLog, setTraceAuditLog] = useState<any[]>([])
    let [systemAuditLog, setSystemAuditLog] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params: any, dropDowns:any) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "NJTraceAuditLog",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": 0,
            "FromDate": params?.FromDate?.toLocaleString(),
            "ToDate": params?.ToDate?.toLocaleString(),
            "SearchType": 'AND',
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames": [],
            "LNames": [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": [],
            "States": [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs": [],
            "SBIs": [],
            "DLNos": params?.Dept ? params.Dept : [],
            "PlateNos": params.EmpID ? params.EmpID : [],
            "VINs": [],
            "AgencyArrested": dropDowns?.AgencyArrested ? dropDowns?.AgencyArrested : [],
            "InfocopDept": [],
            "Indicators": [],
            "IndicatorOccurrence": null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": [],
            "ArrestedCounty": [],
            "Arrest_Type": params?.Action ? params.Action : [],
            "CaseNumbers": params?.CaseNumbers ? params?.CaseNumbers?.split(',') : [],
            "SSN": params.SSN ? params.SSN.split(',') : [],
            "PlaceOfBirth": [],
            "AgeRangeStart": params?.AgeRangeStart,
            "AgeRangeEnd": params.AgeRangeEnd,
            "FTSIDs": params?.FTSIDs ? params.FTSIDs?.split(',') : [],
            "ShootingPersonTypes": [],
            "SystemCall": params?.systemCall ?? false,
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        setTraceAuditLog([])
        //setSystemAuditLog([])
        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            //setTraceAuditLog(qRes.AnyTable?.filter((e:any) => e?.EventType?.toUpperCase() === 'INFO' ))
            //setSystemAuditLog(qRes.AnyTable?.filter((e:any) => e?.EventType?.toUpperCase() === 'SYSTEM_INFO' ))
            setTraceAuditLog(qRes?.AnyTable)
            setNoData(false)
        }
    }

    return (
        <div id="TraceAuditLog">
            <>
                <NJTraceAuditLogSearch SubmitSearch={SubmitSearch} />
                {!noData ? <TraceAuditLogSearchResults UserAuditLogResults={traceAuditLog} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}

            </>
        </div>
    );
}

export default NJTraceAuditLog;