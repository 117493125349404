import React, {useState} from "react";
import ReactECharts from "echarts-for-react";
import {SiteName} from "../../../services/config.service";
import {SITE} from "../../../interfaces/config.interface";

interface StolenVehiclesProps {
    data:any,
    tab:string,
    getAbbreviated:any
}


const Top25Grid: React.FC<StolenVehiclesProps> = ({data, tab, getAbbreviated}) => {
    var option;

    const handleFormatXAxis = () => {
        let xAxis:any = []
        switch(tab){
            case '1':
                if('Top25StolenLocationVehicles' in data && Array.isArray(data.Top25StolenLocationVehicles))
                {
                    for(let d of data.Top25StolenLocationVehicles)
                    {
                        if((SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && data.Top25StolenLocationVehicles.length > 2) {
                            xAxis.push(getAbbreviated(d.District?.toUpperCase()))
                        }
                        else
                            xAxis.push(d.City?.toUpperCase())
                    }
                }
                break;
            case '2':
                if('Top25StolenLocationPlates' in data && Array.isArray(data.Top25StolenLocationPlates))
                {
                    for(let d of data.Top25StolenLocationPlates)
                    {
                        if((SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && data.Top25StolenLocationPlates.length > 2) {
                            xAxis.push(getAbbreviated(d.District?.toUpperCase()))
                        }
                        else
                            xAxis.push(d.City?.toUpperCase())
                    }
                }
                break;
            case '3':
                if('Top25StolenLocationBoth' in data && Array.isArray(data.Top25StolenLocationBoth))
                {
                    for(let d of data.Top25StolenLocationBoth)
                    {
                        if((SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && data.Top25StolenLocationBoth.length > 2) {
                            xAxis.push(getAbbreviated(d.District?.toUpperCase()))
                            
                        }
                        else 
                            xAxis.push(d.City?.toUpperCase())
                    }
                }
                break;
        }
        
        return xAxis
    }

    const handleSeries = () => {
        let list:any = []
        let listP:any = []
        let xAxis:any = handleFormatXAxis()
        let series:any = []

        switch(tab){
            case '1':
                if('Top25StolenLocationVehicles' in data && Array.isArray(data.Top25StolenLocationVehicles))
                {
                    for(let d of data.Top25StolenLocationVehicles)
                    {
                        list.push(d.Count)
                    }
                    series.push({
                        type: 'bar',
                        color:'#1e3a8a',
                        emphasis: {
                            focus: 'series'
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            rotate:20,
                            fontSize:10,
                        },
                        data: list
                    },)
                }
                break;
            case '2':
                if('Top25StolenLocationPlates' in data && Array.isArray(data.Top25StolenLocationPlates))
                {
                    for(let d of data.Top25StolenLocationPlates)
                    {
                        listP.push(d.Count)
                    }
                    series.push({
                        type: 'bar',
                        color:'#1e3a8a',
                        emphasis: {
                            focus: 'series'
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            rotate:20,
                            fontSize:11,
                        },
                        data: listP
                    },)
                }
                break;
            case '3':
                if('Top25StolenLocationBoth' in data && Array.isArray(data.Top25StolenLocationBoth))
                {
                    for(let d of data.Top25StolenLocationBoth)
                    {
                        listP.push(d.Count)
                    }
                    series.push({
                        type: 'bar',
                        color:'#1e3a8a',
                        emphasis: {
                            focus: 'series'
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            rotate:20,
                            fontSize:11,
                        },
                        data: listP
                    },)
                }
                break;
        }
        
        return series
    }

    option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        height:'180',
        width:'95%',
        toolbox: {
            feature: {
                saveAsImage: { show: true }
            }
        },
        legend: {
        },
        grid: {
            left: '1%',
            right: '1%',
            bottom: '1%',
            top:'3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval:0,
                rotate:(handleFormatXAxis().length <= 2 ? 0 : 45),
                fontSize:8,
            },
            data: handleFormatXAxis(),
        },
        yAxis: {
            type: 'value'
        },
        series: handleSeries()
    };

    return (
        <>
            {data && data?.Top25StolenLocationVehicles?.length !== 0 ?
                <div>
                    <ReactECharts option={option} style={{height:'200px'}}/>
                </div>
                :
                <div className={"text-center text-3xl mt-6 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
            }
        </>
    );
}

export default Top25Grid;