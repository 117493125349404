import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Button, CircularProgress} from "@mui/material";
import TSCIcon from "../../../assets/Images/TSC-button.png"; 
import { GetTSCPDF } from "../../../services/tsc.service";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Inject,
    Page,
    Sort,
    Filter, SortSettingsModel,
} from '@syncfusion/ej2-react-grids';
import {JAOS} from "../../../services/JAOS.service";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";
import {SiteName} from "../../../services/config.service";
import { GetTSCContent } from "../../../services/tsc.service";
import {
    getUser,
    isUserPermission,
    isMainRecordEditor
} from "../../../services/auth.service";

interface PanelTSCProps {
    person: PersonInfo,
    TSCInfo: any[],
    isLoading:boolean
}

const PanelTSC: React.FC<PanelTSCProps> = ({ person, TSCInfo, isLoading }) => {
    const [gridData, setGridData] = React.useState([]);
    const [formatData, setFormatData] = React.useState([]);

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'ReportDate', direction: 'Descending' }
    ]
    };

    const getFileContent = async (data: any) => {
        let temp = { ...data }
        if (temp.TSCPhotoList && temp.TSCPhotoList.length > 0) {
            for (let photo of temp.TSCPhotoList) {
                if (photo.Files && photo.Files.length > 0) {
                    for (let fileObj of photo.Files) {
                        let res = await GetTSCContent(fileObj)
                        fileObj.Content = res.Content
                    }
                }
                else {
                    photo.Files = []
                }
            }
        }
    }

    const handleDataTyping = async () => {
        let tempP: any = [...TSCInfo]
        for (let p of tempP) {
            await getFileContent(p)
            if (p.ReportDate) {
                p.ReportDate = new Date(p.ReportDate)
            }
            if (p.UpdateDate) {
                p.UpdateDate = new Date(p.UpdateDate)
            }
        }
        setFormatData(tempP)
    }


    React.useEffect(() => {
        console.log("TSC info (in panel): ", TSCInfo)
        handleDataTyping()
    }, [TSCInfo])

    const openPdf = (tsc: any) => {
        console.log("PDF function started")
        console.log("TSC: ", tsc)
        console.log("TSC ID: ", tsc.ID)
        if (tsc && tsc.ID) {
            console.log("Opening PDF")
            GetTSCPDF(tsc.ID)
        }
    }

    const photoTemplate = (data: any) => {
        const photoContent = data.TSCPhotoList?.[0]?.Files?.[0]?.Content;

        if (photoContent) {
            return (
                <img
                    src={`data:image/jpeg;base64,${photoContent}`}
                    alt="Photo"
                    style={{ width: "100px", height: "auto" }}
                />
            );
        }

        return <span>No Image</span>;
    };

    const nameTemplate = (data: any) => {
        const firstName = data.AnalystFirstName
        const lastName = data.AnalystLastName

        if (firstName && lastName) {
            return (
                <span>{firstName + " " + lastName}</span>
            )
        }

        return <span>No Name Provided</span>
    }

    const pdfTemplate = (data: any) => {
        return (
            <Button onClick={() => openPdf(data)}>VIEW PDF</Button>
        )
    }


    return(
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                        <>
                            <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                                <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                    <span>
                                        <KeyboardArrowDownIcon
                                            className={`${open ? "transform rotate-180" : ""}`}
                                        />
                                        <span className={"font-bold"}>Terrorist Screening Center {<img src={TSCIcon} alt={''} width='30' style={{ display: 'inline' }} />} {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "5px" }} /> : <></>}</span>
                                    </span>
                                    <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {TSCInfo?.length}</span>
                                </span>
                            </Disclosure.Button>

                            <Disclosure.Panel className="text-gray-500 p-4">
                                {isUserPermission('TSC') ? (
                                    <GridComponent
                                        dataSource={formatData}
                                        allowPaging={true}
                                        pageSettings={{ pageSize: 50, pageSizes: [10, 25, 50, 100] }}
                                        allowSorting={true}
                                        sortSettings={sortSettings}
                                        allowMultiSorting={true}
                                        allowFiltering={true}
                                        filterSettings={{ type: 'CheckBox' }}
                                        allowTextWrap={true}
                                        textWrapSettings={{ wrapMode: 'Both' }}
                                        rowHeight={24}
                                    >
                                        <ColumnsDirective>
                                            <ColumnDirective field='ReportDate' headerText='Hit Report Date' format={{ type: 'date', format: 'MM/dd/yyyy HH:mm' }} width={180} />
                                            <ColumnDirective
                                                headerText='Photo'
                                                width={200}
                                                template={photoTemplate}
                                            />
                                            <ColumnDirective field='SRN' headerText='Service Request Number' width={180} />
                                            <ColumnDirective field='NUIN' headerText='NUIN' width={180} />
                                            <ColumnDirective headerText='Analyst Name' width={180} template={nameTemplate} />
                                            <ColumnDirective headerText='Actions' width={180} template={pdfTemplate} />
                                        </ColumnsDirective>
                                        <Inject services={[Sort, Page, Filter]} />
                                    </GridComponent>) : (
                                        <p className="text-red-500 font-bold">
                                            Please contact the G130 Threat Analysis unit for further information.
                                        </p>
                                )}
                                    

                            </Disclosure.Panel>
                        </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelTSC;