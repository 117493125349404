import { GetDarInfo_Query, getDarInfoResponse, PersonInfo, getGraphResponse, ChartData, getAuditLogInfoResponse } from "../interfaces/getDarInfo_interface";
import axios, { Axios, AxiosResponse } from "axios";
import { SearchDL } from "../interfaces/searchDL.interface";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import { ALPRTimeout_Sec, API_URL } from "./config.service";
import PlateSearch from "../interfaces/PlateSearch.interface";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";
import { GRAILReferral } from "../interfaces/GRAILReferral.interface";
import AuthenticatedAPIReqSplashDL from "./AuthenticatedAPIReqSplashDeviceLocation.service";
import { IWWGunShootingFileOrImage, IWWNotificationsObject } from "../interfaces/ShootingInfo.interface";
import { PersonMainRecord, PersonFileOrImage } from "../interfaces/UpdatePersonMainRecord.interface";
import { FirearmFileOrImage } from "../interfaces/Detail/NJTraceDetail.interface";
import { base64_to_blob } from "./detail.service";
import { GVRTFEmailUserInfo, GVRTFGroupInfo } from "../interfaces/GVRTFEmailList.interface";

/////////////////////////// Carjack Controller ///////////////////////////
export async function CarjackingSaveRecord(params: IWWNotificationsObject): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Carjack/SetCarjacking', params)
    return (res.data)
}

export async function CarjackingGetRecord(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Carjack/GetCarjacking', params)
    return (res.data)
}

/////////////////////////// Filter Controller ///////////////////////////
export async function RunDarQuery(params: GetDarInfo_Query): Promise<getDarInfoResponse> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/Filter/GetDARInfo', params)
    return (res.data)
}

export async function RunAuditLogQuery(params: GetDarInfo_Query): Promise<getAuditLogInfoResponse> {
    let res: AxiosResponse<getAuditLogInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/Filter/GetDARInfo', params)
    return (res.data)
}

export async function RunGraphQuery(params: GetDarInfo_Query): Promise<getGraphResponse> {
    let res: AxiosResponse<getGraphResponse> = await AuthenticatedAPIReqSplash.post('/api/Filter/GetDARInfo', params)
    return (res.data)
}

/////////////////////////// NJTrace Controller ///////////////////////////
export async function GetFileContentFirearm(params: FirearmFileOrImage) {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/NJTrace/NJTraceFileContent', params)
        if (res.data != null) {
            base64_to_blob(res.data.FileContent, res.data.FileEncoding).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
    } catch (e) {
        console.error(e)
    }
}

export async function GetFileContentFirearmNonDownload(params: FirearmFileOrImage) {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/NJTrace/NJTraceFileContent', params)
        if (res.data != null) {
            return res.data
        }
        else
            return null
    } catch (e) {
        console.error(e)
        return null;
    }
}

/////////////////////////// GRAIL Controller ///////////////////////////
export async function RunSetGRAILReferral(params: GRAILReferral): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/GRAIL/setGRAILRefferal', params)
    return (res.data)
}

export async function RunGetGRAILReferral(params: GRAILReferral): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/GRAIL/getGRAILRefferal', params)
    return (res.data)
}

export async function RunGetEmailOutputGRAILReferral(params: GRAILReferral, APIKEY: string | null): Promise<any> {
    let res: AxiosResponse<any> = await axios.post('/api/GRAIL/getEmailOutputGRAILRefferal', params, {
        baseURL: API_URL,
        withCredentials: false,
        headers: {
            AuthToken: `${APIKEY}`
        }
    })
    return (res.data)
}

export async function RunGetGRAILReferralGroup(params: GVRTFGroupInfo): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/GRAIL/getGRAILRefferalGroup', params)
    return (res.data)
}

export async function RunGetGRAILReferralEmail(params: GVRTFEmailUserInfo): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/GRAIL/getGRAILRefferalEmail', params)
    return (res.data)
}

export async function RunSetGRAILReferralGroup(params: GVRTFGroupInfo): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/GRAIL/setGRAILRefferalGroup', params)
    return (res.data)
}

export async function RunSetGRAILReferralEmail(params: GVRTFGroupInfo): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/GRAIL/setGRAILRefferalEmail', params)
    return (res.data)
}

export async function RunSendGRAILReferralEmail(params: GVRTFGroupInfo): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/GRAIL/EmailGRAILReferral', params)
    return (res.data)
}

export async function RunRemoveSuspectNJPop(params: GRAILReferral): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/GRAIL/removeSuspectNJPop', params)
    return (res.data)
}

/////////////////////////// IWW Controller ///////////////////////////
export async function GetFileContent(params: IWWGunShootingFileOrImage) {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/IWW/FileContent', params)
        if (res.data != null) {
            base64_to_blob(res.data.Content, res.data.FileEncoding).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
    } catch (e) {
        console.error(e)
    }
}

export async function IWWSaveRecord(params: IWWNotificationsObject): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/IWW/SetIWWGunShooting', params)
    return (res.data)
}

export async function IWWGetRecord(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/IWW/GetIWWGunShooting', params)
    return (res.data)
}

export async function IWWSendEmail(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/IWW/EMailIWWGunShooting', params)
    return (res.data)
}

/////////////////////////// Person Controller ///////////////////////////
export async function RunPerson_ImageQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Image', params)
    return (res.data)
}

export async function RunPerson_ArrestQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Arrest', params)
    return (res.data)
}

export async function RunPerson_NJPOPQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_NJPOP', params)
    return (res.data)
}

export async function RunPerson_CodisDNAQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_CodisDNA', params)
    return (res.data)
}

export async function RunPerson_InfocopQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Infocop', params)
    return (res.data)
}

export async function RunPerson_ETicketQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_ETicket', params)
    return (res.data)
}

export async function RunPerson_CADRMSQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_CADRMS', params)
    return (res.data)
}

export async function RunPerson_ParkMobileQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_ParkMobile', params)
    return (res.data)
}

export async function RunPerson_ETraceQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_ETrace', params)
    return (res.data)
}

export async function RunPerson_njtraceQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_njtrace', params)
    return (res.data)
}

export async function RunPerson_NIBINQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_NIBIN', params)
    return (res.data)
}

export async function RunPerson_PrisonQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Prison', params)
    return (res.data)
}

export async function RunPerson_ParoleQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Parole', params)
    return (res.data)
}

export async function RunPerson_JailQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Jail', params)
    return (res.data)
}
export async function RunPerson_MissingPersonQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_MissingPerson', params)
    return (res.data)
}

export async function RunPerson_GunStatQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_GunStat', params)
    return (res.data)
}
export async function RunPerson_GunOffenderQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_GunOffender', params)
    return (res.data)
}

export async function RunPerson_BOLOQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_BOLO', params)
    return (res.data)
}

export async function RunPerson_FirearmQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Firearm', params)
    return (res.data)
}
export async function RunPerson_M43PersonQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_M43Info', params)
    return (res.data)
}

export async function RunPerson_ViolentQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Violent', params)
    return (res.data)
}

export async function RunPerson_TSC(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_TSC', params)
    return (res.data)
}

export async function RunPerson_ProfessionQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Profession', params)
    return (res.data)
}

export async function RunPerson_ALPRQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_ALPR', params, { timeout: ALPRTimeout_Sec * 1000 })
    return (res.data)
}

export async function RunPerson_DLQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_DL', params)
    return (res.data)
}

export async function RunPerson_HumanTrafficQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_HumanTraffic', params)
    return (res.data)
}

export async function RunPerson_Plate(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Plate', params)
    return (res.data)
}

export async function RunPerson_AddressPhoneQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_AddressPhone', params)
    return (res.data)
}

export async function RunPerson_AKAName(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_AKAName', params)
    return (res.data)
}

export async function RunPerson_ProfileReport(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_ProfileReport', params)
    return (res.data)
}

export async function RunPerson_SaveSupplementalFile(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_SaveSupplementalFile', params)
    return (res.data)
}

export async function RunPerson_SupplementalFile(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_SupplementalFile', params)
    return (res.data)
}

export async function Person_GetSupplementalFile(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_GetSupplementalFile', params)
    return (res.data)
}
export async function Person_DeleteSupplementalFile(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_DeleteSupplementalFile', params)
    return (res.data)
}

//export async function RunSummaryInfoForPersonQuery(params:SearchDL) : Promise<SummaryInfo> {
//    let res:AxiosResponse<SummaryInfo> = await AuthenticatedAPIReq.post('/api/Person/GetSummaryInfoForPerson',params)
//    return(res.data)
//}

export async function RunPerson_CrumbScore(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_CrumbScore', params)
    return (res.data)
}

export async function RunPerson_Duplicate(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Duplicate', params)
    return (res.data)
}

export async function RunPerson_Referral(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Referral', params)
    return (res.data)
}

export async function GetFileContentPersonMain(params: PersonFileOrImage) {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/FileContent', params)
        if (res.data != null) {
            base64_to_blob(res.data.Content, res.data.FileEncoding).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
    } catch (e) {
        console.error(e)
    }
}

/////////////////////////// Search Controller ///////////////////////////
export async function RunMultipleOccurrence(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/Search/MultipleOccurrence', params)
    return (res.data)
}
export async function RunMultipleOccurrenceNoSplash(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Search/MultipleOccurrence', params)
    return (res.data)
}
export async function RunDeviceMultipleOccurrence(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplashDL.post('/DeviceLocation/SearchOccurrence', params)
    return (res.data)
}

/////////////////////////// VIN Controller ///////////////////////////
export async function RunVINQuery(params: PlateSearch): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/Vin/Search', params)
    return (res.data)
}

export async function RunFetchVendorData(params: PlateSearch): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/Vin/FetchVendorData', params)
    return (res.data)
}

export { }