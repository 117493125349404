import React, { useState } from "react";
import VinSearch from "../../components/tools/alpr_vin_search/_vinSearch";
import SearchResults from "../../components/tools/alpr_vin_search/_searchResultsVin";
import PlateSearch from "../../interfaces/PlateSearch.interface";
import { getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import { RunVINQuery, RunFetchVendorData } from "../../services/getAlpr.service";
import { getUser, isUserPermission } from "../../services/auth.service";
import { useLocation } from "react-router-dom";
import { uniqueArrayItems } from "../../services/formatDate.service";
import { getDropDownALPRVendors } from "../../services/getDropdown.service";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ALPRVINSearch() {
    let [vinInfo, setVinInfo] = useState<any[]>([])
    let [paginationInfo, setPaginationInfo] = useState<any>({})
    let [searchQuery, setSearchQuery] = useState<any>({})
    let [errorData, setErrorData] = useState<any[]>([])
    let query = useQuery();

    let alprVendorKeys: any[] = []
    //ALPRVendor: Assign all active vendors account's key to this array
    //const handleALPRVendorKeys = async () => {
    //    let result = await getDropDownALPRVendors()
    //    for (const [key, val] of Object.entries(result)) {
    //        let val1: any = val
    //        for (const [k, v] of Object.entries(val1)) {
    //            let v1: any = v
    //            alprVendorKeys.push(v1["Key"])
    //        }
    //    }
    //}

    //SubmitSearch is triggered by Search Button and changes a state to let Results know there was a search called.
    const SubmitSearch = async (params: any, dropDowns: any, vinType: string) => {
        //1849 - NEED TO REMOVE WHEN CAMERAS GET FIXED
        let cameraList = dropDowns?.ADD?.ALPRCamera ? dropDowns?.ADD?.ALPRCamera : []
        let filteredCameraList = []
        for (let camera of cameraList) {
            filteredCameraList.push(
                camera.key
            )
        }

        let search_query: PlateSearch = {
            PlateNo: params.PlateNo ? params.PlateNo.split(',') : [],
            PlateState: params.PlateState ? params.PlateState.split(',') : [],
            //PerLicenseNo: params.PerLicenseNo,
            //PerFirstName: params.PerFirstName,
            //PerMiddleName: params.PerMiddleName,
            //PerLastName: params.PerLastName,
            VehVINNo: params.VehVINNo ? params.VehVINNo.split(',') : [],
            /*VehMake: dropDowns?.DD?.VehicleMake ? dropDowns?.DD?.VehicleMake : [],*/
            VehMake: dropDowns?.DD?.VehicleMake ? dropDowns?.DD?.VehicleMake : [],
            VehModel: dropDowns?.DD?.VehicleModel ? dropDowns?.DD?.VehicleModel : [],
            VehTrim: dropDowns?.DD?.VehicleTrim ? dropDowns?.DD?.VehicleTrim : [],
            VehYear: dropDowns?.DD?.VehicleYear ? dropDowns?.DD?.VehicleYear : [],
            VehColor: dropDowns?.DD?.VehicleColor ? dropDowns?.DD?.VehicleColor : [],
            VehBody: dropDowns?.DD?.VehicleBody ? dropDowns?.DD?.VehicleBody : [],
            //VINEngineType: params.VINEngineType,
            //VINTransmission_short: params.VINTransmission_short,
            //VINTransmission_long: params.VINTransmission_long,
            //VINDriveline: params.VINDriveline,
            //VINAnti_BrakeSystem: params.VINAnti_BrakeSystem,
            //VINTires: params.VINTires,
            //VINInteriorTrim: params.VINInteriorTrim,
            //VINTrackFront: params.VINTrackFront,
            //VINTrackRear: params.VINTrackRear,
            //VINStandardSeating: params.VINStandardSeating,
            //VINVehicleAntiTheft: params.VINVehicleAntiTheft,
            //VIN4WD_AWD: params.VIN4WD_AWD,
            //VINTractionControl: params.VINTractionControl,
            //VINGenuineWoodTrim: params.VINGenuineWoodTrim,
            //VINFrontSplitBenchSeat: params.VINFrontSplitBenchSeat,
            //VINLeatherSeat: params.VINLeatherSeat,
            //VINAlloyWheels: params.VINAlloyWheels,
            //VINChromeWheels: params.VINChromeWheels,
            //VINSteelWheels: params.VINSteelWheels,
            //VINRearWiper: params.VINRearWiper,
            ALPRSearch: params.ALPR,
            ALPRStartDate: params.fromDate?.toLocaleString(),
            ALPREndDate: params.toDate?.toLocaleString(),
            ALPRServer: dropDowns?.ADD?.ALPRServer ? dropDowns?.ADD?.ALPRServer : [],
            ALPRCamera: filteredCameraList.filter(uniqueArrayItems) ?? [],
            ALPRReason: dropDowns?.ADD?.ALPRReason ? dropDowns?.ADD?.ALPRReason : [],
            ALPRCaseNumber: params.ALPRCaseNumber ? params.ALPRCaseNumber.split(',') : [],
            ALPRVendor: alprVendorKeys, //List of active vendor keys
            ETicket: params.ETicket,
            ETicketAgency: dropDowns?.EDD?.ETicketAgency ? dropDowns?.EDD?.ETicketAgency : [],
            ParkMobile: params.ParkMobile,
            ParkMobileAgency: dropDowns?.PDD?.ParkMobileAgency ? dropDowns?.PDD?.ParkMobileAgency : [],
            ShowVIN: (vinType === "VIN") ? true : false,
            PaginationOffset: 0,
            PaginationNext: 1000,
            ShowStolen: true
        }
        setSearchQuery(search_query)

        try {
            let resALPRVendors = await getDropDownALPRVendors()
            handleVendorData(resALPRVendors, search_query)
        }
        catch (e) {
            setVinInfo([])
            setPaginationInfo({})
            setErrorData([])
            setErrorData([{ ERROR: "Timed Out!", Record: "An ERROR has Occured while fetching the data please limit your search criteria." }])
        }
    }

    function handleVendorData(resALPRVendors: any, search_query: any) {
        setVinInfo([])
        setPaginationInfo({})
        setErrorData([])

        //API: FetchVendorData
        let tempPaginationInfo: any = { ...paginationInfo }
        RunFetchVendorData(search_query).then(res => {
            if (res?.AnyTable) {
                setErrorData((errorData ?? []).concat(res?.AnyTable))
            }
        });
        //API: Search => Loop through all the vendors/ETicket/ParkMobile
        let tempData: any[] = []
        let jobs: any = []
        let alprEnabled: boolean = search_query.ALPRSearch ?? false
        let etEnabled: boolean = search_query.ETicket ?? false
        let pmEnabled: boolean = search_query.ParkMobile ?? false
        //ETicket and ParkMobile
        if (etEnabled) {
            search_query.ETicket = true
            search_query.ALPRSearch = false
            search_query.ParkMobile = false
            //
            jobs.push(RunVINQuery(search_query))
            //
            search_query.ALPRSearch = alprEnabled
            search_query.ParkMobile = pmEnabled
        }
        if (pmEnabled) {
            search_query.ETicket = false
            search_query.ALPRSearch = false
            search_query.ParkMobile = true
            //
            jobs.push(RunVINQuery(search_query))
            //
            search_query.ALPRSearch = alprEnabled
            search_query.ETicket = etEnabled
        }
        //ALPR
        if (alprEnabled) {
            for (const [key, val] of Object.entries(resALPRVendors)) {
                let val1: any = val
                search_query.ALPRVendor = []
                alprVendorKeys = []
                for (const [k, v] of Object.entries(val1)) {
                    let v1: any = v
                    alprVendorKeys.push(v1["Key"])
                }
                search_query.ALPRVendor = alprVendorKeys
                //
                search_query.ETicket = false
                search_query.ParkMobile = false
                //
                jobs.push(RunVINQuery(search_query))
            }
        }
        //DMV
        if (!etEnabled && !pmEnabled && !alprEnabled) {
            search_query.ETicket = false
            search_query.ALPRSearch = false
            search_query.ParkMobile = false
            //
            jobs.push(RunVINQuery(search_query))
        }
        Promise.all(jobs).then(jobResults => {
            //ETicket
            if (etEnabled) {
                let res = JSON.parse((jobResults[0] as any)?.JsonObject)
                setErrorData((errorData ?? []).concat(jobResults[0]?.AnyTable))
                let kGroup: any = "ETicket"
                tempData = { ...tempData, [kGroup]: (tempData[kGroup] ?? []).concat(res) }
            }
            //ParkMobile
            if (pmEnabled) {
                let res = JSON.parse((jobResults[etEnabled ? 1 : 0] as any)?.JsonObject)
                setErrorData((errorData ?? []).concat(jobResults[etEnabled ? 1 : 0]?.AnyTable))
                let kGroup: any = "ParkMobile"
                tempData = { ...tempData, [kGroup]: (tempData[kGroup] ?? []).concat(res) }
            }
            //ALPR
            if (alprEnabled) {
                let idx = (etEnabled && pmEnabled) ? 2 : ((etEnabled || pmEnabled) ? 1 : 0)
                for (const [key, val] of Object.entries(resALPRVendors)) {
                    let jsonData = JSON.parse(key);
                    let kGroup = jsonData['Group']
                    let res = JSON.parse((jobResults[idx] as any)?.JsonObject)
                    //
                    setErrorData((errorData ?? []).concat(jobResults[idx]?.AnyTable))
                    //
                    tempData = { ...tempData, [kGroup]: (tempData[kGroup] ?? []).concat(res) }
                    idx++
                }
            }
            //DMV
            if (!etEnabled && !pmEnabled && !alprEnabled) {
                let res = JSON.parse((jobResults[0] as any)?.JsonObject)
                setErrorData((errorData ?? []).concat(jobResults[0]?.AnyTable))
                let kGroup: any = "DMV"
                tempData = { ...tempData, [kGroup]: (tempData[kGroup] ?? []).concat(res) }
            }
            setVinInfo(tempData)
        })
    }

    //Call from Results Page that gets the next results set
    const NextPage = async (currentPage: any, tabGroup: any) => {
        let tempSearchQuery = { ...searchQuery }
        let tempPaginationInfo: any = { ...paginationInfo }
        let resALPRVendors = await getDropDownALPRVendors()
        let tempData: any = { ...vinInfo }
        let jobs: any = []
        //
        let alprEnabled: boolean = tempSearchQuery.ALPRSearch ?? false
        let etEnabled: boolean = tempSearchQuery.ETicket ?? false
        let pmEnabled: boolean = tempSearchQuery.ParkMobile ?? false
        try {
            if (tabGroup === "ALL") {
                tempPaginationInfo["ALL"] = {
                    PaginationEnd: false,
                    PaginationOffset: 0,
                    PaginationNext: 1000,
                    CurrentPage: currentPage ?? 1
                }
            }
            if (tabGroup === "ETicket") {
                tempPaginationInfo["ETicket"] = {
                    PaginationEnd: false,
                    PaginationOffset: (tempData["ETicket"] ? tempData["ETicket"]?.length ?? 0 : tempPaginationInfo["ETicket"].PaginationOffset),
                    PaginationNext: 1000,
                    CurrentPage: (tabGroup === "ETicket") ? currentPage : tempPaginationInfo["ETicket"].CurrentPage ?? 1
                }
            }
            if (tabGroup === "ParkMobile") {
                tempPaginationInfo["ParkMobile"] = {
                    PaginationEnd: false,
                    PaginationOffset: (tempData["ParkMobile"] ? tempData["ParkMobile"]?.length ?? 0 : tempPaginationInfo["ParkMobile"].PaginationOffset),
                    PaginationNext: 1000,
                    CurrentPage: (tabGroup === "ParkMobile") ? currentPage : tempPaginationInfo["ParkMobile"].CurrentPage ?? 1
                }
            }
            if (tabGroup === "DMV") {
                tempPaginationInfo["DMV"] = {
                    PaginationEnd: false,
                    PaginationOffset: (tempData["DMV"] ? tempData["DMV"]?.length ?? 0 : tempPaginationInfo["DMV"].PaginationOffset),
                    PaginationNext: 1000,
                    CurrentPage: (tabGroup === "DMV") ? currentPage : tempPaginationInfo["DMV"].CurrentPage ?? 1
                }
            }
            //DMV
            if (!etEnabled && !pmEnabled && !alprEnabled) {
                tempSearchQuery.ETicket = false
                tempSearchQuery.ALPRSearch = false
                tempSearchQuery.ParkMobile = false
                //
                jobs.push(RunVINQuery(tempSearchQuery))
            }
            //ETicket and ParkMobile
            if (etEnabled) {
                tempSearchQuery.ETicket = true
                tempSearchQuery.ALPRSearch = false
                tempSearchQuery.ParkMobile = false
                //
                jobs.push(RunVINQuery(tempSearchQuery))
                //
                tempSearchQuery.ALPRSearch = alprEnabled
                tempSearchQuery.ParkMobile = pmEnabled
            }
            if (pmEnabled) {
                tempSearchQuery.ETicket = false
                tempSearchQuery.ALPRSearch = false
                tempSearchQuery.ParkMobile = true
                //
                jobs.push(RunVINQuery(tempSearchQuery))
                //
                tempSearchQuery.ALPRSearch = alprEnabled
                tempSearchQuery.ETicket = etEnabled
            }
            //API: Search => Loop through all the vendors
            //ALPR
            if (alprEnabled) {
                for (const [key, val] of Object.entries(resALPRVendors)) {
                    let jsonData = JSON.parse(key);
                    let kGroup = jsonData['Group']
                    //
                    let val1: any = val
                    tempSearchQuery.ALPRVendor = []
                    alprVendorKeys = []
                    for (const [k, v] of Object.entries(val1)) {
                        let v1: any = v
                        alprVendorKeys.push(v1["Key"])
                    }
                    tempSearchQuery.ALPRVendor = alprVendorKeys
                    //
                    if (paginationInfo && paginationInfo[kGroup]) {
                        tempPaginationInfo = {
                            ...tempPaginationInfo, [kGroup]:
                            {//Update pagination info if it exists for the given tab
                                ...(tempPaginationInfo[kGroup] ?? {}),
                                PaginationEnd: false,
                                PaginationOffset:
                                    (tempData[kGroup] ? tempData[kGroup]?.length ?? 0 : tempPaginationInfo[kGroup].PaginationOffset),
                                PaginationNext: tempPaginationInfo[kGroup].PaginationNext,
                                CurrentPage: (tabGroup === kGroup) ? currentPage : tempPaginationInfo[kGroup].CurrentPage ?? 1
                            }
                        }
                    } else {
                        if (!paginationInfo) {
                            tempPaginationInfo = {
                                "ALL":
                                {
                                    PaginationEnd: false,
                                    PaginationOffset: 0,
                                    PaginationNext: 1000,
                                    CurrentPage: currentPage ?? 1
                                }
                            }
                        }
                        tempPaginationInfo = {
                            ...tempPaginationInfo, [kGroup]:
                            {//Default assigned
                                ...(tempPaginationInfo[kGroup] ?? {}),
                                PaginationEnd: false,
                                PaginationOffset: tempData[kGroup]?.length ?? 0,
                                PaginationNext: 1000,
                                CurrentPage: (tabGroup === kGroup) ? currentPage : 1
                            }
                        }
                    }
                    tempSearchQuery.PaginationOffset = tempPaginationInfo[kGroup].PaginationOffset ?? 0
                    tempSearchQuery.PaginationNext = tempPaginationInfo[kGroup].PaginationNext ?? 1000
                    //
                    jobs.push(RunVINQuery(tempSearchQuery))
                }
            }
            //
            setPaginationInfo(tempPaginationInfo)
            //
            Promise.all(jobs).then(jobResults => {
                //DMV
                if (!etEnabled && !pmEnabled && !alprEnabled) {
                    let res = JSON.parse((jobResults[0] as any)?.JsonObject)
                    setErrorData((errorData ?? []).concat(jobResults[0]?.AnyTable))
                    let kGroup: any = "DMV"
                    tempData = { ...tempData, [kGroup]: (tempData[kGroup] ?? []).concat(res) }
                }
                //ETicket
                if (etEnabled) {
                    let res = JSON.parse((jobResults[0] as any)?.JsonObject)
                    setErrorData((errorData ?? []).concat(jobResults[0]?.AnyTable))
                    let kGroup: any = "ETicket"
                    tempData = { ...tempData, [kGroup]: (tempData[kGroup] ?? []).concat(res) }
                }
                //ParkMobile
                if (pmEnabled) {
                    let res = JSON.parse((jobResults[etEnabled ? 1 : 0] as any)?.JsonObject)
                    setErrorData((errorData ?? []).concat(jobResults[etEnabled ? 1 : 0]?.AnyTable))
                    let kGroup: any = "ParkMobile"
                    tempData = { ...tempData, [kGroup]: (tempData[kGroup] ?? []).concat(res) }
                }
                //ALPR
                if (alprEnabled) {
                    let idx = (etEnabled && pmEnabled) ? 2 : ((etEnabled || pmEnabled) ? 1 : 0)
                    for (const [key, val] of Object.entries(resALPRVendors)) {
                        let jsonData = JSON.parse(key);
                        let kGroup = jsonData['Group']
                        let res = JSON.parse((jobResults[idx] as any)?.JsonObject)
                        //
                        setErrorData((errorData ?? []).concat(jobResults[idx]?.AnyTable))
                        //
                        tempData = { ...tempData, [kGroup]: (tempData[kGroup] ?? []).concat(res) }
                        idx++
                    }
                }
                setVinInfo(tempData)
            })
            //Promise.all(jobs).then(jobResults => {
            //    let idx = 0
            //    for (const [key, val] of Object.entries(resALPRVendors)) {
            //        let jsonData = JSON.parse(key);
            //        let kGroup = jsonData['Group']
            //        let res = JSON.parse((jobResults[idx] as any)?.JsonObject)
            //        tempData = { ...tempData, [kGroup]: (tempData[kGroup] ?? []).concat(res) }
            //        idx++
            //    }
            //    setVinInfo(tempData)
            //})
        }
        catch (e) {
            setVinInfo([])
            setPaginationInfo({})
            setErrorData([])
            setErrorData([{ ERROR: "Timed Out!", Record: "An ERROR has Occured while fetching the data please limit your search criteria." }])
        }
        return paginationInfo
    }

    return (
        <div id="ToolsVIN">
            {isUserPermission('LPR') ?
                <>
                    {query.get("PlateList") ?
                        <VinSearch SubmitSearch={SubmitSearch} data={query.get("PlateList")} />
                        :
                        <VinSearch SubmitSearch={SubmitSearch} />
                    }
                    <SearchResults NextPage={NextPage} PagingInfo={paginationInfo} VINInfo={vinInfo} Errors={errorData} />
                </>
                : <></>}
        </div>
    );
}

export default ALPRVINSearch;