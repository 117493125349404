import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Description';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    Checkbox, DialogActions, TablePagination, InputLabel, TextareaAutosize, Snackbar, Alert, AlertColor
} from '@mui/material';
import { getDropDownCity, getDropDownQuery } from "../../../services/getDropdown.service";
import { GetDefaultStateForSite, GetStringNameForSite } from "../../../interfaces/config.interface";
import {
    ALPRMultipleOccurenceQuery,
    GetDarInfo_Query,
    getDarInfoResponse
} from "../../../interfaces/getDarInfo_interface";
import {
    IWWGetRecord,
    IWWSaveRecord,
    IWWSendEmail    
} from "../../../services/getDar.service";
import { RunMultipleOccurrenceNoSplash } from "../../../services/getAlpr.service";
import PlateSearch from "../../../interfaces/PlateSearch.interface";
import { RunDarQuery } from "../../../services/getDar.service";
import { RunVINQuery, RunFetchVendorData } from "../../../services/getAlpr.service";
import { Account } from "../../../interfaces/auth_interface";
import { handleSearchTime } from "../../../services/formatDate.service";
import { formatDT } from "../../../services/formatDate.service";
import { getUser, isUserPermission } from "../../../services/auth.service";
import { SiteName } from "../../../services/config.service";

import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import HomelandSecurityLogo from '../../../assets/Images/DC_Homeland_Security.png';
import "./styles.css";
import { PIRFiles, PIRReport, PIRReportCameraList } from "../../../interfaces/PIR.interface";
import { readFileAsDataURL } from "../../../services/formatDate.service";
import { GetGUID, GetPIR, SetPIRFile, SetPIRReport, SearchPIR } from "../../../services/pir.service";
import { getCCTVCamerasInRange, getIncidentsInRange } from "../../../services/pir.service";
import Geocode from "react-geocode";
import PreliminaryCameraSearch from './_preliminaryCameraSearch';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface PreliminaryReportProps {
    handleReportSubmit: any,
    editReport: any,
    personDetails: any,
    currentTab: any
}

interface dropdown {
    value: string,
    key: string
}


const PreliminaryReportInputForm: React.FC<PreliminaryReportProps> = ({ handleReportSubmit, editReport = null, personDetails = null, currentTab }) => {
    const [stateList, setStateList] = React.useState<any>([]);
    const [cityList, setCityList] = React.useState<any>([]);
    const [heightDD, setHeightDD] = React.useState<dropdown[]>([]);
    const [exportData, setExportData] = React.useState<any>({});
    const [editIncomplete, setEditIncomplete] = React.useState<any>();
    const [report, setReport] = useState<PIRReport>({
        Summary_Requester: "RTCC",
        LprList: [{ GUID: GetGUID() }],
        ContributorList: [],
        CameraList: [],
        IncidentList: [],
        DYRSVeritracks_List: [{ GUID: GetGUID(), DYRS_VeritracksScreenshot: null }],
        ShotSpotter_List: [{ GUID: GetGUID(), ShotSpotter_Alerted: "No" }],
        Files: [],
        Completed: false
    });

    // Ref to store the latest report state
    const reportRef = useRef(report);

    // Keep the ref updated with the latest report state
    useEffect(() => {
        reportRef.current = report;
    }, [report]);

    const [user, setUser] = useState<Account>(getUser())

    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [alertType, setAlertType] = React.useState<AlertColor>("success");
    const [alertText, setAlertText] = React.useState<string>("Your report was successfully saved (not a final submission). You may continue or come back later.");

    const [isLoading, setIsLoading] = React.useState(true);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [locationCheck, setLocationCheck] = useState<any>("");

    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const [privateDraggedIndex, setPrivateDraggedIndex] = useState<number | null>(null);
    const [privateHoveredIndex, setPrivateHoveredIndex] = useState<number | null>(null);

    // Handle drag start
    const handleDragStart = (index: number) => {
        setDraggedIndex(index);
    };

    const handlePrivateDragStart = (index: number) => {
        setPrivateDraggedIndex(index);
    };

    // Handle drop
    const handleTableDrop = (droppedIndex: number, origination: string) => {
        const cameraList = report?.CameraList ?? [];

        // Separate logic for CCTV/Camera
        if (origination === "CCTV/CAMERA") {
            const filteredCameras = cameraList.filter(f => f.Camera_Type === "CCTV/CAMERA" && !f.deleted);

            if (draggedIndex === null || draggedIndex === droppedIndex) return;

            const reorderedFilteredCameras = [...filteredCameras];
            const [movedItem] = reorderedFilteredCameras.splice(draggedIndex, 1); // Remove dragged item
            reorderedFilteredCameras.splice(droppedIndex, 0, movedItem); // Insert it at the dropped position

            // Map the reordered filtered list back to the full list
            const reorderedList = cameraList.map(camera =>
                camera.Camera_Type === "CCTV/CAMERA" ? reorderedFilteredCameras.shift() : camera
            );

            // Update the report
            setReport((prevReport: any) => ({
                ...prevReport,
                CameraList: reorderedList,
            }));

            setDraggedIndex(null);
        }

        // Separate logic for Private Camera Locations
        else if (origination === "Private Camera Locations") {
            const filteredPrivateCameras = cameraList.filter(f => f.Camera_Type === "Private Camera Locations" && !f.deleted);

            if (privateDraggedIndex === null || privateDraggedIndex === droppedIndex) return;

            const reorderedPrivateCameras = [...filteredPrivateCameras];
            const [movedItem] = reorderedPrivateCameras.splice(privateDraggedIndex, 1); // Remove dragged item
            reorderedPrivateCameras.splice(droppedIndex, 0, movedItem); // Insert at dropped position

            // Map the reordered filtered list back to the full list
            const reorderedList = cameraList.map(camera =>
                camera.Camera_Type === "Private Camera Locations" ? reorderedPrivateCameras.shift() : camera
            );

            // Update the report
            setReport((prevReport: any) => ({
                ...prevReport,
                CameraList: reorderedList,
            }));

            setPrivateDraggedIndex(null);
        }
    };


    const [clearOpen, setClearOpen] = React.useState(false);
    const [cctvClearOpen, setCCTVClearOpen] = React.useState(false);
    const [privateClearOpen, setPrivateClearOpen] = React.useState(false);
    const [vehicleClearOpen, setVehicleClearOpen] = React.useState(false);
    const [cadClearOpen, setCadClearOpen] = React.useState(false);
    const [dyrsClearOpen, setDyrsClearOpen] = React.useState(false);
    const [shotSpotterClearOpen, setShotSpotterClearOpen] = React.useState(false);
    const [cameraSearchOpen, setCameraSearchOpen] = React.useState(false);
    const [excelOpen, setExcelOpen] = React.useState(false);
    const [excelPrivateOpen, setExcelPrivateOpen] = React.useState(false);
    const [incompleteReportOpen, setIncompleteReportOpen] = React.useState(false);
    const [isAutoSaveEnabled, setIsAutoSaveEnabled] = React.useState(true);

    const [incompleteReports, setIncompleteReports] = React.useState<any[]>();
    const [incompleteReportID, setIncompleteReportID] = React.useState<number>(0);

    const weekdays = [
        { label: "Sunday", value: "Sunday" },
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday" }
    ];

    const currentDate = new Date();

    const startDate = new Date();
    startDate.setDate(currentDate.getDate() - 90);
    startDate.setHours(0, 0, 0, 0);

    React.useEffect(() => {
        handleInitialBuild()
        try {
            if (editReport || incompleteReportID !== 0) {
                console.log("Edit report function running")
                GetPIR(`${editReport ? editReport : incompleteReportID}`).then((r: any) => {
                    if (r && r?.length > 0) {
                        var report: PIRReport = r[0]
                        if (report?.Files && report?.Files.length > 0) {
                            for (var file of report?.Files) {
                                try {
                                    if (file.Origination == "Individual Photo" || file.Origination == "Veritracks Screenshot") {
                                        var index = report?.DYRSVeritracks_List?.findIndex(f => f.ID === file?.ParentID)
                                        if ((index as any) >= 0 && report?.DYRSVeritracks_List && report?.DYRSVeritracks_List[(index as any)]) {
                                            switch (file.Origination) {
                                                case "Individual Photo":
                                                    report.DYRSVeritracks_List[(index as any)].DYRS_IndividualPhoto = file
                                                    break;
                                                case "Veritracks Screenshot":
                                                    report.DYRSVeritracks_List[(index as any)].DYRS_VeritracksScreenshot = file
                                                    break;

                                            }
                                        }
                                    }
                                    else if (file.Origination == "ShotSpotter Screenshots") {
                                        var index = report?.ShotSpotter_List?.findIndex((f: any) => f.ID === file?.ParentID)
                                        if ((index as any) >= 0 && report?.ShotSpotter_List) {

                                            if (report?.ShotSpotter_List[(index as any)]) {

                                                if (!report?.ShotSpotter_List[(index as any)]?.Files)
                                                    report.ShotSpotter_List[(index as any)].Files = []

                                                if (report?.ShotSpotter_List[(index as any)]?.Files)
                                                    report?.ShotSpotter_List[(index as any)]?.Files?.push(file)
                                            }

                                        }
                                    }
                                    else if (file.Origination == "LPR Screenshots") {
                                        var index = report?.LprList?.findIndex((f: any) => f.ID === file?.ParentID)
                                        if ((index as any) >= 0 && report?.LprList) {

                                            if (report?.LprList[(index as any)]) {

                                                if (!report?.LprList[(index as any)]?.Files)
                                                    report.LprList[(index as any)].Files = []

                                                if (report?.LprList[(index as any)]?.Files)
                                                    report?.LprList[(index as any)]?.Files?.push(file)
                                            }

                                        }
                                    }
                                }
                                catch (error) {
                                    console.error("Edit report file error: ", error);
                                }
                            }
                        }
                        try {
                            if (!report?.ShotSpotter_List) {
                                report.ShotSpotter_List = [{ GUID: GetGUID() }]
                            }
                        }
                        catch (error) {
                            console.error("Edit report ShotSpotter error: ", error);
                        }
                        console.log("About to set report...")
                        setReport(report)
                    }

                    setIsLoading(false)
                })
            } else {
                try {
                    if (incompleteReportID === 0) {
                        SearchPIR({
                            SearchType: 'AND',
                            DateType: null,
                            FromDate: null,
                            ToDate: null,
                            FirstName: null,
                            MiddleName: null,
                            LastName: null,
                            CaseNumber: null,
                            ReportNumber: null,
                            District: null,
                            PSA: null,
                            IncidentType: null,
                            Location: null,
                            FBI: null,
                            PDID: null,
                            DOB: null,
                            DLNo: null,
                            DLSt: null,
                            PltNo: null,
                            PltSt: null,
                            IncludeIncompleteRecords: true,
                        }).then((r: any) => {
                            console.log(r)
                            if (r != null && r.length > 0) {
                                handleDialog("Incomplete Report", true)
                                setIncompleteReports(r);
                            }
                        })
                    }
                }
                catch (error) {
                    console.error("Incomplete report search failed: ", error);
                }
                setReport({
                    Summary_Requester: "RTCC",
                    LprList: [{ GUID: GetGUID() }],
                    CameraList: [],
                    IncidentList: [],
                    DYRSVeritracks_List: [{ GUID: GetGUID() }],
                    ShotSpotter_List: [{ GUID: GetGUID() }],
                    ContributorList: []
                })
                setIsLoading(false)
            }
        }
        catch (error) {
            console.error("Edit Error: ", error);
        }
    }, [personDetails, editReport, incompleteReportID])

    React.useEffect(() => {
        if (report?.ShotSpotter_List && report?.ShotSpotter_List.length === 0) {
            setReport((prevReport: any) => ({
                ...prevReport,
                ShotSpotter_Disabled: true
            }));
        }
    }, [report?.ShotSpotter_List])


    const handleInitialBuild = async () => {
        await handleDropdowns()
    }

    const handleDropdowns = async () => {
        setStateList([])
        var state = await getDropDownQuery('State')
        if (state && state?.length > 0) {
            setStateList(state)
        }
        var city = await getDropDownCity(GetDefaultStateForSite(SiteName))
        let tempList = city?.filter(x => x.State === GetDefaultStateForSite(SiteName))
        const ids = tempList.map(({ City }) => City);
        const filtered = tempList.filter(({ City }, index) =>
            !ids.includes(City, index + 1));
        setCityList(filtered)

        createHeightDropDown()

    }

    const createHeightDropDown = () => {
        let temp = heightDD
        for (var i = 20; i <= 108; i++) {
            let h = "" + Math.floor(i / 12) + "' " + i % 12 + '"'
            temp.push({ value: h, key: h })
        }
        setHeightDD(temp)
    }

    //const handleChangePage = (event: unknown, newPage: number) => {
    //    setPage(newPage);
    //};

    //const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //    setRowsPerPage(parseInt(event.target.value, 10));
    //    setPage(0);
    //};

    const handleDialog = (section: string, value: boolean) => {
        switch (section) {
            case 'CCTV':
                setCCTVClearOpen(value);
                break;
            case 'Private Camera':
                setPrivateClearOpen(value);
                break;
            case 'Incident':
                setClearOpen(value);
                break
            case 'LPR':
                setVehicleClearOpen(value);
                break
            case 'CAD':
                setCadClearOpen(value);
                break
            case 'Shot Spotter':
                setShotSpotterClearOpen(value);
                break
            case 'DYRS':
                setDyrsClearOpen(value);
                break;
            case 'Camera Search':
                setCameraSearchOpen(value);
                break;
            case 'Excel':
                setExcelOpen(value);
                break;
            case 'Private Excel':
                setExcelPrivateOpen(value);
                break;
            case 'Incomplete Report':
                setIncompleteReportOpen(value);
                break;
        }

    }

    const handleCameraSubmit = (cameras: PIRReportCameraList[]) => {
        setReport(prevReport => ({
            ...prevReport,
            CameraList: prevReport.CameraList ? [...prevReport.CameraList, ...cameras] : cameras
        }));
    };

    const handleSearchCamera = async () => {
        if (report?.Summary_Location && report?.Summary_Location?.length) {
            let response = await Geocode.fromAddress(report?.Summary_Location + ', Washington, DC')
            const { lat, lng } = response.results[0].geometry.location
            console.log(lat, lng)

            var temp: any = { ...report }

            if (lat && lng) {
                let res = await getCCTVCamerasInRange(lat, lng, '1250')
                console.log(res)
                let newCameras: any = res.map((camera: any, index: number) => ({
                    Camera_Type: "CCTV/CAMERA",
                    CCTV_Department: camera?.AgencyName ?? "",
                    CCTV_Location: camera.Name,
                    CCTV_Distance: (camera.distance * 3.2808399).toFixed(2)
                }));

                return newCameras;
            }
        }
        return [];
    }

    const handleSearchIncidents = async () => {
        if (report?.Summary_Location && report?.Summary_Location?.length > 0 && report?.Summary_DateTime) {
            const date = new Date(report?.Summary_DateTime)
            let response = await Geocode.fromAddress(report?.Summary_Location + ', Washington, DC')

            const { lat, lng } = response.results[0].geometry.location
            console.log(lat, lng)

            if (lat && lng) {
                let res = await getIncidentsInRange(lat, lng, '1250', date)
                console.log(res)

                const getDayOfWeek = (dateString: string) => {
                    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                    const date = new Date(dateString);
                    return daysOfWeek[date.getDay()];
                };

                let newIncidents = (res || []).map((incident: any) => ({
                    Incident_CCN: incident.CaseNumber,
                    Incident_EventDateTime: formatDT(incident.CreationDate),
                    Incident_Offense: (incident.STATUTEDESCRIPTION != null ? incident.STATUTEDESCRIPTION : ""),
                    Incident_Address: (incident.StreetNumber != null ? incident.StreetNumber : "") + " " + (incident.StreetName != null ? incident.StreetName : ""),
                    Incident_City: incident.City === "WASHINGTON" ? incident.City + " DC" : incident.City,
                    Incident_DayOfWeek: getDayOfWeek(incident.CreationDate),
                    Incident_Weapon: incident.Weapon ?? "",
                    Incident_PSA: incident.PSA ?? ""
                }));

                return newIncidents;
            }
        }
        return [];
    }

    // Helper function for timeout
    const timeoutPromise = (ms: number) =>
        new Promise((_, reject) => setTimeout(() => reject(new Error("Timeout")), ms));

    const handleLPRSearch = async () => {
        let temp: any = {...report }

        console.log("Function called")

        let zip: any = null;

        let latitude: any = null;
        let longitude: any = null;
        if (report?.Summary_Location) {
            let response = await Geocode.fromAddress(report?.Summary_Location + ', Washington, DC');
            const { lat, lng } = response.results[0].geometry.location;
            latitude = lat;
            longitude = lng;
        }

        if (report?.Summary_DateTime && report?.Summary_DateTime.length > 0) {
            let search_query: ALPRMultipleOccurenceQuery = {
                "incidents": [
                    {
                        date: report?.Summary_DateTime.toLocaleString(),
                        threshold: 8,
                        radius: 1.5, //should convert to roughly 1250 ft***
                        location: {
                            address: report?.Summary_Location ?? "",
                            city: report?.Summary_PSA ?? "",
                            state: "DC",
                            zip: zip,
                            latitude: latitude,
                            longitude: longitude,
                            addressType: ""
                        },
                        showEdit: true,
                        alprServerDB: "",
                        alprServerName: ""
                    }
                ],
                minOccurrence: 0,
                lookFor: "Car",
                plate: {
                    plateNumber: "",
                    plateSt: ""
                },
                queryType: "M",
                alprReason: "Suspicious Incident",
                alprCaseNumber: "Automated",
                UTCDate: false,
            }
                let res: any = await RunMultipleOccurrenceNoSplash(search_query)

                console.log(res)

                if (!res || res.length === 0) {
                    console.warn("LPR search timed out");
                    return; // Stop further execution if timeout occurs
                }
            let parsed = JSON.parse(res?.JsonObject)

            // Function to calculate distance between two lat/lng points
            const haversineDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
                const R = 3958.8; // Radius of Earth in miles
                const rad = (deg: number) => (deg * Math.PI) / 180;
                const dLat = rad(lat2 - lat1);
                const dLon = rad(lon2 - lon1);
                const a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(rad(lat1)) * Math.cos(rad(lat2)) *
                    Math.sin(dLon / 2) * Math.sin(dLon / 2);
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                return R * c; // Distance in miles
            };

            const date = new Date(report?.Summary_DateTime)
            const nowDate = new Date();
            const startDate = new Date(date.getTime() - 15 * 60000)
            const endDate = new Date(date.getTime() + 15 * 60000)

            // 1250 feet converted to miles
            const distanceLimitInMiles = 1250 / 5280;

            if (parsed.MultipleOccurrenceList.length > 0) {
                let filteredLprList = parsed.MultipleOccurrenceList.filter((occurrence: any) => {
                    if (occurrence.Latitude && occurrence.Longitude && latitude && longitude) {
                        const distance = haversineDistance(latitude, longitude, occurrence.Latitude, occurrence.Longitude);
                        return distance <= distanceLimitInMiles; // Check if within 1250 feet
                    }
                    return false;
                });

                // Map the relevant fields and set the data
                let LprList = await Promise.all(filteredLprList.map(async (occurrence: any) => {
                    let address = '';

                    // Check if Latitude and Longitude exist before fetching the address
                    if (occurrence.Latitude && occurrence.Longitude) {
                        try {
                            const response = await Geocode.fromLatLng(
                                occurrence.Latitude.toString(),
                                occurrence.Longitude.toString()
                            );
                            address = response.results[0]?.formatted_address || 'Address not found';
                        } catch (error) {
                            console.error("Error fetching address for coordinates: ", error);
                            address = 'Error retrieving address';
                        }
                    }

                    // Return the mapped object with the formatted address
                    return {
                        GUID: GetGUID(),
                        LPR_Queried: "Yes",
                        LPR_QueryDateTime: nowDate.toLocaleString(),
                        LPR_CountOfHits: "1",
                        LPR_PlateNo: occurrence.red_vrm,
                        LPR_LastHitDateTime: occurrence.red_TimeStamp,
                        LPR_Camera_Location: address,
                        LPR_SearchStartDate: startDate.toLocaleString(),
                        LPR_SearchEndDate: endDate.toLocaleString()
                    };
                }));

                setReport((prevReport: any) => ({
                    ...prevReport,
                    LprList: LprList
                }));
                console.log("Filtered LPR list: ", LprList);
            }
        }
    }

    const runLPRSearchWithTimeout = async () => {
        try {
            await Promise.race([
                handleLPRSearch(), // Original LPR search function
                timeoutPromise(2000) // Timeout after 2 seconds
            ]);
            console.log("LPR search completed within time limit.");
        } catch (error) {
            console.warn("LPR search timed out or failed:", error);
        }
    };

    const handleInputChange = (e: { target: { name: string; value: any; }; }, table: string = "", item: any = null) => {
        const { name, value } = e.target;
        let temp: any = { ...report }

        if (name === "CCTV_School" && value !== "") {
            temp["CCTV_SchoolNotReviewable"] = true;
        }
        else if (name === "CCTV_School") {
            temp["CCTV_SchoolNotReviewable"] = false;
        }

        if (table?.length > 0 && item) {
            let index = temp[table]?.findIndex((f: any) => f === item)
            if (index >= 0) {
                temp[table][index][name] = (value instanceof Date ? value.toLocaleString() : value)
            }
        }
        else {
            temp[name] = (value instanceof Date ? value.toLocaleString() : value)
        }

        setReport(temp)
    };

    //const handleFileChange = (
    //    event: React.ChangeEvent<HTMLInputElement>,
    //    originationOrIndex: string | number,
    //    origination?: string
    //) => {
    //    const file = event.target.files?.[0];
    //    if (file) {
    //        if (typeof originationOrIndex === 'string') {
    //            // Single upload without index
    //            handlePasteOrDrop(file, null, originationOrIndex); // Using -1 as a placeholder index
    //        } else {
    //            // Multi-upload with index
    //            handlePasteOrDrop(file, null, origination!);
    //        }
    //    }
    //};

    //const handleCCTVFileChange = async (
    //    event: React.ChangeEvent<HTMLInputElement> | React.ClipboardEvent<HTMLDivElement> | React.DragEvent<HTMLDivElement>
    //) => {
    //    if ('files' in event.target && event.target.files) {
    //        for (let i = 0; i < event.target.files.length; i++) {
    //            const file = event.target.files.item(i);
    //            if (file) {
    //                handlePasteOrDrop(file, null, "CCTV Screenshots");
    //            }
    //        }
    //    } else if ('clipboardData' in event) {
    //        const items = event.clipboardData.items;
    //        for (let i = 0; i < items.length; i++) {
    //            if (items[i].type.indexOf('image') !== -1) {
    //                const file = items[i].getAsFile();
    //                if (file) {
    //                    handlePasteOrDrop(file, null, "CCTV Screenshots");
    //                }
    //            }
    //        }
    //    } else if ('dataTransfer' in event) {
    //        const files = event.dataTransfer.files;
    //        if (files.length > 0) {
    //            for (let i = 0; i < files.length; i++) {
    //                const file = files[i];
    //                if (file) {
    //                    handlePasteOrDrop(file, null, "CCTV Screenshots");
    //                }
    //            }
    //        }
    //    }

    //    if (event.target instanceof HTMLInputElement) {
    //        event.target.value = ''; // Clear the input value
    //    }
    //};

    const handleShotSpotterFileChange = async (e: ChangeEvent<HTMLInputElement>, item: any) => {
        if (e.target.files && e.target.files.length > 0) {
            let temp = { ...report }
            let files = e.target.files

            if (temp?.ShotSpotter_List && temp?.ShotSpotter_List?.length > 0) {
                var shotIndex = temp?.ShotSpotter_List?.findIndex((f: any) => f == item)

                if ((shotIndex as any) >= 0) {

                    if (!temp?.ShotSpotter_List[(shotIndex as any)]?.Files)
                        temp.ShotSpotter_List[(shotIndex as any)].Files = []

                    for (var index = 0; index < files?.length; index++) {
                        let file = files?.item(index)
                        if (file && temp?.ShotSpotter_List[(shotIndex as any)]?.Files) {
                            let fileData: any = await readFileAsDataURL(file)
                            temp?.ShotSpotter_List[(shotIndex as any)]?.Files?.push({
                                Content: fileData?.toString().split('base64,')[1],
                                FileName: file.name,
                                FileEncoding: file.type,
                                Origination: "ShotSpotter Screenshots"
                            });
                        }
                    }
                }
            }
            setReport(temp)

        }
    }

    //const handleLPRFileChange = async (
    //    event: React.ChangeEvent<HTMLInputElement> | React.ClipboardEvent<HTMLDivElement> | React.DragEvent<HTMLDivElement>,
    //    index: number, 
    //) => {
    //    if ('files' in event.target && event.target.files) {
    //        // Handling files from input element (upload button)
    //        for (let i = 0; i < event.target.files.length; i++) {
    //            const file = event.target.files.item(i);
    //            if (file) {
    //                handlePasteOrDrop(file, null, "LprList");
    //            }
    //        }
    //    } else if ('clipboardData' in event) {
    //        // Handling paste event
    //        const items = event.clipboardData.items;
    //        for (let i = 0; i < items.length; i++) {
    //            if (items[i].type.indexOf('image') !== -1) {
    //                const file = items[i].getAsFile();
    //                if (file) {
    //                    handlePasteOrDrop(file, null, "LprList");
    //                }
    //            }
    //        }
    //    } else if ('dataTransfer' in event) {
    //        // Handling drag-and-drop event
    //        const files = event.dataTransfer.files;
    //        if (files.length > 0) {
    //            for (let i = 0; i < files.length; i++) {
    //                const file = files[i];
    //                if (file) {
    //                    handlePasteOrDrop(file, null, "LprList");
    //                }
    //            }
    //        }
    //    }

    //    if (event.target instanceof HTMLInputElement) {
    //        event.target.value = ''; // Clear the input value
    //    }
    //};


    //const handleDYRSFileChange = async (
    //    event: React.ChangeEvent<HTMLInputElement> | React.ClipboardEvent<HTMLDivElement> | React.DragEvent<HTMLDivElement>,
    //    index: number
    //) => {
    //    if ('files' in event.target && event.target.files) {
    //        for (let i = 0; i < event.target.files.length; i++) {
    //            const file = event.target.files.item(i);
    //            if (file) {
    //                handlePasteOrDrop(file, null, "DYRSVeritracks_List");
    //            }
    //        }
    //    } else if ('clipboardData' in event) {
    //        const items = event.clipboardData.items;
    //        for (let i = 0; i < items.length; i++) {
    //            if (items[i].type.indexOf('image') !== -1) {
    //                const file = items[i].getAsFile();
    //                if (file) {
    //                    handlePasteOrDrop(file, null, "DYRSVeritracks_List");
    //                }
    //            }
    //        }
    //    } else if ('dataTransfer' in event) {
    //        const files = event.dataTransfer.files;
    //        if (files.length > 0) {
    //            for (let i = 0; i < files.length; i++) {
    //                const file = files[i];
    //                if (file) {
    //                    handlePasteOrDrop(file, null, "DYRSVeritracks_List");
    //                }
    //            }
    //        }
    //    }

    //    if (event.target instanceof HTMLInputElement) {
    //        event.target.value = ''; // Clear the input value
    //    }
    //};


    const pasteAreaRef = useRef<HTMLDivElement>(null);

    const handlePasteOrDrop = (file: File, item: any | null, origination: string, table: string = "") => {
        let temp = { ...report }
        /*        console.log("Dropping image file:", file);*/
        console.log("Item: ", item)
        console.log("Origination: ", origination)
        console.log("Table: ", table)
        const reader = new FileReader();

        if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/svg+xml" && file.type !== "image/webp") {
            window.alert("The file you attempted to attach is in an unsupported format. Please try uploading a PNG, JPG, SVG or WEBP image.");
            return;
        }

        reader.onload = (event) => {
            const result = event.target?.result;
            /*            console.log("FileReader result:", result);*/

            if (typeof result === 'string') {
                setReport((prevReport: any) => {
                    if (table !== "") {
                        let index: number | undefined = undefined;

                        if (table === "DYRSVeritracks_List") {
                            index = temp?.DYRSVeritracks_List?.findIndex((f: any) => f.GUID === item.GUID)
                            console.log(temp?.DYRSVeritracks_List?.findIndex((f: any) => f.GUID === item.GUID))
                            if (index !== null && (index as number) >= 0) {
                                console.log("Index: ", index)
                                if (origination === "Veritracks Screenshot") {
                                    item.DYRS_VeritracksScreenshot = {
                                        Content: result.split('base64,')[1],
                                        FileName: file.name,
                                        FileEncoding: file.type,
                                        Origination: origination,
                                    }
                                }
                                else if (origination === "Individual Photo") {
                                    item.DYRS_IndividualPhoto = {
                                        Content: result.split('base64,')[1],
                                        FileName: file.name,
                                        FileEncoding: file.type,
                                        Origination: origination,
                                    }
                                }

                                if (temp && temp.DYRSVeritracks_List) {
                                    temp.DYRSVeritracks_List[index as number] = item;
                                    console.log("Temp report: ", temp)
                                    return (temp)
                                }
                            }



                        }

                        else if (table === "LprList") {
                            let index = temp?.LprList?.findIndex((f: any) => f.GUID === item.GUID)
                            console.log(temp.LprList)
                            if (index as number >= 0) {
                                if (!Array.isArray(item.Files)) {
                                    item.Files = [];
                                }

                                item.Files.push({
                                    Content: result.split('base64,')[1],
                                    FileName: file.name,
                                    FileEncoding: file.type,
                                    Origination: origination,
                                });

                                if (temp.LprList) {
                                    temp.LprList[index as number] = item;
                                }

                            }
                            return temp;
                        }

                        else if (table === "ShotSpotter_List") {
                            let index = temp?.ShotSpotter_List?.findIndex((f: any) => f.GUID === item.GUID)
                            console.log(temp?.ShotSpotter_List)
                            if (index as number >= 0) {
                                if (!Array.isArray(item.Files)) {
                                    item.Files = []
                                }

                                item.Files.push({
                                    Content: result.split('base64,')[1],
                                    FileName: file.name,
                                    FileEncoding: file.type,
                                    Origination: origination,
                                })

                                if (temp.ShotSpotter_List) {
                                    temp.ShotSpotter_List[index as number] = item;
                                }
                            }
                            return temp;
                        }

                    } else {
                        const existingFiles = Array.isArray(prevReport.Files) ? prevReport.Files : [];
                        const updatedFiles = [...existingFiles];

                        updatedFiles.push({
                            Content: result.split('base64,')[1],
                            FileName: file.name,
                            FileEncoding: file.type,
                            Origination: origination,
                        });

                        console.log("Updated Files list:", updatedFiles);
                        return { ...prevReport, Files: updatedFiles };
                    }
                });
            }
        };

        console.log("Report state: ", report);
        reader.readAsDataURL(file);
    };





    const handleContextMenu = async (
        event: React.MouseEvent<HTMLDivElement>,
        item: any | null, // Use GUID for lists or null for standalone areas
        origination: string,
        table: string = ""
    ) => {
        event.preventDefault();
        console.log("Context menu triggered for origination: ", origination);

        const menu = document.createElement('div');
        menu.innerHTML = `
        <ul style="list-style: none; padding: 10px; border: 1px solid #ccc; background: #fff;">
            <li style="margin-bottom: 5px; cursor: pointer;" id="paste-option">Paste</li>
        </ul>
    `;
        menu.style.position = 'absolute';
        menu.style.left = `${event.pageX}px`;
        menu.style.top = `${event.pageY}px`;
        document.body.appendChild(menu);

        const pasteOption = document.getElementById('paste-option');
        if (pasteOption) {
            pasteOption.addEventListener('click', async () => {
                console.log("Paste option clicked for origination: ", origination);

                try {
                    const clipboardItems = await navigator.clipboard.read();
                    let imageBlob: Blob | null = null;
                    let originalFileName: string | undefined;

                    for (const item of clipboardItems) {
                        console.log('Clipboard item types:', item.types); // Debugging
                        const imageType = item.types.find(type => type.startsWith('image/'));
                        if (imageType) {
                            imageBlob = await item.getType(imageType);
                            originalFileName = `pasted-image.${imageBlob.type.split('/')[1]}`; // Default to extension from MIME type
                            break; // Stop after finding the first image
                        }
                    }

                    if (imageBlob) {
                        console.log("Image Blob received for origination: ", origination);
                        const file = new File([imageBlob], originalFileName || 'pasted-image.png', { type: imageBlob.type });
                        handlePasteOrDrop(file, item, origination, table); // Use the item and origination
                    } else {
                        console.warn("No valid image found in clipboard, skipping HTML data");
                    }
                } catch (err) {
                    console.error('Failed to read clipboard contents: ', err);
                } finally {
                    if (menu && menu.parentNode) {
                        menu.parentNode.removeChild(menu);
                    }
                }
            });
        }

        document.addEventListener('click', () => {
            if (menu && menu.parentNode) {
                menu.parentNode.removeChild(menu);
            }
        }, { once: true });
    };



    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement | HTMLTextAreaElement>, item: any | null, origination: string, table: string = "") => {
        event.preventDefault();

        const clipboardItems = event.clipboardData.items;
        let imageBlob: Blob | null = null;
        let originalFileName: string | undefined;
        let clipboardText: string | null = null;

        for (let i = 0; i < clipboardItems.length; i++) {
            const item = clipboardItems[i];
            console.log('Clipboard item types:', item.type); // Debugging

            if (item.type.startsWith('image/')) {
                imageBlob = item.getAsFile();
                originalFileName = `pasted-image.${imageBlob?.type.split('/')[1]}`; // Default to extension from MIME type
                break; // Stop after finding the first image
            }

            if (item.type === 'text/plain') {
                clipboardText = event.clipboardData.getData('Text');
                break;
            }
        }

        if (imageBlob) {
            console.log("Image Blob received for origination: ", origination);
            const file = new File([imageBlob], originalFileName || 'pasted-image.png', { type: imageBlob.type });
            handlePasteOrDrop(file, item, origination, table); // Use the item and origination
        }
        else if (clipboardText) {
            console.log("Text data received:", clipboardText);
            console.log("Text data being sent to origination: ", origination)

            const rows = clipboardText.split('\n').filter(row => row.trim() !== ''); // Filter out any empty rows
            const newCameras = rows.map((row) => {
                const columns = row.split('\t');
                if (origination === "CCTV/CAMERA") {
                    return {
                        CCTV_Distance: columns[0]?.trim() ?? "",
                        CCTV_Location: columns[1]?.trim() ?? "",
                        CCTV_Department: columns[2]?.trim() ?? "",
                        Camera_Type: origination
                    };
                }
                else if (origination === "Private Camera Locations") {
                    return {
                        CCTV_Distance: columns[0]?.trim() ?? "",
                        CCTV_Location: columns[1]?.trim() ?? "",
                        ContactFirstName: columns[2]?.trim() ?? "",
                        ContactLastName: columns[3]?.trim() ?? "",
                        Camera_Type: origination
                    };
                }
            });

            // Add the new cameras to the existing list
            setReport((prevReport: any) => ({
                ...prevReport,
                CameraList: [...prevReport.CameraList, ...newCameras],
            }));
        }
        else {
            console.warn("No valid image found in clipboard");
        }
    };



    const handleDrop = (event: React.DragEvent<HTMLDivElement>, item: any | null, origination: string, table: string = "") => {
        console.log("Event: ", event)
        console.log("Item: ", item)
        console.log("Origination: ", origination)
        console.log("Table: ", table)
        event.preventDefault();
        console.log("Dropping image for origination: ", origination);

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0]; // Assuming only one file is handled per drop event
            console.log("File dropped: ", file);
            handlePasteOrDrop(file, item, origination, table); // Use the item and origination
        } else {
            console.warn("No valid image found in drop event");
        }
    };




    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };


    //const handleRemoveDYRSFile = (item: any, origination: string) => {
    //    let temp = { ...report }
    //    let index = temp?.DYRSVeritracks_List?.findIndex((f: any) => f === item)

    //    if ((index as any) >= 0) {
    //        switch (origination) {
    //            case "Veritracks Screenshot":
    //                if (temp?.DYRSVeritracks_List && temp?.DYRSVeritracks_List[(index as any)]) {
    //                    if (temp.DYRSVeritracks_List[(index as any)].DYRS_VeritracksScreenshot?.ID) {
    //                        temp!.DYRSVeritracks_List[(index as any)]!.DYRS_VeritracksScreenshot!.deleted = true;
    //                    } else {
    //                        temp.DYRSVeritracks_List[(index as any)].DYRS_VeritracksScreenshot = null;
    //                    }
    //                }
    //                break;
    //            case "Individual Photo":
    //                if (temp?.DYRSVeritracks_List && temp?.DYRSVeritracks_List[(index as any)]) {
    //                    if (temp.DYRSVeritracks_List[(index as any)].DYRS_IndividualPhoto?.ID) {
    //                        temp!.DYRSVeritracks_List[(index as any)]!.DYRS_IndividualPhoto!.deleted = true;
    //                    } else {
    //                        temp.DYRSVeritracks_List[(index as any)].DYRS_IndividualPhoto = null;
    //                    }
    //                }
    //                break;
    //        }
    //    }
    //    setReport({ ...temp })
    //}

    const handleRemoveFile = (file: any, table: string = "", item: any = null) => {
        let temp: any = { ...report };

        if (item && table?.length > 0) {
            let objectIndex = temp[table]?.findIndex((fi: any) => fi === item);
            console.log("Object index: ", objectIndex)
            if ((objectIndex as any) >= 0) {
                // Handle removal based on the type of file (VeritracksScreenshot or IndividualPhoto)
                let targetItem = temp[table][objectIndex];
                if ((table === "LprList" || table === "ShotSpotter_List") && targetItem?.Files) {
                    let findIndex = targetItem.Files.findIndex((f: any) => f === file);
                    if (findIndex >= 0) {
                        if (targetItem.Files[findIndex]?.ID && targetItem.Files[findIndex].ID > 0) {
                            targetItem.Files[findIndex].deleted = true;
                        } else {
                            targetItem.Files.splice(findIndex, 1);
                        }
                    }
                }

                if (file?.Origination === "Veritracks Screenshot" && targetItem?.DYRS_VeritracksScreenshot) {
                    if (targetItem.DYRS_VeritracksScreenshot.ID > 0) {
                        targetItem.DYRS_VeritracksScreenshot.deleted = true;
                    } else {
                        targetItem.DYRS_VeritracksScreenshot = null;
                    }
                    console.log("Veritracks Screenshot removed.");
                } else if (file?.Origination === "Individual Photo" && targetItem?.DYRS_IndividualPhoto) {
                    if (targetItem.DYRS_IndividualPhoto.ID > 0) {
                        targetItem.DYRS_IndividualPhoto.deleted = true;
                    } else {
                        targetItem.DYRS_IndividualPhoto = null;
                    }
                    console.log("Individual Photo removed.");
                }
                else {
                    let findIndex = temp[table][objectIndex]?.Files?.findIndex((f: any) => f === file);
                    console.log("Find index: ", findIndex)
                    if ((findIndex as any) >= 0) {
                        if (temp[table][objectIndex]?.Files[findIndex]?.ID && (temp[table][objectIndex]?.Files[findIndex].ID as any) > 0) {
                            temp[table][objectIndex].Files[findIndex].deleted = true;
                        } else {
                            temp[table][objectIndex]?.Files?.splice(findIndex, 1);
                        }
                    }
                }
            }
        } else if (temp?.Files && temp?.Files?.length > 0) {
            let findIndex = temp?.Files?.findIndex((f: any) => f === file);
            if ((findIndex as any) >= 0) {
                if (temp?.Files[findIndex]?.ID && (temp.Files[findIndex].ID as any) > 0) {
                    temp.Files[findIndex].deleted = true;
                } else {
                    temp?.Files?.splice(findIndex, 1);
                }
            }
        }

        
        setReport(temp); // This will trigger a re-render with the updated state
    };

    const handleDeleteIncompleteReport = (index: number) => {
        if (window.confirm("Are you sure you would like to delete this incomplete report?")) {
            const updatedReports = incompleteReports?.filter((_, i) => i !== index);
            setIncompleteReports(updatedReports); // Use the state update function
        }
    };



    const addTableRow = (table: string, field: string = "", value: string = "") => {
        var temp: any = { ...report }

        if (!Array.isArray(temp[table])) {
            temp[table] = [];
        }

        if (table === "CameraList") {
            if (field === "Camera_Type" && value === "CCTV/CAMERA") {
                let newRow = {
                    Camera_Type: "CCTV/CAMERA",
                    CCTV_Distance: "",
                    CCTV_Location: "",
                    CCTV_Department: "",
                };
                (newRow as any).isManualEntry = true;
                temp[table].push(newRow);
            }
            else if (field && value) {
                let newRow = { [field]: value };
                temp[table].push(newRow);
            } else {
                temp[table].push({});
            }
        } else if (field && value) {
            let newRow = { [field]: value };
            temp[table].push(newRow);
        } else {
            temp[table].push({});
        }

        setReport(temp)
    };


    const deleteTableRow = (table: string, item: any) => {
        var temp: any = { ...report }
        console.log("Before deletion:", temp[table]);
        if (table?.length > 0 && item) {
            if (temp[table]) {
                let index = temp[table]?.findIndex((f: any) => f === item)
                console.log("Deleting index:", index, "Item:", item);
                if ((index as any) >= 0) {
                    if (temp[table][index]?.ID && (temp[table][index].ID as any) > 0) {
                        temp[table][index].deleted = true
                    } else {
                        temp[table]?.splice(index, 1)
                    }

                    if (table === "DYRSVeritracks_List" && temp[table].length === 0) {
                        temp.DYRS_ReturnedCloseProximity = false;
                        const events = [
                            { target: { name: "DYRS_ReturnedCloseProximity", checked: false } }
                        ];
                        events.forEach(event => handleCheckboxChange(event));
                        temp[table] = [];
                    }
                    console.log("After deletion:", temp[table]);
                    setReport(temp)
                }
            }
        }
    };

    //const clearTableRows = (table:string, field:string = "", value:string = "") => {
    //    let temp:any = {...report}
    //    if(temp[table]) {
    //        for (var i of temp[table]?.filter((f:any) => (field?.length > 0 && value?.length > 0 ? f[field] == value : true) && !f?.deleted)) {
    //            if ((i?.ID as any) > 0) {
    //                i.deleted = true
    //            }
    //            else
    //            {
    //                var index = temp[table].findIndex((x:any) => x == i)
    //                if(index >= 0)
    //                    temp[table].splice(index,1)
    //            }
    //        }

    //        //If I could get this to run after setReport its just AddTableRow
    //        if(field?.length > 0 && value?.length > 0){
    //            let item:any = {}
    //            item[field] = value
    //            temp[table]?.push(item)
    //        }
    //        else{
    //            temp[table]?.push({})
    //        }

    //        setReport(temp)
    //    }
    //}

    const clearTableRows = (table: string, field = "", value = "") => {
        let temp: any = { ...report };
        if (temp[table]) {
            if (table === "CameraList") {
                // Clears specific items in CameraList based on field and value
                temp[table] = temp[table]?.filter((item: any) => item[field] !== value || item.deleted);
            } else if (table === "DYRSVeritracks_List") {
                // Clear DYRSVeritracks_List and uncheck the checkbox
                temp.DYRS_ReturnedCloseProximity = false;
                const events = [
                    { target: { name: "DYRS_ReturnedCloseProximity", checked: false } }
                ];
                events.forEach(event => handleCheckboxChange(event));
                temp[table] = [];
            } else {
                temp[table] = [];
            }

            setReport(temp);
        }
    };


    const handleCheckboxChange = (e: { target: { name: any; checked: any; }; }) => {
        const { name, checked } = e.target;
        let temp: any = { ...report }

        if (name === "CCTV_NoneInArea" && checked) {
            var cctvCheckbox = ["CCTV_MPDReviewed", "CCTV_DCNotReviewable", "CCTV_SchoolNotReviewable", "CCTV_MPDNotReviewable"]
            for (var i of cctvCheckbox) {
                temp[i] = false
            }
        }
        if (name === "DYRS_ZeroReturns" && checked) {
            var dyrsCheckbox = ["DYRS_NoCloseProximity", "DYRS_ReturnedCloseProximity"]
            for (var i of dyrsCheckbox) {
                temp[i] = false
            }
        }

        if (name === "CCTV_SchoolNotReviewable" && !checked) {
            temp["CCTV_School"] = "";
        }

        if (name.startsWith("LPR_CustomQuery_")) {
            const index = parseInt(name.split("_")[2], 10); // Extract index from name
            if (!isNaN(index) && temp.LprList && temp.LprList.length > index) {
                temp.LprList[index] = {
                    ...temp.LprList[index],
                    LPR_CustomQuery: checked
                };
            }
        }

        if (name.startsWith("LPR_VINIncluded_")) {
            const index = parseInt(name.split("_")[2], 10); // Extract index from name
            if (!isNaN(index) && temp.LprList && temp.LprList.length > index) {
                temp.LprList[index] = {
                    ...temp.LprList[index],
                    LPR_VINIncluded: checked
                };
            }
        }

        else {
            temp[name] = checked
        }

        setReport(temp)
    };
    
    //AutoSave for PIR (Every 15 Seconds)
    useEffect(() => {
        const autosave = () => {
            if (isAutoSaveEnabled && !incompleteReportOpen && currentTab === '1') {
                console.log('Autosaving report: ', reportRef.current);
                handleSubmit(false, "autosave");
            }
        };

        let interval: any;

        const timeout = setTimeout(() => {
            interval = setInterval(autosave, 30000); // autosave every 30 seconds; any quicker might be annoying to users as it would constantly disrupt them
        }, 5000)

        return () => {
            clearTimeout(timeout);
            clearInterval(interval);
        }
    }, [isAutoSaveEnabled, incompleteReportOpen, currentTab]);


    const handleSubmit = (finalReport: boolean = true, submitType: string = "") => {
        console.log("Current report ref: ", reportRef.current);
        console.log("Current report (non-ref): ", report);
        var noFilesReport = JSON.parse(JSON.stringify({ ...reportRef.current }))
        var filesReport = JSON.parse(JSON.stringify({ ...reportRef.current }))
        console.log(filesReport)
        if (noFilesReport?.Files && noFilesReport?.Files?.length > 0) {
            noFilesReport.Files = []
        }
        if (noFilesReport?.DYRSVeritracks_List && noFilesReport?.DYRSVeritracks_List?.length > 0) {
            for (var i of noFilesReport?.DYRSVeritracks_List) {
                if (i.DYRS_VeritracksScreenshot?.deleted) {
                    i.DYRS_VeritracksScreenshot = null
                }
                if (i.DYRS_IndividualPhoto?.deleted) {
                    i.DYRS_IndividualPhoto = null
                }
            }
        }
        if (noFilesReport?.ShotSpotter_List && noFilesReport?.ShotSpotter_List[0] != null && noFilesReport?.ShotSpotter_List?.length > 0) {
            console.log("Shotspotter list: ", noFilesReport?.ShotSpotter_List)
            for (var i of noFilesReport?.ShotSpotter_List) {
                if (i.Files && i.Files != null && i.Files.length > 0) {
                    i.Files = []
                }

            }
        }
        if (noFilesReport?.LprList && noFilesReport?.LprList?.length > 0) {
            for (var i of noFilesReport?.LprList) {
                if (i.Files && i.Files.length > 0) {
                    i.Files = []
                }

            }
        }
        if (finalReport) {
            noFilesReport.Completed = true;
        }
        else {
            if (submitType === "autosave") {
                if ((noFilesReport.ID === 0 || noFilesReport.ID == null) || (noFilesReport.Completed == null && noFilesReport.CreateDate == null)) {
                    noFilesReport.Completed = false;
                }
                setAlertType("info")
                setAlertText("Autosaving report...")
                setAlertOpen(true)
            }
            else {
                if ((noFilesReport.ID === 0 || noFilesReport.ID == null) || (noFilesReport.Completed == null && noFilesReport.CreateDate == null)) {
                    noFilesReport.Completed = false;
                }
                setAlertText("Your report was successfully saved (not a final submission). You may continue or come back later.")
                setAlertType("success");
                setAlertOpen(true);
            }
        }
        console.log("Payload to SetPIRReport: ", noFilesReport);

        SetPIRReport(noFilesReport).then(async (res) => {
            let tempWithFiles = await handleSubmitFiles(filesReport, res.ID)

            let dyrsIdMap = new Map(
                res.DYRSVeritracks_List.map((entry: any) => [entry.GUID, entry.ID])
            );

            let lprIdMap = new Map(
                res.LprList.map((entry: any) => [entry.GUID, entry.ID])
            );

            let shotIdMap = new Map(
                res.ShotSpotter_List.map((entry: any) => [entry.GUID, entry.ID])
            );

            tempWithFiles.DYRSVeritracks_List = tempWithFiles.DYRSVeritracks_List?.map((entry: any) => {
                if (dyrsIdMap.has(entry.GUID)) {
                    entry.ID = dyrsIdMap.get(entry.GUID); // Assign the ID from the response
                }
                return entry;
            });

            tempWithFiles.ShotSpotter_List = tempWithFiles.ShotSpotter_List?.map((entry: any) => {
                if (shotIdMap.has(entry.GUID)) {
                    entry.ID = shotIdMap.get(entry.GUID); // Assign the ID from the response
                }
                return entry;
            });

            tempWithFiles.LprList = tempWithFiles.LprList?.map((entry: any) => {
                if (lprIdMap.has(entry.GUID)) {
                    entry.ID = lprIdMap.get(entry.GUID);
                }
                return entry;
            })


            if (!finalReport) {
                const updatedReport = {
                    ...reportRef.current,
                    ID: res.ID,
                    ContributorList: res.ContributorList,
                    DYRSVeritracks_List: tempWithFiles.DYRSVeritracks_List,
                    IncidentList: res.IncidentList,
                    CameraList: res.CameraList,
                    LprList: tempWithFiles.LprList,
                    ShotSpotter_List: tempWithFiles.ShotSpotter_List,
                    Files: tempWithFiles.Files
                }
                console.log(updatedReport)
                setReport(updatedReport)
            }
        if (finalReport) {
                handleReportSubmit(res)
            }
        })
    };

    const handleSubmitFiles = async (data: any, reportId: any) => {
        let temp = { ...data }
        console.log("Line 1534 temp: ", temp)
        if (!temp?.Files)
            temp.Files = []

        if (temp?.DYRSVeritracks_List && temp?.DYRSVeritracks_List?.length > 0) {
            for (var i of temp?.DYRSVeritracks_List) {
                let index = data?.DYRSVeritracks_List?.findIndex((f: any) => f.GUID === i.GUID)
                if (index >= 0) {
                    if (i.DYRS_VeritracksScreenshot && !i.DYRS_VeritracksScreenshot.deleted) {
                        console.log(i, index)
                        i.DYRS_VeritracksScreenshot.ParentID = data?.DYRSVeritracks_List[index]?.ID
                        temp.Files.push(i.DYRS_VeritracksScreenshot)
                    }
                    if (i.DYRS_IndividualPhoto && !i.DYRS_IndividualPhoto.deleted) {
                        i.DYRS_IndividualPhoto.ParentID = data?.DYRSVeritracks_List[index]?.ID
                        temp.Files.push(i.DYRS_IndividualPhoto)
                    }
                }
            }
        }
        if (temp?.ShotSpotter_List && temp?.ShotSpotter_List?.length > 0 && temp?.ShotSpotter_List[0] != null) {
            for (var shot of temp?.ShotSpotter_List) {
                let index = data?.ShotSpotter_List?.findIndex((f: any) => f.GUID === shot.GUID)
                if (index >= 0) {
                    if (shot?.Files && shot?.Files.length > 0) {
                        for (var file of shot?.Files) {
                            file.ParentID = data?.ShotSpotter_List[index]?.ID
                            temp.Files.push(file)
                        }
                    }
                }
            }
        }
        if (temp?.LprList && temp?.LprList?.length > 0) {
            for (var lpr of temp?.LprList) {
                let index = data?.LprList?.findIndex((f: any) => f.GUID === lpr.GUID)
                if (index >= 0) {
                    if (lpr?.Files && lpr?.Files.length > 0) {
                        for (var file of lpr?.Files) {
                            file.ParentID = data?.LprList[index]?.ID
                            temp.Files.push(file)
                        }
                    }
                }
            }
        }
        if (temp?.Files && temp?.Files.length > 0) {
            for (var f of temp?.Files) {
                f.ReportID = reportId
                let res = await SetPIRFile(f)
                f.ID = res.ID
                if (f.Origination === "LPR Screenshots" && temp.LprList) {
                    for (let entry of temp.LprList) {
                        if (entry.ID === f.ParentID) {
                            if (entry.Files == null || !entry.Files) {
                                entry.Files = []
                            }
                            let pushFlag = true;
                            for (file of entry.Files) {
                                if (file === f) {
                                    pushFlag = false
                                }
                            }
                            if (pushFlag === true)
                                entry.Files.push(f)
                            //let index = temp.Files.indexOf(f)
                            //temp.Files.splice(index, 1)
                        }
                    }
                } 
                if (f.Origination === "ShotSpotter Screenshots" && temp.ShotSpotter_List) {
                    for (let entry of temp.ShotSpotter_List) {
                        if (entry.ID === f.ParentID) {
                            if (entry.Files == null || !entry.Files) {
                                entry.Files = []
                            }
                            let pushFlag = true;
                            for (file of entry.Files) {
                                if (file === f) {
                                    pushFlag = false
                                }
                            }
                            if (pushFlag === true)
                                entry.Files.push(f)
                            //let index = temp.Files.indexOf(f)
                            //temp.Files.splice(index, 1)
                        }
                    }
                } 
                if (f.Origination === "Veritracks Screenshot" && temp.DYRSVeritracks_List) {
                    for (let entry of temp.DYRSVeritracks_List) {
                        if (entry.ID === f.ParentID) {
                            if (entry.DYRS_VeritracksScreenshot === f) {
                                break;
                            }
                            else {
                                entry.DYRS_VeritracksScreenshot = f
                            }
                            
                            //let index = temp.Files.indexOf(f)
                            //temp.Files.splice(index, 1)
                        }
                    }
                } 
                if (f.Origination === "Individual Photo" && temp.DYRSVeritracks_List) {
                    for (let entry of temp.DYRSVeritracks_List) {
                        if (entry.ID === f.ParentID) {
                            if (entry.DYRS_IndividualPhoto === f) {
                                break;
                            }
                            else {
                                entry.DYRS_IndividualPhoto = f
                            }
                            //let index = temp.Files.indexOf(f)
                            //temp.Files.splice(index, 1)
                        }
                    }
                } 
            }
        }
        console.log(temp)
        return temp;
    }

    const clearAllIncompleteReports = () => {
        if (window.confirm("Are you sure you want to clear all incomplete reports?")) {
            incompleteReports?.forEach((record: any) => {
                record.deleted = true;
                SetPIRReport(record);
                setIncompleteReports([]); // Clear all reports
            })
                
        }
    };

    let isRunning = false;

    const handleBlur = async (fieldName: string, value: any) => {
        if (isRunning) return;
        isRunning = true;
        try {
            if (report?.Summary_DateTime && report?.Summary_Location !== "" && locationCheck !== report?.Summary_Location) { //Make sure it doesn't keep triggering after already running for the same location once
                setLocationCheck(report?.Summary_Location)
                const date = new Date(report.Summary_DateTime);

                // Calculate 30 minutes before and after for DYRS query start/end times
                const startDate = new Date(date.getTime() - 30 * 60000);
                const endDate = new Date(date.getTime() + 30 * 60000);

                // Create the initial updated report without the CameraList
                let updatedReport = {
                    ...report,
                    [fieldName]: value,
                    DYRS_SearchStartDate: startDate.toLocaleString(),
                    DYRS_SearchEndDate: endDate.toLocaleString(),
                };

                let latitude = null;
                let longitude = null;
                if (report?.Summary_Location) {
                    let response = await Geocode.fromAddress(report?.Summary_Location + ', Washington, DC');
                    const { lat, lng } = response.results[0].geometry.location;
                    latitude = lat;
                    longitude = lng;
                }

                let search_query: GetDarInfo_Query = {
                    QueryType: "I",
                    Dept: user.Dept,
                    EmpID: user.EmpID,
                    DeptORI: user.DeptORI,
                    DateRange: true,
                    DateTypeValue: 0,
                    FromDate: formatDT(startDate),
                    ToDate: formatDT(endDate),
                    SearchType: "AND",
                    IndicatorOn: false,
                    TopArrestNumber: 0,
                    FNames: [],
                    MNames: [],
                    LNames: [],
                    DOBs: [],
                    SEX: [],
                    Address: report?.Summary_Location ? report?.Summary_Location : "",
                    Cities: report?.Summary_PSA ? [report?.Summary_PSA] : [],
                    States: [],
                    Zip: "",
                    Statutes: [],
                    Gang: [],
                    FBIs: [],
                    SBIs: [],
                    DLNos: [],
                    PlateNos: [],
                    VINs: [],
                    AgencyArrested: [],
                    InfocopDept: [],
                    Indicators: [],
                    IndicatorOccurrence: null,
                    Latitude: latitude,
                    Longitude: longitude,
                    Radius: 0,
                    ResidentCounty: [],
                    ArrestedCounty: [],
                    Arrest_Type: [],
                    CaseNumbers: [],
                    FTSIDs: [],
                    SystemCall: false,
                };

                let qRes: getDarInfoResponse = await RunDarQuery(search_query);
                let shootings: any[] = [];

                if (qRes?.AnyTable && qRes?.AnyTable?.length > 0) {
                    for (let i of qRes.AnyTable) {
                        if (!shootings.some((shot: any) => shot.MainID === i.MainID)) {
                            shootings.push(i);
                        }
                    }
                }

                // Now fetch camera data and merge it into the updatedReport
                var newCameras = await handleSearchCamera();
                if (updatedReport.CameraList != null && updatedReport.CameraList.length > 0) {
                    updatedReport.CameraList = updatedReport.CameraList.filter(f => f?.Camera_Type === "CCTV/CAMERA").map((camera: any) => ({
                        ...camera,
                        deleted: true
                    }))
                } else {
                    updatedReport.CameraList = []
                }
                newCameras = newCameras.sort((a: any, b: any) => a.CCTV_Distance - b.CCTV_Distance);

                updatedReport.CameraList = [
                    ...updatedReport.CameraList, // Include deleted cameras
                    ...newCameras.map((camera: any) => ({
                        ...camera,
                        deleted: false, // Ensure new cameras are not marked as deleted
                    })),
                ];

                const newIncidents = await handleSearchIncidents();

                // Merge camera data into the updatedReport
                updatedReport.ShotSpotter_Disabled = shootings.length === 0 && (!updatedReport?.ShotSpotter_List?.[0]?.ShotSpotter_DateTime)

                var existingList: any = []
                if (updatedReport?.ShotSpotter_List && (updatedReport?.ShotSpotter_List[0] && updatedReport?.ShotSpotter_List[0]?.ShotSpotter_Alerted && updatedReport?.ShotSpotter_List[0]?.ShotSpotter_Alerted?.length > 0))
                    existingList = updatedReport?.ShotSpotter_List

                var newEntries: any = []
                for (var shooting of shootings.filter(shooting => !existingList.some((existing: any) => existing.ShotSpotter_DateTime === shooting.DateTimeIN))) {
                    newEntries.push({
                        GUID: GetGUID(),
                        ShotSpotter_Alerted: "Yes",
                        ShotSpotter_RoundCount: shooting.Rounds,
                        ShotSpotter_DateTime: shooting.DateTimeIN,
                        ShotSpotter_Location: shooting.STREETNAME,
                    })
                }

                if (updatedReport.IncidentList != null && updatedReport.IncidentList.length > 0) {
                    updatedReport.IncidentList = updatedReport.IncidentList.map((incident: any) => ({
                        ...incident,
                        deleted: true
                    }))
                } else {
                    updatedReport.IncidentList = []
                }

                updatedReport.IncidentList = [
                    ...updatedReport.IncidentList, // Include deleted incidents
                    ...newIncidents.map((incident: any) => ({
                        ...incident,
                        deleted: false, // Ensure new incidents are not marked as deleted
                    })),
                ];

                updatedReport.ShotSpotter_List = existingList.concat(newEntries);

                // Finally, set the report with both the camera and ShotSpotter data
                setReport(updatedReport);

                console.log("Camera and ShotSpotter data set")
                // Time-limited LPR search
                //await runLPRSearchWithTimeout(); 
            }
        } 
        catch (error) {
            console.error("Blur function error: ", error);
        }
        finally {
            isRunning = false;
        }
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container>
                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                    <Typography variant="h4" gutterBottom>
                        Preliminary Investigative Report
                    </Typography>
                    <Typography variant="h5" sx={{ fontStyle: 'italic' }} gutterBottom>
                        Metropolitan Police Department
                    </Typography>
                    <img style={{ height: "15%", width: "15%" }} src={HomelandSecurityLogo} alt="DC Homeland Security" />
                </Box>

                {!isLoading &&
                    <div>
                        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                            <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%', height: '25%', fontSize: '1.2rem' }}>
                                {alertText}
                            </Alert>
                        </Snackbar>
                        <Dialog
                            open={incompleteReportOpen}
                            onClose={() => handleDialog("Incomplete Report", false)}
                            fullWidth={true} // Makes the dialog take full width of maxWidth
                            maxWidth="lg" // Sets the max width of the dialog
                            aria-labelledby="incomplete-dialog"
                            aria-describedby="incomplete"
                        >
                            <DialogTitle id="incomplete-dialog-title">
                                {"Resume incomplete report?"}
                            </DialogTitle>
                            <DialogContent>
                                {incompleteReports && incompleteReports?.length > 0 ? (
                                    <>
                                    <p>Below is a list of reports that you have not yet completed. You may click on one and continue if you wish:</p>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Creation Date</TableCell>
                                                <TableCell>Last Updated</TableCell>
                                                <TableCell>CCN</TableCell>
                                                <TableCell>Incident Type</TableCell>
                                                <TableCell>Incident Date</TableCell>
                                                <TableCell>Location</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {incompleteReports?.filter((report: any) => !report.deleted)?.map((row, index) => (
                                                <>
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {formatDT(row?.CreateDate)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatDT(row?.UpdateDate)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.Summary_CCN}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.Summary_Type}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatDT(row?.Summary_DateTime)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.Summary_Location}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Tooltip title="Resume Incomplete Report">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => {
                                                                        setIncompleteReportID(row?.ID);
                                                                        handleDialog("Incomplete Report", false);
                                                                    }}
                                                                    style={{
                                                                        color: 'blue'
                                                                    }}
                                                                >
                                                                    <ModeEditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete Incomplete Report">
                                                                <IconButton
                                                                    onClick=
                                                                    {() => {
                                                                        row.deleted = true;
                                                                        handleDeleteIncompleteReport(index)
                                                                        SetPIRReport(row);
                                                                    }
                                                                    }
                                                                    style={{
                                                                        color: 'red'
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            ))}
                                        </TableBody>
                                        </Table>
                                        <Button
                                            variant="contained"
                                            startIcon={<ClearIcon />}
                                            onClick={() => clearAllIncompleteReports()}
                                            color="error"
                                            style={{marginTop: 10} }
                                        >
                                            Discard All Incomplete Reports
                                        </Button>
                                    </>
                                ) : <><p>You have no more incomplete reports to continue. You may close this window and fill out a new one if you wish.</p></> 
                            }
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleDialog("Incomplete Report", false)}>Close</Button>
                            </DialogActions>
                        </Dialog>
                        <Grid container spacing={3} marginTop="2%" className="border-double border-2 border-inherit">

                            <div className="container mt-4 ml-4 mr-5">
                                {/* SUMMARY Header */}
                                <Box className="header" display="flex" justifyContent="center" width="100%">
                                    <Typography variant="h5">
                                        SUMMARY
                                    </Typography>
                                </Box>

                                <Grid container spacing={3} className="pl-2 pr-2 mb-6">

                                    {/* Requester */}
                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Requested By:
                                            </FormLabel>
                                            <RadioGroup row className="mt-2" aria-label="Requester"
                                                name="Summary_Requester"
                                                value={report?.Summary_Requester}
                                                onChange={handleInputChange}
                                                defaultValue="RTCC">
                                                <FormControlLabel value="RTCC" control={<Radio />} label="RTCC" />
                                                <FormControlLabel value="Detective" control={<Radio />} label="Detective" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {/* Date and Time*/}
                                    <Grid item xs={6}>
                                        <DateTimePicker
                                            label="Date and Time"
                                            ampm={false}
                                            value={report?.Summary_DateTime ?? ""}
                                            onChange={(date) => handleInputChange({ target: { name: "Summary_DateTime", value: (date ?? "") } })}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </Grid>
                                    {/* Incident Type */}
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Incident Type"
                                            name="Summary_Type"
                                            value={report?.Summary_Type ?? ""}
                                            onChange={handleInputChange}
                                            fullWidth

                                        />
                                    </Grid>

                                    {/* Location */}
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Location"
                                            name="Summary_Location"
                                            value={report?.Summary_Location ?? ""}
                                            onChange={handleInputChange}
                                            onBlur={(e) => handleBlur("DYRS_Location", e.target.value)}
                                            fullWidth

                                        />
                                    </Grid>

                                    {/* PSA */}
                                    <Grid item xs={1}>
                                        <TextField
                                            label="PSA"
                                            name="Summary_PSA"
                                            value={report?.Summary_PSA ?? ""}
                                            onChange={handleInputChange}
                                            fullWidth
                                            onBlur={() => handleBlur("Summary_PSA", report?.Summary_PSA)}
                                        />
                                    </Grid>

                                    {/* CCN */}
                                    <Grid item xs={2}>
                                        <TextField
                                            label="CCN"
                                            name="Summary_CCN"
                                            value={report?.Summary_CCN ?? ""}
                                            onChange={handleInputChange}
                                            fullWidth

                                        />
                                    </Grid>

                                    {/*Rank/Title*/}
                                    <Grid item xs={2}>
                                        <TextField
                                            label="Rank/Title"
                                            name="Summary_Rank"
                                            value={report?.Summary_Rank ?? ""}
                                            onChange={handleInputChange}
                                            fullWidth
                                        />
                                    </Grid>

                                    {/* CRS First Name */}
                                    <Grid item xs={2}>
                                        <TextField
                                            label="First Name"
                                            name="Summary_SpecialistFirstName"
                                            value={report?.Summary_SpecialistFirstName ?? ""}
                                            onChange={handleInputChange}
                                            fullWidth

                                        ></TextField>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <TextField
                                            label="Last Name"
                                            name="Summary_SpecialistLastName"
                                            value={report?.Summary_SpecialistLastName ?? ""}
                                            onChange={handleInputChange}
                                            fullWidth

                                        ></TextField>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className="pl-2 pr-2">
                                    <Button
                                        onClick={() => addTableRow("ContributorList")}
                                        aria-label="delete"
                                        variant="contained"
                                        color="info"
                                        startIcon={<AddIcon />}
                                        style={{
                                            marginBottom: 10
                                        } }
                                    >
                                        Add More Contributors
                                    </Button> 
                                    {report?.ContributorList?.filter((f: any) => !f.deleted)?.map((row, index) => (
                                        <>
                                            <Table className="mt-4 mb-4">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                        Title
                                                        </TableCell>
                                                        <TableCell>
                                                        First Name
                                                        </TableCell>
                                                        <TableCell>
                                                        Last Name
                                                        </TableCell>
                                                        <TableCell>
                                                        Actions
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            <TextField
                                                                name="Title"
                                                                value={row?.Title ?? ""}
                                                                onChange={(e) => handleInputChange(e, "ContributorList", row)}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="FirstName"
                                                                value={row?.FirstName ?? ""}
                                                                onChange={(e) => handleInputChange(e, "ContributorList", row)}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="LastName"
                                                                value={row?.LastName ?? ""}
                                                                onChange={(e) => handleInputChange(e, "ContributorList", row)}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Tooltip title="Delete Contributor">
                                                                <IconButton
                                                                    style={{
                                                                        color: 'red'
                                                                    } }
                                                                    onClick={() => deleteTableRow("ContributorList", row)}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </>
                                    ))}
                                </Grid>

                                {/* Radio */}
                                <Grid item xs={12} className="pl-2 pr-2">
                                    {report?.Summary_Requester === "RTCC" && (
                                        <div>
                                            <Typography variant="body1" component="span">
                                                The RTCC initiated a Preliminary Investigative Report in reference to the above
                                                offense based on the CAD call and actively monitoring the
                                            </Typography>
                                            <TextField
                                                name="Summary_Radio"
                                                value={report?.Summary_Radio ?? ""}
                                                onChange={handleInputChange}
                                                rows={1}

                                                size="small"
                                                style={{ width: '8%', margin: '0 10px', verticalAlign: 'middle' }}
                                                inputProps={{
                                                    style: {
                                                        borderRadius: "1px",
                                                        height: "10px",
                                                        textAlign: 'center'
                                                    }
                                                }}
                                            />
                                            <Typography variant="body1" component="span">
                                                radio transmissions. Please advise if further investigation reveals additional or
                                                conflicting information necessary to update this initial report. </Typography>
                                        </div>)}

                                    {report?.Summary_Requester === "Detective" && (
                                        <div>
                                            <Typography
                                                style={{
                                                    display: 'inline-block',
                                                    verticalAlign: 'middle',
                                                }}>
                                                Detective
                                            </Typography>
                                            <TextField
                                                name="Summary_Detective"
                                                value={report?.Summary_Detective ?? ""}
                                                onChange={handleInputChange}
                                                rows={1}

                                                size="small"
                                                style={{ width: '15%', margin: '0 10px', verticalAlign: 'middle' }}
                                                inputProps={{
                                                    style: {
                                                        borderRadius: "1px",
                                                        height: "10px",
                                                        textAlign: 'center'
                                                    }
                                                }}
                                            />
                                            <Typography variant="body1" component="span">
                                                of
                                            </Typography>
                                            <TextField
                                                name="Summary_Detective_District"
                                                value={report?.Summary_Detective_District ?? ""}
                                                onChange={handleInputChange}
                                                rows={1}
                                                size="small"
                                                style={{ width: '15%', margin: '0 10px', verticalAlign: 'middle' }}
                                                inputProps={{
                                                    style: {
                                                        borderRadius: "1px",
                                                        height: "10px",
                                                        textAlign: 'center'
                                                    }
                                                }}
                                            />
                                            <Typography variant="body1" component="span">
                                                requested a Preliminary Investigative Report in reference to the incident
                                                listed above.
                                            </Typography>
                                        </div>
                                    )}

                                    <br />
                                    <br />
                                    <Typography>
                                        Add any positive results from CCTV/cameras, DYRS, ShotSpotter, or LPR. Do NOT
                                        include any other information (e.g., no more running resume).
                                    </Typography>
                                    <TextField
                                        label="Summary of Positive Results"
                                        name="Summary_Narrative"
                                        value={report?.Summary_Narrative ?? ""}
                                        onChange={handleInputChange}
                                        multiline
                                        rows={10}
                                        fullWidth

                                        style={{ marginTop: '10px' }}
                                    />
                                    <Typography variant="body1" component="span">
                                        For any questions or additional requests for information, please contact the Real
                                        Time Crime Center at 202-727-0559 or <a className="a" href="mailto:mpdcrs@dc.gov">mpdcrs@dc.gov</a>
                                    </Typography>
                                </Grid>
                            </div>

                            <div className="container mt-4 ml-4 mr-5">
                                {/* CCTV/CAMERAS Header */}
                                <Box className="header" display="flex" justifyContent="center" width="100%">
                                    <Typography variant="h5">
                                        CCTV/CAMERAS
                                    </Typography>
                                </Box>

                                {/* CCTV Location Map */}
                                <Grid item xs={12} className="pl-2 pr-2">
                                    <Typography variant="body1" component="span">
                                        Please upload a screenshot of MPD, DDOT, DCPS, Capital Shield, and other relevant
                                        cameras within
                                        1,250 feet of the incident location.
                                        <br />
                                    </Typography>
                                    <Typography variant="body2" component="span" fontStyle="italic" color="gray">
                                        (MPD CCTV and OCTO - CCTV, take out duplicates)
                                        <br />
                                        (Take off gang/crew layer, schools, private cameras, etc. All layers except OCTO/MPD
                                        cameras) <br />
                                    </Typography>

                                    <Grid item xs={12} className="pl-2 pr-2">
                                        <div
                                            onDrop={(e) => handleDrop(e, null, "Camera Locations")}
                                            onPaste={(e) => handlePaste(e, null, "Camera Locations")}
                                            onContextMenu={(e) => handleContextMenu(e, null, "Camera Locations")}
                                            onDragOver={(e) => e.preventDefault()}
                                            style={{
                                                border: '5px dashed #ccc',
                                                padding: '20px',
                                                textAlign: 'center',
                                                marginTop: "2%",
                                                marginBottom: '2%'
                                            }}
                                        >
                                            {report?.Files && report?.Files?.filter(f => f?.Origination === "Camera Locations").length > 0 ? (
                                                report?.Files?.filter(f => f?.Origination === "Camera Locations" && !f?.deleted).map((file, index) => (
                                                    <div key={`${file?.FileName}-${index}`}>
                                                        <Box position="relative" display="inline-block">
                                                            <img
                                                                src={`data:${file.FileEncoding};base64,${file.Content}`}
                                                                alt={file.FileName ?? "Camera Locations"}
                                                                style={{ maxWidth: '100%' }}
                                                            />
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => handleRemoveFile(file)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    right: 0,
                                                                    zIndex: 1,
                                                                    color: 'red'
                                                                }}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </div>
                                                ))
                                            ) : (
                                                "Paste or Drag & Drop Your Camera Locations Screenshot here"
                                            )}
                                        </div>
                                    </Grid>
                                    {/*<Button*/}
                                    {/*        variant="contained"*/}
                                    {/*        component="label"*/}
                                    {/*        sx={{*/}
                                    {/*            marginTop: "2%",*/}
                                    {/*            marginBottom: "2%",*/}
                                    {/*            marginLeft: "2%"*/}
                                    {/*        }}*/}
                                    {/*        onClick={() => handleDialog("Camera Search", true)}*/}
                                    {/*>*/}
                                    {/*    Import Camera(s)*/}
                                    {/*</Button>*/}
                                    <Dialog
                                        open={cameraSearchOpen}
                                        onClose={() => handleDialog("Camera Search", false)}
                                        aria-labelledby="clear-dialog"
                                        aria-describedby="clear-cameras"
                                        maxWidth="lg"
                                        fullWidth={true}
                                    >
                                        <DialogContent className="pb-8">
                                            <PreliminaryCameraSearch handleCameraSubmit={handleCameraSubmit} handleCameraClose={() => handleDialog("Camera Search", false)} />
                                        </DialogContent>
                                    </Dialog>
                                </Grid>


                                {/* Table */}
                                {report?.CameraList && report?.CameraList?.length > 0 && (
                                    <Grid item xs={12} className="pl-2 pr-2">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Actions</TableCell>
                                                    <TableCell>Camera #</TableCell>
                                                    <TableCell>Distance (feet)</TableCell>
                                                    <TableCell>Camera Name</TableCell>
                                                    <TableCell>Department</TableCell>
                                                    <TableCell>Technical Difficulties</TableCell>
                                                    <TableCell>Delete</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {report?.CameraList?.filter((f: any) => f.Camera_Type === "CCTV/CAMERA" && !f.deleted)?.map((row, index) => ( /*.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/
                                                    <>
                                                        <TableRow key={index} draggable onDragStart={() => handleDragStart(index)} onDragOver={(e) => { e.preventDefault(); setHoveredIndex(index); }} onDrop={() => handleTableDrop(index, "CCTV/CAMERA")}
                                                            onDragEnd={() => setHoveredIndex(null)} style={{
                                                                backgroundColor:
                                                                    draggedIndex === index
                                                                        ? 'lightgray'
                                                                        : hoveredIndex === index
                                                                            ? '#f0f0f0'
                                                                            : 'white',
                                                            }}>
                                                            {/* Drag Handle */}
                                                            <TableCell>
                                                                <Tooltip title="Drag to reorder">
                                                                    <IconButton
                                                                        onDragStart={() => handleDragStart(index)} onDrop={() => handleTableDrop(index, "CCTV/CAMERA")} onDragOver={(e) => { e.preventDefault(); setHoveredIndex(index) }}>
                                                                        <DragIndicatorIcon
                                                                            sx={{ cursor: 'grab' }}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell>{(page === 0 ? index + 1 : index + (rowsPerPage * page) + 1)}</TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="CCTV_Distance"
                                                                    value={row?.CCTV_Distance ?? " feet"}
                                                                    onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="CCTV_Location"
                                                                    value={row?.CCTV_Location ?? ""}
                                                                    onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                    multiline
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="CCTV_Department"
                                                                    value={row?.CCTV_Department ?? ""}
                                                                    onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                    multiline
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <RadioGroup
                                                                    aria-label="technical difficulties"
                                                                    name="CCTV_TechnicalDifficulties"
                                                                    value={row?.CCTV_TechnicalDifficulties ?? "No"}
                                                                    onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                >
                                                                    <FormControlLabel value="Yes" control={<Radio />}
                                                                        label="Yes" />
                                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                                </RadioGroup>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Tooltip title="Delete Camera">
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        onClick={() => deleteTableRow("CameraList", row)}
                                                                    >
                                                                        <DeleteIcon sx={{ color: "red" }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        {/*<TablePagination*/}
                                        {/*    rowsPerPageOptions={[5, 10, 25, 50]}*/}
                                        {/*    component="div"*/}
                                        {/*    count={report.CameraList ? report?.CameraList?.filter((f: any) => f.Camera_Type === "CCTV/CAMERA").length : 0}*/}
                                        {/*    rowsPerPage={rowsPerPage}*/}
                                        {/*    page={page}*/}
                                        {/*    onPageChange={handleChangePage}*/}
                                        {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
                                        {/*/>*/}
                                    </Grid>
                                )}


                                <Grid item xs={12}>

                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        <ButtonGroup variant="contained" aria-label="Table buttons" size="small"
                                            color="inherit">
                                            <Button
                                                variant="contained"
                                                startIcon={<AddIcon />}
                                                onClick={() => addTableRow("CameraList", "Camera_Type", "CCTV/CAMERA")}
                                            >
                                                Add Camera Manually
                                            </Button>
                                            <Button
                                                variant="contained"
                                                startIcon={<DescriptionIcon />}
                                                onClick={() => handleDialog("Excel", true)}
                                            >
                                                Paste Cameras from Excel
                                            </Button>
                                            <Dialog
                                                open={excelOpen}
                                                onClose={() => handleDialog("Excel", false)}
                                                aria-labelledby="excel-dialog"
                                                aria-describedby="excel-paste"
                                            >
                                                <DialogTitle id="excel-dialog-title">
                                                    {"Paste cameras from your Excel sheet"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <textarea
                                                        id="excel-paste-area"
                                                        style={{
                                                            width: '100%',
                                                            height: '150px',
                                                            padding: '10px',
                                                            fontSize: '16px',
                                                            borderRadius: '4px',
                                                            borderColor: '#ccc'
                                                        }}
                                                        placeholder="Paste your camera data here (do not include headers)"
                                                        onPaste={(e) => {
                                                            handlePaste(e, null, "CCTV/CAMERA");
                                                            handleDialog("Excel", false); // Close the dialog after pasting
                                                        }}
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => handleDialog("Excel", false)}>Close</Button>
                                                </DialogActions>
                                            </Dialog>
                                            <Button
                                                variant="contained"
                                                startIcon={<ClearIcon />}
                                                onClick={() => handleDialog("CCTV", true)}
                                            >
                                                Clear All Cameras
                                            </Button>
                                            <Dialog
                                                open={cctvClearOpen}
                                                onClose={() => handleDialog("CCTV", false)}
                                                aria-labelledby="clear-dialog"
                                                aria-describedby="clear-cameras"
                                            >
                                                <DialogTitle id="clear-dialog-title">
                                                    {"Clear all existing cameras?"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="clear-cameras">
                                                        Are you sure you would like to clear all cameras in the list?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => handleDialog("CCTV", false)}>No</Button>
                                                    <Button onClick={() => { clearTableRows("CameraList", "Camera_Type", "CCTV/CAMERA"); handleDialog("CCTV", false) }} autoFocus>
                                                        Yes
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </ButtonGroup>
                                    </Box>
                                </Grid>

                                {/* Additional CCTV Checkboxes */}
                                <Grid item xs={12} className="pl-2 pr-2" style={{ marginTop: '2%' }}>
                                    <Typography variant="h6" gutterBottom>
                                        CCTV/Cameras Review
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <FormControlLabel
                                            control={<Checkbox checked={report?.CCTV_NoneInArea ?? false}
                                                onChange={handleCheckboxChange}
                                                name="CCTV_NoneInArea" />}
                                            label="No MPD, DDOT, or Capital Shield cameras in the immediate area of the incident location."
                                            disabled={(report?.CCTV_MPDReviewed || report?.CCTV_MPDNotReviewable) ?? false}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={report?.CCTV_MPDReviewed ?? false}
                                                onChange={handleCheckboxChange}
                                                name="CCTV_MPDReviewed" />}
                                            label="MPD, DDOT, or Capital Shield camera reviewed."
                                            disabled={report?.CCTV_NoneInArea ?? false}
                                        />
                                        {report?.CCTV_MPDReviewed && (
                                            <>
                                                {report?.CameraList?.filter(f => f?.Camera_Type === "MPDReviewed" && !f?.deleted).map((row, index) => (
                                                    <Grid container spacing={2} key={index} alignItems="center">
                                                        <Grid item xs={5}>
                                                            <TextField
                                                                label="Camera Location"
                                                                name="CCTV_Location"
                                                                value={row?.CCTV_Location ?? ""}
                                                                onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                label="Review Time [Optional]"
                                                                name="CCTV_ReviewTime"
                                                                value={row?.CCTV_ReviewTime ?? ""}
                                                                onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Tooltip title="Delete Camera">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => deleteTableRow("CameraList", row)}
                                                                >
                                                                    <DeleteIcon sx={{ color: "red" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Button onClick={() => addTableRow("CameraList", "Camera_Type", "MPDReviewed")} startIcon={<AddIcon />}>
                                                    Add Another Camera
                                                </Button>
                                            </>
                                        )}

                                        <FormControlLabel
                                            control={<Checkbox checked={report?.CCTV_DCNotReviewable ?? false}
                                                onChange={handleCheckboxChange}
                                                name="CCTV_DCNotReviewable" />}
                                            label="DC Housing Camera"
                                        />
                                        {report?.CCTV_DCNotReviewable && (
                                            <>
                                                {report?.CameraList?.filter(f => f?.Camera_Type === "DCNotReviewable" && !f?.deleted).map((row, index) => (
                                                    <Grid container spacing={2} key={index} alignItems="center">
                                                        <Grid item xs={5}>
                                                            <TextField
                                                                label="DC Housing Camera Location"
                                                                name="CCTV_Location"
                                                                value={row?.CCTV_Location ?? ""}
                                                                onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                label="Review Time [Optional]"
                                                                name="CCTV_ReviewTime"
                                                                value={row?.CCTV_ReviewTime ?? ""}
                                                                onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                fullWidth
                                                                multiline
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Tooltip title="Delete Camera">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => deleteTableRow("CameraList", row)}
                                                                >
                                                                    <DeleteIcon sx={{ color: "red" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Button
                                                    onClick={() => addTableRow("CameraList", "Camera_Type", "DCNotReviewable")}
                                                    startIcon={<AddIcon />}>
                                                    Add Another Camera
                                                </Button>
                                            </>
                                        )}

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={report?.CCTV_SchoolNotReviewable ?? false}
                                                    onChange={handleCheckboxChange}
                                                    name="CCTV_SchoolNotReviewable"
                                                />
                                            }
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ marginRight: '10px' }}>DCPS camera at</span>
                                                    <TextField
                                                        value={report?.CCTV_School ?? ''}
                                                        onChange={handleInputChange}
                                                        name="CCTV_School"
                                                        placeholder="School Name"
                                                        style={{ width: '300px' }}
                                                        inputProps={{
                                                            style: {
                                                                borderRadius: "1px",
                                                                height: "10px",
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }
                                        />

                                        {report?.CCTV_SchoolNotReviewable && (
                                            <>
                                                {report?.CameraList?.filter(f => f?.Camera_Type === "SchoolNotReviewable" && !f?.deleted).map((row, index) => (
                                                    <Grid container spacing={2} key={index} alignItems="center" style={{ marginTop: '1%' }}>
                                                        <Grid item xs={5}>
                                                            <TextField
                                                                label="Camera Location"
                                                                name="CCTV_Location"
                                                                value={row?.CCTV_Location ?? ""}
                                                                onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                label="Review Time [Optional]"
                                                                name="CCTV_ReviewTime"
                                                                value={row?.CCTV_ReviewTime ?? ""}
                                                                onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Tooltip title="Delete Camera">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => deleteTableRow("CameraList", row)}
                                                                >
                                                                    <DeleteIcon sx={{ color: "red" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Button onClick={() => addTableRow("CameraList", "Camera_Type", "SchoolNotReviewable")} startIcon={<AddIcon />}>
                                                    Add Another Camera
                                                </Button>
                                            </>
                                        )}

                                        <FormControlLabel
                                            control={<Checkbox checked={report?.CCTV_MPDNotReviewable ?? false}
                                                onChange={handleCheckboxChange}
                                                name="CCTV_MPDNotReviewable" />}
                                            label="MPD Photo Enforcement Camera located in close proximity but not remotely reviewable"
                                            disabled={report?.CCTV_NoneInArea ?? false}
                                        />
                                        {report?.CCTV_MPDNotReviewable && (
                                            <>
                                                {report?.CameraList?.filter(f => f?.Camera_Type === "MPDNotReviewable" && !f?.deleted).map((row, index) => (
                                                    <Grid container spacing={2} key={index} alignItems="center">
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="MPD Photo Enforcement Camera Location"
                                                                name="CCTV_Location"
                                                                value={row?.CCTV_Location ?? ""}
                                                                onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        {/*<Grid item xs={3}>*/}
                                                        
                                                        {/*</Grid>*/}
                                                        <Grid item xs={2}>
                                                            <Tooltip title="Delete Camera">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => deleteTableRow("CameraList", row)}
                                                                >
                                                                    <DeleteIcon sx={{ color: "red" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Button onClick={() => addTableRow("CameraList", "Camera_Type", "MPDNotReviewable")} startIcon={<AddIcon />}>
                                                    Add Another Camera
                                                </Button>
                                            </>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} className="pl-2 pr-2" style={{ marginTop: '2%' }}>
                                    <Typography variant="h6" gutterBottom>
                                        Camera Observations
                                    </Typography>
                                    <TextField
                                        label="Positive Camera Observations"
                                        name="CCTV_Observations"
                                        value={report?.CCTV_Observations ?? ""}
                                        onChange={handleInputChange}
                                        multiline
                                        rows={10}
                                        fullWidth
                                        style={{ marginTop: '5x' }}
                                    />
                                </Grid>

                                {/* Screenshots */}
                                <Grid item xs={12} className="pl-2 pr-2" style={{ marginTop: '2%' }}>
                                    <Typography variant="h6" gutterBottom>
                                        CCTV Screenshots
                                    </Typography>
                                    <Grid item xs={12} className="pl-2 pr-2" style={{ marginTop: '2%' }}>
                                        <div
                                            onDrop={(e) => handleDrop(e, null, "CCTV Screenshots")}
                                            onPaste={(e) => handlePaste(e, null, "CCTV Screenshots")}
                                            onContextMenu={(e) => handleContextMenu(e, null, "CCTV Screenshots")}
                                            onDragOver={(e) => e.preventDefault()}
                                            style={{
                                                border: '5px dashed #ccc',
                                                padding: '20px',
                                                textAlign: 'center',
                                                marginTop: "2%",
                                                marginBottom: '2%'
                                            }}
                                        >
                                            {report?.Files && report?.Files?.filter(f => f?.Origination === "CCTV Screenshots" && !f.deleted).length > 0 ? (
                                                report?.Files?.filter(f => f.Origination === "CCTV Screenshots" && !f.deleted).map((file, index) => (
                                                    <div key={index}>
                                                        <Box position="relative" display="inline-block">
                                                            <img
                                                                src={`data:image/png;base64,${file.Content}`}
                                                                alt={file.FileName ?? "CCTV Screenshot"}
                                                                style={{ maxWidth: '100%' }}
                                                            />
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => handleRemoveFile(file)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    right: 0,
                                                                    zIndex: 1,
                                                                    color: 'red'
                                                                }}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </div>
                                                ))
                                            ) : (
                                                "Paste or Drag & Drop CCTV Screenshots here"
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className="container mt-4 ml-4 mr-5">
                                {/* DYRS Veritracks header */}
                                <Box className="header" display="flex" flexDirection="column" alignItems="center"
                                    textAlign="center"
                                    width="100%">
                                    <Typography variant="h5">
                                        DYRS VERITRACKS
                                    </Typography>
                                </Box>

                                <Grid item xs={12} className="pl-2 pr-2">
                                    <div
                                        onDrop={(e) => handleDrop(e, null, "DYRS Screenshot")}
                                        onPaste={(e) => handlePaste(e, null, "DYRS Screenshot")}
                                        onContextMenu={(e) => handleContextMenu(e, null, "DYRS Screenshot")}
                                        onDragOver={(e) => e.preventDefault()}
                                        style={{
                                            border: '5px dashed #ccc',
                                            padding: '20px',
                                            textAlign: 'center',
                                            marginTop: "2%",
                                            marginBottom: '2%'
                                        }}
                                    >
                                        {report?.Files && report?.Files?.filter(f => f?.Origination === "DYRS Screenshot" && !f.deleted).length > 0 ? (
                                            report?.Files?.filter(f => f.Origination === "DYRS Screenshot" && !f.deleted).map((file, index) => (
                                                <div>
                                                    <Box position="relative" display="inline-block">
                                                        <img
                                                            src={`data:image/png;base64,${file.Content}`}
                                                            alt={file.FileName ?? "CCTV Screenshot"}
                                                            style={{ maxWidth: '100%' }}
                                                        />
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => handleRemoveFile(file)}
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0,
                                                                zIndex: 1,
                                                                color: 'red'
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Box>
                                                </div>
                                            ))
                                        ) : (
                                            "Paste or Drag & Drop DYRS Screenshot here"
                                        )}
                                    </div>
                                </Grid>


                                {/* Veritracks Query Information*/}
                                <Grid item xs={12} className="pl-2 pr-2">
                                    <TextField
                                        label="Location Queried"
                                        name="DYRS_Location"
                                        value={report?.DYRS_Location ?? report?.Summary_Location ?? ""}
                                        onChange={handleInputChange}
                                        style={{
                                            width: '50%',
                                            marginTop: '2%',
                                            marginBottom: '2%'
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} className="pl-2 pr-2">
                                    <Box display="flex" alignItems="center">
                                        <DateTimePicker
                                            label="Query Start Date/Time"
                                            ampm={false}
                                            value={report?.DYRS_SearchStartDate ?? ""}
                                            onChange={(date) => handleInputChange({ target: { name: "DYRS_SearchStartDate", value: (date ?? "") } })}
                                            renderInput={(params) => <TextField {...params}
                                                style={{ marginBottom: '2%' }} />}
                                        />
                                        <Box sx={{ mx: 1, marginBottom: '2%' }}> to </Box>
                                        <DateTimePicker
                                            label="Query End Date/Time"
                                            ampm={false}
                                            value={report?.DYRS_SearchEndDate ?? ""}
                                            onChange={(date) => handleInputChange({ target: { name: "DYRS_SearchEndDate", value: (date ?? "") } })}
                                            renderInput={(params) => <TextField {...params}
                                                style={{ marginBottom: '2%' }} />}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="pl-2 pr-2">
                                    <DateTimePicker
                                        label="Time/Date Query Was Conducted"
                                        ampm={false}
                                        value={report?.DYRS_QueryDateTime ?? ""}
                                        onChange={(date) => handleInputChange({ target: { name: "DYRS_QueryDateTime", value: (date ?? "") } })}
                                        onOpen={() => {
                                            const now = new Date();
                                            setReport((prevReport) => ({
                                                ...prevReport,
                                                DYRS_QueryDateTime: now.toLocaleString(),
                                            }));
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            style={{ width: '35%', marginBottom: '3%' }}
                                            onFocus={() => {
                                                const now = new Date();
                                                setReport((prevReport) => ({
                                                    ...prevReport,
                                                    DYRS_QueryDateTime: now.toLocaleString(),
                                                }));
                                            }}
                                        />}
                                    />
                                    <Button
                                        variant="contained"
                                        color="inherit"
                                        sx={{ marginLeft: 4, marginTop: 1 }}
                                        onClick={() => {
                                            const now = new Date();
                                            setReport((prevReport) => ({
                                                ...prevReport,
                                                DYRS_QueryDateTime: now.toLocaleString(),
                                            })
                                            );
                                        }}
                                    >Set Date/Time to Now</Button>

                                    <FormControl component="fieldset">
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={report?.DYRS_ZeroReturns ?? false}
                                                            disabled={(report?.DYRS_NoCloseProximity || report?.DYRS_ReturnedCloseProximity) ?? false}
                                                            onChange={handleCheckboxChange}
                                                            name="DYRS_ZeroReturns"
                                                        />
                                                    }
                                                    label="The query of the Juvenile Veritracks System returned ZERO individuals in close proximity to the incident location monitored under DYRS supervision."
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={report?.DYRS_NoCloseProximity ?? false}
                                                            onChange={handleCheckboxChange}
                                                            disabled={(report?.DYRS_ZeroReturns || report?.DYRS_ReturnedCloseProximity) ?? false}
                                                            name="DYRS_NoCloseProximity"
                                                        />
                                                    }
                                                    label={
                                                        <Box display="flex" alignItems="center">
                                                            <Typography variant="body1" component="span" mr={1}>
                                                                The query of the Juvenile Veritracks System returned
                                                                individuals monitored under
                                                                DYRS supervision. All of the hits that were returned were
                                                                not in close proximity
                                                                to the incident location.
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                            </Grid>
                                            {report?.DYRS_NoCloseProximity && (
                                                <Grid item xs={10}>
                                                    <TextField
                                                        name="DYRS_ReturnedHitsCount"
                                                        label="# of individuals Returned"
                                                        disabled={!report?.DYRS_NoCloseProximity}
                                                        value={report?.DYRS_ReturnedHitsCount ?? ""}
                                                        onChange={handleInputChange}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={report?.DYRS_ReturnedCloseProximity ?? false}
                                                            onChange={handleCheckboxChange}
                                                            name="DYRS_ReturnedCloseProximity"
                                                            disabled={(report?.DYRS_ZeroReturns || report?.DYRS_NoCloseProximity) ?? false}
                                                        />
                                                    }
                                                    label={
                                                        <Box display="flex" alignItems="center">
                                                            <Typography variant="body1" component="span" mr={1}>
                                                                The query of the Juvenile Veritracks System returned
                                                                individuals monitored under
                                                                DYRS supervision. There were hits that returned within 200
                                                                feet from the
                                                                incident
                                                                location. The remaining hits returned exceeded 200 feet from
                                                                the incident
                                                                location.
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                            </Grid>
                                            {report?.DYRS_ReturnedCloseProximity && (
                                                <Grid item xs={10}>
                                                    <TextField
                                                        name="DYRS_ReturnedHitsCount"
                                                        label="# of Individuals Returned"
                                                        disabled={!report?.DYRS_ReturnedCloseProximity}
                                                        value={report?.DYRS_ReturnedHitsCount ?? ""}
                                                        onChange={handleInputChange}
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ marginRight: '2%' }}
                                                    />
                                                    <TextField
                                                        name="DYRS_ReturnedCloseHits"
                                                        label="# of Hits Within 200 Feet"
                                                        disabled={!report?.DYRS_ReturnedCloseProximity}
                                                        value={report?.DYRS_ReturnedCloseHits ?? ""}
                                                        onChange={handleInputChange}
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ marginRight: '2%' }}
                                                    />
                                                </Grid>
                                            )}

                                            {/*Form opens up when third checkbox is selected*/}
                                            {report?.DYRS_ReturnedCloseProximity && (
                                                <>
                                                    <Box display="flex" flexDirection="column" alignItems="center"
                                                        width="100%"
                                                        padding="0 16px">
                                                        {report?.DYRSVeritracks_List?.filter((f) => !f.deleted)?.map((row, index) => (
                                                            <div>
                                                                <Box display="flex" justifyContent="center" width="100%" mt={2}>
                                                                    <Typography variant="h4" mt={2}>Hit {index + 1}</Typography>
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        onClick={() => deleteTableRow("DYRSVeritracks_List", row)}
                                                                    >
                                                                        <DeleteIcon sx={{ color: "red" }} />
                                                                    </IconButton>
                                                                </Box>
                                                                <Box key={index} display="flex" flexDirection="row"
                                                                    alignItems="flex-start"
                                                                    textAlign="center" mt={2} mb={2}>
                                                                    <Grid container spacing={2} xs={9}>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="First Name"
                                                                                name="DYRS_FirstName"
                                                                                value={row?.DYRS_FirstName ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="Last Name"
                                                                                name="DYRS_LastName"
                                                                                value={row?.DYRS_LastName ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="AKA(s)"
                                                                                name="DYRS_Aliases"
                                                                                value={row?.DYRS_Aliases ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <DatePicker
                                                                                label="DOB"
                                                                                value={row?.DYRS_IndividualDOB ?? ""}
                                                                                onChange={(date) => handleInputChange({ target: { name: "DYRS_IndividualDOB", value: (date ?? "") } }, "DYRSVeritracks_List", row)}
                                                                                renderInput={(params) => <TextField {...params}
                                                                                    fullWidth />}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="PDID"
                                                                                name="DYRS_PDID"
                                                                                value={row?.DYRS_PDID ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="Juv. X-ref"
                                                                                name="DYRS_JuvXRef"
                                                                                value={row?.DYRS_JuvXRef ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="FBI"
                                                                                name="DYRS_FBI"
                                                                                value={row?.DYRS_FBI ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Autocomplete
                                                                                id="free-solo-demo"
                                                                                autoComplete={true}
                                                                                size="small"
                                                                                sx={{
                                                                                    width: '100%',
                                                                                    height: 56,
                                                                                    textAlign: "center",
                                                                                    justifyContent: "center"
                                                                                }}
                                                                                options={heightDD.map((option) => option.key)}
                                                                                value={row?.DYRS_Height ?? ""}
                                                                                onChange={(event, newValue) => handleInputChange({ target: { name: "DYRS_Height", value: newValue as string } }, "DYRSVeritracks_List", row)}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        label="Height"
                                                                                        variant="outlined"
                                                                                        fullWidth
                                                                                        InputProps={{
                                                                                            ...params.InputProps,
                                                                                            style: {
                                                                                                height: 56
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="Weight"
                                                                                name="DYRS_Weight"
                                                                                value={row?.DYRS_Weight ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="SSN"
                                                                                name="DYRS_SSN"
                                                                                value={row?.DYRS_SSN ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="Last Known Address(es)"
                                                                                name="DYRS_LastKnownAddress"
                                                                                value={row?.DYRS_LastKnownAddress ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        {/*<Grid item xs={12}>*/}
                                                                        {/*    <TextField*/}
                                                                        {/*        label="Phone Number(s)"*/}
                                                                        {/*        name="DYRS_PhoneNumber"*/}
                                                                        {/*        value={row?.DYRS_PhoneNumber ?? ""}*/}
                                                                        {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                        {/*        fullWidth*/}
                                                                        {/*    />*/}
                                                                        {/*</Grid>*/}
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="Gang/Crew"
                                                                                name="DYRS_GangCrew"
                                                                                value={row?.DYRS_GangCrew ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="Homicide Reduction Partnership (HRP) Area POI"
                                                                                name="DYRS_HRPAreaPOI"
                                                                                value={row?.DYRS_HRPAreaPOI ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="GunStat POI"
                                                                                name="DYRS_GunStatPOI"
                                                                                value={row?.DYRS_GunStatPOI ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        {/*<Grid item xs={12}>*/}
                                                                        {/*    <TextField*/}
                                                                        {/*        label="Target PSA/Area"*/}
                                                                        {/*        name="DYRS_TargetPSA"*/}
                                                                        {/*        value={row?.DYRS_TargetPSA ?? ""}*/}
                                                                        {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                        {/*        fullWidth*/}
                                                                        {/*    />*/}
                                                                        {/*</Grid>*/}
                                                                        {/*<Grid item xs={12}>*/}
                                                                        {/*    <TextField*/}
                                                                        {/*        label="Driver's License #"*/}
                                                                        {/*        name="DYRS_LicenseNo"*/}
                                                                        {/*        value={row?.DYRS_LicenseNo ?? ""}*/}
                                                                        {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                        {/*        fullWidth*/}
                                                                        {/*    />*/}
                                                                        {/*</Grid>*/}
                                                                        {/*<Grid item xs={12}>*/}
                                                                        {/*    <Autocomplete*/}
                                                                        {/*        id="free-solo-demo"*/}
                                                                        {/*        autoComplete={true}*/}
                                                                        {/*        size="small"*/}
                                                                        {/*        sx={{*/}
                                                                        {/*            width: '100%',*/}
                                                                        {/*            height: 56,*/}
                                                                        {/*            textAlign: "center",*/}
                                                                        {/*            justifyContent: "center"*/}
                                                                        {/*        }}*/}
                                                                        {/*        options={stateList.map((option: any) => option.Key)}*/}
                                                                        {/*        value={row?.DYRS_LicenseState ?? ""}*/}
                                                                        {/*        onChange={(event, newValue) => handleInputChange({target: {name: "DYRS_LicenseState" ,value: newValue as string}}, "DYRSVeritracks_List", row )}*/}
                                                                        {/*        renderInput={(params) => (*/}
                                                                        {/*            <TextField*/}
                                                                        {/*                {...params}*/}
                                                                        {/*                label="DL State"*/}
                                                                        {/*                variant="outlined"*/}
                                                                        {/*                fullWidth*/}
                                                                        {/*                InputProps={{*/}
                                                                        {/*                    ...params.InputProps,*/}
                                                                        {/*                    style: {*/}
                                                                        {/*                        height: 56*/}
                                                                        {/*                    }*/}
                                                                        {/*                }}*/}
                                                                        {/*            />*/}
                                                                        {/*        )}*/}
                                                                        {/*    />*/}
                                                                        {/*</Grid>*/}
                                                                        {/*<Grid item xs={12}>*/}
                                                                        {/*    <TextField*/}
                                                                        {/*        label="Vehicles"*/}
                                                                        {/*        name="DYRS_Vehicles"*/}
                                                                        {/*        value={row?.DYRS_Vehicles ?? ""}*/}
                                                                        {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                        {/*        fullWidth*/}
                                                                        {/*    />*/}
                                                                        {/*</Grid>*/}
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="DC Criminal History"
                                                                                name="DYRS_CriminalHistoryDC"
                                                                                value={row?.DYRS_CriminalHistoryDC ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="DC Juvenile Criminal History"
                                                                                name="DYRS_JuvenileCriminalHistoryDC"
                                                                                value={row?.DYRS_JuvenileCriminalHistoryDC ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="Cases and Charges"
                                                                                name="DYRS_CasesAndCharges"
                                                                                value={row?.DYRS_CasesAndCharges ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        {/*<Grid item xs={12}>*/}
                                                                        {/*    <TextField*/}
                                                                        {/*        label="DOC Information"*/}
                                                                        {/*        name="DYRS_DOCInformation"*/}
                                                                        {/*        value={row?.DYRS_DOCInformation ?? ""}*/}
                                                                        {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                        {/*        fullWidth*/}
                                                                        {/*    />*/}
                                                                        {/*</Grid>*/}
                                                                        {/*<Grid item xs={12}>*/}
                                                                        {/*    <TextField*/}
                                                                        {/*        label="BOP Information"*/}
                                                                        {/*        name="DYRS_BOPInformation"*/}
                                                                        {/*        value={row?.DYRS_BOPInformation ?? ""}*/}
                                                                        {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                        {/*        fullWidth*/}
                                                                        {/*    />*/}
                                                                        {/*</Grid>*/}
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="CSOSA/PSA/DYRS Supervision Information"
                                                                                name="DYRS_Supervision_CSOSA_PSA_DYRS_Information"
                                                                                value={row?.DYRS_Supervision_CSOSA_PSA_DYRS_Information ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="Offender Court Events"
                                                                                name="DYRS_OffenderCourtEvents"
                                                                                value={row?.DYRS_OffenderCourtEvents ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="Active Warrants"
                                                                                name="DYRS_ActiveWarrants"
                                                                                value={row?.DYRS_ActiveWarrants ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="Warrant History"
                                                                                name="DYRS_WarrantHistory"
                                                                                value={row?.DYRS_WarrantHistory ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        {/*<Grid item xs={12}>*/}
                                                                        {/*    <TextField*/}
                                                                        {/*        label="MD Criminal History"*/}
                                                                        {/*        name="DYRS_MDCriminalHistory"*/}
                                                                        {/*        value={row?.DYRS_MDCriminalHistory ?? ""}*/}
                                                                        {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                        {/*        fullWidth*/}
                                                                        {/*    />*/}
                                                                        {/*</Grid>*/}
                                                                        {/*<Grid item xs={12}>*/}
                                                                        {/*    <TextField*/}
                                                                        {/*        label="Additional History"*/}
                                                                        {/*        name="DYRS_AdditionalHistory"*/}
                                                                        {/*        value={row?.DYRS_AdditionalHistory ?? ""}*/}
                                                                        {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                        {/*        fullWidth*/}
                                                                        {/*    />*/}
                                                                        {/*</Grid>*/}
                                                                        {/*<Grid item xs={12}>*/}
                                                                        {/*    <TextField*/}
                                                                        {/*        label="Possible Associates"*/}
                                                                        {/*        name="DYRS_PossibleAssociates"*/}
                                                                        {/*        value={row?.DYRS_PossibleAssociates ?? ""}*/}
                                                                        {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                        {/*        fullWidth*/}
                                                                        {/*    />*/}
                                                                        {/*</Grid>*/}
                                                                        {/*<Grid item xs={12}>*/}
                                                                        {/*    <TextField*/}
                                                                        {/*        label="Possible Social Media"*/}
                                                                        {/*        name="DYRS_PossibleSocialMedia"*/}
                                                                        {/*        value={row?.DYRS_PossibleSocialMedia ?? ""}*/}
                                                                        {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                        {/*        fullWidth*/}
                                                                        {/*    />*/}
                                                                        {/*</Grid>*/}
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="Veritracks Summary"
                                                                                name="DYRS_VeritracksSummary"
                                                                                value={row?.DYRS_VeritracksSummary ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <div className="ml-5 mt-3">
                                                                            <div
                                                                                ref={pasteAreaRef}
                                                                                onPaste={(e) => handlePaste(e, row, "Veritracks Screenshot", "DYRSVeritracks_List")}
                                                                                onDragOver={handleDragOver}
                                                                                onDrop={(e) => handleDrop(e, row, "Veritracks Screenshot", "DYRSVeritracks_List")}
                                                                                onContextMenu={(e) => handleContextMenu(e, row, "Veritracks Screenshot")}
                                                                                style={{
                                                                                    border: '5px dashed #ccc',
                                                                                    padding: '20px',
                                                                                    marginBottom: '20px',
                                                                                    textAlign: 'center',
                                                                                }}
                                                                            >
                                                                                {row?.DYRS_VeritracksScreenshot && !row?.DYRS_VeritracksScreenshot.deleted ? (
                                                                                    <div>
                                                                                        <Box key={index} position="relative" display="inline-block">
                                                                                            <img
                                                                                                src={`data:image/png;base64,${row?.DYRS_VeritracksScreenshot?.Content}`}
                                                                                                alt=""
                                                                                                style={{ maxWidth: '100%' }}
                                                                                            />
                                                                                            <IconButton
                                                                                                aria-label="delete"
                                                                                                onClick={() => handleRemoveFile(row?.DYRS_VeritracksScreenshot, "DYRSVeritracks_List", row)}
                                                                                                style={{
                                                                                                    position: 'absolute',
                                                                                                    top: 0,
                                                                                                    right: 0,
                                                                                                    zIndex: 1,
                                                                                                    color: 'red'
                                                                                                }}
                                                                                            >
                                                                                                <CloseIcon />
                                                                                            </IconButton>
                                                                                        </Box>
                                                                                    </div>

                                                                                ) : (
                                                                                    "Paste or Drag & Drop Veritracks Screenshot Here"
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                    <Box display="flex" flexDirection="column"
                                                                        alignItems="center"
                                                                        justifyContent="center" ml={2}>
                                                                        <DatePicker
                                                                            label="Image Date"
                                                                            value={row?.DYRS_ImageDate ?? ""}
                                                                            onChange={(date: any) => handleInputChange({ target: { name: "DYRS_ImageDate", value: (date ?? "") } }, "DYRSVeritracks_List", row)}
                                                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                                                        />
                                                                        <div
                                                                            onPaste={(e) => handlePaste(e, row, "Individual Photo", "DYRSVeritracks_List")}
                                                                            onDrop={(e) => handleDrop(e, row, "Individual Photo", "DYRSVeritracks_List")}
                                                                            onDragOver={handleDragOver}
                                                                            onContextMenu={(e) => handleContextMenu(e, row, "Individual Photo", "DYRSVeritracks_List")}
                                                                            style={{ border: '5px dashed #ccc', padding: '20px', textAlign: 'center', marginTop: '10px' }}
                                                                        >
                                                                            {row?.DYRS_IndividualPhoto && !row?.DYRS_IndividualPhoto?.deleted ? (
                                                                                <div>
                                                                                    <Box position="relative" display="inline-block">
                                                                                        <img
                                                                                            src={`data:image/png;base64,${row?.DYRS_IndividualPhoto?.Content}`}
                                                                                            alt=""
                                                                                            style={{ maxWidth: '100%' }}
                                                                                        />
                                                                                        <IconButton
                                                                                            aria-label="delete"
                                                                                            onClick={() => handleRemoveFile(row?.DYRS_IndividualPhoto, "DYRSVeritracks_List", row)}
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                top: 0,
                                                                                                right: 0,
                                                                                                zIndex: 1,
                                                                                                color: 'red'
                                                                                            }}
                                                                                        >
                                                                                            <CloseIcon />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                </div>
                                                                            ) : (
                                                                                "Paste or Drag & Drop Face Photo Here"
                                                                            )}
                                                                        </div>
                                                                    </Box>
                                                                </Box>
                                                            </div>
                                                        ))}
                                                    </Box>

                                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                                        <ButtonGroup variant="contained" aria-label="Table buttons" size="small"
                                                            color="inherit">
                                                            <Button
                                                                color="inherit"
                                                                onClick={() => addTableRow("DYRSVeritracks_List", "GUID", GetGUID())}
                                                                startIcon={<AddIcon />}
                                                                variant="contained"
                                                            >
                                                                Add Another DYRS Veritracks Hit
                                                            </Button>
                                                            <Button
                                                                color="inherit"
                                                                onClick={() => handleDialog("DYRS", true)}
                                                                startIcon={<ClearIcon />}
                                                                variant="contained"
                                                            >
                                                                Clear All Hits
                                                            </Button>
                                                            <Dialog
                                                                open={dyrsClearOpen}
                                                                onClose={() => handleDialog("DYRS", false)}
                                                                aria-labelledby="clear-dialog"
                                                                aria-describedby="clear-dyrs"
                                                            >
                                                                <DialogTitle id="clear-dialog-title">
                                                                    {"Clear all existing DYRS hits?"}
                                                                </DialogTitle>
                                                                <DialogContent>
                                                                    <DialogContentText id="clear-dyrs">
                                                                        Are you sure you would like to clear all DYRS background hits from the list?
                                                                    </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={() => handleDialog("DYRS", false)}>No</Button>
                                                                    <Button onClick={() => { clearTableRows("DYRSVeritracks_List"); handleDialog("DYRS", false) }} autoFocus>
                                                                        Yes
                                                                    </Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </ButtonGroup>
                                                    </Box>
                                                </>
                                            )}

                                            <Typography className="italic pl-6">
                                                <br />
                                                Please note, DYRS Veritracks is experiencing technical difficulties. The
                                                incident was queried
                                                and results were reviewed to the best of the writer's ability.
                                                Please contact the RTCC at a later date if you need the incident re-ran or
                                                you need an analyst to
                                                look at a specific track.
                                            </Typography>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </div>

                            <div className="container mt-4 ml-4 mr-5">
                                {/* Shotspotter Header */}
                                <Box className="header" textAlign="center" position={"relative"}
                                    width="100%" style={(report?.ShotSpotter_Disabled ? { backgroundColor: 'gray' } : {})}>
                                    <span style={{ position: 'absolute', left: '0.5rem', bottom: '0.1rem, top:0.5rem' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={report?.ShotSpotter_Disabled ?? false}
                                                    onChange={handleCheckboxChange}
                                                    color={"default"}
                                                    name="ShotSpotter_Disabled"
                                                    checkedIcon={<VisibilityOffIcon />}
                                                />
                                            }
                                            label="NO SHOTSPOTTER NEEDED"
                                        />
                                    </span>
                                    <Typography variant="h5">
                                        SHOTSPOTTER
                                    </Typography>
                                </Box>

                                <Grid item xs={15} className="pl-2 pr-2">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Shotspotter alerted
                                        </FormLabel>
                                        <RadioGroup row className="mt-2" aria-label="shotspotterAlerted"
                                            name="ShotSpotter_Alerted"
                                            value={report?.ShotSpotter_Alerted ?? ""}
                                            onChange={handleInputChange}>
                                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={report?.ShotSpotter_Disabled ?? false} />
                                            <FormControlLabel value="No" control={<Radio />} label="No" disabled={report?.ShotSpotter_Disabled ?? false} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {/* Shotspotter Details */}
                                {report?.ShotSpotter_List?.filter(s => !s?.deleted)?.map((row, index) => (
                                    <div key={index} className={index > 0 ? "mt-4" : "mt-2"}>
                                        {report?.ShotSpotter_Alerted === 'Yes' && (
                                            <div>
                                                <Box display="flex" justifyContent="center" width="100%" mt={2}>
                                                    <Typography variant="h4" mt={2}>ShotSpotter Hit {index + 1}</Typography>
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => deleteTableRow("ShotSpotter_List", row)}
                                                    >
                                                        <DeleteIcon sx={{ color: "red" }} />
                                                    </IconButton>
                                                </Box>
                                                <Table className="pl-2 pr-2">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>ShotSpotter Findings</TableCell>
                                                            <TableCell>ShotSpotter Date Time</TableCell>
                                                            <TableCell width={'40%'}>ShotSpotter Location</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <TextField
                                                                    name="ShotSpotter_RoundCount"
                                                                    label="# of Rounds"
                                                                    disabled={!report?.ShotSpotter_Alerted}
                                                                    value={row?.ShotSpotter_RoundCount ?? ""}
                                                                    onChange={(e) => handleInputChange(e, "ShotSpotter_List", row)}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <DateTimePicker
                                                                    label="ShotSpotter Date/Time"
                                                                    ampm={false}
                                                                    value={row?.ShotSpotter_DateTime ?? ""}
                                                                    onChange={(date) => handleInputChange({ target: { name: "ShotSpotter_DateTime", value: (date ?? "") } }, "ShotSpotter_List", row)}
                                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="ShotSpotter_Location"
                                                                    label="ShotSpotter Location"
                                                                    disabled={!report?.ShotSpotter_Alerted}
                                                                    value={row?.ShotSpotter_Location ?? ""}
                                                                    onChange={(e) => handleInputChange(e, "ShotSpotter_List", row)}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>

                                                <Grid item xs={12} className="pl-2 pr-2">
                                                    <div
                                                        onDrop={(e) => handleDrop(e, row, "ShotSpotter Screenshots", "ShotSpotter_List")}
                                                        onPaste={(e) => handlePaste(e, row, "ShotSpotter Screenshots", "ShotSpotter_List")}
                                                        onContextMenu={(e) => handleContextMenu(e, row, "ShotSpotter Screenshots", "ShotSpotter_List")}
                                                        onDragOver={(e) => e.preventDefault()}
                                                        style={{
                                                            border: '5px dashed #ccc',
                                                            padding: '20px',
                                                            textAlign: 'center',
                                                            marginTop: "2%",
                                                            marginBottom: '2%'
                                                        }}
                                                    >
                                                        {row.Files && row.Files.length > 0 ? row.Files.filter((file: any) => file.Origination === "ShotSpotter Screenshots" && !file.deleted).map((file: any, idx: number) =>
                                                            <div key={idx}>
                                                                <Box position="relative" display="inline-block">
                                                                    <img
                                                                        src={`data:image/png;base64,${file.Content}`}
                                                                        alt=""
                                                                        style={{ maxWidth: '100%' }}
                                                                    />
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        onClick={() => handleRemoveFile(file, "ShotSpotter_List", row)}
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 0,
                                                                            right: 0,
                                                                            zIndex: 1,
                                                                            color: 'red'
                                                                        }}
                                                                    >
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            </div>
                                                        ) : (
                                                            "Paste or Drag & Drop Your ShotSpotter Screenshot here"
                                                        )}
                                                    </div>
                                                </Grid>
                                            </div>
                                        )}

                                    </div>
                                ))}
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        <ButtonGroup variant="contained" aria-label="Table buttons" size="small"
                                            color="inherit"
                                            className="mb-4">
                                            <Button
                                                variant="contained"
                                                startIcon={<AddIcon />}
                                                disabled={report?.ShotSpotter_Disabled ?? false}
                                                onClick={() => addTableRow("ShotSpotter_List", "GUID", GetGUID())}
                                            >
                                                Add Shot Spotter
                                            </Button>
                                            <Button
                                                variant="contained"
                                                startIcon={<ClearIcon />}
                                                disabled={report?.ShotSpotter_Disabled ?? false}
                                                onClick={() => handleDialog("Shot Spotter", true)}
                                            >
                                                Clear All ShotSpotter Hits
                                            </Button>
                                            <Dialog
                                                open={shotSpotterClearOpen}
                                                onClose={() => handleDialog("Shot Spotter", false)}
                                                aria-labelledby="clear-dialog"
                                                aria-describedby="clear-cameras"
                                            >
                                                <DialogTitle id="clear-dialog-title">
                                                    {"Clear all existing Shot Spotters?"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="clear-cameras">
                                                        Are you sure you would like to clear all shot spotters in the list?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => handleDialog("Shot Spotter", false)}>No</Button>
                                                    <Button onClick={() => { clearTableRows("ShotSpotter_List", "GUID", GetGUID()); handleDialog("Shot Spotter", false); console.log("Shotspotter: ", report?.ShotSpotter_List) }} autoFocus>
                                                        Yes
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </ButtonGroup>
                                    </Box>
                                </Grid>
                            </div>

                            <div className="container mt-4 ml-4 mr-5">
                                <Box className="header" display="flex" flexDirection="column" alignItems="center"
                                    textAlign="center" position={"relative"}
                                    width="100%" style={(report?.Private_Disabled ? { backgroundColor: 'gray' } : {})}>
                                    <span style={{ position: 'absolute', left: '0.5rem', bottom: '0.1rem, top:0.5rem' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={report?.Private_Disabled ?? false}
                                                    onChange={handleCheckboxChange}
                                                    color={"default"}
                                                    name="Private_Disabled"
                                                    checkedIcon={<VisibilityOffIcon />}
                                                    disabled={report?.CameraList && report?.CameraList?.filter(f => !f.deleted && f.Camera_Type === "Private Camera Locations")?.length > 1}
                                                />
                                            }
                                            label="NO PRIVATE CAMERAS"
                                        />
                                    </span>
                                    <Typography variant="h5">
                                        PRIVATE SECURITY CAMERA INCENTIVE PROGRAM
                                    </Typography>
                                </Box>

                                <Grid item xs={12} className="pl-2 pr-2 mt-4">
                                    <Box>
                                        <Typography variant="body1">
                                            Listed below are residential and business cameras registered with
                                            the Government of the District of Columbia's Private Security Camera Incentive
                                            Program.
                                            The RTCC does not have access to these cameras. The detective must contact the
                                            resident or business
                                            owner directly.
                                            <br />
                                            <br />
                                            Private Security Camera System Incentive Program cameras within 1,250 feet of
                                            the incident location:
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} className="pl-2 pr-2 mt-2">
                                    <Typography variant="body1" component="span">
                                        Please upload a screenshot of Private Cameras within 1,250 feet of the incident
                                        location.
                                        <br />
                                    </Typography>
                                    <Typography variant="body2" component="span" fontStyle="italic" color="gray">
                                        (Take off gang/crew layer, schools, CCTV/Octo cameras, etc. Only leave the PSA and
                                        private camera layer)
                                        <br />
                                    </Typography>
                                    <Grid item xs={12} className="pl-2 pr-2">
                                        <div
                                            onDrop={(e) => handleDrop(e, null, "Private Camera Locations")}
                                            onPaste={(e) => handlePaste(e, null, "Private Camera Locations")}
                                            onContextMenu={(e) => handleContextMenu(e, null, "Private Camera Locations")}
                                            onDragOver={(e) => e.preventDefault()}
                                            style={{
                                                border: '5px dashed #ccc',
                                                padding: '20px',
                                                textAlign: 'center',
                                                marginTop: "2%",
                                                marginBottom: '2%'
                                            }}
                                        >
                                            {report?.Files && report?.Files?.filter(f => f?.Origination === "Private Camera Locations").length > 0 ? (
                                                report?.Files?.filter(f => f.Origination === "Private Camera Locations").map((file, index) => (
                                                    <div>
                                                        <Box position="relative" display="inline-block">
                                                            <img
                                                                src={`data:image/png;base64,${file.Content}`}
                                                                alt=""
                                                                style={{ maxWidth: '100%' }}
                                                            />
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => handleRemoveFile(file)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    right: 0,
                                                                    zIndex: 1,
                                                                    color: 'red'
                                                                }}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </div>
                                                ))
                                            ) : (
                                                "Paste or Drag & Drop Your Private Camera Locations Screenshot here"
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className="pl-2 pr-2 mt-2">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Actions</TableCell>
                                                <TableCell>Camera #</TableCell>
                                                <TableCell>Distance</TableCell>
                                                <TableCell>Address</TableCell>
                                                <TableCell>Contact First Name</TableCell>
                                                <TableCell>Contact Last Name</TableCell>
                                                <TableCell>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {report?.CameraList?.filter(f => f?.Camera_Type === "Private Camera Locations" && !f?.deleted)?.map((row, index) => (
                                                <TableRow key={index} draggable onDragStart={() => handlePrivateDragStart(index)} onDragOver={(e) => { e.preventDefault(); setPrivateHoveredIndex(index); }} onDrop={() => handleTableDrop(index, "Private Camera Locations")}
                                                    onDragEnd={() => setPrivateHoveredIndex(null)} style={{
                                                        backgroundColor:
                                                            privateDraggedIndex === index
                                                                ? 'lightgray'
                                                                : privateHoveredIndex === index
                                                                    ? '#f0f0f0'
                                                                    : 'white',
                                                    }}>
                                                    <TableCell>
                                                        <Tooltip title="Drag to reorder">
                                                            <IconButton
                                                                onDragStart={() => handlePrivateDragStart(index)} onDrop={() => handleTableDrop(index, "Private Camera Locations")} onDragOver={(e) => { e.preventDefault(); setPrivateHoveredIndex(index) }}>
                                                                <DragIndicatorIcon
                                                                    sx={{ cursor: 'grab' }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="CCTV_Distance"
                                                            value={row?.CCTV_Distance ?? ""}
                                                            disabled={report?.Private_Disabled ?? false}
                                                            onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="CCTV_Location"
                                                            value={row?.CCTV_Location ?? ""}
                                                            disabled={report?.Private_Disabled ?? false }
                                                            onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="ContactFirstName"
                                                            value={row?.ContactFirstName ?? ""}
                                                            disabled={report?.Private_Disabled ?? false }
                                                            onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="ContactLastName"
                                                            value={row?.ContactLastName ?? ""}
                                                            disabled={report?.Private_Disabled ?? false }
                                                            onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title="Delete Camera">
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => deleteTableRow("CameraList", row)}
                                                            >
                                                                <DeleteIcon sx={{ color: "red" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>

                                {/* Table Buttons */}
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        <ButtonGroup variant="contained" aria-label="Table buttons" size="small"
                                            color="inherit"
                                            className="mb-4">
                                            <Button
                                                variant="contained"
                                                startIcon={<AddIcon />}
                                                onClick={() => addTableRow("CameraList", "Camera_Type", "Private Camera Locations")}
                                                disabled={report?.Private_Disabled ?? false }
                                            >
                                                Add Camera
                                            </Button>
                                            <Button
                                                variant="contained"
                                                startIcon={<DescriptionIcon />}
                                                onClick={() => handleDialog("Private Excel", true)}
                                                disabled={report?.Private_Disabled ?? false }
                                            >
                                                Paste Cameras from Excel
                                            </Button>
                                            <Dialog
                                                open={excelPrivateOpen}
                                                onClose={() => handleDialog("Private Excel", false)}
                                                aria-labelledby="excel-dialog"
                                                aria-describedby="excel-paste"
                                            >
                                                <DialogTitle id="excel-dialog-title">
                                                    {"Paste cameras from your Excel sheet"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <textarea
                                                        id="excel-paste-area"
                                                        style={{
                                                            width: '100%',
                                                            height: '150px',
                                                            padding: '10px',
                                                            fontSize: '16px',
                                                            borderRadius: '4px',
                                                            borderColor: '#ccc'
                                                        }}
                                                        placeholder="Paste your camera data here (do not include headers)"
                                                        onPaste={(e) => {
                                                            handlePaste(e, null, "Private Camera Locations");
                                                            handleDialog("Private Excel", false); // Close the dialog after pasting
                                                        }}
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => handleDialog("Private Excel", false)}>Close</Button>
                                                </DialogActions>
                                            </Dialog>
                                            <Button
                                                variant="contained"
                                                startIcon={<ClearIcon />}
                                                onClick={() => handleDialog("Private Camera", true)}
                                                disabled={report?.Private_Disabled ?? false }
                                            >
                                                Clear All Cameras
                                            </Button>
                                            <Dialog
                                                open={privateClearOpen}
                                                onClose={() => handleDialog("Private Camera", false)}
                                                aria-labelledby="clear-dialog"
                                                aria-describedby="clear-cameras"
                                            >
                                                <DialogTitle id="clear-dialog-title">
                                                    {"Clear all existing cameras?"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="clear-cameras">
                                                        Are you sure you would like to clear all cameras in the list?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => handleDialog("Private Camera", false)}>No</Button>
                                                    <Button onClick={() => { clearTableRows("CameraList", "Camera_Type", "Private Camera Locations"); handleDialog("Private Camera", false) }} autoFocus>
                                                        Yes
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </ButtonGroup>
                                    </Box>
                                </Grid>
                                {/*<Grid item xs={12} className="pl-2 pr-2">*/}
                                {/*    <Typography variant="h6" gutterBottom>*/}
                                {/*        Camera Observations*/}
                                {/*    </Typography>*/}
                                {/*    <TextField*/}
                                {/*        label="Positive Camera Observations"*/}
                                {/*        name="Private_Observations"*/}
                                {/*        value={report?.Private_Observations ?? ""}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        multiline*/}
                                {/*        rows={10}*/}
                                {/*        fullWidth*/}

                                {/*        style={{ marginTop: '10px', marginBottom: '5px' }}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                            </div>

                            <div className="container mt-4 ml-4 mr-5">
                                <Box className="header" display="flex" flexDirection="column" alignItems="center"
                                    textAlign="center"
                                    width="100%">
                                    <Typography variant="h5">
                                        GANG/CREW INFORMATION
                                    </Typography>
                                </Box>

                                <Grid item xs={12} className="pl-2 pr-2">
                                    <FormControl component="fieldset">
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={report?.Gang_NonGangArea ?? false}
                                                            disabled={(report?.Gang_GangArea || report?.Gang_GangAreaBlock) ?? false}
                                                            onChange={handleCheckboxChange}
                                                            name="Gang_NonGangArea"
                                                        />
                                                    }
                                                    label="This incident did not occur in a known Gang/Crew area."
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={report?.Gang_GangArea ?? false}
                                                            onChange={handleCheckboxChange}
                                                            disabled={(report?.Gang_NonGangArea) ?? false}
                                                            name="Gang_GangArea"
                                                        />
                                                    }
                                                    label={
                                                        <Box display="flex" alignItems="center">
                                                            <Typography variant="body1" component="span" mr={1}>
                                                                This incident occurred in a Gang/Crew area. If you need any
                                                                additional
                                                                information, please contact the Intelligence Branch.
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                            </Grid>
                                            {report?.Gang_GangArea && (
                                                <Grid item xs={10}>
                                                    <TextField
                                                        name="Gang_Area"
                                                        label="Gang/Crew Area"
                                                        disabled={!report?.Gang_GangArea}
                                                        value={report?.Gang_Area ?? ""}
                                                        onChange={handleInputChange}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={report?.Gang_GangAreaBlock ?? false}
                                                            onChange={handleCheckboxChange}
                                                            disabled={(report?.Gang_NonGangArea) ?? false}
                                                            name="Gang_GangAreaBlock"
                                                        />
                                                    }
                                                    label={
                                                        <Box display="flex" alignItems="center">
                                                            <Typography variant="body1" component="span" mr={1}>
                                                                This incident occurred within a block of a Gang/Crew area.
                                                                If you need any
                                                                additional information, please contact the Intelligence
                                                                Branch.
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>

                                        {report?.Gang_GangAreaBlock && (
                                            <Grid item xs={10}>
                                                <TextField
                                                    name="Gang_Area"
                                                    label="Gang/Crew Area"
                                                    disabled={!report?.Gang_GangAreaBlock}
                                                    value={report?.Gang_Area ?? ""}
                                                    onChange={handleInputChange}
                                                    variant="outlined"
                                                    size="small"
                                                    style={{ marginBottom: '2%' }}
                                                />
                                            </Grid>
                                        )}
                                    </FormControl>
                                </Grid>
                            </div>

                            <div className="container mt-4 ml-4 mr-5">
                                <Box className="header" display="flex" flexDirection="column" alignItems="center"
                                    textAlign="center"
                                    width="100%">
                                    <Typography variant="h5">
                                        OPERATION THRIVE
                                    </Typography>
                                </Box>

                                <Grid item xs={12} className="pl-2 pr-2">
                                    <FormControl component="fieldset">
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={report?.OperationThrive_NonThriveArea ?? false}
                                                            disabled={report?.OperationThrive_ThriveArea ?? false}
                                                            onChange={handleCheckboxChange}
                                                            name="OperationThrive_NonThriveArea"
                                                        />
                                                    }
                                                    label="This incident did not occur in an Operation THRIVE Area."
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={report?.OperationThrive_ThriveArea ?? false}
                                                            onChange={handleCheckboxChange}
                                                            disabled={report?.OperationThrive_NonThriveArea ?? false}
                                                            name="OperationThrive_ThriveArea"
                                                        />
                                                    }
                                                    label={
                                                        <Box display="flex" alignItems="center">
                                                            <Typography variant="body1" component="span" mr={1}>
                                                                This incident occurred in an Operation THRIVE area.
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                            </Grid>
                                            {report?.OperationThrive_ThriveArea && (
                                                <Grid item xs={10}>
                                                    <TextField
                                                        name="OperationThrive_Area"
                                                        label="Operation THRIVE Area"
                                                        disabled={!report?.OperationThrive_ThriveArea}
                                                        value={report?.OperationThrive_Area ?? ""}
                                                        onChange={handleInputChange}
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ marginBottom: '8%' }}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </div>

                            <div className="container mt-4 ml-4 mr-5">
                                <Box className="header" display="flex" flexDirection="column" alignItems="center"
                                    textAlign="center" position={"relative"}
                                    width="100%" style={(report?.Incident_Disabled ? { backgroundColor: 'gray' } : {})}>
                                    <span style={{ position: 'absolute', left: '0.5rem', bottom: '0.1rem, top:0.5rem' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={report?.Incident_Disabled ?? false}
                                                    onChange={handleCheckboxChange}
                                                    color={"default"}
                                                    name="Incident_Disabled"
                                                    checkedIcon={<VisibilityOffIcon />}
                                                    disabled={report?.IncidentList && report?.IncidentList?.filter(f => !f.deleted)?.length > 1}
                                                />
                                            }
                                            label="NO RELATED INCIDENTS"
                                        />
                                    </span>
                                    <Typography variant="h5">
                                        INCIDENT ADDRESS INFORMATION
                                    </Typography>
                                </Box>

                                <Grid item xs={12} className="pl-2 pr-2">
                                    <Box>
                                        <Typography variant="body1">
                                            Listed below are the ADWs, Carjackings, Homicides, Robberies, and Burglaries
                                            that have occurred in
                                            the last 30 days
                                            within 1,250 feet of the incident location. The data refreshes between 0130 and
                                            0230 each day.
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="pl-2 pr-2">
                                    <Typography variant="body1" component="span">
                                        Please upload a screenshot of crimes that have occurred within 1250 feet of the
                                        incident location over
                                        the past 30 days.
                                        <br />
                                    </Typography>
                                    <Typography variant="body2" component="span" fontStyle="italic" color="gray">
                                        (Take off gang/crew layer, cameras, schools, etc.)
                                        <br />
                                    </Typography>
                                    <Grid item xs={12} className="pl-2 pr-2">
                                        <div
                                            onDrop={(e) => handleDrop(e, null, "Crime Locations")}
                                            onPaste={(e) => handlePaste(e, null, "Crime Locations")}
                                            onContextMenu={(e) => handleContextMenu(e, null, "Crime Locations")}
                                            onDragOver={(e) => e.preventDefault()}
                                            style={{
                                                border: '5px dashed #ccc',
                                                padding: '20px',
                                                textAlign: 'center',
                                                marginTop: "2%",
                                                marginBottom: '2%'
                                            }}
                                        >
                                            {report?.Files && report?.Files?.filter(f => f?.Origination === "Crime Locations" && !f.deleted).length > 0 ? (
                                                report?.Files?.filter(f => f.Origination === "Crime Locations").map((file, index) => (
                                                    <div>
                                                        <Box position="relative" display="inline-block">
                                                            <img
                                                                src={`data:image/png;base64,${file.Content}`}
                                                                alt=""
                                                                style={{ maxWidth: '100%' }}
                                                            />
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => handleRemoveFile(file)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    right: 0,
                                                                    zIndex: 1,
                                                                    color: 'red'
                                                                }}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </div>
                                                ))
                                            ) : (
                                                "Paste or Drag & Drop Crime Locations Screenshot here"
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className="pl-2 pr-2">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Crime #</TableCell>
                                                <TableCell>CCN</TableCell>
                                                <TableCell>Day of Week</TableCell>
                                                <TableCell>Event Date/Time</TableCell>
                                                <TableCell>Offense</TableCell>
                                                <TableCell>Weapon</TableCell>
                                                <TableCell>Address</TableCell>
                                                <TableCell>PSA</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {report?.IncidentList?.filter(f => !f?.deleted)?.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell style={{ width: 150 }}>
                                                        <TextField
                                                            name="Incident_CCN"
                                                            value={row?.Incident_CCN ?? ""}
                                                            multiline
                                                            onChange={(e) => handleInputChange(e, "IncidentList", row)}
                                                            inputProps={{
                                                                style: {
                                                                    borderRadius: "1px",
                                                                    textAlign: 'center'
                                                                }
                                                            }}
                                                            disabled={report?.Incident_Disabled ?? false}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ width: 200 }}>
                                                        <Autocomplete
                                                            options={weekdays}
                                                            getOptionLabel={(option) => option.label}
                                                            value={weekdays.find(day => day.value === row?.Incident_DayOfWeek) ?? null}
                                                            disabled={report?.Incident_Disabled ?? false}
                                                            onChange={(event, newValue) => handleInputChange({ target: { name: "Incident_DayOfWeek", value: newValue?.value ?? "" } }, "IncidentList", row)}
                                                            renderInput={(params) => <TextField {...params} label="" variant="outlined" fullWidth disabled={report?.Incident_Disabled ?? false} />}
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ width: 260 }}>
                                                        <DateTimePicker
                                                            value={row?.Incident_EventDateTime ?? ""}
                                                            ampm={false}
                                                            disabled={report?.Incident_Disabled ?? false}
                                                            onChange={(date) => handleInputChange({ target: { name: "Incident_EventDateTime", value: (date ?? "") } }, "IncidentList", row)}
                                                            renderInput={(params) => <TextField {...params} fullWidth disabled={report?.Incident_Disabled ?? false} />}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title={row?.Incident_Offense ?? ""}>
                                                            <TextField
                                                                name="Incident_Offense"
                                                                value={row?.Incident_Offense ?? ""}
                                                                onChange={(e) => handleInputChange(e, "IncidentList", row)}
                                                                inputProps={{
                                                                    style: {
                                                                        borderRadius: "1px",
                                                                        textAlign: 'center'
                                                                    }
                                                                }}
                                                                disabled={report?.Incident_Disabled ?? false}
                                                                fullWidth
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="Incident_Weapon"
                                                            value={row?.Incident_Weapon ?? ""}
                                                            multiline
                                                            onChange={(e) => handleInputChange(e, "IncidentList", row)}
                                                            inputProps={{
                                                                style: {
                                                                    borderRadius: "1px",
                                                                    textAlign: 'center'
                                                                }
                                                            }}
                                                            disabled={report?.Incident_Disabled ?? false}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title={row?.Incident_Address ?? ""}>
                                                            <TextField
                                                                name="Incident_Address"
                                                                value={row?.Incident_Address ?? ""}
                                                                onChange={(e) => handleInputChange(e, "IncidentList", row)}
                                                                inputProps={{
                                                                    style: {
                                                                        borderRadius: "1px",
                                                                        textAlign: 'center',
                                                                        wordBreak: 'break-word',
                                                                        whiteSpace: 'pre-wrap',
                                                                        overflowWrap: 'break-word',
                                                                    }
                                                                }}
                                                                disabled={report?.Incident_Disabled ?? false}
                                                                fullWidth
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="Incident_PSA"
                                                            value={row?.Incident_PSA ?? ""}
                                                            multiline
                                                            onChange={(e) => handleInputChange(e, "IncidentList", row)}
                                                            inputProps={{
                                                                style: {
                                                                    borderRadius: "1px",
                                                                    textAlign: 'center'
                                                                }
                                                            }}
                                                            disabled={report?.Incident_Disabled ?? false}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title="Delete Crime">
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => deleteTableRow("IncidentList", row)}
                                                            >
                                                                <DeleteIcon sx={{ color: "red" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>

                                {/* Table Buttons */}
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        <ButtonGroup variant="contained" className="mb-4" aria-label="Table buttons"
                                            size="small"
                                            color="inherit">
                                            <Button
                                                variant="contained"
                                                startIcon={<AddIcon />}
                                                onClick={() => addTableRow("IncidentList")}
                                                disabled={report?.Incident_Disabled ?? false}
                                            >
                                                Add Crime
                                            </Button>
                                            <Button
                                                variant="contained"
                                                startIcon={<ClearIcon />}
                                                onClick={() => handleDialog("Incident", true)}
                                                disabled={report?.Incident_Disabled ?? false}
                                            >
                                                Clear All Crimes
                                            </Button>
                                            <Dialog
                                                open={clearOpen}
                                                onClose={() => handleDialog("Incident", false)}
                                                aria-labelledby="clear-dialog"
                                                aria-describedby="clear-crimes"
                                            >
                                                <DialogTitle id="clear-dialog-title">
                                                    {"Clear all existing crimes?"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="clear-crimes">
                                                        Are you sure you would like to clear all crimes in the list?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => handleDialog("Incident", false)}>No</Button>
                                                    <Button onClick={() => { clearTableRows("IncidentList"); handleDialog("Incident", false) }} autoFocus>
                                                        Yes
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </ButtonGroup>
                                    </Box>
                                </Grid>
                            </div>

                            <div className="container mt-4 ml-4 mr-5">
                                <Box className="header" textAlign="center" position={"relative"}
                                    width="100%" style={(report?.LPR_Disabled ? { backgroundColor: 'gray' } : {})}>
                                    <span style={{ position: 'absolute', left: '0.5rem', bottom: '0.1rem, top:0.5rem' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={report?.LPR_Disabled ?? false}
                                                    onChange={handleCheckboxChange}
                                                    color={"default"}
                                                    name="LPR_Disabled"
                                                    checkedIcon={<VisibilityOffIcon />}
                                                />
                                            }
                                            label="NO LPR/RELATED VEHICLES"
                                        />
                                    </span>
                                    <Typography variant="h5">
                                        LPR/RELATED VEHICLES
                                    </Typography>
                                </Box>
                                <Grid item xs={12} className="pl-2 pr-2">
                                    {report?.LprList?.filter(f => !f.deleted)?.map((row, index) => (
                                        <div key={index} style={{ width: '100%' }}>
                                            <Grid container spacing={2}>
                                                <Box display="flex" justifyContent="center" width="100%" mt={2}>
                                                    <Typography variant="h4" mt={2}>Vehicle {index + 1}</Typography>
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => deleteTableRow("LprList", row)}
                                                    >
                                                        <DeleteIcon sx={{ color: "red" }} />
                                                    </IconButton>
                                                </Box>
                                                <Grid item xs={12} className="pl-2 pr-2">
                                                    <div
                                                        onDrop={(e) => handleDrop(e, row, "LPR Screenshots", "LprList")}
                                                        onPaste={(e) => handlePaste(e, row, "LPR Screenshots", "LprList")}
                                                        onContextMenu={(e) => handleContextMenu(e, row, "LPR Screenshots", "LprList")}
                                                        onDragOver={(e) => e.preventDefault()}
                                                        style={{
                                                            border: '5px dashed #ccc',
                                                            padding: '20px',
                                                            textAlign: 'center',
                                                            marginTop: "2%",
                                                            marginBottom: '2%'
                                                        }}
                                                    >

                                                        {row.Files && row.Files.length > 0 && row.Files.every(file => !file.deleted) ? row.Files.filter((file: any) => file.Origination === "LPR Screenshots" && !file.deleted).map((file: any, idx: number) =>
                                                            <div key={idx}>
                                                                <Box position="relative" display="inline-block">
                                                                    <img
                                                                        src={`data:image/png;base64,${file.Content}`}
                                                                        alt=""
                                                                        style={{ maxWidth: '100%' }}
                                                                    />
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        onClick={() => handleRemoveFile(file, "LprList", row)}
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 0,
                                                                            right: 0,
                                                                            zIndex: 1,
                                                                            color: 'red'
                                                                        }}
                                                                    >
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Box>

                                                            </div>
                                                        ) : (
                                                            "Paste or Drag & Drop Your LPR Screenshot here"
                                                        )}
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label={`Vehicle ${index + 1} Description`}
                                                        name={"LPR_Veh_Description"}
                                                        disabled={report?.LPR_Disabled ?? false}
                                                        value={row?.LPR_Veh_Description ?? ""}
                                                        onChange={(e) => handleInputChange(e, "LprList", row)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Relationship"
                                                        name={"LPR_Veh_Relationship"}
                                                        disabled={report?.LPR_Disabled ?? false}
                                                        value={row?.LPR_Veh_Relationship ?? ""}
                                                        onChange={(e) => handleInputChange(e, "LprList", row)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Was LPR queried?</FormLabel>
                                                        <RadioGroup row aria-label="lprHit"
                                                            name={"LPR_Queried"}
                                                            value={row?.LPR_Queried ?? ""}
                                                            onChange={(e) => handleInputChange(e, "LprList", row)}
                                                        >
                                                            <FormControlLabel value="Yes" control={<Radio />} disabled={report?.LPR_Disabled ?? false} label="Yes" />
                                                            <FormControlLabel value="No" control={<Radio />} disabled={report?.LPR_Disabled ?? false} label="No" />
                                                        </RadioGroup>
                                                        {(row?.LPR_Queried === 'Yes' || row?.LPR_Queried === 'No') && (
                                                            <div key={index}>
                                                                <Grid container spacing={2} marginTop='1%'>
                                                                    <Grid item xs={12}>
                                                                        <FormControlLabel
                                                                            key={index}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={row?.LPR_CustomQuery ?? false}
                                                                                    onChange={handleCheckboxChange}
                                                                                    name={`LPR_CustomQuery_${index}`}
                                                                                />
                                                                            }
                                                                            label="Click Here to Type a Custom Summary"
                                                                        />
                                                                    </Grid>
                                                                    {!row.LPR_CustomQuery && (
                                                                        <Grid item xs={12}>
                                                                            <FormControlLabel
                                                                                key={index}
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={row?.LPR_VINIncluded ?? false}
                                                                                        onChange={handleCheckboxChange}
                                                                                        name={`LPR_VINIncluded_${index}`}
                                                                                    />
                                                                                }
                                                                                label="VIN Included"
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {!row.LPR_CustomQuery && (
                                                                        <div className="ml-4">
                                                                            {row?.LPR_Queried === 'No' && (
                                                                                <Grid item xs={12}>
                                                                                    <Typography sx={{ color: 'yellow', WebkitTextStroke: '0.8px black', fontSize: '1.6rem', fontStyle: 'bold' }}>* All fields are optional, yellow fields only identify available fields.</Typography>
                                                                                </Grid>) }
                                                                                
                                                                            <Grid item xs={12}>
                                                                                <DateTimePicker
                                                                                    label="Date/Time Query Was Made"
                                                                                    ampm={false}
                                                                                    value={row?.LPR_QueryDateTime ?? ""}
                                                                                    onChange={(date) => handleInputChange({ target: { name: "LPR_QueryDateTime", value: (date ?? "") } }, "LprList", row)}
                                                                                    disabled={row?.LPR_Queried === "No" }
                                                                                    renderInput={(params) => <TextField {...params}
                                                                                        style={{ marginBottom: '10px', flex: 1 }}
                                                                                        fullWidth />}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} className="mb-2">
                                                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                                                    <TextField
                                                                                        label="Plate Number"
                                                                                        name={"LPR_PlateNo"}
                                                                                        value={row?.LPR_PlateNo ?? ""}
                                                                                        onChange={(e) => handleInputChange(e, "LprList", row)}
                                                                                        style={
                                                                                            row?.LPR_Queried === 'No'
                                                                                                ? (row?.LPR_PlateNo && row?.LPR_PlateNo.length > 0
                                                                                                    ? { background: 'transparent', marginBottom: '10px', }
                                                                                                    : { background: 'yellow', marginBottom: '10px' })
                                                                                                : { marginBottom: '10px' }
                                                                                        }
                                                                                        fullWidth
                                                                                    />
                                                                                    <Box flexGrow={1} style={{ marginLeft: '10px', flex: 1 }}>
                                                                                        <Autocomplete
                                                                                            id="free-solo-demo"
                                                                                            autoComplete={true}
                                                                                            options={stateList.map((option: any) => option.Key)}
                                                                                            value={row?.LPR_PlateState ?? ""}
                                                                                            onChange={(event, newValue) => handleInputChange({ target: { name: "LPR_PlateState", value: newValue as string } }, "LprList", row)}
                                                                                            renderInput={(params) => (
                                                                                                <TextField
                                                                                                    {...params}
                                                                                                    label="State"
                                                                                                    variant="outlined"
                                                                                                    fullWidth
                                                                                                    style={
                                                                                                        row?.LPR_Queried === 'No'
                                                                                                            ? (row?.LPR_PlateState && row?.LPR_PlateState.length > 0
                                                                                                                ? { background: 'transparent', marginBottom: '10px', }
                                                                                                                : { background: 'yellow', marginBottom: '10px' })
                                                                                                            : { marginBottom: '10px' }
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                            {row?.LPR_VINIncluded && (
                                                                                <Grid item xs={12}>
                                                                                    <TextField
                                                                                        label="VIN"
                                                                                        name={"LPR_VINNo"}
                                                                                        value={row?.LPR_VINNo ?? ""}
                                                                                        onChange={(e) => handleInputChange(e, "LprList", row)}
                                                                                        style={
                                                                                            row?.LPR_Queried === 'No'
                                                                                                ? (row?.LPR_VINNo && row?.LPR_VINNo.length > 0
                                                                                                    ? { background: 'transparent', marginBottom: '10px', }
                                                                                                    : { background: 'yellow', marginBottom: '10px' })
                                                                                                : { marginBottom: '10px' }
                                                                                        }
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                            )}
                                                                            <Grid item xs={12} className="mb-2">
                                                                                <Box display="flex" alignItems="center">
                                                                                    <DateTimePicker
                                                                                        label="Query Start Date/Time"
                                                                                        ampm={false}
                                                                                        value={row?.LPR_SearchStartDate ?? (row?.LPR_Queried === 'No' ? null : startDate)}
                                                                                        disabled={row?.LPR_Queried === "No"}
                                                                                        onChange={(date) => handleInputChange({ target: { name: "LPR_SearchStartDate", value: (date ?? "") } }, "LprList", row)}
                                                                                        renderInput={(params) =>
                                                                                            <TextField {...params} style={{ marginBottom: '10px' }} />}

                                                                                    />
                                                                                    <Box sx={{ mx: 1 }}> to </Box>
                                                                                    <DateTimePicker
                                                                                        label="Query End Date/Time"
                                                                                        ampm={false}
                                                                                        value={row?.LPR_SearchEndDate ?? (row?.LPR_Queried === 'No' ? null : currentDate)}
                                                                                        disabled={row?.LPR_Queried === "No"}
                                                                                        onChange={(date) => handleInputChange({ target: { name: "LPR_SearchEndDate", value: (date ?? "") } }, "LprList", row)}
                                                                                        renderInput={(params) =>
                                                                                            <TextField {...params} style={{ marginBottom: '10px' }} />}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={12} className="mb-2">
                                                                                <Box display="flex" alignItems="center">
                                                                                    <TextField
                                                                                        label="# of Hits"
                                                                                        name={"LPR_CountOfHits"}
                                                                                        value={row?.LPR_CountOfHits ?? ""}
                                                                                        disabled={row?.LPR_Queried === "No"}
                                                                                        onChange={(e) => handleInputChange(e, "LprList", row)}
                                                                                        style={{ marginBottom: '10px' }}
                                                                                        fullWidth
                                                                                    />
                                                                                    <DateTimePicker
                                                                                        label="Date/Time of Last Hit"
                                                                                        ampm={false}
                                                                                        value={row?.LPR_LastHitDateTime ?? ""}
                                                                                        disabled={row?.LPR_Queried === "No"}
                                                                                        onChange={(date) => handleInputChange({ target: { name: "LPR_LastHitDateTime", value: (date ?? "") } }, "LprList", row)}
                                                                                        renderInput={(params) => <TextField {...params} style={{ marginBottom: '10px', marginLeft: '10px' }}
                                                                                            fullWidth />}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={12} className="mb-2">
                                                                                <TextField
                                                                                    label="Camera Location"
                                                                                    name={"LPR_Camera_Location"}
                                                                                    disabled={row?.LPR_Queried === "No"}
                                                                                    value={row?.LPR_Camera_Location ?? ""}
                                                                                    onChange={(e) => handleInputChange(e, "LprList", row)}
                                                                                    fullWidth
                                                                                    style={{ marginBottom: '10px' }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} className="mb-2">
                                                                                <Box display="flex" alignItems="center">
                                                                                    <TextField
                                                                                        label="Owner's First Name"
                                                                                        name={"LPR_Veh_OwnerFirstName"}
                                                                                        value={row?.LPR_Veh_OwnerFirstName ?? ""}
                                                                                        onChange={(e) => handleInputChange(e, "LprList", row)}
                                                                                        fullWidth
                                                                                        style={
                                                                                            row?.LPR_Queried === 'No'
                                                                                                ? (row?.LPR_Veh_OwnerFirstName && row?.LPR_Veh_OwnerFirstName.length > 0
                                                                                                    ? { background: 'transparent', marginBottom: '10px'}
                                                                                                    : { background: 'yellow', marginBottom: '10px' })
                                                                                                : {marginBottom: '10px'}
                                                                                        }
                                                                                    />
                                                                                    <TextField
                                                                                        label="Owner's Last Name"
                                                                                        name={"LPR_Veh_OwnerLastName"}
                                                                                        value={row?.LPR_Veh_OwnerLastName ?? ""}
                                                                                        onChange={(e) => handleInputChange(e, "LprList", row)}
                                                                                        fullWidth
                                                                                        style={
                                                                                            row?.LPR_Queried === 'No'
                                                                                                ? (row?.LPR_Veh_OwnerLastName && row?.LPR_Veh_OwnerLastName.length > 0
                                                                                                    ? { background: 'transparent', marginBottom: '10px', marginLeft: '10px' }
                                                                                                    : { background: 'yellow', marginBottom: '10px', marginLeft: '10px' })
                                                                                                : { marginBottom: '10px' }
                                                                                        }
                                                                                    />
                                                                                    <DatePicker
                                                                                        label="Owner DOB"
                                                                                        value={row?.LPR_Veh_OwnerDOB ?? ""}
                                                                                        onChange={(date) => handleInputChange({ target: { name: "LPR_Veh_OwnerDOB", value: (date ?? "") } }, "LprList", row)}
                                                                                        renderInput={(params) => <TextField {...params} style={
                                                                                            row?.LPR_Queried === 'No'
                                                                                                ? (row?.LPR_Veh_OwnerDOB && row?.LPR_Veh_OwnerDOB.length > 0
                                                                                                    ? { background: 'transparent', marginBottom: '10px', marginLeft: '10px' }
                                                                                                    : { background: 'yellow', marginBottom: '10px', marginLeft: '10px' })
                                                                                                : { marginBottom: '10px' }
                                                                                        }
                                                                                            fullWidth />}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>

                                                                            <Grid item xs={12} className="mb-2">
                                                                                <TextField
                                                                                    label="Owner's Address"
                                                                                    name={"LPR_Veh_OwnerAddress"}
                                                                                    value={row?.LPR_Veh_OwnerAddress ?? ""}
                                                                                    onChange={(e) => handleInputChange(e, "LprList", row)}
                                                                                    style={
                                                                                        row?.LPR_Queried === 'No'
                                                                                            ? (row?.LPR_Veh_OwnerAddress && row?.LPR_Veh_OwnerAddress.length > 0
                                                                                                ? { background: 'transparent', marginBottom: '10px' }
                                                                                                : { background: 'yellow', marginBottom: '10px'})
                                                                                            : { marginBottom: '10px' }
                                                                                    }
                                                                                    fullWidth
                                                                                />
                                                                            </Grid>
                                                                        </div>
                                                                    )}



                                                                </Grid>
                                                            </div>
                                                        )}

                                                    </FormControl>
                                                </Grid>
                                                {row?.LPR_CustomQuery && (
                                                    <TextField
                                                        label="LPR Summary"
                                                        name={"LPR_CustomSummary"}
                                                        value={row?.LPR_CustomSummary ?? ""}
                                                        onChange={(e) => handleInputChange(e, "LprList", row)}
                                                        fullWidth
                                                        multiline
                                                        rows={10}
                                                        style={{ marginLeft: '15px' }}
                                                    />
                                                )}
                                            </Grid>
                                        </div>
                                    ))}
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                            <ButtonGroup className="mb-4">
                                                <Button
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    disabled={report?.LPR_Disabled ?? false}
                                                    onClick={() => addTableRow("LprList", "GUID", GetGUID())}
                                                    color="inherit"
                                                >
                                                    Add Another Vehicle
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    disabled={report?.LPR_Disabled ?? false}
                                                    onClick={() => handleDialog("LPR", true)}
                                                    color="inherit"
                                                >
                                                    Clear All Vehicles
                                                </Button>
                                                <Dialog
                                                    open={vehicleClearOpen}
                                                    onClose={() => handleDialog("LPR", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-cameras"
                                                >
                                                    <DialogTitle id="clear-dialog-title">
                                                        {"Clear all vehicles?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-cameras">
                                                            Are you sure you would like to clear all vehicles in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("LPR", false)}>No</Button>
                                                        <Button onClick={() => { clearTableRows("LprList"); handleDialog("LPR", false) }} autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className="container mt-4 ml-4 mr-5">
                                <Box className="header" display="flex" flexDirection="column" alignItems="center"
                                    textAlign="center"
                                    width="100%">
                                    <Typography variant="h5">
                                        CAD EVENT INFORMATION
                                    </Typography>
                                </Box>

                                <Grid item xs={12} className="pl-2 pr-2">
                                    <div
                                        onDrop={(e) => handleDrop(e, null, "CAD")}
                                        onPaste={(e) => handlePaste(e, null, "CAD")}
                                        onContextMenu={(e) => handleContextMenu(e, null, "CAD")}
                                        onDragOver={(e) => e.preventDefault()}
                                        style={{
                                            border: '5px dashed #ccc',
                                            padding: '20px',
                                            textAlign: 'center',
                                            marginTop: "2%",
                                            marginBottom: '2%'
                                        }}
                                    >
                                        {report?.Files && report?.Files?.filter(f => f?.Origination === "CAD" && !f.deleted).length > 0 ? (
                                            report?.Files?.filter(f => f.Origination === "CAD").map((file, index) => (
                                                <div>
                                                    <Box position="relative" display="inline-block">
                                                        <img
                                                            src={`data:image/png;base64,${file.Content}`}
                                                            alt=""
                                                            style={{ maxWidth: '100%' }}
                                                        />
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => handleRemoveFile(file)}
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0,
                                                                zIndex: 1,
                                                                color: 'red'
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Box>
                                                </div>
                                            ))
                                        ) : (
                                            "Paste or Drag & Drop CAD Screenshot here"
                                        )}
                                    </div>
                                </Grid>

                                <Grid item xs={12} className="pl-2 pr-2">
                                    <div style={{ width: '100%', marginBottom: '1vh' }}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="CAD_EventDetails">Event Details</InputLabel>
                                            <TextareaAutosize
                                                id="CAD_EventDetails"
                                                name="CAD_EventDetails"
                                                value={report?.CAD_EventDetails ?? ""}
                                                onChange={handleInputChange}
                                                style={{
                                                    width: '100%', marginTop: '10px',
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderRadius: '4px',
                                                    padding: '16.5px 14px',
                                                    fontSize: '16px',
                                                    lineHeight: '1.4375em',
                                                    backgroundColor: 'inherit',
                                                    resize: 'vertical'
                                                }}
                                                minRows={10}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                            </div>

                            <button
                                className="floating-button"
                                onClick={() => handleSubmit(false, "")}
                            >
                                Save Report for Later
                            </button>

                            {/* Submit Button */}
                            <Grid item xs={12} className="pr-6 pb-4">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    style={{ float: 'right' }}
                                    onClick={() => handleSubmit()}
                                >
                                    Submit Report
                                </Button>
                            </Grid>
                        </Grid>
                    </div>}
            </Container>
        </LocalizationProvider>
    );

}

export default PreliminaryReportInputForm;
