import { GetDarInfo_Query, getDarInfoResponse, PersonInfo, getGraphResponse, ChartData, getAuditLogInfoResponse } from "../interfaces/getDarInfo_interface";
import axios, { Axios, AxiosResponse } from "axios";
import { SearchDL } from "../interfaces/searchDL.interface";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import { ALPRTimeout_Sec, ALPR_URL } from "./config.service";
import PlateSearch from "../interfaces/PlateSearch.interface";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";

import AuthenticatedAPIReqSplashDL from "./AuthenticatedAPIReqSplashDeviceLocation.service";

import DarDropdown from "../interfaces/DarDropDown.interface";
import { GetDefaultStateForSite } from "../interfaces/config.interface";
import { SiteName } from "./config.service";
import ALPRDetail from "../interfaces/Detail/ALPRDetail.interface";
import LPRDetail from "../interfaces/Detail/LPRDetail.interface";

/////////////////////////// Detail Controller ///////////////////////////
export async function RunALPRDetailQuery(ID: string | null, Dept: string | null, SystemCall: boolean | null = false): Promise<ALPRDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ALPR/?ID=' + ID + '&Dept=' + Dept + '&SystemCall=' + SystemCall, {
        baseURL: ALPR_URL
    })
    return (res.data.AnyData)
}

export async function RunLPRDetailQuery(ID: string | null, Dept: string | null): Promise<LPRDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ALPR/?ID=' + ID + '&Dept=' + Dept + '&SystemCall=false', {
        baseURL: ALPR_URL
    })
    return (res.data.AnyData)
}

export async function RunALPRDetailNo_IDQuery(ID: string | null, Dept: string | null): Promise<ALPRDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ALPR_NoID?ALPRItem=' + ID + '&Dept=' + Dept, {
        baseURL: ALPR_URL
    })
    return (res.data.AnyData)
}

export async function RunALPRImageQuery(ID: string | null, Dept: string | null, SystemCall: boolean | null = false): Promise<ALPRDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ALPR_Image/?ID=' + ID + '&Dept=' + Dept + '&SystemCall=' + SystemCall, {
        baseURL: ALPR_URL,
        timeout: 30000
    })
    return (res.data.AnyData)
}

export async function GetALPRReportPDF(ID: string, Dept: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.get('/api/Detail/ALPR_VehicleReportPDF?ID=' + ID + '&Dept=' + Dept, {
        baseURL: ALPR_URL,
        responseType: 'blob'
    })
    if (res != null) {
        const url = window.URL.createObjectURL(new File([res.data], "FileName.pdf", { type: 'application/pdf' }));
        return url;
    }
    return null;
}

/////////////////////////// Dropdown Controller ///////////////////////////
export async function getDropDownALPRQuery(dropdownName: string, controller: string = 'Dropdown', state: string = GetDefaultStateForSite(SiteName)): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/' + controller + '/' + dropdownName + "?State=" + state, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function getDropDownALPRCountyCity(state: string | null): Promise<any[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ALPRServer_WithCounty?State=' + (state ? state : GetDefaultStateForSite(SiteName)), {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function getDropDownALPRVendors(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/ALPRVendors', {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function getALPRCameras(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/FixedALPRCamera', {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function getCCTVCameras(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/FixedCCTVCamera', {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function getDropDownALPRCamera(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/ALPRCamera', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function getDropDownMake(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleMake', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function getDropDownModel(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleModel', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function getDropDownBody(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleBody', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function getDropDownYear(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleYear', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function getDropDownTrim(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleTrim', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function getDropDownColor(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleColor', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

/////////////////////////// Filter Controller ///////////////////////////
export async function RunDarQuery(params: GetDarInfo_Query): Promise<getDarInfoResponse> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/Filter/GetDARInfo', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function RunAuditLogQuery(params: GetDarInfo_Query): Promise<getAuditLogInfoResponse> {
    let res: AxiosResponse<getAuditLogInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/Filter/GetDARInfo', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function RunGraphQuery(params: GetDarInfo_Query): Promise<getGraphResponse> {
    let res: AxiosResponse<getGraphResponse> = await AuthenticatedAPIReqSplash.post('/api/Filter/GetDARInfo', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

/////////////////////////// Person Controller ///////////////////////////
export async function RunPerson_ALPRQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_ALPR', params, {
        baseURL: ALPR_URL,
        timeout: ALPRTimeout_Sec * 1000
    })
    return (res.data)
}

/////////////////////////// Search Controller ///////////////////////////
export async function RunMultipleOccurrence(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/Search/MultipleOccurrence', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}
export async function RunMultipleOccurrenceNoSplash(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Search/MultipleOccurrence', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

/////////////////////////// VIN Controller ///////////////////////////
export async function RunVINQuery(params: PlateSearch): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/Vin/Search', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}
export async function RunFetchVendorData(params: PlateSearch): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReq.post('/api/Vin/FetchVendorData', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export { }