import {
    Autocomplete,
    Button,
    ButtonGroup,
    Card,
    CardContent, Checkbox,
    FormControl, FormControlLabel, InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    RunAuditPermission,
    RunDeptUnits,
    RunEmpAuthLevels,
    RunGetUser,
    RunMangers,
    RunSetUser,
    RunAuthorizedBy
} from "../../services/account.service";
import UserAttributes from "../../interfaces/UserAttributes.interface";
import {DateToString, formatDate, formatDT, formatPhone} from "../../services/formatDate.service";
import {Account} from "../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../services/auth.service";
import ErrorIcon from "@mui/icons-material/Error";
import {getSiteName, SiteName} from "../../services/config.service";
import {SITE} from "../../interfaces/config.interface";

interface UpdateUserProps {
    ID:string|null
    CloseBox:any
    EditStatus?:string|null
}

const UpdateUser: React.FC<UpdateUserProps> = ({ID, CloseBox, EditStatus = "Add"}) =>{
    const [auth, setAuth] = React.useState<string>('')
    let [user, setUser] = useState<Account>(getUser())
    const [params, setParams] = React.useState<UserAttributes>({})
    const [notesError, setNotesError] = React.useState<string>("")
    const [empIDError, setEmpIDError] = React.useState<string>("")
    const [fnError, setFNError] = React.useState<string>("")
    const [lnError, setLNError] = React.useState<string>("")
    const [emailError, setEmailError] = React.useState<string>("")
    const [phoneError, setPhoneError] = React.useState<string>("")
    const [submitError, setSubmitError] = React.useState<string>("")
    const [DateTermination, setDateTermination] = React.useState<any>()

    const LoadUserData = async (ID:string) => {
        let query = {
            "uniqueID": ID,
        }
        let r = await RunGetUser(query)
        if(r?.AnyData)
            if(r?.AnyData?.DeptCode){
                let res = await RunMangers(r?.AnyData?.DeptCode, r?.AnyData?.DeptUnitName)
                let rSup = await RunAuthorizedBy(r?.AnyData?.DeptCode, (r?.AnyData?.DeptUnitName ? r?.AnyData?.DeptUnitName : ""))
                let tempParam:UserAttributes = r?.AnyData
                tempParam.ManagerList = res?.AnyData
                tempParam.AuthorizedByList = rSup?.AnyData
                let response = await RunDeptUnits(r?.AnyData?.DeptCode, "")
                let userRoleRsp = await RunEmpAuthLevels(r?.AnyData?.DeptCode, 'M')
                let roleList = await RunAuditPermission(r?.AnyData?.DeptCode, tempParam?.EmpAuthLevel ?? '')
                tempParam.DeptUnitList = response?.AnyData
                tempParam.DeptUnitID = tempParam?.UserDeptUnitList && tempParam?.UserDeptUnitList.length > 0 ? tempParam?.UserDeptUnitList[0] : ""
                tempParam.AuthLevelList = userRoleRsp?.AnyData
                tempParam.RoleList = roleList
                await setParams(tempParam)
            }
       
    }

    useEffect(() => {
        if(ID !== null)
            LoadUserData(ID)
    }, [ID]);
    
    const checkValidation = () => {
        setEmpIDError("")
        setNotesError("")
        setFNError("")
        setLNError("")
        setEmailError("")
        setPhoneError("")
        
        var response = true
        if(EditStatus !== "PartialEdit") {
            if (params.EmpID === null || (params.EmpID && params.EmpID.length <= 0)) {
                setEmpIDError("The User ID Field is required.")
                response = false
            }
            if (params.FirstName === null || (params.FirstName && params.FirstName.length <= 0)) {
                setFNError("The User First Name Field is required.")
                response = false
            }
            if (params.LastName === null || (params.LastName && params.LastName.length <= 0)) {
                setLNError("The User Last Name Field is required.")
                response = false
            }
            if ((params.EmailID === null || (params.EmailID && params.EmailID.length <= 0))) {
                setEmailError("The EMail Address Field is required.")
                response = false
            }
            /*if ((params.EmailID === null || params.EmailID?.length === 0) && (params.CellPhone === null || (params.CellPhone && params.CellPhone.length <= 0))) {
                setPhoneError("The Cell Phone # Field is required.")
                response = false
            }*/
            if (params.Notes === null || (params.Notes && params.Notes.trim().length <= 0)) {
                setNotesError("The Notes Field is required.")
                response = false
            }
        }
        return response
        
    }
    
    const submitUser = async () => {
        setSubmitError("")
        if (checkValidation()) {
            params.UserDeptUnitList = params?.DeptUnitID && params?.DeptUnitID?.length > 0 ? [params?.DeptUnitID] : []
            if(params && params.EmpID)
                params.EmpID = params?.EmpID.trim()
            if(params && params.EmailID)
                params.EmailID = params?.EmailID.trim()
            if(EditStatus)
                params.EditStatus = EditStatus
            if(params.DeptAccountType === "CJSU")
            {
                params.NJSTAD = true;
            }
            let r = await RunSetUser(params)
            if(r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0)
            {
                CloseBox(r?.AnyData, 'Add/Edit')
            }
            else
            {
                let errors = r.AnyData.ErrorMessage?.join(",")
                setSubmitError(errors)
            }
        }
    }
    
    const handleDeptChange = async (e:any) => {
        let value = e.target.value
        let obj = params?.DeptList?.filter((x) => x.Key === value)        
        if (obj && obj?.length > 0 && params?.EmpAuthLevel) {
            let r = await RunMangers(obj[0].Key, params?.DeptUnitName)
            let rSup = await RunAuthorizedBy(obj[0].Key, params?.DeptUnitName)
            let res = await RunEmpAuthLevels(obj[0].Key, 'M')
            let response = await RunDeptUnits(obj[0].Key, "")
            let roleList = await RunAuditPermission(obj[0].Key, 'M')
            setDateTermination(obj[0].Additional); //store Old Termination date incase user changes mind to not to set TFO
            //Supervisor list is AuthorizedByList
            await setParams({ ...params, ManagerList: r.AnyData, AuthorizedByList: rSup.AnyData, DeptCode: obj[0].Key, DeptName: obj[0].Value, EmpAuthLevel: 'M', DeptAccountType: res?.AuthToken, AuthLevelList:res?.AnyData, DeptUnitList: response?.AnyData, RoleList: roleList})
        }
    }

    const handleTFOChange = async (e: any) => {
        let obj = { ...params};
        obj.TFO = !obj.TFO;
        if (obj.TFO) {
            var todayDate = new Date(); //todayDate.AddMonths(3)
            todayDate = new Date(todayDate.setMonth(todayDate.getMonth() + 3));
            setDateTermination(obj.DateTerminated); //store Old Termination date incase user changes mind to not to set TFO
            obj.DateTerminated = DateToString(todayDate); // formatDate(todayDate.toDateString());  //   Add 3 Months
        }
        else {
            obj.DateTerminated = DateTermination;
        }
        await setParams(obj);
    }
    
    const showManager = ():boolean => {
        return params?.EmpAuthLevel === 'P' || params?.EmpAuthLevel === 'I' || params?.EmpAuthLevel === 'C' || params?.EmpAuthLevel === 'D' || params?.EmpAuthLevel === 'L' || params?.EmpAuthLevel === 'H' || params?.EmpAuthLevel === 'E' || params?.EmpAuthLevel === 'O' || params?.EmpAuthLevel === 'W'
    }
    
    const handleDeptUnitChange = async (e:any) => { 
        //let value = e.target?.value; //for non autoselect
        var sDeptID = params?.DeptCode ? params?.DeptCode : "";
        let sDeptUnitID = ""; 
        let sDeptUnitName = e == null ? "" : e; //.target?.value;
        if (sDeptUnitName) {
            let obj = params?.DeptUnitList?.filter((x) => x.Value === sDeptUnitName)
            if (obj && obj?.length > 0 && params?.EmpAuthLevel) {
                sDeptUnitID = obj[0].Key;
            }
        }
        let r = await RunMangers(sDeptID, sDeptUnitName)
        let rSup = await RunAuthorizedBy(sDeptID, sDeptUnitName)
        await setParams({ ...params, ManagerList: r.AnyData, AuthorizedByList: rSup.AnyData, DeptUnitID: sDeptUnitID, DeptUnitName: sDeptUnitName })
    }

    const handleAuthChange = (e:any) => {
        let auth = e.target.value
        switch(auth){
            case('P'):
                setParams({ ...params, EmpAuthLevel: auth, ConfidentialPOI: false, Role:'', ManagerEmpID:'', SupervisorEmpID:'' })
                break;
            case('I'):
                setParams({ ...params, EmpAuthLevel: auth, ConfidentialPOI: false, Role:'', ManagerEmpID:'', SupervisorEmpID:'' })
                break;
            case('C'):
                setParams({ ...params, EmpAuthLevel: auth, NJPOPModify: false, HTModify: false, NIBINModify: false, SearchAnySource: false, TFO: false, ThreatAssessment: false, NJSTAD: false, ConfidentialPOI: false, Role:'', ManagerEmpID:'', SupervisorEmpID:'' })
                break;
            case('D'):
                setParams({ ...params, EmpAuthLevel: auth, NJPOPModify: false, HTModify: false, NIBINModify: false, SearchAnySource: false, TFO: false, ThreatAssessment: false, NJSTAD: false, ConfidentialPOI: false, Role:'', ManagerEmpID:'', SupervisorEmpID:'' })
                break;
            case ('L'):  //same as CODIS except they don't PIO, CODIS permission
                setParams({ ...params, EmpAuthLevel: auth, NJPOPModify: false, HTModify: false, NIBINModify: false, SearchAnySource: false, TFO: false, ThreatAssessment: false, NJSTAD: false, ConfidentialPOI: false, Role: '', ManagerEmpID: '', SupervisorEmpID: '' })
                break;
            case ('H'):
                setParams({ ...params, EmpAuthLevel: auth, NJPOPModify: false, NIBINModify: false, HTModify: true, SearchAnySource: false, TFO: false, ThreatAssessment: false, NJSTAD: false, ConfidentialPOI: false, Role:'', ManagerEmpID:'', SupervisorEmpID:'' })
                break;
            case('A'):
                setParams({...params, EmpAuthLevel:auth, Role:'', ManagerEmpID:'', SupervisorEmpID:''})
                break;
            case('G'):
                setParams({...params, EmpAuthLevel:auth, Role:'', ManagerEmpID:'', SupervisorEmpID:''})
                break;
            case('S'):
                setParams({...params, EmpAuthLevel:auth, Role:'', ManagerEmpID:'', SupervisorEmpID:''})
                break;
            case('M'):
                if(params.DeptAccountType === 'BTAM')
                    setParams({ ...params, EmpAuthLevel: auth, NJPOPModify: false, HTModify: false, NIBINModify: false, SearchAnySource: false, TFO: false, ThreatAssessment: true, NJSTAD: false, ConfidentialPOI: false, Role:'', ManagerEmpID:'', SupervisorEmpID:'' })
                else
                    setParams({...params, EmpAuthLevel:auth, Role:'', ManagerEmpID:'', SupervisorEmpID:''})
             break;
            case('N'):
                setParams({ ...params, EmpAuthLevel: auth, NJPOPModify: false, HTModify: false, SearchAnySource: false, TFO: false, ThreatAssessment: false, NJSTAD: false, ConfidentialPOI: false, Role:'', ManagerEmpID:'', SupervisorEmpID:'' })
                break;
            case('T'):
                setParams({ ...params, EmpAuthLevel: auth, NJPOPModify: false, HTModify: false, NIBINModify: false, SearchAnySource: false, TFO: false, ThreatAssessment: true, NJSTAD: false, ConfidentialPOI: false, Role:'', ManagerEmpID:'', SupervisorEmpID:'' })
                break;
            case('B'):
                setParams({...params, EmpAuthLevel:auth, Role:'', ManagerEmpID:'', SupervisorEmpID:''})
                break;
        }
    }
  
    return (
        <div className = "mx-auto">
            {(params && params?.DeptList && params?.DeptList.length > 0 ?
            
            <Card sx={{ minWidth:900, maxWidth: 900}} className = "mx-auto" >
                <CardContent>
                {EditStatus !== "PartialEdit" ?
                    <>
                    <span className="text-blue-400 font-bold text-xl ">
                        {params?.UniqueID === 0 ? <span>Add New User</span> : <span>Edit Account Details </span>}
                    </span>
                        <hr/>
                        <div className="grid grid-cols-2 mt-5 gap-y-5 gap-x-5">
                            <div className="w-full">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    margin='none'
                                    disabled={EditStatus !== 'Add'}
                                    variant={EditStatus !== 'Add' ? "filled" : "outlined"}
                                    placeholder={"USER AGENCY EMAIL ADDRESS"}
                                    label={empIDError.length > 0 ? empIDError : "User ID"}
                                    value={params?.EmpID}
                                    onChange={e => setParams({...params, EmpID: e.target.value})}
                                    color={empIDError.length > 0 ? "error" : "primary"}
                                    focused={empIDError.length > 0 ? true : undefined}
                                />
                            </div>
                            <div className="w-full">
                                {user.AuthLevel === 'S' ?
                                    <FormControl fullWidth>
                                        <InputLabel id="DeptLabel">Department</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="DeptLabel"
                                            label="Department"
                                            value={params?.DeptCode}
                                            onChange={handleDeptChange}
                                        >
                                            {params.DeptList && params?.DeptList.map((dept) => (
                                                <MenuItem value={dept.Key}>{dept.Value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    :
                                    <TextField size="small" InputLabelProps={{'shrink': true}} disabled margin='none'
                                               className="w-full" variant="filled" label="Department"
                                               value={params?.DeptCode}/>
                                }
                            </div>
                        </div>
                        <div
                                    className={(params?.EmpAuthLevel === 'I' || params?.EmpAuthLevel === 'C' || params?.EmpAuthLevel === 'D' || params?.EmpAuthLevel === 'L' || params?.EmpAuthLevel === 'N' || params?.EmpAuthLevel === 'E' || params?.EmpAuthLevel === 'O' || params?.EmpAuthLevel === 'W') ? "grid grid-cols-2 mt-5 gap-y-5 gap-x-5" : "grid grid-cols-2 mt-5 gap-y-5 gap-x-5"}>
                            <div className="w-full">
                                <FormControl fullWidth>
                                    <InputLabel id="AuthLevel">Authorization</InputLabel>
                                    <Select
                                        disabled={!(user.AuthLevel === 'B' || user.AuthLevel === 'S')}
                                        size="small"
                                        labelId="AuthLabel"
                                        label="Authorization"
                                        value={params?.EmpAuthLevel}
                                        onChange={handleAuthChange}
                                    >
                                        {params.AuthLevelList && params?.AuthLevelList.map((auth) => (
                                            <MenuItem value={auth.Key}>{auth.Value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                                    {(params?.DeptAccountType !== "NIBIN" && params?.DeptAccountType !== "ACCOUNTING" && params?.DeptAccountType !== "BTAM" && params?.DeptAccountType !== "CJSU" && params?.EmpAuthLevel !== 'C' && params?.EmpAuthLevel !== 'D' && params?.EmpAuthLevel !== 'L' && params?.EmpAuthLevel !== 'T' && params?.EmpAuthLevel !== 'H') &&
                                <div className="w-full">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disabled={!(user.AuthLevel === 'B' || user.AuthLevel === 'S')}
                                            id="RoleID"
                                            options={params.RoleList ? params.RoleList?.map((option: any) => option.Key) : []}
                                            size="small"
                                            className="w-full"
                                            renderInput={(params)=> <TextField {...params} label = "Role" />}
                                            onChange={(event,value)=> setParams({...params, Role: value})}
                                            value={params.Role ? params.Role : ''}
                                        />
                                    </FormControl>
                                </div>
                            }
                            <div className="w-full">
                                <Autocomplete
                                    disabled={params?.DeptAccountType === "NIBIN" || !(user.AuthLevel === 'B' || user.AuthLevel === 'S')}
                                    id="DeptUnitID"
                                    options={params.DeptUnitList ? params.DeptUnitList?.map((option: any) => option.Value) : []}
                                    size="small"
                                    className="w-full"
                                    renderInput={(params) => <TextField {...params} label="Dept Unit" />}
                                    onChange={(event, value) => handleDeptUnitChange(value)}
                                    value={params.DeptUnitName ? params.DeptUnitName : ''}
                                />
                                {/*<FormControl fullWidth>*/}
                                {/*    <InputLabel id="DeptUnitID">Dept Unit</InputLabel>*/}
                                {/*    <Select*/}
                                {/*        disabled={params?.DeptAccountType === "NIBIN" || !(user.AuthLevel === 'B' || user.AuthLevel === 'S')*/}
                                {/*        size="small"*/}
                                {/*        labelId="DeptUnitLabel"*/}
                                {/*        label="Dept Unit"*/}
                                {/*        value={params?.DeptUnitID}*/}
                                {/*        onChange={handleDeptUnitChange}*/}
                                {/*    >*/}
                                {/*       {params?.DeptUnitList && params?.DeptUnitList.map((deptUnit) => (*/}
                                {/*           <MenuItem value={deptUnit.Key}>{deptUnit.Value}</MenuItem>*/}
                                {/*        ))}*/}
                                {/*    </Select>*/}
                                {/*</FormControl>*/}
                            </div>
                                    {(params?.EmpAuthLevel === 'P' || params?.EmpAuthLevel === 'I' || params?.EmpAuthLevel === 'C' || params?.EmpAuthLevel === 'D' || params?.EmpAuthLevel === 'L' || params?.EmpAuthLevel === 'H' || params?.EmpAuthLevel === 'T' || params?.EmpAuthLevel === 'N' || params?.EmpAuthLevel === 'E' || params?.EmpAuthLevel === 'O' || params?.EmpAuthLevel === 'W') ?
                                <div className="w-full">
                                    {params && params?.ManagerList && params?.ManagerList.length !== 0 && (user.AuthLevel === 'B' || user.AuthLevel === 'S') ?
                                        <FormControl fullWidth>
                                            <InputLabel id="Manager ID">Manager ID</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="Manager ID"
                                                label="Manager ID"
                                                value={params?.ManagerEmpID}
                                                onChange={e => setParams({...params, ManagerEmpID: e.target.value})}
                                            >
                                                {params?.ManagerList && params?.ManagerList.map((manager) => (
                                                    <MenuItem value={manager.Key}>{manager.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        :
                                        <FormControl fullWidth>
                                            <InputLabel id="Manager ID">Manager ID</InputLabel>
                                            <Select
                                                disabled
                                                size="small"
                                                labelId="Manager ID"
                                                label="Manager ID"
                                                value={params?.ManagerEmpID}
                                                onChange={e => setParams({...params, ManagerEmpID: e.target.value})}
                                            >
                                                {params?.ManagerList && params?.ManagerList.map((manager) => (
                                                    <MenuItem value={manager.Key}>{manager.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>}
                                </div>
                                : <></>}
                            {(params?.EmpAuthLevel === 'I' ||
                                        params?.EmpAuthLevel === 'D' || params?.EmpAuthLevel === 'L' || params?.EmpAuthLevel === 'E' || params?.EmpAuthLevel === 'O' || params?.EmpAuthLevel === 'C') ?
                                <div className="w-full">
                                    {params && params?.AuthorizedByList && params?.AuthorizedByList.length !== 0 && (user.AuthLevel === 'B' || user.AuthLevel === 'S') ?
                                        <FormControl fullWidth>
                                            <InputLabel id="Supervisor ID">Supervisor ID</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="Supervisor ID"
                                                label="Supervisor ID"
                                                value={params?.SupervisorEmpID}
                                                onChange={e => setParams({...params, SupervisorEmpID: e.target.value})}
                                            >
                                                {params?.AuthorizedByList && params?.AuthorizedByList.map((supervisor) => (
                                                    <MenuItem value={supervisor.Key}>{supervisor.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        :
                                        <FormControl fullWidth>
                                            <InputLabel id="Supervisor ID">Supervisor ID</InputLabel>
                                            <Select
                                                disabled
                                                size="small"
                                                labelId="Supervisor ID"
                                                label="Supervisor ID"
                                                value={params?.SupervisorEmpID}
                                                onChange={e => setParams({...params, SupervisorEmpID: e.target.value})}
                                            >
                                                {params?.AuthorizedByList && params?.AuthorizedByList.map((supervisor) => (
                                                    <MenuItem value={supervisor.Key}>{supervisor.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>}
                                </div>
                                : <></>}
                        </div>
                        {(SiteName==SITE.NJPROD || SiteName==SITE.NJDEMO || SiteName == SITE.NJUAT || SiteName==SITE.LOCAL) && 
                            <>
                                    {params?.DeptAccountType === "NIBIN" || params?.DeptAccountType === "CODIS" || params?.DeptAccountType === "ACCOUNTING" || params?.DeptAccountType === "BTAM" || params?.DeptAccountType === "CJSU" || (params?.EmpAuthLevel === 'C' || params?.EmpAuthLevel === 'D' || params?.EmpAuthLevel === 'L') ? <></> :
                                    <div className="w-full grid grid-cols-3 gap-y-4 pl-4 border-2 border-gray-300 rounded-md mt-5">
                                        <div>
                                            <FormControlLabel
                                                control={ 
                                                <Checkbox 
                                                    disabled = {!(params?.DeptAccountType !== "NIBIN" && isUserPermission('NJSP') && !(user.AuthLevel === "A" || user.AuthLevel === "P" || user.AuthLevel === "I" || user.AuthLevel === "C" || user.AuthLevel === "D" || user.AuthLevel === "H" || user.AuthLevel === "N") && !(params?.EmpAuthLevel === 'H'))}
                                                    checked={params?.NJPOPModify}
                                                    onChange={e => setParams({...params, NJPOPModify: !params?.NJPOPModify})} sx={{'& .MuiSvgIcon-root': {fontSize: 26}}}/>}
                                                    label="Add/Edit NJPOP"
                                                />
                                        </div>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                <Checkbox 
                                                    disabled={!(params?.DeptAccountType !== "NIBIN" && (params.DeptCode === "NJSP" || params.DeptAccountType === "INTERNAL") && (user.AuthLevel === 'B' || user.AuthLevel === 'S') && (params?.EmpAuthLevel === 'H' || params?.EmpAuthLevel === 'I' || params?.EmpAuthLevel === 'A' || params?.EmpAuthLevel === 'M' || params?.EmpAuthLevel === 'G'))}
                                                    checked={params?.HTModify} 
                                                    onChange={e => setParams({...params, HTModify: !params?.HTModify})} sx={{'& .MuiSvgIcon-root': {fontSize: 26}}}/>}
                                                    label="Add/Edit Human Traffic"
                                                />
                                        </div>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                <Checkbox 
                                                    disabled = {!((params?.DeptAccountType !== "NIBIN" && params?.DeptAccountType !== "ACCOUNTING") && (user.AuthLevel === 'B' || user.AuthLevel === 'S' || params?.EmpAuthLevel === 'A' || params?.EmpAuthLevel === 'G' || params?.EmpAuthLevel === 'M'))}
                                                    checked={params?.ConfidentialPOI}
                                                    onChange={e => setParams({...params, ConfidentialPOI: !params?.ConfidentialPOI})} sx={{'& .MuiSvgIcon-root': {fontSize: 26}}}/>}
                                                    label="View/Edit Confidential POI"
                                                />
                                        </div>
                                        {(params?.NJSTAD || isUserPermission("NJSTAD"))?
                                            <div>
                                                <Tooltip title="JSTAD User">
                                                    <FormControlLabel
                                                        control={
                                                        <Checkbox 
                                                            disabled={(user.AuthLevel != 'B' && user.AuthLevel != 'S')}
                                                            checked={params?.NJSTAD}
                                                            onChange={e => setParams({...params, NJSTAD: !params?.NJSTAD})} sx={{'& .MuiSvgIcon-root': {fontSize: 26}}}/>}
                                                            label="Add/Edit JSTAD"
                                                        />
                                                </Tooltip>
                                            </div>
                                            : <></>
                                        }
                                        <div>
                                            <FormControlLabel
                                                control={
                                                <Checkbox 
                                                    disabled={!((user.AuthLevel === 'S') && !(params?.EmpAuthLevel === 'P' || params?.EmpAuthLevel === 'H' || params?.EmpAuthLevel === 'S' || params?.EmpAuthLevel === 'E' || params?.EmpAuthLevel === 'O' || params?.EmpAuthLevel === 'W'))}
                                                    checked={params?.SearchAnySource} 
                                                    onChange={e => setParams({...params, SearchAnySource: !params?.SearchAnySource})} sx={{'& .MuiSvgIcon-root': {fontSize: 26}}} />}
                                                    label="Any Source Search"
                                                />
                                        </div>
                                        <div>
                                            <Tooltip title="Transferred From Other agency">
                                                <FormControlLabel
                                                    control={
                                                    <Checkbox 
                                                        disabled = {params?.DeptAccountType !== "NIBIN" && (user.AuthLevel == "A" || user.AuthLevel == "P" || user.AuthLevel == "I" || user.AuthLevel == "C" || user.AuthLevel == "D" || user.AuthLevel == "H" || user.AuthLevel == "N" || user?.AuthLevel === 'E' || user?.AuthLevel === 'O' || user?.AuthLevel === 'W') || (params?.EmpAuthLevel === 'H')}
                                                        checked={params?.TFO}
                                                        onChange={e => setParams({...params, TFO: !params?.TFO})} sx={{'& .MuiSvgIcon-root': {fontSize: 26}}}/>}
                                                        label="TFO"
                                                />
                                            </Tooltip>
                                        </div> 
                                        {(params?.ThreatAssessment) || user.AuthLevel == "S" ?
                                            <div>
                                                <Tooltip title="Access to Threat Assessment Site">
                                                    <FormControlLabel
                                                        control={
                                                        <Checkbox 
                                                            disabled={params?.DeptAccountType === "NIBIN"}
                                                            checked={params?.ThreatAssessment}
                                                            onChange={e => setParams({...params, ThreatAssessment: !params?.ThreatAssessment})} sx={{'& .MuiSvgIcon-root': {fontSize: 26}}}/>}
                                                            label="View/Edit Threat Assessment"
                                                    />
                                                </Tooltip>
                                            </div>
                                            : <></>
                                        }
                                        <div>
                                            <Tooltip title="Include in CODIS Distribution List">
                                                <FormControlLabel
                                                    control={
                                                    <Checkbox 
                                                        disabled = {(params?.DeptAccountType !== "NIBIN" && (user.AuthLevel == "A" || user.AuthLevel == "P" || user.AuthLevel == "I" || user.AuthLevel == "C" || user.AuthLevel == "D" || user.AuthLevel == "H" || user.AuthLevel == "N") || (params?.EmpAuthLevel === 'H'))}
                                                        checked={params?.CodisDistribution}
                                                        onChange={e => setParams({...params, CodisDistribution: !params?.CodisDistribution})} sx={{'& .MuiSvgIcon-root': {fontSize: 26}}}/>}
                                                        label="CDL"
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        
                    </> : <></>}
                    <div className="grid grid-cols-3 mt-5 gap-x-5">
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={fnError.length > 0 ? fnError:"User First Name"}
                                value={params?.FirstName}
                                onChange={e => setParams({ ...params, FirstName: e.target.value })}
                                color = {fnError.length > 0 ? "error" : "primary"}
                                focused = {fnError.length > 0 ? true : undefined}
                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                className="w-full"
                                margin='none'
                                label="User Middle Initial"
                                value={params?.MiddleName}
                                onChange={e => setParams({ ...params, MiddleName: e.target.value })}
                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                className="w-full"
                                margin='none'
                                label={lnError.length > 0 ? lnError:"User Last Name"}
                                value={params?.LastName}
                                onChange={e => setParams({ ...params, LastName: e.target.value })}
                                color = {lnError.length > 0 ? "error" : "primary"}
                                focused = {lnError.length > 0 ? true : undefined}
                            />
                        </div>
                    </div>
                    <div className = "grid grid-cols-1 mt-5">
                        <div className="w-full">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                className="w-full"
                                margin='none'
                                label={emailError.length > 0 ? emailError:"EMail Address"}
                                value={params?.EmailID}
                                onChange={e => setParams({ ...params, EmailID: e.target.value })}
                                color = {emailError.length > 0 ? "error" : "primary"}
                                focused = {emailError.length > 0 ? true : undefined}
                            />
                        </div>
                    </div>
                    <div className = "grid grid-cols-2 mt-5 gap-y-5 gap-x-5">
                        <div className="w-full">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                margin='none'
                                className="w-full"
                                label={phoneError.length > 0 ? phoneError:"Cell Phone #"}
                                value={params?.CellPhone}
                                onChange={e => setParams({ ...params, CellPhone: e.target.value })}
                                color = {phoneError.length > 0 ? "error" : "primary"}
                                focused = {phoneError.length > 0 ? true : undefined}
                            />
                        </div>
                        {EditStatus === "Add" ?
                            <>
                                <div className="w-full">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        margin='none'
                                        className="w-full"
                                        label="Deactivation Date"
                                        type="date"
                                        value={params?.DateTerminated?.substring(0,10)}
                                        onChange={e => setParams({ ...params, DateTerminated: e.target.value })}
                                    />
                                </div>
                            </>
                            : <>
                                <div className="w-full">
                                    <TextField
                                        size = "small"
                                        type={"date"}
                                        InputLabelProps={{'shrink':true}}
                                        margin='none'
                                        className="w-full"
                                        label="DOB"
                                        value={params?.DOB?.substring(0,10)}
                                        onChange={e => setParams({ ...params, DOB: e.target.value })}
                                    />
                                </div>
                            </> }
                        <div className="w-full">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                margin='none'
                                className="w-full"
                                label={phoneError.length > 0 ? phoneError:"Work Phone #"}
                                value={params?.WorkPhone}
                                onChange={e => setParams({ ...params, WorkPhone: e.target.value })}
                                color = {phoneError.length > 0 ? "error" : "primary"}
                                focused = {phoneError.length > 0 ? true : undefined}
                            />
                        </div>
                    </div>
                    <div className = "grid grid-cols-2 mt-5 gap-y-5 gap-x-5">   
                        {EditStatus === "Add" ?
                            <>
                                <div className="w-full">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        disabled
                                        margin='none'
                                        className="w-full"
                                        variant="filled"
                                        label="Created User"
                                        value={params?.CreateUID}
                                        onChange={e => setParams({ ...params, CreateUID: e.target.value })}
                                    />
                                </div>
                                <div className="w-full">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        disabled
                                        margin='none'
                                        className="w-full"
                                        variant="filled"
                                        label="Created Date"
                                        value={formatDate(params?.CreateDate)}
                                        onChange={e => setParams({ ...params, CreateDate: e.target.value })}
                                    />
                                </div>
                            </>
                            : <>
                                {EditStatus !== 'PartialEdit' ? 
                                <div className="w-full">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        margin='none'
                                        className="w-full"
                                        label="Deactivation Date"
                                        type="date"
                                        value={params?.DateTerminated?.substring(0,10)}
                                        onChange={e => setParams({ ...params, DateTerminated: e.target.value })}
                                    />
                                </div>
                                :
                                <div className="w-full">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        disabled
                                        margin='none'
                                        className="w-full"
                                        variant="filled"
                                        label="Created User"
                                        value={params?.CreateUID}
                                    />
                                </div>
                                }
                                <div className="w-full">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        disabled
                                        margin='none'
                                        className="w-full"
                                        variant="filled"
                                        label="Password Date"
                                        value={params?.PasswordDate}
                                    />
                                </div>
                                <div className="w-full">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        disabled
                                        margin='none'
                                        className="w-full"
                                        variant="filled"
                                        label="Updated User"
                                        value={params?.UpdUID}
                                    />
                                </div>
                                <div className="w-full">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        disabled
                                        margin='none'
                                        className="w-full"
                                        variant="filled"
                                        label="Updated Date"
                                        value={formatDate(params?.UpdDate)}
                                    />
                                </div>
                            </> }
                    </div>
                    <div className = "grid grid-cols-1 mt-5 gap-x-5">
                    {EditStatus !== "PartialEdit" ?
                        <>
                        <div className="w-full">
                            <TextField
                                size = "small"
                                multiline minRows={2}
                                InputLabelProps={{'shrink':true}}
                                margin='none'
                                className="w-full"
                                label={notesError.length > 0 ? notesError:"Notes"}
                                value={params?.Notes}
                                onChange={e => setParams({ ...params, Notes: e.target.value })}
                                color = {notesError.length > 0 ? "error" : "primary"}
                                focused = {notesError.length > 0 ? true : undefined}
                            />
                        </div>
                        {EditStatus !== "PartialEdit" ? 
                        <div className="w-full mt-5">
                            <TextField
                                size = "small"
                                disabled
                                multiline
                                minRows={4}
                                InputLabelProps={{'shrink':true}}
                                margin='none'
                                className="w-full"
                                label="Previous Notes"
                                value={params?.PreviousNotes}
                                onChange={e => setParams({ ...params, PreviousNotes: e.target.value })}
                            />
                        </div> : <></>}
                            {EditStatus === "Add" ?    
                            <div className="w-full mt-5 mb-3">
                                <TextField
                                    size = "small"
                                    InputLabelProps={{'shrink':true}}
                                    disabled
                                    margin='none'
                                    className="w-full"
                                    variant="filled"
                                    label="Updated User"
                                    value={params?.UpdUID}
                                    onChange={e => setParams({ ...params, UpdUID: e.target.value })}
                                />
                            </div>
                                :
                                <div className="w-full mt-5 mb-3">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        disabled
                                        margin='none'
                                        className="w-full"
                                        variant="filled"
                                        label="Created User"
                                        value={params?.CreateUID}
                                    />
                                </div>
                            }
                       </> : <></> }
                    </div>
                    {submitError?.length > 0 ?
                        <span style={{ color: "red", fontSize:14}}>
                           {submitError.split(",").map((error)=> <div> <ErrorIcon style={{color:"red"}} fontSize = 'small' />{error}</div>)}
                        </span> 
                    : <></>}
                    <div className="mt-8 flex justify-center">
                        <ButtonGroup variant="outlined" size={"large"} color={"inherit"} >
                        <Button onClick={submitUser} >Save</Button>
                        <Button onClick = {() => CloseBox({}, 'Close')}>CANCEL</Button>
                        </ButtonGroup>
                    </div>
                </CardContent>
            </Card>
                : <div className = "text-blue-400 text-6xl font-bold"></div>)}  
        </div>
    );
}

export default UpdateUser;