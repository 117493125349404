import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WeaponTraceInfo from "../../../interfaces/WeaponTraceInfo.interface";
import { TablePagination, CircularProgress, Button } from "@mui/material";
import GunTraceIcon from "../../../assets/Images/GunOffender.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';

interface PanelGunOffenderProps {
    person: PersonInfo,
    GunInfo: WeaponTraceInfo[],
    isLoading:boolean
}

const PanelGunOffender: React.FC<PanelGunOffenderProps> = ({ person, GunInfo, isLoading }) => {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...GunInfo]
        for (let p of tempP) {
            if (p.Supervision_End_Date_CSOSA) {
                p.Supervision_End_Date_CSOSA = new Date(p.Supervision_End_Date_CSOSA)
            }
            if (p.Scheduled_Annual_Update) {
                p.Scheduled_Annual_Update = new Date(p.Scheduled_Annual_Update)
            }
            if (p.Home_Visit_Due_Date) {
                p.Home_Visit_Due_Date = new Date(p.Home_Visit_Due_Date)
            }
            if (p.Date_Registered_GORU) {
                p.Date_Registered_GORU = new Date(p.Date_Registered_GORU)
            }
        }
   
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [GunInfo])

    return (
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Gun Offender <img src={GunTraceIcon} alt={''} width={25} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "5px" }} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {GunInfo?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{ pageSize: 50, pageSizes: [10, 25, 50, 100] }}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{ type: 'CheckBox' }}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='Supervision_End_Date_CSOSA' headerText='Supervision End Date CSOSA' format={{ type: 'date', format: 'MM/dd/yyyy' }} />
                                    <ColumnDirective field='Scheduled_Annual_Update' headerText='Scheduled Annual Update' format={{ type: 'date', format: 'MM/dd/yyyy' }}/>
                                    <ColumnDirective field='Home_Visit_Due_Date' headerText='Home Visit Due Date' format={{ type: 'date', format: 'MM/dd/yyyy' }}/>
                                    <ColumnDirective field='Date_Registered_GORU' headerText='Date Registered GORU' format={{ type: 'date', format: 'MM/dd/yyyy' }} />
                                    <ColumnDirective field='Court_Sentence' headerText='Court Sentence' />
                                    <ColumnDirective template={(item: any) => (<Button onClick={() => { window.open('/detail/GunOffender?ID=' + item?.id + '&Dept=' + item?.GunOffenderID, '_blank') }} >View</Button>)} headerText='View' />
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]} />
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelGunOffender;