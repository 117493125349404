import { GetDarInfo_Query, getDarInfoResponse, getGraphResponse } from "../interfaces/getDarInfo_interface";
import axios, { AxiosResponse } from "axios";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import DarDropdown from "../interfaces/DarDropDown.interface";
import {GetDefaultStateForSite} from "../interfaces/config.interface";
import {SiteName} from "./config.service";

export async function getDropDownQuery(dropdownName: string, controller: string = 'Dropdown', state: string = GetDefaultStateForSite(SiteName) ): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/' + controller + '/' + dropdownName + "?State=" + state)
    return (res.data)
}

export async function getDropDownALPRCountyCity(state: string | null): Promise<any[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ALPRServer_WithCounty?State=' + (state ? state : GetDefaultStateForSite(SiteName)))
    return (res.data)
}

export async function getDropDownCity(state : string | null): Promise<any[]> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.get('/api/Dropdown/CityList?State='+ (state ? state : GetDefaultStateForSite(SiteName)))
    return (res.data)
}

export async function getAgencyORI(state : string | null): Promise<any[]> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.get('/api/Dropdown/AgencyORI?State='+ (state ? state : GetDefaultStateForSite(SiteName)))
    return (res.data)
}

export async function getDropDownMake(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleMake', params)
    return (res.data)
}

export async function getDropDownModel(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleModel', params)
    return (res.data)
}

export async function getDropDownBody(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleBody', params)
    return (res.data)
}

export async function getDropDownYear(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleYear', params)
    return (res.data)
}

export async function getDropDownTrim(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleTrim', params)
    return (res.data)
}

export async function getDropDownColor(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleColor', params)
    return (res.data)
}

export async function RunGraphQuery(params: GetDarInfo_Query): Promise<getGraphResponse> {
    let res: AxiosResponse<getGraphResponse> = await AuthenticatedAPIReq.post('/api/Filter/GetDARInfo', params)
    return (res.data)
}

export async function getDropDownALPRVendors(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/ALPRVendors')
    return (res.data)
}

export async function getALPRCameras(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/FixedALPRCamera')
    return (res.data)
}

export async function getCCTVCameras(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/FixedCCTVCamera')
    return (res.data)
}

export async function getDropDownALPRCamera(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/ALPRCamera', params)
    return (res.data)
}

export async function getUserAuthLevel(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/UserAuthLevel')
    return (res.data)
}

export async function getDeptAccountType(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/DeptAccountType')
    return (res.data)
}

export async function getDeptGroup(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/DeptGroup')
    return (res.data)
}

export async function getAuditLogAction(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/AuditAction')
    return (res.data)
}

export async function getAuditLogDept(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/AuditDept')
    return (res.data)
}
export async function getAuditLogUser(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/AuditUser')
    return (res.data)
}
export async function getCustodyCounty(state: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/CustodyCounty?State=' + state)
    return (res.data)
}

export async function getRTCCArea(state: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/RTCCArea?State=' + state)
    return (res.data)
}

export async function getFAQList(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/FAQList')
    return (res.data)
}

export async function getNJTraceAgencyList(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/NJTraceAgency')
    return (res.data)
}

export async function getLocationTypeList(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_LocationType')
    return (res.data)
}

export async function getWeaponTypeList(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_WeaponType')
    return (res.data)
}

export async function getWeaponTypeFilteredList(manufacturer: string | null, country: string | null): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_WeaponType?Manufacturer=' + manufacturer + '&Country=' + country)
    return (res.data)
}

export async function getWeaponCaliberList(weaponType: string | null, manufacturer: string | null, country: string | null): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_Caliber?WeaponType=' + weaponType + '&Manufacturer=' + manufacturer + '&Country=' + country) 
    return (res.data)
}

export async function getWeaponModelList(weaponType: string | null, manufacturer: string | null, country: string | null, caliber: string | null): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_Model?WeaponType=' + weaponType + '&Manufacturer=' + manufacturer + '&Country=' + country + '&Caliber=' + caliber) 
    return (res.data)
}

export async function getIdentificationTypeList(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_IdentificationType')
    return (res.data)
}

export async function getATFStateList(country: string | null): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_State?Country=' + (country ?? 'US'))
    return (res.data)
}

export async function getATFRolesList(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_Role_NJTrace')
    return (res.data)
}

export async function getATFCaliberList(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_Caliber')
    return (res.data)
}

export async function getWeaponManufacturerList(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_Manufacturer')
    return (res.data)
}

export async function getWeaponImporterList(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_Importer')
    return (res.data)
}

export async function getCountryList(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_Country')
    return (res.data)
}

export async function getNJTraceCrimeCodeList(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_Crime')
    return (res.data)
}

export async function getNJTraceCaliberList(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/Caliber')
    return (res.data)
}

export async function getNJTraceWeaponMakeList(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/GunManufacturer')
    return (res.data)
}

export async function getATFStreetSuffix(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_StreetSuffix')
    return (res.data)
}

export async function getATFSex(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_Gender')
    return (res.data)
}

export async function getATFRace(): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/Dropdown/ATF_Race')
    return (res.data)
}

export async function getCounty(state: string, initialCase: boolean = false): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/County?State=' + state + '&Case=' + initialCase)
    return (res.data)
}

export async function getBTIncidentTypeList(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/BTIncidentTypeList')
    return (res.data)
}

export async function getCityFromStateCounty(state: string, county: string, initialCase: boolean = false): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/CityFromStateCounty?State=' + state + '&County=' + county + '&Case=' + initialCase)
    return (res.data)
}
export async function getNJPOPCityFromStateCounty(state: string, county: string, initialCase: boolean = false): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/NJPOPCityFromStateCounty?State=' + state + '&County=' + county + '&Case=' + initialCase)
    return (res.data)
}
export async function getIWW_Dissemination(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/IWW_DISSEMINATION')
    return (res.data)
}

export async function getBTCarjackIncidentTypeList(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/BTCarjackIncidentTypeList')
    return (res.data)
}

export async function getPOIPurpose(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/POIPurpose')
    return (res.data)
}

export async function getNJTraceAuditLogAction(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/NJTraceAuditAction')
    return (res.data)
}

export { }