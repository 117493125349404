import React from 'react'

import BTPersonReport from "../../shared/_BTPersonReport";
import { PersonInfo } from "../../../interfaces/getDarInfo_interface";

import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import CodisPerson from './_searchResultsCodisPerson'
import CodisCaseDisplay from './_searchResultsCodisCase'
import { OpenCodisPDF } from "../../../services/detail.service";
import PersonIcon from "../../../assets/Images/Person.png";
import {showCrumbScore, SiteName} from '../../../services/config.service';
import BTPersonGridImage from "../../shared/_PersonGridImage";
import { Avatar } from "@mui/material";
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import { green } from '@mui/material/colors';
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import {getValue} from "@syncfusion/ej2-base";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";

interface SearchResultsProps {
    CodisPerson: PersonInfo[]
}

const SearchResultsPerson: React.FC<SearchResultsProps> = ({ CodisPerson }) => {
    const [typedPerson, setTypedPersons] = React.useState<any>({})


    let grid: Grid | null;
    const miniPersonImage = (person: any): any => {
        return (
            <BTPersonGridImage person={person} />
        )
    }
    const IndicatorTemplate = (person: any): any => {
        return (
            <BTPersonIndicatorGrid person={person} />
        );
    }


    const getPersonSummary = (p: any) => {
        return (<div style={{maxWidth:"80%"}}><BTPersonReport person={p} /></div>)
    }

    //const getCODISDetailRow = (codisHit: any) => {
    //    let CodisCaseInfo = CodisCase?.filter((x) => x.HitNo === codisHit.HitNo)
    //    let PersonsInfo = Persons?.filter((x) => x.OpenCaseRole === codisHit.HitNo)
    //    return (
    //        <div>
    //            <CodisCaseDisplay CodisCase={CodisCaseInfo} />
    //            <CodisPerson CodisPerson={PersonsInfo} />
    //        </div>
    //    )
    //}

    const handleDataTyping = () => {
        if (CodisPerson) {
            let tempP: any = [...CodisPerson]

            for (let p of tempP) {
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
                if (p.DTOFARREST) {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
                if (p.DTOFUPDATE) {
                    p.DTOFUPDATE = new Date(p.DTOFUPDATE)
                }
            }
            setTypedPersons(tempP)
        }

    }

    React.useEffect(() => {
        handleDataTyping()
    }, [CodisPerson])

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'CodisPerson.xlsx';

            (grid.getColumnByField("image") as Column).visible = false;
            
            grid.excelExport();
            
        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("image") as Column).visible = true;
        }
    }
    
    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }

    const expandRow = () => {
        if (grid) {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }

    return (
        <div className={"pl-10 pr-10 mt-5 border-2 border-gray-100 pb-5 pt-5"}>
            <span style={{display:"inline-block", width:"full"}}>
                CODIS Person Information <img className={"inline ml-2"} src={PersonIcon} alt="ai" />
                {(CodisPerson && CodisPerson?.length > 0 && CodisPerson[0]?.LName !== null) ? <h4 className={"font-bold pl-5"}> {CodisPerson.length} Records Found</h4> : <h4 className={"font-bold pl-5"}>No Records Found</h4>}
            </span>
            {CodisPerson && CodisPerson?.length > 0 && CodisPerson[0]?.LName !== null ? <>
            <GridComponent
                dataSource={typedPerson}
                allowPaging={true}
                dataBound={dataBound}
                pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                allowSorting={true}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{ type: 'CheckBox' }}
                detailTemplate={getPersonSummary}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbar={['Search']}
                toolbarClick={GridToolsClick}
                excelExportComplete={excelExportComplete}
                ref={g => grid = g}
                selectionSettings={{
                    persistSelection: true,
                    type: "Multiple",
                    //checkboxOnly: true,
                    mode: "Row"
                }}
                //rowSelecting={(event: any) => { console.log(event) }}
                rowSelected={(event: any) => { expandRow() }}
                allowSelection={true}
                allowResizing={true}
                rowHeight={24}
                rowDataBound={(args : any) => {
                    if (args.row) {
                        if (getValue('FoundJail', args.data)  === 2){
                            args.row.classList.add('red');
                        }
                    }
                }}
            >

                <ColumnsDirective>
                    <ColumnDirective field='image' template={miniPersonImage} maxWidth={120} headerText='Image' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='Role' headerText='Role' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FName' headerText='First Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='MName' headerText='MI' width={110} minWidth={110} maxWidth={110} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='LName' headerText='Last Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DOB' headerText='DOB' width={115} minWidth={115} maxWidth={115} format={{ type: 'date', format: 'MM/dd/yyyy' }} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DNANo' headerText='DNA #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    { showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText={`${GetStringNameForSite(SiteName,'SCOR_TBL')}`} customAttributes={{ class: ['e-attr'] }}/>:<></>}
                    <ColumnDirective field='FBI' headerText='FBI #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='SBINo' headerText={`${GetStringNameForSite(SiteName,'SBI #')}`} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='SBIState' headerText={`${GetStringNameForSite(SiteName,'SBI #')} State`} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DLNo' headerText='DL #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DLSt' headerText='DL State' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective template={IndicatorTemplate} headerText='Indicator' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='STATUTE' headerText={`${GetStringNameForSite(SiteName,'STATUTE')}`} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='STATUTEDESCRIPTION' headerText='Statute Description' maxWidth={250} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DTOFARREST' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Date of Arrest' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='ORIGINATINGORINAME' headerText='Agency Arrested' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />
                    {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<ColumnDirective field='Dept' maxWidth={200} headerText='InfoCop Dept' customAttributes={{ class: ['e-attr'] }}/>}
                </ColumnsDirective>
                <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
                </GridComponent>
            </> : <></>}
        </div>

    )
};

export default SearchResultsPerson
