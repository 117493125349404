import React, { useState, useEffect } from "react";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';


interface TraceAuditLogSearchResultsProps {
    UserAuditLogResults: any[],
}

const TraceAuditLogSearchResults: React.FC<TraceAuditLogSearchResultsProps> = ({ UserAuditLogResults }) => {
    const [typedAuditLogResults, setTypedAuditLogResults] = useState<any[]>([])

    const handleDataTyping = () => {
        if (UserAuditLogResults) {
            if (UserAuditLogResults) {
                let tempP: any = [...UserAuditLogResults]

                for (let p of tempP) {
                    if (p.ChangeTimestamp) {
                        p.ChangeTimestamp = new Date(p.ChangeTimestamp)
                    }
                    if(p.CreateDate){
                        p.CreateDate = new Date(p.CreateDate)
                    }
                }
                setTypedAuditLogResults(tempP)
            }
        }
    }


    useEffect(() => { handleDataTyping() }, [UserAuditLogResults])
    let grid: Grid | null;
    let gridS: Grid | null;
    const GridToolsClick = (args: ClickEventArgs) => {
        
        if (grid && args.item.text === "Excel Export") {
            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'NJTraceAuditLog.xlsx';

            grid.excelExport(excelProp);
        }
    }
    const excelExportComplete = () => {
    }

    // const SystemGridToolsClick = (args: ClickEventArgs) => {

    //     if (gridS && args.item.text === "Excel Export") {
    //         let excelProp: ExcelExportProperties = {}
    //         excelProp.fileName = 'NJTraceAuditLog.xlsx';

    //         gridS.excelExport(excelProp);
    //     }
    // }
    // const SystemExcelExportComplete = () => {
    // }

    const detailsTemplate = (props:any) => {
        return(
            <table className="details">
                <tbody>
                    {props?.ORI_EMAIL && props?.ORI_EMAIL?.trim()?.length > 0 ? 
                     <tr>
                        <td>{"Email: "+ props?.ORI_EMAIL?.trim()} </td>
                    </tr> 
                    :
                    <tr>
                        <td>{props?.ORI_FIRSTNAME?.trim() + " " + props?.ORI_LASTNAME?.trim()} </td>
                    </tr>}
                </tbody>   
            </table>
        )
    }

    let sortSettings: SortSettingsModel = { columns: [{ field: 'ChangeTimestamp', direction: 'Descending' }] };

    return (
        <div>
            <div className={"pl-10 pr-10 mt-3"}>
                {typedAuditLogResults && typedAuditLogResults.length > 0 ? <>
                    <h4 className={"font-bold pl-5"}> {typedAuditLogResults?.length} Records Found</h4>
                    <GridComponent
                        dataSource={typedAuditLogResults}
                        allowPaging={true}
                        pageSettings={{pageSize: 25, pageSizes: [10, 25, 50, 100]}}
                        allowSorting={true}
                        sortSettings={sortSettings}
                        allowMultiSorting={true}
                        allowFiltering={true}
                        filterSettings={{type: 'CheckBox'}}
                        allowTextWrap={true}
                        textWrapSettings={{wrapMode: 'Both'}}
                        allowExcelExport={true}
                        allowPdfExport={true}
                        toolbar={['ExcelExport', 'Search']}
                        toolbarClick={GridToolsClick}
                        excelExportComplete={excelExportComplete}
                        ref={g => grid = g}
                        selectionSettings={{
                            persistSelection: true,
                            type: "Multiple",
                            mode: "Row"
                        }}
                        allowSelection={true}
                        rowHeight={24}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='Section' headerText='Section' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                            <ColumnDirective field='Action' headerText='Action' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>    
                            <ColumnDirective field='Details' headerText='Details' width={300} minWidth={300}  maxWidth={1340}
                                             customAttributes={{class: ['e-attr']}}/>                    
                            <ColumnDirective field='ChangeItemFieldName' headerText='Field' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>      
                            <ColumnDirective field='OldValue' headerText='Old Value' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>   
                            <ColumnDirective field='NewValue' headerText='New Value' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>          
                            <ColumnDirective field='ChangeTimestamp'
                                             format={{type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss'}}
                                             headerText='Date' width={200} minWidth={200} maxWidth={1340}
                                             customAttributes={{class: ['e-attr']}}/>                           
                            <ColumnDirective field='ChangeUser' headerText='Action Taken By' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                            <ColumnDirective field='ChangeDept' headerText='Department' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>                      
                            <ColumnDirective field ='ORI_EMAIL' template={detailsTemplate} headerText='Reporting Officer' width={250} minWidth={250} maxWidth={1340} 
                                             customAttributes={{class: ['e-attr']}}/>
                                             {/* added ORI_FIRSTNAME and ORI_LASTNAME column but not visible to allow user to search since template above will only allow email search*/}
                            <ColumnDirective field='ORI_FIRSTNAME' headerText='Reporting Officer First Name' width={200} minWidth={200} visible={false}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>    
                            <ColumnDirective field='ORI_LASTNAME' headerText='Reporting Officer Last Name' width={200} minWidth={200} visible={false}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>    
                            <ColumnDirective field='OFFICE' headerText='Agency' width={200} minWidth={200}
                                             maxWidth={200} customAttributes={{class: ['e-attr']}}/>
                            {/* <ColumnDirective field='CreateDate'
                                             format={{type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss'}}
                                             headerText='Create Date' width={200} minWidth={200} maxWidth={1340}
                                             customAttributes={{class: ['e-attr']}}/>          */}             
                        </ColumnsDirective>
                        <Inject
                            services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]}/>
                    </GridComponent>
                </> : <></>}
            </div>
            

        </div>
    );
};
export default TraceAuditLogSearchResults;

//<ColumnDirective field='EventType' headerText='EventType' customAttributes={{ class: ['e-attr'] }} />