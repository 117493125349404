import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    CardContent, Checkbox, CircularProgress,
    FormControl, FormControlLabel,
    InputLabel,
    MenuItem,
    Select, Snackbar, Step, StepContent, StepLabel, Stepper,
    TextField, Tooltip
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Account} from "../../interfaces/auth_interface";
import {getUser} from "../../services/auth.service";
import {DateToString, formatDate, formatInputDate} from "../../services/formatDate.service";
import POIAttributes from "../../interfaces/POI.interface";
import UserAttributes from "../../interfaces/UserAttributes.interface";
import {RunGetNCIC, RunGetPOI, RunSetPOI, RunSetUser} from "../../services/account.service";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {JAOS} from "../../services/JAOS.service";
import ErrorIcon from "@mui/icons-material/Error";
import {enableNJCAP5, SiteName} from '../../services/config.service';
import {AlertColor} from "../tools/njtrace/_njtraceInputForm";
import {GetStringNameForSite, SITE} from "../../interfaces/config.interface";



interface UpdatePOIProps {
    ID:string|null
    CloseBox:any
    Hash?:string | null
}

const UpdatePOI: React.FC<UpdatePOIProps> = ({ID, CloseBox, Hash=null}) =>{
    let [user, setUser] = useState<Account>(getUser())
    const [params, setParams] = React.useState<POIAttributes>({})
    const [submitError, setSubmitError] = React.useState<string>("")
    const [supervisorError, setSupervisorError] = React.useState<string>("")
    const [isSaving, setSaving] = React.useState<boolean>(false)
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("success")

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };


    const LoadUserData = async (ID:string) => {
        if(Hash){
            try {
                let obj = new JAOS()
                let res = obj.objFromStack(Hash ? Hash : "")

                let r = await RunGetPOI(res)
                let temp = handleEditPOI(r?.AnyData)
                await setParams(temp)
            }
            catch(e)
            {
                console.log(e)
            }
        }
        else {
            let query = {
                "uniqueID": ID,
            }
            let r = await RunGetPOI(query)
            let temp = handleEditPOI(r?.AnyData)
            await setParams(temp)
        }
    }

    const handleEditPOI = (poi:POIAttributes) => {
        let obj = poi
        if(poi?.Justification?.toUpperCase().includes('OTHER*'))
        {
            let split = poi.Justification?.split('|')
            if(split && split.length === 2) {
                poi.Justification = split[1]?.trim()
                poi.PurposeSecondary = split[0]?.trim()
            }
        }
        return obj
    }

    const isFieldLocked = (poi:POIAttributes, field:string) => {
        if(poi.UniqueID === null || poi.UniqueID === 0)
            return false;
        
        switch (field) {
            case 'StartDate':
            case 'ExpiryDate':
            case 'FelonyVehicle':
            case 'FelonyVehicleReason':
            case 'NICNum':
            case 'FBINo':
            case 'SBINo':
            case 'DLNo':
            case 'DLSt':
            case 'SearchNotes':
            case 'AdditionalEmail':
                return false;
            default: 
                return true;

        }
        
    }
    
    const checkValidation = () => {
        setSupervisorError("")


        var response = true
        if(params.AuthorizedBy === null || params.AuthorizedBy && params.AuthorizedBy?.length === 0)
        {
            setSupervisorError("The Supervisor field is required.")
            response = false;
        }

        return response

    }
    
    const validateEmails = (emailText :string) => {
        const emails = emailText.split(/[;, ]/);
        const emailRegex = /<([^>]+)>|([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})/i;
        const validatedEmails : string[] = [];

        emails.forEach(email => {
            const match = email.match(emailRegex);
            if (match) {
                const emailAddress = match[1] || match[0];
                if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress)) {
                    validatedEmails.push(emailAddress);
                }
            }
        });

        return validatedEmails;
    }

    const submitPOI = async () => {
        setSubmitError("")
        if(checkValidation()) {
            setSaving(true)
            let param = {...params}
            if(param.ExpiryDate && param.ExpiryDate?.split('T')?.length === 1) {
                param.ExpiryDate = param.ExpiryDate+"T23:59:59"
            }
            if(param.StartDate && param.StartDate?.split('T')?.length === 1){
                let currentTime = "T" + new Date().getHours().toString().padStart(2,'0') + ":" + new Date().getMinutes().toString().padStart(2,'0') + ":" + new Date().getSeconds().toString().padStart(2,'0')
                param.StartDate = param.StartDate + currentTime
            }
            if(param.PurposeSecondary && param.PurposeSecondary.toUpperCase() === 'OTHER*')
            {
                param.Justification = param.PurposeSecondary + " | " + param.Justification;
            }
            
            if(param.AdditionalEmail){
                param.AdditionalEmail = validateEmails(param.AdditionalEmail).join(';')
            }
            
            let r = await RunSetPOI(param)
            if(r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0)
            {
                CloseBox(r?.AnyData, 'Add/Edit')
            }
            else
            {
                let errors = r.AnyData.ErrorMessage?.join(",")
                setSubmitError(errors)
                setSaving(false)
            }
        }
    }

    useEffect(() => {
        if(ID !== null)
            LoadUserData(ID)
    }, [ID]);

    const handlePurposeSecondaryChange = (value: string | null) => {
        let p = { ...params }
        let expDate = new Date()
        p.PurposeSecondary = value
        p.Justification = !value?.toUpperCase()?.includes('OTHER') ? (value ?? "") : ""
        if(p.ReasonLEPurposeList){
            let reason = p.ReasonLEPurposeList.find((e:any) => e.Value === value)
            if(reason && reason.Additional){
                expDate.setDate(expDate.getDate() + parseInt(reason?.Additional));
                p.ExpiryDate = formatInputDate(expDate)
            }
            else{
                expDate.setDate(expDate.getDate() + 30);
                p.ExpiryDate = formatInputDate(expDate)
            }
        }
        setParams(p)
    }
    const handlePurposeChange = (value: string) => {
        let p = { ...params }
        let expDate = new Date()
        //Reset Others
        p.ReasonNCIC = ''
        p.ReasonNonNCIC = ''
        p.ReasonCovert = ''
        p.ReasonLEPurpose = ''
        p.ReasonAlert = ''


        switch (p.ReasonType?.toUpperCase()?.trim()) {
            case ("NCIC"):
                p.PurposeSecondary = null

                p.ReasonNCIC = value
                p.Justification = value
                p.Reason = value
                p.FelonyVehicle = false;
                p.FelonyVehicleReason = '';
                if(p.ReasonNCICList){
                    let reason = p.ReasonNCICList.find((e:any) => e.Value === value)
                    if(reason && reason.Additional){
                        expDate.setDate(expDate.getDate() + parseInt(reason?.Additional));
                        p.ExpiryDate = formatInputDate(expDate)
                    }
                    else{
                        expDate.setDate(expDate.getDate() + 30);
                        p.ExpiryDate = formatInputDate(expDate)

                    }
                }
                break;
            case ("COVERT"):
                p.PurposeSecondary = null
                p.ReasonCovert = value
                p.Justification = value
                p.Reason = value
                if(p.ReasonCovertList){
                    let reason = p.ReasonCovertList.find((e:any) => e.Value === value)
                    if(reason && reason.Additional){
                        expDate.setDate(expDate.getDate() + parseInt(reason?.Additional));
                        p.ExpiryDate = formatInputDate(expDate)
                    }
                    else{
                        expDate.setDate(expDate.getDate() + 30);
                        p.ExpiryDate = formatInputDate(expDate)

                    }
                }
                break;
            case ("NON-NCIC"):
                p.PurposeSecondary = null
                p.ReasonNonNCIC = value
                p.Justification = value
                p.Reason = value
                if(p.ReasonNonNCICList){
                    let reason = p.ReasonNonNCICList.find((e:any) => e.Value === value)
                    if(reason && reason.Additional){
                        expDate.setDate(expDate.getDate() + parseInt(reason?.Additional));
                        p.ExpiryDate = formatInputDate(expDate)
                    }
                    else{
                        expDate.setDate(expDate.getDate() + 30);
                        p.ExpiryDate = formatInputDate(expDate)

                    }
                }
                break;
            case ("ALERT"):
                p.PurposeSecondary = null
                p.Reason = value
                p.ReasonAlert = value
                if(p.ReasonAlertList){
                    let reason = p.ReasonAlertList.find((e:any) => e.Value === value)
                    if(reason && reason.Additional){
                        expDate.setDate(expDate.getDate() + parseInt(reason?.Additional));
                        p.ExpiryDate = formatInputDate(expDate)
                    }
                    else{
                        expDate.setDate(expDate.getDate() + 30);
                        p.ExpiryDate = formatInputDate(expDate)

                    }
                }
                if (value !== 'LAW ENFORCEMENT PURPOSE') {
                    p.Justification = value
                }
                else {
                    p.Justification = ''
                }
                break;
            case ("LAW ENFORCEMENT PURPOSE"):
                p.PurposeSecondary = null
                p.ReasonLEPurpose = value
                p.Reason = value
                if(p.ReasonLEPurposeList){
                    let reason = p.ReasonLEPurposeList.find((e:any) => e.Value === value)
                    if(reason && reason.Additional){
                        expDate.setDate(expDate.getDate() + parseInt(reason?.Additional));
                        p.ExpiryDate = formatInputDate(expDate)
                    }
                    else{
                        expDate.setDate(expDate.getDate() + 30);
                        p.ExpiryDate = formatInputDate(expDate)

                    }
                }
                if (value !== 'Other*' && value !== 'LAW ENFORCEMENT PURPOSE') {
                    p.Justification = value
                }
                else {
                    p.Justification = ''
                }
                break;
            default:
                p.PurposeSecondary = null
                p.ReasonCovert = value
                p.Justification = value
                p.Reason = value
                break;

        }

        setParams(p)
    }
    const handleMaxExpireTime = (expiryTime: string) => {
        let p = { ...params }
        let expDate = new Date(expiryTime)
        let today = new Date()
        let diff = expDate.getTime() - today.getTime()
        diff = diff / (1000 * 60 * 60 * 24)
        let reasonType = p.ReasonType
        let purpose = ''
        switch (reasonType?.toUpperCase()?.trim()) {
            case ("NCIC"):
                purpose = p?.ReasonNCIC ?? ''
                if(p.ReasonNCICList && purpose){
                    let reason = p.ReasonNCICList.find((e:any) => e.Value === purpose)
                    if(reason && reason.Additional2){
                        if (diff <= parseInt(reason.Additional2) ?? 30) {
                            p.ExpiryDate = formatInputDate(expiryTime)
                        }
                        else{
                            setAlertType("warning")
                            setAlertText("Exceeds maximum Expiry Date of " + reason.Additional2 + " days")
                            setAlertOpen(true)
                        }
                    }
                }
                break;
            case ("COVERT"):
                purpose = p?.ReasonCovert ?? ''
                if(p.ReasonCovertList && purpose){
                    let reason = p.ReasonCovertList.find((e:any) => e.Value === purpose)
                    if(reason && reason.Additional2){
                        if (diff <= parseInt(reason.Additional2) ?? 30) {
                            p.ExpiryDate = formatInputDate(expiryTime)
                        }
                        else{
                            setAlertType("warning")
                            setAlertText("Exceeds maximum Expiry Date of " + reason.Additional2 + " days")
                            setAlertOpen(true)
                        }
                    }
                }
                break;
            case ("NON-NCIC"):
                purpose = p?.ReasonNonNCIC ?? ''
                if(p.ReasonNonNCICList){
                    let reason = p.ReasonNonNCICList.find((e:any) => e.Value === purpose)
                    if(reason && reason.Additional2){
                        if (diff <= parseInt(reason.Additional2) ?? 30) {
                            p.ExpiryDate = formatInputDate(expiryTime)
                        }
                        else{
                            setAlertType("warning")
                            setAlertText("Exceeds maximum Expiry Date of " + reason.Additional2 + " days")
                            setAlertOpen(true)
                        }
                    }
                }
                break;
            case ("ALERT"):
                purpose = p?.ReasonAlert ?? ''
                if(purpose === 'LAW ENFORCEMENT PURPOSE'){
                    let justification = p?.Justification ?? ''
                    if(p.ReasonLEPurposeList){
                        let reason = p.ReasonLEPurposeList.find((e:any) => e.Value === justification)
                        if(reason && reason.Additional2){
                            if (diff <= parseInt(reason.Additional2) ?? 30) {
                                p.ExpiryDate = formatInputDate(expiryTime)
                            }
                            else{
                                setAlertType("warning")
                                setAlertText("Exceeds maximum Expiry Date of " + reason.Additional2 + " days")
                                setAlertOpen(true)
                            }
                        }
                    }
                }
                else if(p.ReasonAlertList) {
                    let reason = p.ReasonAlertList.find((e: any) => e.Value === purpose)
                    if(reason && reason.Additional2){
                        if (diff <= parseInt(reason.Additional2) ?? 30) {
                            p.ExpiryDate = formatInputDate(expiryTime)
                        }
                        else{
                            setAlertType("warning")
                            setAlertText("Exceeds maximum Expiry Date of " + reason.Additional2 + " days")
                            setAlertOpen(true)
                        }
                    }
                }
                break;
        }
        setParams(p)
    }

    return (
        <div className = "mx-auto">
            <Snackbar open={alertOpen} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => {
                setAlertOpen(false)
            }}>
                <Alert onClose={() => {
                    setAlertOpen(false)
                }} severity={alertType} sx={{width: '100%'}}>
                    {alertText}
                </Alert>
            </Snackbar>

            {(params && params?.ReasonTypeList && params?.ReasonTypeList.length > 0 && [SITE.DCPROD, SITE.DCUAT, SITE.DCDEMO, SITE.DCLOCAL].includes(SiteName)) &&
                <Card sx={{width:800}} className="mx-auto">
                    <CardContent>
                        <div>
                            <span className="text-blue-400 font-bold text-xl">
                                {params?.UniqueID === 0 ? <span>Add New POI</span> : <span>Edit POI Details</span>}
                            </span>
                        </div>

                        <hr/>

                        {submitError && submitError?.length > 0 &&
                            <div style={{ color: "red", fontSize:14}}>
                               {submitError.split(",").map((error)=> <div> <ErrorIcon style={{color:"red"}} fontSize = 'small' />{error}</div>)}
                            </div>
                        }
                        <Stepper activeStep={activeStep} nonLinear orientation="vertical">

                            <Step key={"Issuance Type"}>
                                <StepLabel>Issuance Type</StepLabel>
                                <StepContent>

                                    <div className="grid grid-cols-2 mt-5 gap-y-5">
                                        <div className="w-full pr-5">
                                            <FormControl fullWidth>
                                                <InputLabel
                                                    id="ReasonTypeID">{'Type'}</InputLabel>
                                                <Select
                                                    disabled={isFieldLocked(params, 'ReasonType')}
                                                    size="small"
                                                    labelId="ReasonTypeID"
                                                    label="Type"
                                                    className="w-full"
                                                    value={params?.ReasonType}
                                                    defaultValue={params?.ReasonType}
                                                    onChange={e => setParams({
                                                        ...params,
                                                        ReasonType: e.target.value,
                                                        Justification: '',
                                                        ReasonAlert: '',
                                                        ReasonCovert: '',
                                                        ReasonNCIC: '',
                                                        ReasonLEPurpose: '',
                                                        FelonyVehicle: false,
                                                        FelonyVehicleReason: ''
                                                    })}
                                                    //onChange={e => handleExpireTime(e.target.value)}
                                                >
                                                    {params.ReasonTypeList && params?.ReasonTypeList.map((reason) => (
                                                        <MenuItem value={reason.Key}>{reason.Value}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {params && params?.ReasonType === ('ALERT') ?
                                            <div className="w-full pl-5">
                                                <FormControl fullWidth>
                                                    <InputLabel
                                                        id="ReasonID">{'Purpose'}</InputLabel>
                                                    <Select
                                                        disabled={isFieldLocked(params, 'ReasonAlert')}
                                                        size="small"
                                                        labelId="ReasonID"
                                                        label="Purpose"
                                                        className="w-full"
                                                        value={params?.ReasonAlert}
                                                        onChange={e => handlePurposeChange(e.target.value)}
                                                    >
                                                        {
                                                            params.ReasonAlertList && params?.ReasonAlertList.map((reason) => (
                                                                <MenuItem value={reason.Key}>{reason.Value}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            : params && params?.ReasonType === 'COVERT' ?
                                                <div className="w-full pl-5">
                                                    <FormControl fullWidth>
                                                        <InputLabel
                                                            id="ReasonID">{'Purpose'}</InputLabel>
                                                        <Select
                                                            disabled={isFieldLocked(params, 'ReasonCovert')}
                                                            size="small"
                                                            labelId="ReasonID"
                                                            label="Purpose"
                                                            className="w-full"
                                                            value={params?.ReasonCovert}
                                                            onChange={e => handlePurposeChange(e.target.value)}
                                                        >
                                                            {params.ReasonCovertList && params?.ReasonCovertList.map((reason) => (
                                                                <MenuItem value={reason.Key}>{reason.Value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                : params && params?.ReasonType === 'NCIC' ?
                                                    <div className="w-full pl-5">
                                                        <FormControl fullWidth>
                                                            <InputLabel
                                                                id="ReasonID">{'Purpose'}</InputLabel>
                                                            <Select
                                                                disabled={isFieldLocked(params, 'ReasonNCIC')}
                                                                size="small"
                                                                labelId="ReasonID"
                                                                label="Purpose"
                                                                className="w-full"
                                                                value={params?.ReasonNCIC}
                                                                onChange={e => handlePurposeChange(e.target.value)}
                                                            >
                                                                {params.ReasonNCICList && params?.ReasonNCICList.map((reason) => (
                                                                    <MenuItem
                                                                        value={reason.Key}>{reason.Value}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    : params && params?.ReasonType === 'Law Enforcement Purpose' ?
                                                        <div className="w-full pl-5">
                                                            <FormControl fullWidth>
                                                                <InputLabel
                                                                    id="ReasonID">{'Purpose'}</InputLabel>
                                                                <Select
                                                                    disabled={isFieldLocked(params, 'ReasonLEPurpose')}
                                                                    size="small"
                                                                    labelId="ReasonID"
                                                                    label="Purpose"
                                                                    className="w-full"
                                                                    value={params?.ReasonLEPurpose}
                                                                    onChange={e => handlePurposeChange(e.target.value)}
                                                                >
                                                                    {params.ReasonLEPurposeList && params?.ReasonLEPurposeList.map((reason) => (
                                                                        <MenuItem
                                                                            value={reason.Key}>{reason.Value}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        : <></>}
                                    </div>
                                    <div className={(params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE' && params?.PurposeSecondary?.toUpperCase()?.includes('OTHER')) ? "grid grid-cols-2 mt-5 gap-y-5" : "grid grid-cols-1 mt-5 gap-y-5"}>
                                            {params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE' &&
                                                <div
                                                    className={params?.PurposeSecondary?.toUpperCase()?.includes('OTHER') ? "w-full pr-5" : "w-full"}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="JustificationID">Justification</InputLabel>
                                                        <Select
                                                            disabled={isFieldLocked(params, 'ReasonLEPurpose')}
                                                            size="small"
                                                            labelId="JustificationID"
                                                            label="Justification"
                                                            className="w-full"
                                                            value={params?.PurposeSecondary}
                                                            onChange={e => handlePurposeSecondaryChange(e.target.value)}
                                                        >
                                                            {params.ReasonLEPurposeList && params?.ReasonLEPurposeList.map((reason) => (
                                                                <MenuItem value={reason.Key}>{reason.Value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            }
                                            {!(params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE' && !params?.PurposeSecondary?.toUpperCase()?.includes('OTHER')) && //THIS IS THE ACTUAL JUSTIFICATION FIELD BUT WE HIDE IF LEP IS SELECTED SO THAT THEY CAN JUST PICK FROM DROPDOWN UNLESS ITS OTHER.
                                                <div
                                                    className={(params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE') ? "w-full pl-5" : "w-full"}>
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink': true}}
                                                        label={(params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE') ? "Other" : "Justification"}
                                                        className="w-full"
                                                        disabled={isFieldLocked(params, 'Justification') || !(params?.ReasonAlert?.toUpperCase() === 'BOLO ALERT' || (params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE' && params?.PurposeSecondary?.toUpperCase()?.includes('OTHER')))}
                                                        value={params?.Justification}
                                                        onChange={e => setParams({
                                                            ...params,
                                                            Justification: e.target.value
                                                        })}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    <div className="grid grid-cols-2 mt-5 gap-y-5">
                                        <div className="w-full pr-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'StartDate')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="Start Date"
                                                className="w-full"
                                                type="date"
                                                value={params?.StartDate?.substring(0, 10)}
                                                onChange={e => setParams({...params, StartDate: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full pl-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'ExpiryDate')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="Expiry Date"
                                                className="w-full"
                                                type="date"
                                                value={params?.ExpiryDate?.substring(0, 10)}
                                                onChange={e => handleMaxExpireTime(e.target.value)}
                                            />
                                        </div>
                                        <div className="w-full pr-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'NICNum')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="NIC #"
                                                className="w-full"
                                                value={params?.NICNum}
                                                onChange={e => setParams({...params, NICNum: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full ml-5">
                                            <FormControlLabel
                                                disabled={isFieldLocked(params, 'ConfidentialPOI')}
                                                control={<Checkbox checked={params?.ConfidentialPOI}
                                                                   onChange={e => setParams({
                                                                       ...params,
                                                                       ConfidentialPOI: !params?.ConfidentialPOI
                                                                   })} sx={{'& .MuiSvgIcon-root': {fontSize: 32}}}/>}
                                                label="Confidential POI"
                                            />
                                            {params &&
                                                <Tooltip
                                                    title="Select this box if the vehicle is suspected of being involved in the commission of a crime. Then select one or multiple crimes. Note: A high-priority designation will be added to this POI."
                                                    placement={"top"}>
                                                    <FormControlLabel
                                                        disabled={isFieldLocked(params, 'FelonyVehicle')}
                                                        control={<Checkbox checked={params?.FelonyVehicle}
                                                                           onChange={e => setParams({
                                                                               ...params,
                                                                               FelonyVehicle: !params?.FelonyVehicle,
                                                                               FelonyVehicleReason: ''
                                                                           })}
                                                                           sx={{'& .MuiSvgIcon-root': {fontSize: 32}}}/>}
                                                        label="Felony Vehicle"
                                                    />
                                                </Tooltip>
                                            }
                                        </div>
                                        {params?.FelonyVehicle && params &&
                                            <div className="w-full col-span-2">
                                                <FormControl fullWidth>
                                                    <InputLabel id="FelonyReasonID">Felony Vehicle Reason</InputLabel>
                                                    <Tooltip
                                                        title="Select one or multiple crimes. Note: A high-priority designation will be added to this POI."
                                                        placement={"top"}>
                                                        <Select
                                                            disabled={isFieldLocked(params, 'FelonyVehicleReason')}
                                                            multiple
                                                            size="small"
                                                            labelId="FelonyReasonID"
                                                            label="Felony Vehicle Reason"
                                                            className="w-full"
                                                            value={params?.FelonyVehicleReason ? params?.FelonyVehicleReason?.split(',') : []}
                                                            renderValue={(selected) => selected.join(', ')}
                                                            onChange={e => {
                                                                setParams({
                                                                    ...params,
                                                                    FelonyVehicleReason: (e.target.value as string[])?.join(',',)
                                                                });
                                                            }}
                                                        >
                                                            {params.FelonyVehicleReasonList && params?.FelonyVehicleReasonList.map((reason) => (
                                                                <MenuItem value={reason.Key}>
                                                                    <Checkbox checked={((params.FelonyVehicleReason && params.FelonyVehicleReason.indexOf(reason.Key) > -1) as any)}/>
                                                                    {reason.Value}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Tooltip>
                                                </FormControl>
                                            </div>
                                        }
                                    </div>
                                    
                                    <div>
                                        <Button
                                           
                                            color="inherit"
                                            onClick={CloseBox}
                                        >
                                            CANCEL
                                        </Button>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            color="inherit"
                                        >
                                            Back
                                        </Button>
                                        <Button
                                           
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </StepContent>
                            </Step>

                            <Step key={"Vehicle Information"}>
                                <StepLabel>Vehicle Information</StepLabel>
                                <StepContent>
                                    <div className="grid grid-cols-2 mt-5 gap-y-5">
                                        <div className="w-full pr-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'PlateNumber')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="Plate #1"
                                                className="w-full"
                                                value={params?.PlateNumber}
                                                onChange={e => setParams({...params, PlateNumber: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full pl-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'PlateSt')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="State #1"
                                                inputProps={{maxLength: 2}}
                                                placeholder="NJ, PA, CA, DC"
                                                className="w-full"
                                                value={params?.PlateSt}
                                                onChange={e => setParams({...params, PlateSt: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full pr-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'PlateNumber2')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="Plate #2"
                                                className="w-full"
                                                value={params?.PlateNumber2}
                                                onChange={e => setParams({...params, PlateNumber2: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full pl-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'PlateSt2')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="State #2"
                                                inputProps={{maxLength: 2}}
                                                placeholder="NJ, PA, CA, DC"
                                                className="w-full"
                                                value={params?.PlateSt2}
                                                onChange={e => setParams({...params, PlateSt2: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full pr-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'PlateNumber3')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="Plate #3"
                                                className="w-full"
                                                value={params?.PlateNumber3}
                                                onChange={e => setParams({...params, PlateNumber3: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full pl-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'PlateSt3')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="State #3"
                                                inputProps={{maxLength: 2}}
                                                placeholder="NJ, PA, CA, DC"
                                                className="w-full"
                                                value={params?.PlateSt3}
                                                onChange={e => setParams({...params, PlateSt3: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 mt-5 gap-y-5">
                                        <div className="w-full">
                                            <TextField
                                                disabled={isFieldLocked(params, 'VIN')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="VIN"
                                                className="w-full"
                                                value={params?.VIN}
                                                onChange={e => setParams({...params, VIN: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div>
                                        <Button
                                           
                                            color="inherit"
                                            onClick={CloseBox}
                                        >
                                            CANCEL
                                        </Button>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            color="inherit"
                                        >
                                            Back
                                        </Button>
                                        <Button
                                           
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </StepContent>
                            </Step>
                            
                            <Step key={"Suspect Information"}>
                                <StepLabel>Suspect Information</StepLabel>
                                <StepContent>
                                    <div className="grid grid-cols-3 mt-5 gap-y-5">
                                        <div className="w-full pr-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'FName')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="First Name"
                                                className="w-full"
                                                value={params?.FName}
                                                onChange={e => setParams({...params, FName: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full pr-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'MName')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="Middle Initial"
                                                className="w-full"
                                                value={params?.MName}
                                                onChange={e => setParams({...params, MName: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <TextField
                                                disabled={isFieldLocked(params, 'LName')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="Last Name"
                                                className="w-full"
                                                value={params?.LName}
                                                onChange={e => setParams({...params, LName: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full pr-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'DOB')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="DOB"
                                                className="w-full"
                                                type={"date"}
                                                value={params?.DOB?.substring(0, 10)}
                                                onChange={e => setParams({...params, DOB: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full pr-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'DLNo')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="DL #"
                                                className="w-full"
                                                value={params?.DLNo}
                                                onChange={e => setParams({...params, DLNo: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <TextField
                                                disabled={isFieldLocked(params, 'DLSt')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="DL State"
                                                inputProps={{maxLength: 2}}
                                                placeholder="NJ, PA, CA, DC"
                                                className="w-full"
                                                value={params?.DLSt}
                                                onChange={e => setParams({...params, DLSt: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full pr-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'SSN')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="SSN #"
                                                className="w-full"
                                                value={params?.SSN}
                                                onChange={e => setParams({...params, SSN: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full pr-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'FBINo')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label="FBI #"
                                                className="w-full"
                                                value={params?.FBINo}
                                                onChange={e => setParams({...params, FBINo: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <TextField
                                                disabled={isFieldLocked(params, 'SBINo')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label={GetStringNameForSite(SiteName, "SBI #")}
                                                className="w-full"
                                                value={params?.SBINo}
                                                onChange={e => setParams({...params, SBINo: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                           
                                            color="inherit"
                                            onClick={CloseBox}
                                        >
                                            CANCEL
                                        </Button>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            color="inherit"
                                        >
                                            Back
                                        </Button>
                                        <Button
                                           
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </StepContent>
                            </Step>
                            
                            <Step key={"Supervisory Information"}>
                                <StepLabel>Supervisory & Notification Information</StepLabel>
                                <StepContent>
                                    <div className="grid grid-cols-1 mt-5 gap-y-5">
                                        <div className="w-full">
                                            <TextField
                                                disabled={isFieldLocked(params, 'SearchNotes')}
                                                size="small"
                                                multiline
                                                minRows={4}
                                                InputLabelProps={{'shrink': true}}
                                                className="w-full"
                                                label="Comments"
                                                value={params?.SearchNotes}
                                                onChange={e => setParams({...params, SearchNotes: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-3 mt-5 gap-y-5">
                                        <div className="w-full pr-5">
                                            <FormControl fullWidth
                                                         color={supervisorError.length > 0 ? "error" : "primary"}
                                                         focused={supervisorError.length > 0 ? true : undefined}>
                                                {supervisorError.length > 0 ?
                                                    <InputLabel id="AccountTypeLevel">{supervisorError}</InputLabel> :
                                                    <InputLabel id="SupervisorID">Supervisor</InputLabel>}
                                                <Select
                                                    disabled={isFieldLocked(params, 'AuthorizedBy') || params.AuthorizedByList && params.AuthorizedByList?.length === 1 ? true : false}
                                                    size="small"
                                                    labelId="SupervisorID"
                                                    label={supervisorError.length > 0 ? supervisorError : "Supervisor"}
                                                    className="w-full"
                                                    value={params?.AuthorizedBy}
                                                    onChange={e => setParams({...params, AuthorizedBy: e.target.value})}
                                                >
                                                    {params.AuthorizedByList && params?.AuthorizedByList.map((supervisor) => (
                                                        <MenuItem value={supervisor.Key}>{supervisor.Value}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="w-full pr-5">
                                            <TextField
                                                disabled={isFieldLocked(params, 'CaseNo')}
                                                size="small"
                                                InputLabelProps={{'shrink': true}}
                                                label={GetStringNameForSite(SiteName, "Case #")}
                                                placeholder="20 Character Limit"
                                                inputProps={{maxLength: 20}}
                                                className="w-full"
                                                value={params?.CaseNo}
                                                onChange={e => setParams({...params, CaseNo: e.target.value})}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <FormControl fullWidth>
                                                <InputLabel id="AlertOptionID">Alert Option</InputLabel>
                                                <Select
                                                    disabled={isFieldLocked(params, 'AlertOption')}
                                                    size="small"
                                                    labelId="AlertOptionID"
                                                    label="Alert Option"
                                                    className="w-full"
                                                    value={params?.AlertOption}
                                                    onChange={e => setParams({...params, AlertOption: e.target.value})}
                                                >
                                                    {params.AlertOptionList && params?.AlertOptionList.map((alert) => (
                                                        <MenuItem value={alert.Key}>{alert.Value}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 mt-5 gap-y-5">
                                        <div className="w-full">
                                            <TextField
                                                disabled={isFieldLocked(params, 'AdditionalEmail')}
                                                size="small"
                                                multiline
                                                className="w-full"
                                                label="Notification Emails"
                                                placeholder="emails seperated by , or ;"
                                                value={params?.AdditionalEmail}
                                                onChange={e => setParams({...params, AdditionalEmail: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                           
                                            color="inherit"
                                            onClick={CloseBox}
                                        >
                                            CANCEL
                                        </Button>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            color="inherit"
                                        >
                                            Back
                                        </Button>
                                        <Button
                                           
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </StepContent>
                            </Step>
                            {(SiteName==SITE.NJPROD || SiteName==SITE.NJUAT || SiteName==SITE.NJDEMO || SiteName==SITE.LOCAL) &&
                                <Step key={"POI Agreement"}>
                                <StepLabel>Acceptance Agreement</StepLabel>
                                <StepContent>
                                    <h1 className="mt-2 font-normal text-3xl">Criteria for and Examples of Legitimate
                                        BOLO
                                        Situations</h1>
                                    <div className="mt-2">
                                        <p>
                                            A license plate number or partial license plate number shall not be included
                                            in
                                            an ALPR BOLO list unless there is a legitimate and specific law enforcement
                                            reason to identify, locate or ascertain the past location(s) of that
                                            particular
                                            vehicle, or any person or persons who are reasonably believed to be
                                            associated
                                            with that vehicle.
                                        </p>
                                        <br/>
                                        <p>
                                            Examples of legitimate and specific reasons include, but are not limited to:
                                            persons who are subject to an outstanding arrest warrant; missing persons;
                                            AMBER
                                            Alerts; stolen vehicles; vehicles that are reasonably believed to be
                                            involved in
                                            the commission of a crime or disorderly persons offense; vehicles that are
                                            registered to or are reasonably believed to be operated by persons who do
                                            not
                                            have a valid operator’s license or who are on the revoked or suspended list;
                                            vehicles with expired registrations or other Title 39 violations; persons
                                            who
                                            are subject to a restraining order or curfew issued by a court or by the
                                            Parole
                                            Board, or who are subject to any other duly issued order restricting their
                                            movements; persons wanted by a law enforcement agency who are of interest in
                                            a
                                            specific investigation, whether or not such persons are themselves suspected
                                            of
                                            criminal activity; and persons who are on any watch list issued by a State
                                            or
                                            federal agency responsible for homeland security.
                                        </p>
                                    </div>

                                    <br/>

                                    <div>
                                        <Button
                                           
                                            color="inherit"
                                            onClick={CloseBox}
                                        >
                                            CANCEL
                                        </Button>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            color="inherit"
                                        >
                                            Back
                                        </Button>
                                        <Button
                                           
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </StepContent>
                            </Step>
                            }
                            <Step key={"Review"}>
                                <StepLabel>Review</StepLabel>
                                <StepContent>
                                    <div className="mt-2">


                                        {/*Issuance Type*/}
                                        <div className="grid grid-cols-2 mt-5 gap-y-5">
                                            <div className="w-full pr-5">
                                                <FormControl fullWidth>
                                                    <InputLabel
                                                        id="ReasonTypeID">{'Type'}</InputLabel>
                                                    <Select
                                                        disabled={true}
                                                        size="small"
                                                        labelId="ReasonTypeID"
                                                        label="Type"
                                                        className="w-full"
                                                        value={params?.ReasonType}
                                                        defaultValue={params?.ReasonType}
                                                        onChange={e => setParams({
                                                            ...params,
                                                            ReasonType: e.target.value,
                                                            Justification: '',
                                                            ReasonAlert: '',
                                                            ReasonCovert: '',
                                                            ReasonNCIC: '',
                                                            ReasonLEPurpose: '',
                                                            FelonyVehicle: false,
                                                            FelonyVehicleReason: ''
                                                        })}
                                                        //onChange={e => handleExpireTime(e.target.value)}
                                                    >
                                                        {params.ReasonTypeList && params?.ReasonTypeList.map((reason) => (
                                                            <MenuItem value={reason.Key}>{reason.Value}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {params && params?.ReasonType === ('ALERT') ?
                                                <div className="w-full pl-5">
                                                    <FormControl fullWidth>
                                                        <InputLabel
                                                            id="ReasonID">{'Purpose'}</InputLabel>
                                                        <Select
                                                            disabled={true}
                                                            size="small"
                                                            labelId="ReasonID"
                                                            label="Purpose"
                                                            className="w-full"
                                                            value={params?.ReasonAlert}
                                                            onChange={e => handlePurposeChange(e.target.value)}
                                                        >
                                                            {
                                                                params.ReasonAlertList && params?.ReasonAlertList.map((reason) => (
                                                                    <MenuItem value={reason.Key}>{reason.Value}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                : params && params?.ReasonType === 'COVERT' ?
                                                    <div className="w-full pl-5">
                                                        <FormControl fullWidth>
                                                            <InputLabel
                                                                id="ReasonID">{'Purpose'}</InputLabel>
                                                            <Select
                                                                disabled={true}
                                                                size="small"
                                                                labelId="ReasonID"
                                                                label="Purpose"
                                                                className="w-full"
                                                                value={params?.ReasonCovert}
                                                                onChange={e => handlePurposeChange(e.target.value)}
                                                            >
                                                                {params.ReasonCovertList && params?.ReasonCovertList.map((reason) => (
                                                                    <MenuItem
                                                                        value={reason.Key}>{reason.Value}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    : params && params?.ReasonType === 'NCIC' ?
                                                        <div className="w-full pl-5">
                                                            <FormControl fullWidth>
                                                                <InputLabel
                                                                    id="ReasonID">{'Purpose'}</InputLabel>
                                                                <Select
                                                                    disabled={true}
                                                                    size="small"
                                                                    labelId="ReasonID"
                                                                    label="Purpose"
                                                                    className="w-full"
                                                                    value={params?.ReasonNCIC}
                                                                    onChange={e => handlePurposeChange(e.target.value)}
                                                                >
                                                                    {params.ReasonNCICList && params?.ReasonNCICList.map((reason) => (
                                                                        <MenuItem
                                                                            value={reason.Key}>{reason.Value}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        : params && params?.ReasonType === 'Law Enforcement Purpose' ?
                                                            <div className="w-full pl-5">
                                                                <FormControl fullWidth>
                                                                    <InputLabel
                                                                        id="ReasonID">{'Purpose'}</InputLabel>
                                                                    <Select
                                                                        disabled={true}
                                                                        size="small"
                                                                        labelId="ReasonID"
                                                                        label="Purpose"
                                                                        className="w-full"
                                                                        value={params?.ReasonLEPurpose}
                                                                        onChange={e => handlePurposeChange(e.target.value)}
                                                                    >
                                                                        {params.ReasonLEPurposeList && params?.ReasonLEPurposeList.map((reason) => (
                                                                            <MenuItem
                                                                                value={reason.Key}>{reason.Value}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            : <></>}
                                        </div>
                                        <div
                                            className={(params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE' && params?.PurposeSecondary?.toUpperCase()?.includes('OTHER')) ? "grid grid-cols-2 mt-5 gap-y-5" : "grid grid-cols-1 mt-5 gap-y-5"}>
                                            {params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE' &&
                                                <div
                                                    className={params?.PurposeSecondary?.toUpperCase()?.includes('OTHER') ? "w-full pr-5" : "w-full"}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="JustificationID">Justification</InputLabel>
                                                        <Select
                                                            disabled={true}
                                                            size="small"
                                                            labelId="JustificationID"
                                                            label="Justification"
                                                            className="w-full"
                                                            value={params?.PurposeSecondary}
                                                            onChange={e => handlePurposeSecondaryChange(e.target.value)}
                                                        >
                                                            {params.ReasonLEPurposeList && params?.ReasonLEPurposeList.map((reason) => (
                                                                <MenuItem value={reason.Key}>{reason.Value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            }
                                            {!(params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE' && !params?.PurposeSecondary?.toUpperCase()?.includes('OTHER')) && //THIS IS THE ACTUAL JUSTIFICATION FIELD BUT WE HIDE IF LEP IS SELECTED SO THAT THEY CAN JUST PICK FROM DROPDOWN UNLESS ITS OTHER.
                                                <div
                                                    className={(params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE') ? "w-full pl-5" : "w-full"}>
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink': true}}
                                                        label={(params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE') ? "Other" : "Justification"}
                                                        className="w-full"
                                                        disabled={isFieldLocked(params, 'Justification') || !(params?.ReasonAlert?.toUpperCase() === 'BOLO ALERT' || (params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE' && params?.PurposeSecondary?.toUpperCase()?.includes('OTHER')))}
                                                        value={params?.Justification}
                                                        onChange={e => setParams({
                                                            ...params,
                                                            Justification: e.target.value
                                                        })}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className="grid grid-cols-2 mt-5 gap-y-5">
                                            <div className="w-full pr-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="Start Date"
                                                    className="w-full"
                                                    type="date"
                                                    value={params?.StartDate?.substring(0, 10)}
                                                    onChange={e => setParams({...params, StartDate: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full pl-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="Expiry Date"
                                                    className="w-full"
                                                    type="date"
                                                    value={params?.ExpiryDate?.substring(0, 10)}
                                                    onChange={e => handleMaxExpireTime(e.target.value)}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="NIC #"
                                                    className="w-full"
                                                    value={params?.NICNum}
                                                    onChange={e => setParams({...params, NICNum: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full ml-5">
                                                <FormControlLabel
                                                    disabled={true}
                                                    control={<Checkbox checked={params?.ConfidentialPOI}
                                                                       onChange={e => setParams({
                                                                           ...params,
                                                                           ConfidentialPOI: !params?.ConfidentialPOI
                                                                       })}
                                                                       sx={{'& .MuiSvgIcon-root': {fontSize: 32}}}/>}
                                                    label="Confidential POI"
                                                />
                                                {params &&
                                                    <Tooltip
                                                        title="Select this box if the vehicle is suspected of being involved in the commission of a crime. Then select one or multiple crimes. Note: A high-priority designation will be added to this POI."
                                                        placement={"top"}>
                                                        <FormControlLabel
                                                            disabled={true}
                                                            control={<Checkbox checked={params?.FelonyVehicle}
                                                                               onChange={e => setParams({
                                                                                   ...params,
                                                                                   FelonyVehicle: !params?.FelonyVehicle,
                                                                                   FelonyVehicleReason: ''
                                                                               })}
                                                                               sx={{'& .MuiSvgIcon-root': {fontSize: 32}}}/>}
                                                            label="Felony Vehicle"
                                                        />
                                                    </Tooltip>
                                                }
                                            </div>
                                            {params?.FelonyVehicle && params &&
                                                <div className="w-full col-span-2">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="FelonyReasonID">Felony Vehicle
                                                            Reason</InputLabel>
                                                        <Tooltip
                                                            title="Select one or multiple crimes. Note: A high-priority designation will be added to this POI."
                                                            placement={"top"}>
                                                            <Select
                                                                disabled={true}
                                                                multiple
                                                                size="small"
                                                                labelId="FelonyReasonID"
                                                                label="Felony Vehicle Reason"
                                                                className="w-full"
                                                                value={params?.FelonyVehicleReason ? params?.FelonyVehicleReason?.split(',') : []}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                onChange={e => {
                                                                    setParams({
                                                                        ...params,
                                                                        FelonyVehicleReason: (e.target.value as string[])?.join(',',)
                                                                    });
                                                                }}
                                                            >
                                                                {params.FelonyVehicleReasonList && params?.FelonyVehicleReasonList.map((reason) => (
                                                                    <MenuItem value={reason.Key}>
                                                                        <Checkbox
                                                                            checked={((params.FelonyVehicleReason && params.FelonyVehicleReason.indexOf(reason.Key) > -1) as any)}/>
                                                                        {reason.Value}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </Tooltip>
                                                    </FormControl>
                                                </div>
                                            }
                                        </div>

                                        {/*Vehicle Information*/}
                                        <div className="grid grid-cols-2 mt-5 gap-y-5">
                                            <div className="w-full pr-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="Plate #1"
                                                    className="w-full"
                                                    value={params?.PlateNumber}
                                                    onChange={e => setParams({...params, PlateNumber: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full pl-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="State #1"
                                                    inputProps={{maxLength: 2}}
                                                    placeholder="NJ, PA, CA, DC"
                                                    className="w-full"
                                                    value={params?.PlateSt}
                                                    onChange={e => setParams({...params, PlateSt: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="Plate #2"
                                                    className="w-full"
                                                    value={params?.PlateNumber2}
                                                    onChange={e => setParams({...params, PlateNumber2: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full pl-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="State #2"
                                                    inputProps={{maxLength: 2}}
                                                    placeholder="NJ, PA, CA, DC"
                                                    className="w-full"
                                                    value={params?.PlateSt2}
                                                    onChange={e => setParams({...params, PlateSt2: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="Plate #3"
                                                    className="w-full"
                                                    value={params?.PlateNumber3}
                                                    onChange={e => setParams({...params, PlateNumber3: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full pl-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="State #3"
                                                    inputProps={{maxLength: 2}}
                                                    placeholder="NJ, PA, CA, DC"
                                                    className="w-full"
                                                    value={params?.PlateSt3}
                                                    onChange={e => setParams({...params, PlateSt3: e.target.value})}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 mt-5 gap-y-5">
                                            <div className="w-full">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="VIN"
                                                    className="w-full"
                                                    value={params?.VIN}
                                                    onChange={e => setParams({...params, VIN: e.target.value})}
                                                />
                                            </div>
                                        </div>


                                        {/*Suspect Information*/}
                                        <div className="grid grid-cols-3 mt-5 gap-y-5">
                                            <div className="w-full pr-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="First Name"
                                                    className="w-full"
                                                    value={params?.FName}
                                                    onChange={e => setParams({...params, FName: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="Middle Initial"
                                                    className="w-full"
                                                    value={params?.MName}
                                                    onChange={e => setParams({...params, MName: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="Last Name"
                                                    className="w-full"
                                                    value={params?.LName}
                                                    onChange={e => setParams({...params, LName: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="DOB"
                                                    className="w-full"
                                                    type={"date"}
                                                    value={params?.DOB?.substring(0, 10)}
                                                    onChange={e => setParams({...params, DOB: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="DL #"
                                                    className="w-full"
                                                    value={params?.DLNo}
                                                    onChange={e => setParams({...params, DLNo: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="DL State"
                                                    inputProps={{maxLength: 2}}
                                                    placeholder="NJ, PA, CA, DC"
                                                    className="w-full"
                                                    value={params?.DLSt}
                                                    onChange={e => setParams({...params, DLSt: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="SSN #"
                                                    className="w-full"
                                                    value={params?.SSN}
                                                    onChange={e => setParams({...params, SSN: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label="FBI #"
                                                    className="w-full"
                                                    value={params?.FBINo}
                                                    onChange={e => setParams({...params, FBINo: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label={GetStringNameForSite(SiteName, "SBI #")}
                                                    className="w-full"
                                                    value={params?.SBINo}
                                                    onChange={e => setParams({...params, SBINo: e.target.value})}
                                                />
                                            </div>
                                        </div>


                                        {/*Supervisory Information*/}
                                        <div className="grid grid-cols-1 mt-5 gap-y-5">
                                            <div className="w-full">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    multiline
                                                    minRows={4}
                                                    InputLabelProps={{'shrink': true}}
                                                    className="w-full"
                                                    label="Comments"
                                                    value={params?.SearchNotes}
                                                    onChange={e => setParams({...params, SearchNotes: e.target.value})}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 mt-5 gap-y-5">
                                            <div className="w-full pr-5">
                                                <FormControl fullWidth
                                                             color={supervisorError.length > 0 ? "error" : "primary"}
                                                             focused={supervisorError.length > 0 ? true : undefined}>
                                                    {supervisorError.length > 0 ?
                                                        <InputLabel
                                                            id="AccountTypeLevel">{supervisorError}</InputLabel> :
                                                        <InputLabel id="SupervisorID">Supervisor</InputLabel>}
                                                    <Select
                                                        disabled={true}
                                                        size="small"
                                                        labelId="SupervisorID"
                                                        label={supervisorError.length > 0 ? supervisorError : "Supervisor"}
                                                        className="w-full"
                                                        value={params?.AuthorizedBy}
                                                        onChange={e => setParams({
                                                            ...params,
                                                            AuthorizedBy: e.target.value
                                                        })}
                                                    >
                                                        {params.AuthorizedByList && params?.AuthorizedByList.map((supervisor) => (
                                                            <MenuItem
                                                                value={supervisor.Key}>{supervisor.Value}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    InputLabelProps={{'shrink': true}}
                                                    label={GetStringNameForSite(SiteName, "Case #")}
                                                    placeholder="20 Character Limit"
                                                    inputProps={{maxLength: 20}}
                                                    className="w-full"
                                                    value={params?.CaseNo}
                                                    onChange={e => setParams({...params, CaseNo: e.target.value})}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <FormControl fullWidth>
                                                    <InputLabel id="AlertOptionID">Alert Option</InputLabel>
                                                    <Select
                                                        disabled={true}
                                                        size="small"
                                                        labelId="AlertOptionID"
                                                        label="Alert Option"
                                                        className="w-full"
                                                        value={params?.AlertOption}
                                                        onChange={e => setParams({
                                                            ...params,
                                                            AlertOption: e.target.value
                                                        })}
                                                    >
                                                        {params.AlertOptionList && params?.AlertOptionList.map((alert) => (
                                                            <MenuItem value={alert.Key}>{alert.Value}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 mt-5 gap-y-5">
                                            <div className="w-full">
                                                <TextField
                                                    disabled={true}
                                                    size="small"
                                                    multiline
                                                    className="w-full"
                                                    label="Notification Emails"
                                                    placeholder="emails seperated by , or ;"
                                                    value={params?.AdditionalEmail}
                                                    onChange={e => setParams({
                                                        ...params,
                                                        AdditionalEmail: e.target.value
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <br/>

                                    <div>
                                        <Button
                                           
                                            color="inherit"
                                            onClick={CloseBox}
                                            disabled={isSaving}
                                        >
                                            CANCEL
                                        </Button>
                                        <Button
                                            disabled={isSaving || activeStep === 0}
                                            onClick={handleBack}
                                            color="inherit"
                                        >
                                            Back
                                        </Button>
                                        <Button
                                           
                                            color="success"
                                            disabled={isSaving}
                                            onClick={submitPOI}
                                        >
                                            SAVE {isSaving &&
                                            <CircularProgress size={20} color="inherit" style={{"marginLeft": "5px"}}/>}
                                        </Button>

                                    </div>
                                </StepContent>
                            </Step>

                        </Stepper>

                    </CardContent>
                </Card>
            }

            {( (params && params?.ReasonTypeList && params?.ReasonTypeList.length > 0 && [SITE.LOCAL,SITE.NJPROD, SITE.NJDEMO,SITE.NJUAT].includes(SiteName)) ?
                <Card sx={{maxWidth: 900}} className="mx-auto">
                    <CardContent>
                        <span className="text-blue-400 font-bold text-xl">
                        {params?.UniqueID === 0 ? <span>Add New POI</span> : <span>Edit POI Details</span>}
                        </span>
                        <hr/>
                        <Disclosure defaultOpen={true}>
                            {({open}) => (
                                <>
                                    <Card sx={{maxWidth: 900, marginTop: 2}}>
                                        <CardContent>
                                            <Disclosure.Button className="w-auto w-full text-left">
                                            <span style={{"display": "flex", "justifyContent": "space-between"}}>
                                                <span>
                                                    <KeyboardArrowDownIcon
                                                        className={`${open ? "transform rotate-180" : ""}`}
                                                        style={{fontSize: "2em"}}
                                                    />
                                                    <span
                                                        className="text-blue-400 font-bold text-xl"> Issuance Type </span>
                                                </span>
                                            </span>
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="text-gray-500 p-4">
                                                <div className="grid grid-cols-2 mt-5 gap-y-5">
                                                    <div className="w-full pr-5">
                                                        <FormControl fullWidth>
                                                            <InputLabel
                                                                id="ReasonTypeID">{enableNJCAP5 ? 'Type' : 'Reason Type'}</InputLabel>
                                                            <Select
                                                                disabled={isFieldLocked(params, 'ReasonType')}
                                                                size="small"
                                                                labelId="ReasonTypeID"
                                                                label="Type"
                                                                className="w-full"
                                                                value={params?.ReasonType}
                                                                defaultValue={params?.ReasonType}
                                                                onChange={e => setParams({
                                                                    ...params,
                                                                    ReasonType: e.target.value,
                                                                    Justification: '',
                                                                    ReasonAlert: '',
                                                                    ReasonCovert: '',
                                                                    ReasonNCIC: '',
                                                                    ReasonLEPurpose: '',
                                                                    FelonyVehicle: false,
                                                                    FelonyVehicleReason: ''
                                                                })}
                                                                //onChange={e => handleExpireTime(e.target.value)}
                                                            >
                                                                {params.ReasonTypeList && params?.ReasonTypeList.map((reason) => (
                                                                    <MenuItem
                                                                        value={reason.Key}>{reason.Value}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    {params && params?.ReasonType === (enableNJCAP5 ? 'ALERT' : 'NON-NCIC') ?
                                                        <div className="w-full pl-5">
                                                            <FormControl fullWidth>
                                                                <InputLabel
                                                                    id="ReasonID">{enableNJCAP5 ? 'Purpose' : 'Reason'}</InputLabel>
                                                                <Select
                                                                    disabled={isFieldLocked(params, 'ReasonAlert')}
                                                                    size="small"
                                                                    labelId="ReasonID"
                                                                    label="Purpose"
                                                                    className="w-full"
                                                                    value={enableNJCAP5 ? params?.ReasonAlert : params?.ReasonNonNCIC}
                                                                    onChange={e => handlePurposeChange(e.target.value)}
                                                                >
                                                                    {enableNJCAP5 ?
                                                                        params.ReasonAlertList && params?.ReasonAlertList.map((reason) => (
                                                                            <MenuItem
                                                                                value={reason.Key}>{reason.Value}</MenuItem>
                                                                        ))
                                                                        :
                                                                        params.ReasonNonNCICList && params?.ReasonNonNCICList.map((reason) => (
                                                                            <MenuItem
                                                                                value={reason.Key}>{reason.Value}</MenuItem>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        : params && params?.ReasonType === 'COVERT' ?
                                                            <div className="w-full pl-5">
                                                                <FormControl fullWidth>
                                                                    <InputLabel
                                                                        id="ReasonID">{enableNJCAP5 ? 'Purpose' : 'Reason'}</InputLabel>
                                                                    <Select
                                                                        disabled={isFieldLocked(params, 'ReasonCovert')}
                                                                        size="small"
                                                                        labelId="ReasonID"
                                                                        label="Purpose"
                                                                        className="w-full"
                                                                        value={params?.ReasonCovert}
                                                                        onChange={e => handlePurposeChange(e.target.value)}
                                                                    >
                                                                        {params.ReasonCovertList && params?.ReasonCovertList.map((reason) => (
                                                                            <MenuItem
                                                                                value={reason.Key}>{reason.Value}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            : params && params?.ReasonType === 'NCIC' ?
                                                                <div className="w-full pl-5">
                                                                    <FormControl fullWidth>
                                                                        <InputLabel
                                                                            id="ReasonID">{enableNJCAP5 ? 'Purpose' : 'Reason'}</InputLabel>
                                                                        <Select
                                                                            disabled={isFieldLocked(params, 'ReasonNCIC')}
                                                                            size="small"
                                                                            labelId="ReasonID"
                                                                            label="Purpose"
                                                                            className="w-full"
                                                                            value={params?.ReasonNCIC}
                                                                            onChange={e => handlePurposeChange(e.target.value)}
                                                                        >
                                                                            {params.ReasonNCICList && params?.ReasonNCICList.map((reason) => (
                                                                                <MenuItem
                                                                                    value={reason.Key}>{reason.Value}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                                : params && params?.ReasonType === 'Law Enforcement Purpose' ?
                                                                    <div className="w-full pl-5">
                                                                        <FormControl fullWidth>
                                                                            <InputLabel
                                                                                id="ReasonID">{enableNJCAP5 ? 'Purpose' : 'Reason'}</InputLabel>
                                                                            <Select
                                                                                disabled={isFieldLocked(params, 'ReasonLEPurpose')}
                                                                                size="small"
                                                                                labelId="ReasonID"
                                                                                label="Purpose"
                                                                                className="w-full"
                                                                                value={params?.ReasonLEPurpose}
                                                                                onChange={e => handlePurposeChange(e.target.value)}
                                                                            >
                                                                                {params.ReasonLEPurposeList && params?.ReasonLEPurposeList.map((reason) => (
                                                                                    <MenuItem
                                                                                        value={reason.Key}>{reason.Value}</MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                    : <></>}
                                                </div>
                                                {enableNJCAP5 &&
                                                    <div
                                                        className={(params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE' && params?.PurposeSecondary?.toUpperCase()?.includes('OTHER')) ? "grid grid-cols-2 mt-5 gap-y-5" : "grid grid-cols-1 mt-5 gap-y-5"}>
                                                        {params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE' &&
                                                            <div
                                                                className={params?.PurposeSecondary?.toUpperCase()?.includes('OTHER') ? "w-full pr-5" : "w-full"}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel
                                                                        id="JustificationID">Justification</InputLabel>
                                                                    <Select
                                                                        disabled={isFieldLocked(params, 'ReasonLEPurpose')}
                                                                        size="small"
                                                                        labelId="JustificationID"
                                                                        label="Justification"
                                                                        className="w-full"
                                                                        value={params?.PurposeSecondary}
                                                                        onChange={e => handlePurposeSecondaryChange(e.target.value)}
                                                                    >
                                                                        {params.ReasonLEPurposeList && params?.ReasonLEPurposeList.map((reason) => (
                                                                            <MenuItem
                                                                                value={reason.Key}>{reason.Value}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        }
                                                        {!(params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE' && !params?.PurposeSecondary?.toUpperCase()?.includes('OTHER')) && //THIS IS THE ACTUAL JUSTIFICATION FIELD BUT WE HIDE IF LEP IS SELECTED SO THAT THEY CAN JUST PICK FROM DROPDOWN UNLESS ITS OTHER.
                                                            <div
                                                                className={(params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE') ? "w-full pl-5" : "w-full"}>
                                                                <TextField
                                                                    size="small"
                                                                    InputLabelProps={{'shrink': true}}
                                                                    label={(params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase() === 'LAW ENFORCEMENT PURPOSE') ? "Other" : "Justification"}
                                                                    className="w-full"
                                                                    disabled={isFieldLocked(params, 'Justification') || !( params?.ReasonAlert?.toUpperCase() === 'BOLO ALERT' || (params?.ReasonType === 'ALERT' && params?.Reason?.toUpperCase()==='LAW ENFORCEMENT PURPOSE' && params?.PurposeSecondary?.toUpperCase()?.includes('OTHER')))}
                                                                    value={params?.Justification}
                                                                    onChange={e => setParams({ ...params, Justification: e.target.value })}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className="grid grid-cols-2 mt-5 gap-y-5">
                                                    <div className="w-full pr-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'StartDate')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="Start Date"
                                                            className="w-full"
                                                            type="date"
                                                            value={params?.StartDate?.substring(0,10)}
                                                            onChange={e => setParams({ ...params, StartDate: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full pl-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'ExpiryDate')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="Expiry Date"
                                                            className="w-full"
                                                            type="date"
                                                            value={params?.ExpiryDate?.substring(0,10)}
                                                            onChange={e => handleMaxExpireTime(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="w-full pr-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'NICNum')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="NIC #"
                                                            className="w-full"
                                                            value={params?.NICNum}
                                                            onChange={e => setParams({ ...params, NICNum: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full ml-5">
                                                        <FormControlLabel
                                                            disabled={isFieldLocked(params, 'ConfidentialPOI')}
                                                            control={<Checkbox checked={params?.ConfidentialPOI} onChange = {e => setParams({ ...params, ConfidentialPOI: !params?.ConfidentialPOI })} sx={{'& .MuiSvgIcon-root': {fontSize: 32}}}/>}
                                                            label="Confidential POI"
                                                        />
                                                        {enableNJCAP5 && params &&
                                                            <Tooltip title="Select this box if the vehicle is suspected of being involved in the commission of a crime. Then select one or multiple crimes. Note: A high-priority designation will be added to this POI." placement={"top"}>
                                                                <FormControlLabel
                                                                    disabled={isFieldLocked(params, 'FelonyVehicle')}
                                                                    control={<Checkbox checked={params?.FelonyVehicle} onChange = {e => setParams({ ...params, FelonyVehicle: !params?.FelonyVehicle, FelonyVehicleReason : '' })} sx={{'& .MuiSvgIcon-root': {fontSize: 32}}}/>}
                                                                    label="Felony Vehicle"
                                                                />
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                    {params?.FelonyVehicle && params && enableNJCAP5 &&
                                                        <div className="w-full col-span-2">
                                                            <FormControl fullWidth>
                                                                <InputLabel id="FelonyReasonID">Felony Vehicle Reason</InputLabel>
                                                                <Tooltip title="Select one or multiple crimes. Note: A high-priority designation will be added to this POI." placement={"top"}>
                                                                    <Select
                                                                        disabled={isFieldLocked(params, 'FelonyVehicleReason')}
                                                                        multiple
                                                                        size="small"
                                                                        labelId="FelonyReasonID"
                                                                        label="Felony Vehicle Reason"
                                                                        className="w-full"
                                                                        value={params?.FelonyVehicleReason? params?.FelonyVehicleReason?.split(','): []}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        onChange={e => {setParams({ ...params, FelonyVehicleReason: (e.target.value as string[])?.join(',',) }); } }
                                                                    >
                                                                        {params.FelonyVehicleReasonList && params?.FelonyVehicleReasonList.map((reason) => (
                                                                            <MenuItem value={reason.Key}>
                                                                                <Checkbox checked={((params.FelonyVehicleReason && params.FelonyVehicleReason.indexOf(reason.Key) > -1) as boolean)} />
                                                                                {reason.Value}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </Tooltip>
                                                            </FormControl>
                                                        </div>
                                                    }
                                                </div>
                                            </Disclosure.Panel>
                                        </CardContent>
                                    </Card>
                                </>
                            )}
                        </Disclosure>
                        <Disclosure defaultOpen={true}>
                            {({ open }) => (
                                <>
                                    <Card  sx={{ maxWidth: 900 , marginTop: 2 }}>
                                        <CardContent>
                                            <Disclosure.Button className="w-auto w-full text-left">
                                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                <span>
                                                    <KeyboardArrowDownIcon
                                                        className={`${open ? "transform rotate-180" : ""}`}
                                                        style = {{fontSize : "2em"}}
                                                    />
                                                    <span className = "text-blue-400 font-bold text-xl " > Vehicle Information </span>
                                                </span>
                                            </span>
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="text-gray-500 p-4">
                                                <div className = "grid grid-cols-2 mt-5 gap-y-5">
                                                    <div className="w-full pr-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'PlateNumber')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="Plate #1"
                                                            className="w-full"
                                                            value={params?.PlateNumber}
                                                            onChange={e => setParams({ ...params, PlateNumber: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full pl-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'PlateSt')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="State #1"
                                                            inputProps={{ maxLength: 2 }}
                                                            placeholder = "NJ, PA, CA, DC"
                                                            className="w-full"
                                                            value={params?.PlateSt}
                                                            onChange={e => setParams({ ...params, PlateSt: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full pr-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'PlateNumber2')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="Plate #2"
                                                            className="w-full"
                                                            value={params?.PlateNumber2}
                                                            onChange={e => setParams({ ...params, PlateNumber2: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full pl-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'PlateSt2')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="State #2"
                                                            inputProps={{ maxLength: 2 }}
                                                            placeholder = "NJ, PA, CA, DC"
                                                            className="w-full"
                                                            value={params?.PlateSt2}
                                                            onChange={e => setParams({ ...params, PlateSt2: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full pr-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'PlateNumber3')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="Plate #3"
                                                            className="w-full"
                                                            value={params?.PlateNumber3}
                                                            onChange={e => setParams({ ...params, PlateNumber3: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full pl-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'PlateSt3')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="State #3"
                                                            inputProps={{ maxLength: 2 }}
                                                            placeholder = "NJ, PA, CA, DC"
                                                            className="w-full"
                                                            value={params?.PlateSt3}
                                                            onChange={e => setParams({ ...params, PlateSt3: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className = "grid grid-cols-1 mt-5 gap-y-5">
                                                    <div className="w-full">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'VIN')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="VIN"
                                                            className="w-full"
                                                            value={params?.VIN}
                                                            onChange={e => setParams({ ...params, VIN: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </Disclosure.Panel>
                                        </CardContent>
                                    </Card>
                                </>
                            )}
                        </Disclosure>
                        <Disclosure defaultOpen={true}>
                            {({ open }) => (
                                <>
                                    <Card  sx={{ maxWidth: 900 , marginTop: 2 }}>
                                        <CardContent>
                                            <Disclosure.Button className="w-auto w-full text-left">
                                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                <span>
                                                    <KeyboardArrowDownIcon
                                                        className={`${open ? "transform rotate-180" : ""}`}
                                                        style = {{fontSize : "2em"}}
                                                    />
                                                    <span className = "text-blue-400 font-bold text-xl " > Suspect Information </span>
                                                </span>
                                            </span>
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="text-gray-500 p-4">
                                                <div className = "grid grid-cols-3 mt-5 gap-y-5">
                                                    <div className="w-full pr-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'FName')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="First Name"
                                                            className="w-full"
                                                            value={params?.FName}
                                                            onChange={e => setParams({ ...params, FName: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full pr-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'MName')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="Middle Initial"
                                                            className="w-full"
                                                            value={params?.MName}
                                                            onChange={e => setParams({ ...params, MName: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'LName')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="Last Name"
                                                            className="w-full"
                                                            value={params?.LName}
                                                            onChange={e => setParams({ ...params, LName: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full pr-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'DOB')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="DOB"
                                                            className="w-full"
                                                            type={"date"}
                                                            value={params?.DOB?.substring(0,10)}
                                                            onChange={e => setParams({ ...params, DOB: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full pr-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'DLNo')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="DL #"
                                                            className="w-full"
                                                            value={params?.DLNo}
                                                            onChange={e => setParams({ ...params, DLNo: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'DLSt')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="DL State"
                                                            inputProps={{ maxLength: 2 }}
                                                            placeholder = "NJ, PA, CA, DC"
                                                            className="w-full"
                                                            value={params?.DLSt}
                                                            onChange={e => setParams({ ...params, DLSt: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full pr-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'SSN')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="SSN #"
                                                            className="w-full"
                                                            value={params?.SSN}
                                                            onChange={e => setParams({ ...params, SSN: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full pr-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'FBINo')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label="FBI #"
                                                            className="w-full"
                                                            value={params?.FBINo}
                                                            onChange={e => setParams({ ...params, FBINo: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'SBINo')}
                                                            size="small"
                                                            InputLabelProps={{'shrink':true}}
                                                            label={GetStringNameForSite(SiteName,"SBI #")}
                                                            className="w-full"
                                                            value={params?.SBINo}
                                                            onChange={e => setParams({ ...params, SBINo: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </Disclosure.Panel>
                                        </CardContent>
                                    </Card>
                                </>
                            )}
                        </Disclosure>
                        <Disclosure defaultOpen={true}>
                            {({ open }) => (
                                <>
                                    <Card  sx={{ maxWidth: 900 , marginTop: 2 }}>
                                        <CardContent>
                                            <Disclosure.Button className="w-auto w-full text-left">
                                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                <span>
                                                    <KeyboardArrowDownIcon
                                                        className={`${open ? "transform rotate-180" : ""}`}
                                                        style = {{fontSize : "2em"}}
                                                    />
                                                    <span className = "text-blue-400 font-bold text-xl " > Notes </span>
                                                </span>
                                            </span>
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="text-gray-500 p-4">
                                                <div className = "grid grid-cols-1 mt-5 gap-y-5">
                                                    <div className="w-full">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'SearchNotes')}
                                                            size="small"
                                                            multiline
                                                            minRows={4}
                                                            InputLabelProps={{'shrink':true}}
                                                            className="w-full"
                                                            label="Comments"
                                                            value={params?.SearchNotes}
                                                            onChange={e => setParams({ ...params, SearchNotes: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-3 mt-5 gap-y-5">
                                                    <div className="w-full pr-5">
                                                        <FormControl fullWidth color={supervisorError.length > 0 ? "error" : "primary"} focused={supervisorError.length > 0 ? true : undefined}>
                                                            {supervisorError.length > 0 ? <InputLabel id="AccountTypeLevel">{supervisorError}</InputLabel> : <InputLabel id="SupervisorID">Supervisor</InputLabel>}
                                                            <Select
                                                                disabled={isFieldLocked(params, 'AuthorizedBy') || params.AuthorizedByList && params.AuthorizedByList?.length === 1 ? true : false}
                                                                size="small"
                                                                labelId="SupervisorID"
                                                                label={supervisorError.length > 0 ? supervisorError : "Supervisor"}
                                                                className="w-full"
                                                                value={params?.AuthorizedBy}
                                                                onChange={e => setParams({ ...params, AuthorizedBy: e.target.value })}
                                                            >
                                                                {params.AuthorizedByList && params?.AuthorizedByList.map((supervisor) => (
                                                                    <MenuItem value={supervisor.Key}>{supervisor.Value}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="w-full pr-5">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'CaseNo')}
                                                            size="small"
                                                            InputLabelProps={{ 'shrink': true }}
                                                            label={GetStringNameForSite(SiteName,"Case #")}
                                                            placeholder = "20 Character Limit"
                                                            inputProps={{ maxLength: 20 }}
                                                            className="w-full"
                                                            value={params?.CaseNo}
                                                            onChange={e => setParams({ ...params, CaseNo: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <FormControl fullWidth>
                                                            <InputLabel id="AlertOptionID">Alert Option</InputLabel>
                                                            <Select
                                                                disabled={isFieldLocked(params, 'AlertOption')}
                                                                size="small"
                                                                labelId="AlertOptionID"
                                                                label="Alert Option"
                                                                className="w-full"
                                                                value={params?.AlertOption}
                                                                onChange={e => setParams({ ...params, AlertOption: e.target.value })}
                                                            >
                                                                {params.AlertOptionList && params?.AlertOptionList.map((alert) => (
                                                                    <MenuItem value={alert.Key}>{alert.Value}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                <div className = "grid grid-cols-1 mt-5 gap-y-5">
                                                    <div className="w-full">
                                                        <TextField
                                                            disabled={isFieldLocked(params, 'AdditionalEmail')}
                                                            size="small"
                                                            multiline
                                                            className="w-full"
                                                            label="Notification Emails"
                                                            placeholder="emails seperated by , or ;"
                                                            value={params?.AdditionalEmail}
                                                            onChange={e => setParams({ ...params, AdditionalEmail: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </Disclosure.Panel>
                                        </CardContent>
                                    </Card>
                                </>
                            )}
                        </Disclosure>
                        {(SiteName==SITE.NJPROD || SiteName==SITE.NJUAT || SiteName==SITE.NJDEMO || SiteName==SITE.LOCAL) &&
                            <Card  sx={{ maxWidth: 900, marginTop: 2 }}>
                                <CardContent>
                                    <span className = "text-blue-400 font-bold text-xl" >Acceptance Agreement</span>
                                    <h1 className = "mt-2 font-normal text-3xl">Criteria for and Examples of Legitimate BOLO Situations</h1>
                                    <div className = "mt-2">
                                        {enableNJCAP5 ?
                                            "A license plate number or partial license plate number shall not be included in an ALPR BOLO list unless there is a legitimate and specific law enforcement reason to identify, locate or ascertain the past location(s) of that particular vehicle, or any person or persons who are reasonably believed to be associated with that vehicle."
                                            : "A license plate number or partial license plate number shall not be included in an ALPR initial BOLO list unless there is a legitimate and specific law enforcement reason to identify or locate that particular vehicle, or any person or persons who are reasonably believed to be associated with that vehicle. A license plate or partial license plate number shall not be included in a Post-Scan BOLO list unless there is a legitimate and specific law enforcement reason to ascertain the past locations(s) of that particular vehicle, or of any person or persons who are reasonably believed to be associated with that vehicle."}
                                        <br />
                                        <br />
                                        Examples of legitimate and specific reasons include, but are not limited to: persons who are subject to an outstanding arrest warrant; missing persons; AMBER Alerts; stolen vehicles; vehicles that are reasonably believed to be involved in the commission of a crime or disorderly persons offense; vehicles that are registered to or are reasonably believed to be operated by persons who do not have a valid operator’s license or who are on the revoked or suspended list; vehicles with expired registrations or other Title 39 violations; persons who are subject to a restraining order or curfew issued by a court or by the Parole Board, or who are subject to any other duly issued order restricting their movements; persons wanted by a law enforcement agency who are of interest in a specific investigation, whether or not such persons are themselves suspected of criminal activity; and persons who are on any watch list issued by a State or federal agency responsible for homeland security.
                                    </div>
                                </CardContent>
                            </Card>
                        }
                        {submitError && submitError?.length > 0 ?
                            <span style={{ color: "red", fontSize:14}}>
                           {submitError.split(",").map((error)=> <div> <ErrorIcon style={{color:"red"}} fontSize = 'small' />{error}</div>)}
                        </span>
                            : <></>}
                        <div className="mt-8 flex justify-center">
                            <ButtonGroup variant="outlined" size={"large"} color={"inherit"} >
                                <Button disabled={isSaving} onClick = {submitPOI}>Save{(isSaving) ? <CircularProgress size={20} color="inherit" style={{"marginLeft" : "5px"}} /> : <></>}</Button>
                                <Button onClick = {CloseBox}>CANCEL</Button>
                            </ButtonGroup>
                        </div>
                    </CardContent>
                </Card>
                : <></>)}
        </div>
    );
}

export default UpdatePOI;