import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React, {useState} from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {TablePagination, CircularProgress, Tooltip, Button, Box, IconButton} from "@mui/material";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter, SortSettingsModel,
} from '@syncfusion/ej2-react-grids';
import {JAOS} from "../../../services/JAOS.service";
import {Account} from "../../../interfaces/auth_interface";
import { getUser } from "../../../services/auth.service";
import BTPersonIndicatorGrid from "../_PersonIndicators";
import MainRecordIcon from "../../../assets/Images/MainRecord.png";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import {Person_DeleteSupplementalFile, Person_GetSupplementalFile} from "../../../services/getDar.service";
interface PanelPanelSupplementProps {
    person: PersonInfo,
    files: any[],
    isLoading:boolean,
    reloadTrigger: any
}

const  PanelSupplement: React.FC<PanelPanelSupplementProps> = ({person,files,isLoading,reloadTrigger})=> {
    let [user, setUser] = useState<Account>(getUser())

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'FName', direction: 'Ascending' }
        ] };

    const DownloadFile = async (record: any) => {
        Person_GetSupplementalFile(record).then(response => {
            console.log(response)
            let dL = document.createElement('a');
            dL.href = `data:${response.FileEncoding};base64,${response.Content}`;
            dL.download = response.FileName;
            dL.click();


        }).catch( e => {
            console.log(e)
        })
    }

    const DeleteFile = async (record: any) => {
        if(window.confirm(`Are you sure you want to delete ${record.FileName}?`))
        {
            Person_DeleteSupplementalFile(record).then(response => {
                window.alert('Deletion request has been submitted.')
                reloadTrigger()
            }).catch( e => {
                console.log(e)
            })
        }
    }
    
    return(
        <div className={"m-5 border"}>
            <Disclosure >
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }} >
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                        <span className={"font-bold"}>
                                            Person Supplemental <svg style={{height:25, display:'inline', color:'black'}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"/></svg>
                                            {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}
                                        </span>
                                </span>
                                <span
                                    className={"font-bold mr-2"}>Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records:  {files?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={files}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                sortSettings={sortSettings}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='FileName' headerText='File Name'/>
                                    <ColumnDirective field='FileCategory' headerText='Title'/>
                                    <ColumnDirective field='FileDescription' headerText='Description'/>
                                    <ColumnDirective field='CreateUser' headerText='Added By'/>
                                    <ColumnDirective template={(item: any) => { return(
                                        <>
                                            <Tooltip title="Download File" placement="top" arrow>
                                                <IconButton size={"small"} style={{ color: "gray" }}  onClick={()=> {DownloadFile(item)}}>
                                                    <DownloadIcon style={{ height: 22, width: 22, marginRight: 4 }}/>
                                                </IconButton>
                                            </Tooltip>

                                            {item.CreateUser === user?.EmpID ? <Tooltip title="Delete File" placement="top" arrow>
                                                <IconButton size={"small"} style={{ color: "gray" }}  onClick={()=> {DeleteFile(item)}}>
                                                    <DeleteOutlineIcon style={{ height: 22, width: 22, marginRight: 4 }} />
                                                </IconButton>
                                            </Tooltip> : <></>}
                                        </>
                                    )}} headerText='Action' />

                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    )
};

export default PanelSupplement;