import React, { forwardRef } from "react";

import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import Slide, { SlideProps } from '@mui/material/Slide';
import { useHistory } from "react-router-dom";
import { SiteName } from "../../../services/config.service";
import { GetDefaultStateForSite, GetStringNameForSite } from "../../../interfaces/config.interface";
import { getDropDownCity, getDropDownQuery } from "../../../services/getDropdown.service";
type TransitionProps = Omit<SlideProps, 'direction'>;

interface PreliminaryReportSearchProps {
    SubmitSearch: any,
    passedData?: any | null,
    RefreshTrigger:any

}

interface PreliminaryReportSearchRef {
    handleSubmit: any
}

const PreliminaryReportSearch = forwardRef<PreliminaryReportSearchRef, PreliminaryReportSearchProps>(({ SubmitSearch,RefreshTrigger, passedData = null }, ref) => {
    const [params, setParams] = React.useState({
        toDate: "", fromDate: "", ccn: "", reportNum: "", firstName: "", lastName: "", psa: "", pdid: "", incidentType: "", location: "",
        fbi: "", plateNum: "", dlNumber: "", state: "", SystemCall: false
    });
    const [dateType, setDateType] = React.useState<string>("Entry Date");
    const [statusOptions, setStatusOptions] = React.useState<string[]>(["In Progress", "Complete"]);
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<boolean>(false);
    const [showALPR, setShowALPR] = React.useState<boolean>(false)
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    const [stateList, setStateList] = React.useState<any>([]);
    const [cityList, setCityList] = React.useState<any>([]);

    const history = useHistory();



    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const setStatusValue = (option: string) => {
        switch (option) {
            case 'In Progress':
                option = 'InProgress';
                break;
            case 'For Review':
                option = 'ForReview';
                break;
            default: break;
        }

        return option;
    }

    const handleInitialBuild = async () => {
        await handleStateDropdown()
        let p = { ...params }
        p.SystemCall = true
        SubmitSearch(p, dateType, andOrType, dropDownParams)
    }

    const handleStateDropdown = async () => {
        setStateList([])
        var state = await getDropDownQuery('State')
        if (state && state?.length > 0) {
            setStateList(state)
        }
        var city = await getDropDownCity(GetDefaultStateForSite(SiteName))
        let tempList = city?.filter(x => x.State === GetDefaultStateForSite(SiteName))
        const ids = tempList.map(({ City }) => City);
        const filtered = tempList.filter(({ City }, index) =>
            !ids.includes(City, index + 1));
        setCityList(filtered)
    }

    React.useEffect(() => {
        handleInitialBuild()
    }, [passedData,RefreshTrigger])

    React.useImperativeHandle(ref, () => ({
        handleSubmit: () => handleClear(),
    }))

    const handleClear = () => {
        setParams({
            ...params, toDate: "", fromDate: "", ccn: "", reportNum: "", firstName: "", lastName: "", psa: "", pdid: "", incidentType: "", location: "",
            fbi: "", plateNum: "", dlNumber: "", state: "", SystemCall: false
        })
        setDateType("Entry Date")
        setAndOrType("AND")
        setShowALPR(false)
        setClear(true)
        history.push('/tools/preliminary_report');
    }

    const handleSearch = () => {
        SubmitSearch(params, dateType, andOrType, dropDownParams)
    }

    return (
        <div className="bg-gray-100 mt-5 ml-5 mr-5 p-5">
            <Box className="pt-3" sx={{ pl: 2 }}>
                <Grid container spacing={1} sx={{pl: 2} }>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={dateType}
                            exclusive
                            fullWidth
                            onChange={handleDateType}
                        >
                            <ToggleButton value="Report Date">Report Date</ToggleButton>
                            <ToggleButton value="Entry Date">Entry Date</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' type="date" label="From Date" InputLabelProps={{ shrink: true }} value={params.fromDate} onChange={(e: any) => setParams({ ...params, fromDate: e.target.value.toLocaleString()})} />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' type="date" label="To Date" InputLabelProps={{ shrink: true }} value={params.toDate} onChange={(e: any) => setParams({ ...params, toDate: e.target.value.toLocaleString()}) } />
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="Incident Type" value={params.incidentType} onChange={(e: any) => setParams({ ...params, incidentType: e.target.value}) } />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="Location" value={params.location} onChange={(e: any) => setParams({ ...params, location: e.target.value}) } />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="PSA" value={params.psa} onChange={(e: any) => setParams({ ...params, psa: e.target.value}) } />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="CCN" value={params.ccn} onChange={(e: any) => setParams({ ...params, ccn: e.target.value }) } />
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="First Name" value={params.firstName} onChange={(e: any) => setParams({ ...params, firstName: e.target.value })} />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="Last Name" value={params.lastName} onChange={(e: any) => setParams({ ...params, lastName: e.target.value })} />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="PDID" value={params.pdid} onChange={(e: any) => setParams({ ...params, pdid: e.target.value}) } />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="FBI" value={params.fbi} onChange={(e: any) => setParams({ ...params, fbi: e.target.value}) } />
                    </Grid>
                    {/*<Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="Report Number" value={params.reportNum} onChange={(e: any) => setParams({ ...params, reportNum: e.target.value })} />
                    </Grid>*/}
                </Grid>
                {/*<Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
                    <Grid item xs={2}></Grid>
                    
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="DL Number" value={params.dlNumber} onChange={(e: any) => setParams({ ...params, dlNumber: e.target.value })} />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <Autocomplete
                            id="state-list"
                            autoComplete={true}
                            sx={{ width: '100%', textAlign: "center", justifyContent: "center" }}
                            options={stateList.map((option: any) => option.Key)}
                            value={params.state}
                            onChange={(event, newValue) => {
                                setParams((prevState : any) => ({
                                    ...prevState,
                                    state: newValue
                                }));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="DL State"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        ...params.InputProps,
                                        
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                        <TextField fullWidth margin='none' label="Plate Number" value={params.plateNum} onChange={(e: any) => setParams({ ...params, plateNum: e.target.value}) } />
                    </Grid>
                </Grid>*/}
                <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
                    <Grid item xs={8} sx={{ pr: 2 }}></Grid>
                    <Grid item xs={2} sx={{ pr: 2 }}>
                    <ButtonGroup fullWidth size="large" color="inherit">
                        <Button type="submit" onClick={handleSearch}>Search</Button>
                        <Button onClick={handleClear}>CLEAR</Button>
                    </ButtonGroup>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
})

export default PreliminaryReportSearch;