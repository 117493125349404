import { TSC, TSCFiles } from "../interfaces/TSC.interface";
import {AxiosResponse} from "axios";
import {PersonInfo, getDarInfoResponse} from "../interfaces/getDarInfo_interface";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";

export function GetGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
}

export async function SetTSCReport(report: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/TSC/SetTSC', report)
    return (res.data)
}

export async function GetTSCPDF(id: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.get('/api/TSC/GetTSCPDF?IDs=' + id, { responseType: "blob" })
    if (res && res != null) {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', 'TSC.pdf');
        document.body.appendChild(link);
        link.click();
    }
}

export async function GetTSCZIP(id: any): Promise<any> {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.get('/api/TSC/GetTSCZip?IDs=' + id, { responseType: "blob" });

        if (res && res.data) {
            const blob = new Blob([res.data], { type: 'application/zip' });
            const fileSize = blob.size;

            if (fileSize > 50) {
                // Extract filename from Content-Disposition header
                const contentDisposition = res.headers['content-disposition'];
                console.log('Content-Disposition Header:', contentDisposition);

                let fileName = 'TSC_files.zip'; // Default file name

                if (contentDisposition) {
                    // Match `filename*` first, otherwise match `filename`
                    let fileNameMatch = contentDisposition.match(/filename\*\s*=\s*UTF-8''([^;]+)/i);
                    if (!fileNameMatch) {
                        fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/i);
                    }
                    if (fileNameMatch && fileNameMatch.length > 1) {
                        fileName = decodeURIComponent(fileNameMatch[1]);
                    }
                }

                // Create a link element, trigger download, then remove the link element
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); // Use the correct filename from the header
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                window.alert('There are no attachments to download.');
            }
        }
    }
    catch (error) {
        console.error('Failed to download ZIP file', error);
    }
}


export async function GetTSCReport(ID: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.get('/api/TSC/GetTSC?IDs='+ID)
    return (res.data)
}

export async function GetTSCPOB(personDetails: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/TSC/GetTSCPOB', {
        params: personDetails
    })
    return (res.data)
}

export async function SetTSCFile(fileObj: TSCFiles): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/TSC/SetTSCFile', fileObj)
    return (res.data)
}


export async function GetTSCContent(fileObj: TSCFiles): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/TSC/TSCFileContent', fileObj)
    return (res.data)
}
