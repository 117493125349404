import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    Alert,
    Autocomplete,
    IconButton,
    Snackbar,
    Tooltip, FormGroup,
    FormLabel, RadioGroup, Radio,
    TextField, InputLabel, Select, MenuItem
} from "@mui/material";
import React, { useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { PersonMainRecord, PersonFileOrImage } from "../../interfaces/UpdatePersonMainRecord.interface";
import { RunGetPersonMainRecord, RunSetPersonMainRecord } from "../../services/account.service";
import { JAOS } from "../../services/JAOS.service";
import { CardActionArea } from "@mui/material";
//import TimeField from 'react-simple-timefield';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DownloadIcon from '@mui/icons-material/Download';
import { base64_to_blob } from "../../services/detail.service";
import UndoIcon from '@mui/icons-material/Undo';
import { GetFileContent } from "../../services/getDar.service";
import {SiteName} from "../../services/config.service";
import {GetStringNameForSite, SITE} from "../../interfaces/config.interface";

interface UpdatePersonMainRecordProps {
    ID: string | null
    Hash: string | null
    //handleSubmit: any,
    //handleCancel: any,
    //isEdit: boolean,
    person?: any
}

const UpdatePersonMainRecord: React.FC<UpdatePersonMainRecordProps> = ({ ID, Hash = null, person = null }) => {
    const [params, setParams] = React.useState<PersonMainRecord>({})
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<String>('')
    const [error, setError] = React.useState<string>("")
    const [validation, setValidation] = React.useState<string>("")
    const [addedFiles, setAddedFiles] = React.useState<PersonFileOrImage[]>([])
    const [existingFiles, setExistingFiles] = React.useState<PersonFileOrImage[]>([])

    const LoadUpdatePersonMainData = async () => {
        try {
            let obj = new JAOS()
            let res = obj.objFromStack(Hash ? Hash : "")
            res.ETicket = 'EditMainRecord';  //Differentiate user click from PaersonSummary -> FBI -> Edit
            let r = await RunGetPersonMainRecord(res)
            await setParams(r)
            await setExistingFiles(r?.FileOrImages)
        }
        catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {
        if (Hash !== null) {
            LoadUpdatePersonMainData()
        }
    }, [Hash]);

    const handleInitialBuild = async () => {
        person = params.Main
        let f = params.FileOrImages ? [...params.FileOrImages] : [] //Grab all existng files
        setExistingFiles(f);
        await handleDropdowns()
    }

    const handleDropdowns = async () => {
        //setStateList([])
        //var state = await getDropDownQuery('State')
        //if (state && state?.length > 0) {
        //    setStateList(state)
        //}
    }

    const handleUpdatePersonMainCancel = () => {
        setValidation("");
        setError("");
        setAlertText("Please close the browser Tab")
        setAlertOpen(true)
    }

    const handleValidation = () => {
        let p = { ...params }
        let m = p.Main
        let res = false
        //FIRSTNAME, LASTNAME, DOB
        let b1 = false

        ////let bValid: boolean;
        ////bValid = true;
        ////if (!m?.FIRSTNAME || m?.FIRSTNAME?.length < 1) {
        ////    bValid = bValid && false;
        ////}

        if (m != undefined) {
            let b1 = false
            if ((m.FIRSTNAME?.length !== 0) &&
                (m.FIRSTNAME?.length !== undefined) &&
                (m.LASTNAME?.length !== 0) &&
                (m.LASTNAME?.length !== undefined)) {
                if ((m.DOB?.length !== 0) &&
                    (m.DOB?.length !== undefined)) {
                    b1 = true;
                }
                else {
                    setAlertText("Please enter a valid DOB")
                }
            }
            else {
                setAlertText("Please enter First Name and Last Name")
            }
            //DLNo, DLState
            let b2 = false
            if (m.DLNo?.length !== 0 &&
                m.DLNo?.length !== undefined &&
                m.DLState?.length !== 0 &&
                m.DLState?.length !== undefined) {
                b2 = true;
            }
            else {
                setAlertText("Please enter a valid DL Number and DL State")
            }
            //SBINo, SBIState
            let b3 = false
            if (m.SBINo?.length !== 0 &&
                m.SBINo?.length !== undefined &&
                m.SBIState?.length !== 0 &&
                m.SBIState?.length !== undefined) {
                b3 = true;
            }
            else {
                setAlertText("Please enter a valid SBI Number and SBI State")
            }
            //FBI
            let b4 = false
            if (m.FBI?.length !== 0 &&
                m.FBI?.length !== undefined) {
                b4 = true;
            }
            else {
                setAlertText("Please enter a valid FBI")
            }
            //SSN
            let b5 = false
            if (m.SSN?.length !== 0 &&
                m.SSN?.length !== undefined) {
                b5 = true;
            }
            else {
                setAlertText("Please enter a valid SSN")
            }
            //Sex
            let b6 = false
            if (m.Sex?.length !== 0 &&
                m.Sex?.length !== undefined) {
                b6 = true;
            }
            else {
                setAlertText("Please enter a valid Sex")
            }
            //CITY
            let b7 = false
            if (m.CITY?.length !== 0 &&
                m.CITY?.length !== undefined) {
                b7 = true;
            }
            else {
                setAlertText("Please enter a valid City")
            }
            //COUNTY
            let b8 = false
            if (m.COUNTY?.length !== 0 &&
                m.COUNTY?.length !== undefined) {
                b8 = true;
            }
            else {
                setAlertText("Please enter a valid County")
            }
            //STATE
            let b9 = false
            if (m.STATE?.length !== 0 &&
                m.STATE?.length !== undefined) {
                b9 = true;
            }
            else {
                setAlertText("Please enter a valid State")
            }
            //ZIP
            let b10 = false
            if (m.ZIP?.length !== 0 &&
                m.ZIP?.length !== undefined) {
                b10 = true;
            }
            else {
                setAlertText("Please enter a valid Zip")
            }
            //STREETNo
            let b11 = false
            if (m.STREETNo?.length !== 0 &&
                m.STREETNo?.length !== undefined) {
                b9 = true;
            }
            else {
                setAlertText("Please enter a valid Street No")
            }
            //STREETNAME
            let b12 = false
            if (m.STREETNAME?.length !== 0 &&
                m.STREETNAME?.length !== undefined) {
                b12 = true;
            }
            else {
                setAlertText("Please enter a valid Street Name")
            }
            //Notes
            let b13 = false
            if (m.Notes?.length !== 0 &&
                m.Notes?.length !== undefined) {
                b13 = true;
            }
            else {
                setAlertText("Please enter a valid Note")
            }
            res = b1 && b13 && (b2 || b3 || b4 || b5 || b6 || b7 || b8 || b9 || b10 || b11 || b12);
        }

        if (!res) {
            setAlertOpen(true)
        }
        return res
    }

    const handleAdditionalFiles = (e: any) => {
        let files = e.files
        for (var i = 0; i < files.length; i++) {
            handleChangeFile(files[i])
        }
    };

    const handleChangeFile = (file: any) => {
        let fileData = new FileReader();
        fileData.onloadend = (e) => handleFile(e, file);
        fileData.readAsDataURL(file);
    }

    const handleFile = (e: any, file: any) => {
        let f = addedFiles ? [...addedFiles] : [] //Grab all newly added files
        let ex = existingFiles ? [...existingFiles] : [] //Grab all existingFiles (From Edit)
        let fIndex = f.findIndex((fil: any) => fil.FileName === file.name) //if file.name does not exist in list returns -1
        let exIndex = ex.findIndex((fil: any) => fil.FileName === file.name) //if file.name does not exist in list returns -1
        if (fIndex === -1 && exIndex === -1) //if is not already in list
        {
            f.push({
                Content: e.target.result.split('base64,')[1],
                FileName: file.name,
                FileEncoding: file.type
            })
        }
        else {
            let name = file.name.split('.') //grabs NAME part of file temp.jpg -> [temp, jpg]
            let x = f.filter((res) => res.FileName?.includes(name[0]))?.length
            let y = ex.filter((res) => res.FileName?.includes(name[0]))?.length
            let fileName = name[0] + '(' + (x + y) + ')' //y > x ? name[0] + '(' + y + ')' : name[0] + '(' + x + ')'
            f.push({
                Content: e.target.result.split('base64,')[1],
                FileName: fileName + (name?.length > 1 ? '.' + name[1] : ''),
                FileEncoding: file.type
            })
        }
        setAddedFiles(f)
    }

    const removeFile = (file: any, index: number) => {
        let temp = []
        if (!file.ID) {
            temp = [...addedFiles]
            temp.splice(index, 1)
            setAddedFiles(temp)
        }
        else {
            temp = [...existingFiles]
            temp[index].Deleted = true
            setExistingFiles(temp)
        }
    }

    const reinstateFile = (file: any, index: number) => {
        let temp: any[] = []
        if (file.ID) {
            temp = [...existingFiles]
            temp[index].Deleted = false
            setExistingFiles(temp)
        }
    }

    const downloadFile = async (file: any, index: number) => {
        let temp = []
        if (file.Content != null) {
            await base64_to_blob(file.Content, file.FileEncoding).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
        else if (file.ID) {
            temp = [...existingFiles]
            await GetFileContent(temp[index])
        }
    }

    const handleValueChange = async (field: string, value: any) => {
        //SET UP OBJECT IF IT DOESNT EXIST
        let p = { ...params }
        let m = p.Main
        if (m != undefined) {
            switch (field) {
                case 'Last Name': m.LASTNAME = value; break;
                case 'First Name': m.FIRSTNAME = value; break;
                case 'MI': m.MI = value; break;
                case 'DOB': m.DOB = value; break;
                case 'Sex': m.Sex = value; break;
                //
                case 'SSN': m.SSN = value; break;
                case 'FBI': m.FBI = value; break;
                case 'SBI No': m.SBINo = value; break;
                case 'SBI State': m.SBIState = value; break;
                case 'DL No': m.DLNo = value; break;
                case 'DL State': m.DLState = value; break;
                //
                case 'Street No': m.STREETNo = value; break;
                case 'Street Name': m.STREETNAME = value; break;
                case 'City': m.CITY = value; break;
                case 'County': m.COUNTY = value; break;
                case 'State': m.STATE = value; break;
                case 'Zip': m.ZIP = value; break;
                //
                case 'Notes': m.Notes = value; break;
                //
                case 'PlateNo': m.PlateNo = value; break;
                case 'PlateSt': m.PlateSt = value; break;
            }
        }
        p.Main = m
        //p.FileOrImages = existingFiles;
        setParams(p)
    }

    const handleUpdatePersonMainSave = async () => {
        if (handleValidation()) {
            //params.FileOrImages = existingFiles;
            let added = [...addedFiles]
            let existing = existingFiles ? [...existingFiles] : [] //Grab all existingFiles (From Edit)
            params.FileOrImages = existing.concat(added)
            let r = await RunSetPersonMainRecord(params)
            if (r?.IsValid) {
                setValidation("");
                setError("");
                setAlertText("Person Record is Updated. Please close the browser Tab")
                setAlertOpen(true)
            }
            else {
                let errors = r.ErrorMessage?.join(",");
                setError(errors)
            }
        }
    }

    return (
        <div className=" border-2 border-gray-600 mx-4 mt-4 mb-4">
            <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" >
                <CardContent>
                    <span className="text-blue-400 font-bold text-xl ">Person Information</span>
                    <br/>
                    <div className="grid grid-cols-3 mt-5">
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"First Name"}
                                value={params?.Main?.FIRSTNAME}
                                onChange={(e) => handleValueChange('First Name', e.target.value)}
                                error={params?.Main?.FIRSTNAME && params?.Main?.FIRSTNAME?.length > 0 ? false : true}
                            />
                        </div>
                        <div className="w-full pr-10 pl-10">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label="Middle Initial"
                                value={params?.Main?.MI}
                                onChange={(e) => handleValueChange('MI', e.target.value)}
                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"Last Name"}
                                value={params?.Main?.LASTNAME}
                                onChange={(e) => handleValueChange('Last Name', e.target.value)}
                                error={params?.Main?.LASTNAME && params?.Main?.LASTNAME?.length > 0 ? false : true}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 mt-10 gap-y-5">
                        <div className="w-full pr-5">
                            <TextField
                                size="small"
                                type={"date"}
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"DOB"}
                                value={params?.Main?.DOB?.substring(0, 10)}
                                onChange={(e) => handleValueChange('DOB', e.target.value)}
                                error={params?.Main?.DOB && params?.Main?.DOB?.length > 0 ? false : true}
                            />
                        </div>
                        <div className="w-full pl-5">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"Sex"}
                                value={params?.Main?.Sex}
                                onChange={(e) => handleValueChange('Sex', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 mt-10 gap-y-5">
                        <div className="w-full pr-5">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"DL #"}
                                value={params?.Main?.DLNo}
                                onChange={(e) => handleValueChange('DL No', e.target.value)}
                            />
                        </div>
                        <div className="w-full pl-5">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"DL State"}
                                value={params?.Main?.DLState}
                                onChange={(e) => handleValueChange('DL State', e.target.value)}
                            />
                        </div>
                    </div>
                    {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && 
                        <div className="grid grid-cols-2 mt-10 gap-y-5">
                            <div className="w-full pr-5">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    margin='none'
                                    label={"Plate #"}
                                    value={params?.Main?.PlateNo}
                                    onChange={(e) => handleValueChange('PlateNo', e.target.value)}
                                />
                            </div>
                            <div className="w-full pl-5">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    margin='none'
                                    label={"Plate State"}
                                    value={params?.Main?.PlateSt}
                                    onChange={(e) => handleValueChange('PlateSt', e.target.value)}
                                />
                            </div>
                        </div>
                    }
                    <div className="grid grid-cols-2 mt-10 gap-y-5">
                        <div className="w-full pr-5">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"FBI #"}
                                value={params?.Main?.FBI}
                                onChange={(e) => handleValueChange('FBI', e.target.value)}
                            />
                        </div>
                        <div className="w-full pl-5">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"SSN"}
                                value={params?.Main?.SSN}
                                onChange={(e) => handleValueChange('SSN', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 mt-10 gap-y-5">
                        <div className="w-full pr-5">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={`${GetStringNameForSite(SiteName,'SBI #')}`}
                                value={params?.Main?.SBINo}
                                onChange={(e) => handleValueChange('SBI No', e.target.value)}
                            />
                        </div>
                        <div className="w-full pl-5">
                            <TextField
                                //aria-readonly="true"
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"SBI State"}
                                value={params?.Main?.SBIState}
                                onChange={(e) => handleValueChange('SBI State', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 mt-10 gap-y-5">
                        <div className="w-full pr-5">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"StreetNo"}
                                value={params?.Main?.STREETNo}
                                onChange={(e) => handleValueChange('Street No', e.target.value)}
                            />
                        </div>
                        <div className="w-full pl-5">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"Street Name"}
                                value={params?.Main?.STREETNAME}
                                onChange={(e) => handleValueChange('STreet Name', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 mt-10 gap-y-5">
                        <div className="w-full pr-5">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"City"}
                                value={params?.Main?.CITY}
                                onChange={(e) => handleValueChange('City', e.target.value)}
                            />
                        </div>
                        <div className="w-full pl-5">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"County"}
                                value={params?.Main?.COUNTY}
                                onChange={(e) => handleValueChange('County', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 mt-10 gap-y-5">
                        <div className="w-full pr-5">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"State"}
                                value={params?.Main?.STATE}
                                onChange={(e) => handleValueChange('State', e.target.value)}
                            />
                        </div>
                        <div className="w-full pl-5">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                margin='none'
                                label={"Zip"}
                                value={params?.Main?.ZIP}
                                onChange={(e) => handleValueChange('Zip', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 mt-10">
                        <div className="w-full">
                            <TextField
                                size="small"
                                multiline minRows={2}
                                InputLabelProps={{'shrink': true}}
                                margin='none'
                                className="w-full"
                                label="Notes"
                                value={params?.Main?.Notes}
                                onChange={(e) => handleValueChange('Notes', e.target.value)}
                                error={params?.Main?.Notes && params?.Main?.Notes?.length > 0 ? false : true}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 mt-10">
                        <div className="w-full">
                            <TextField
                                disabled={true}
                                size="small"
                                multiline minRows={4}
                                InputLabelProps={{'shrink': true}}
                                margin='none'
                                className="w-full"
                                label="Previous Notes"
                                value={params?.Main?.PreviousNotes}
                            />
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card sx={{maxWidth: 900, minWidth: 900}} className="mx-auto mt-5">
                <CardContent>
                    <span className="text-blue-400 font-bold text-xl ">Person Attachments</span>
                    <br/>
                    <br/>
                    <label className=" border-2 border-gray-300 py-2 px-2 text-xl rounded-xl text-gray-400"
                           htmlFor="icon">
                        <input
                            id="icon"
                            hidden
                            className="input"
                            multiple
                            onChange={e => {
                                handleAdditionalFiles(e.target)
                            }}
                            type="file"
                            accept=".pdf, .txt, .doc, .docx, .png, .jpg"
                            value=''
                        />
                        Attach Files to this Person
                        <IconButton color="primary" component="span">
                            <AttachFileIcon/>
                        </IconButton>
                    </label>
                    {existingFiles && existingFiles.filter(res => res.Deleted === false).length > 0
                        ? <div className={"font-bold mb-3"}>
                            Files Attached
                        </div>
                        : <></>
                    }
                    {existingFiles ?
                        existingFiles.filter(res => res.Deleted === false).map((file: any, indx: number) => {
                            return (
                                <span className={"border-2 rounded-lg border-green-500 px-1 mx-1 my-1"}>
                                    <span>
                                        <Tooltip title="Download File" >
                                            <IconButton style={{ margin: 0, padding: 0 }} onClick={() => downloadFile(file, indx)}><DownloadIcon /></IconButton>
                                        </Tooltip>
                                    </span>
                                    <span className={"font-bold text-green-500 mr-1"} onClick={() => downloadFile(file, indx)}>
                                        {file.FileName}
                                    </span>
                                    <span>
                                        <Tooltip title="Delete File" >
                                            <IconButton color="error" style={{ margin: 0, padding: 0 }} onClick={() => removeFile(file, indx)}><DeleteIcon /></IconButton>
                                        </Tooltip>
                                    </span>
                                </span>
                            );
                        }) : <></>}

                    {existingFiles && existingFiles.filter(res => res.Deleted === true).length > 0
                        ? <div className={"font-bold mb-3"}>
                            Files Pending For Deletion
                        </div>
                        : <></>
                    }
                    {existingFiles ?
                        existingFiles.filter(res => res.Deleted === true).map((file: any, indx: number) => {
                            return (
                                <span className={"border-2 rounded-lg border-yellow-500 px-1 mx-1 my-1"}>
                                    <span>
                                        <Tooltip title="Undo Deleting" >
                                            <IconButton style={{ margin: 0, padding: 0 }} onClick={() => reinstateFile(file, indx)}><UndoIcon /></IconButton>
                                        </Tooltip>
                                    </span>
                                    <span className={"font-bold text-yellow-500 mr-1"} onClick={() => reinstateFile(file, indx)}>
                                        {file.FileName}
                                    </span>
                                </span>
                            );
                        }) : <></>}
                    {addedFiles && addedFiles.length > 0
                        ? <div className={"font-bold mb-3"}>
                            Files Pending for Upload
                        </div>
                        : <></>
                    }
                    {addedFiles ?
                        addedFiles.map((file: any, indx: number) => {
                            return (
                                <span className={"border-2 rounded-lg border-blue-400 px-1 mx-1 my-1"}>
                                    <span>
                                        <Tooltip title="Download File" >
                                            <IconButton style={{ margin: 0, padding: 0 }} onClick={() => downloadFile(file, indx)}><DownloadIcon /></IconButton>
                                        </Tooltip>
                                    </span>
                                    <span className={"font-bold text-blue-400 mr-1"} onClick={() => downloadFile(file, indx)}>
                                        {file.FileName}
                                    </span>
                                    <span>
                                        <Tooltip title="Delete File" >
                                            <IconButton color="error" style={{ margin: 0, padding: 0 }} onClick={() => removeFile(file, indx)}><DeleteIcon /></IconButton>
                                        </Tooltip>
                                    </span>
                                </span>
                            );
                        }) : <></>}
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" >
                <CardContent>
                    <Snackbar open={alertOpen} autoHideDuration={7000} onClose={() => { setAlertOpen(false) }}>
                        <Alert onClose={() => { setAlertOpen(false) }} severity={'error'} sx={{ width: '100%' }}>
                            {alertText}
                        </Alert>
                    </Snackbar>
                    <div className={"mr-4 mb-4"} style={{ "display": "flex", "justifyContent": "end" }}>
                        <ButtonGroup size="large" color="inherit" >
                            <Button type="submit" onClick={handleUpdatePersonMainSave}>Save</Button>
                            <Button onClick={handleUpdatePersonMainCancel}>Cancel</Button>
                        </ButtonGroup>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

export default UpdatePersonMainRecord;