import React, {useState} from "react";
import ReactECharts from "echarts-for-react";
import {SiteName} from "../../../services/config.service";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";

interface ShootingsDashboardGridProps {
    location:string,
    dbData:any,
}


const CombinedDashboardGraph: React.FC<ShootingsDashboardGridProps> = ({location, dbData}) => {
    var option;

    const handleXAxis = () => {
        let xAxis:any = new Set()
        if(dbData?.ShootingData)
        {
            for(let x of dbData?.ShootingData)
            {
                let date = new Date(x.Date)
                let fullDate = (date.getMonth() + 1).toString() + '/' + date.getDate().toString() + '/' + date.getFullYear().toString()
                xAxis.add(fullDate)
            }
            
        }
        
        return Array.from(xAxis)
    }
    
    const handleSeries = () => {
        let series: any = []
        let shooting = []
        let hit = []
        let murder = []
        let XAxis:any = handleXAxis()
        
        for (let i = 0; i < XAxis.length; i++)
        {
            shooting.push(0)
            hit.push(0)
            murder.push(0)
        }
        
        if(dbData?.ShootingData)
        {
            for(let x of dbData?.ShootingData)
            {
                let date = new Date(x.Date)
                let fullDate = (date.getMonth() + 1).toString() + '/' + date.getDate().toString() + '/' + date.getFullYear().toString()
                switch(x.Type)
                {
                    case 'Shootings':
                        let shoot = XAxis.indexOf(fullDate)
                        shooting[shoot] = (x.Count)
                        break;
                    case 'Hits':
                    case 'Contacts':
                        let idx = XAxis.indexOf(fullDate)
                        hit[idx] = (x.Count)
                        break;
                    case 'Murders':
                        let pos = XAxis.indexOf(fullDate)
                        murder[pos] = (x.Count)
                        break;
                }
            }
            series.push({
                name: 'Shooting',
                type: 'line',
                data: shooting
            },)
            series.push({
                name: (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ? 'Shooting Contact' : 'Shooting Hit',
                type: 'line',
                color: '#FEBE00',
                data: hit
            },)
            series.push({
                name: 'Murder',
                type: 'line',
                color: '#8E1600',
                data: murder
            },)
        }
        return series
    }
    
    option = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Shooting', (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ? 'Shooting Contact' : 'Shooting Hit', 'Murder'],
            left: '20%'
        },
        height:'200',
        grid: {
            left: '5%',
            right: '10%',
            bottom: '3%',
            top: '10%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: handleXAxis()
        },
        yAxis: {
            type: 'value',
        },
        series: handleSeries()
    };

    return (
        <div className = "border-gray-900 border-2 h-72">
            <div className="bg-blue-900 font-bold text-yellow-400 text-center h-8 text-lg">Shooting/{(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ? 'Contacts' : 'Hits'}/Murders</div>
            {dbData && dbData.length !== 0 ?
                <div className = "border-gray-900 border-t-2">
                    <ReactECharts option={option} />
                </div>
                :
                <div>No Data</div>
            }
        </div>
    );
}

export default CombinedDashboardGraph;